import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerMemberManagementDashboardType } from "../../looker-member-management-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-enrollment-oversight-dashboard",
  templateUrl: "./enrollment-oversight-dashboard.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrollmentOversightDashboardComponent {
filters: string[] = ["Projects"];
dashboardType = LookerMemberManagementDashboardType.Enrollment_Oversight_Dashboard;
hideFilters: string[] = ["PROJECT_ID", "USER_ID", "ORG_ID", HideFilters.CUSTOMER_NAME2];
}
