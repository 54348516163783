<app-server-grid *ngIf="approvalCenterGridRequest" [configuration]="approvalCenterGridConfiguration"
                 [(request)]="approvalCenterGridRequest"
                 [refresh]="refreshGrid"
                 [(selection)]="approvalCenterGridSelection"
                 [actions]="actions"
                 [filterTemplate]="filterForm"
                 (dataLoaded)="gridDataLoaded($event)"
                 [actionTemplate]="approvalCenterActionTemplate"
                 [views]="views"
                 [refreshViews]="refreshViews">

  <ng-template #approvalCenterActionTemplate
               let-rowData let-localActionPopover="actionPopover">
    <div class="action-template-container">
      <app-button *ngFor="let action of actions; trackBy: trackByIndex"
                  [text]="action.name"
                  (onClick)="action.action(rowData); localActionPopover.hide();"></app-button>
    </div>
  </ng-template>

  <ng-template #filterForm
               let-form>
    <app-tab-menu orientation="left" (activeIndexChange)="onActiveIndexChange($event)">

      <app-tab-panel header="Request ID"
                     *ngIf="showRequestIdFilter">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form"
                        [model]="requestIdInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel header="Chase">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form"
                        [model]="chaseIdInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel header="Project(s)">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form"
                        [model]="projectsInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel header="Measure(s)">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form"
                        [model]="measuresInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel header="Original AID"
                     *ngIf="showOriginalAIDFilter">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form"
                        [model]="originalAIDInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel header="Destination AID"
                     *ngIf="showDestinationAIDFilter">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form"
                        [model]="destinationAIDInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel header="Requested By" *ngIf="showRequestedByFilter">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form"
                        [model]="requestedByInput"></form-factory>
        </div>
      </app-tab-panel>
      <app-tab-panel header="Pursuit Requested By" *ngIf="showPursuitRequestedByFilter">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form"
                        [model]="pursuitRequestedByInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel header="Status(s)">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form"
                        [model]="statusInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel header="Pursuit Type" *ngIf="showPursuitTypeFilter">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form"
                        [model]="pursuitTypeInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel class="checkbox-group__fourths" header="Chase Compliance">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="complianceInput"></form-factory>
        </div>
      </app-tab-panel>

      <app-tab-panel class="checkbox-group__fourths" header="Sample Compliance">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="form" [model]="sampleComplianceInput"></form-factory>
        </div>
      </app-tab-panel>

    </app-tab-menu>

  </ng-template>
</app-server-grid>

<app-modal [allowClose]="isCancel" class="approve" [(visible)]="isMoveChase"
           header="APPROVE">
  <div  class="approve-label">{{getHeader}}</div>
  <div class="approve-label">
    <form-factory [formGroup]="chaseMoveForm"
                  [model]="chaseMoveInput"></form-factory>
  </div>
  <footer>
    <app-button classes="secondary confirm"  text="No" (onClick)="onCancel()"></app-button>
    <app-button classes="secondary confirm" text="Yes" (onClick)="chaseMoved()"></app-button>
  </footer>
</app-modal>
