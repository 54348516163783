import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { List } from "immutable";
import { finalize } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../../auth/auth.service";
import { UserToken } from "../../../../auth/user-token.model";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { LocalService } from "../../../../core/storage/local.service";
import { SessionService } from "../../../../core/storage/session.service";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ChaseGridComponent } from "../../../../shared/chase-grid/chase-grid.component";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { ListItem } from "../../../../shared/list/list-item";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { DASHBOARD_MY_CHASES_GRID } from "../../member/chase-detail/chase-detail-chart/attributes";
import { OverreadFeedbackQuerySearch } from "../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-query-search.model";
import { OverreadFeedbackStatus } from "../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-status.enum";
import { OverreadFeedbackService } from "../../member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback.service";
import { DirectoryUserRole } from "../../retrieval/directory-user-role";
import { ClinicalService } from "./clinical.service";

@Component({
  selector: "app-clinical",
  templateUrl: "./clinical.component.html",
  styleUrls: ["./clinical.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalComponent implements OnInit, OnDestroy {
  @ViewChild(ChaseGridComponent, { static: true }) chaseGridComponent: ChaseGridComponent;
  private sink = new SubSink();
  private user: UserToken;
  headerStatistics = List<ListItem>();
  isRoleModalVisible = false;
  isRequestAdditionalWorkEnabled = true;
  viewAttributeId = DASHBOARD_MY_CHASES_GRID.attributeId;
  stateName = DASHBOARD_MY_CHASES_GRID.attributeCode;
  additionalFilters: GridFilter[];
  isOverreadFeedbackEnabled = false;
  overreadFeedbackCount = 0;
  clinicalFormGroup: FormGroup;
  roleInput: Dropdown;
  isClinicalGetNextEnabled = false;
  isOverreadFeedbackEmpty = true;
  private selectedStat = "";

  private pFunctionalRoleIds: number[] = [];
  get functionalRoleIds(): number[] {
    if (!ArrayHelper.isAvailable(this.pFunctionalRoleIds)) {
      this.pFunctionalRoleIds = this.authService.user.functionalRoleIds
        .filter(item => item === 10 || item === 11 || item === 12)
        .filter((id, i, ids) => ids.indexOf(id) === i);
    }

    return this.pFunctionalRoleIds;
  }

  get hasSingleRole(): boolean {
    return this.functionalRoleIds.length === 1 && this.isClinicalGetNextEnabled;
  }

  get hasMultipleRoles(): boolean {
    return this.functionalRoleIds.length > 1 && this.isClinicalGetNextEnabled;
  }

  get isManagerOrAdminRole(): boolean {
    const roles = [
      DirectoryUserRole.DocumentIntakeManager,
      DirectoryUserRole.DocumentQAManager,
      DirectoryUserRole.CallCenterManager,
      DirectoryUserRole.FieldTechManager,
      DirectoryUserRole.EMRManager,
      DirectoryUserRole.ThirdPartyManager,
      DirectoryUserRole.MRRManager,
      DirectoryUserRole.OverreadManager,
      DirectoryUserRole.ClientOverreadManager,
      DirectoryUserRole.AuditManager,
      DirectoryUserRole.ProjectManager,
      DirectoryUserRole.ReportingManager,
      DirectoryUserRole.SpecialHandlingManager,
      DirectoryUserRole.Admin,
    ];
    return this.authService.user.directoryRoleIds.some(roleId => roles.includes(roleId));
  }

  private pRoleList: SelectableInput[] = [];
  get roleList(): SelectableInput[] {
    if (!ArrayHelper.isAvailable(this.pRoleList)) {
      this.pRoleList = this.functionalRoleIds
        .map(id => {
          switch (id) {
            case 10:
              return new SelectableInput({ text: "MRR", value: 10 });
            case 11:
              return new SelectableInput({ text: "OR1", value: 11 });
            case 12:
              return new SelectableInput({ text: "OR2", value: 12 });
            default:
              throw new Error(`The id '${id}' should not be here.`);
          }
        });
    }
    return this.pRoleList;
  }


  constructor(
    private userService: UserService,
    private readonly clinicalService: ClinicalService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly router: Router,
    private readonly authService: AuthService,
    private overreadFeedbackService: OverreadFeedbackService,
    private formService: FormService,
    private readonly messagingService: MessagingService,
    private readonly localService: LocalService,
    private readonly sessionService: SessionService
  ) {
    this.additionalFilters = [
      new GridFilter({
        input: new Textbox(),
        key: "FunctionalRoleIds",
        value: this.functionalRoleIds.join(","),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "AssignedToUserId",
        value: this.authService.user.userId.toString(),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "UseTransactionalDatabaseAsString",
        value: "true",
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "CompletedByUserId",
        value: null,
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "ChartAcquired",
        name: "Chart Acquired Date",
        value: null,
        show: true,
      }),
    ];
    this.createclinicalForm();
    this.rolelistSet();
  }

  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.getStatisticsData();
    this.checkClinicalGetNextEnabled();
    this.roleInput = new Dropdown({ ...this.roleInput, options: [...this.roleList] } as any);
    setTimeout(() => {
      // HACK: Must remove the actions for this page.
      this.chaseGridComponent.actions = [];
      this.chaseGridComponent.configuration.showActionColumn = false;
      this.chaseGridComponent.configuration.selectionMode = "";
      this.chaseGridComponent.serverGridComponent.configuration.showActionColumn = false;
      this.chaseGridComponent.serverGridComponent.configuration.selectionMode = "";
    });

    this.sink.add(
      this.overreadFeedbackService.getOverreadFeedbackList(new OverreadFeedbackQuerySearch())
        .subscribe(data => {
          if (ArrayHelper.isAvailable(data)) {
            const feedbackData = data.filter(x => x.status !== OverreadFeedbackStatus.MANAGER && ((
              x.abstractorUserId === this.user.userId && (
                x.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED ||
                x.status === OverreadFeedbackStatus.ABSTRACTOR)) || (
                x.overreaderUserId === this.user.userId &&
                x.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED))
            );
            this.isOverreadFeedbackEnabled = true;
            const inCompleteFeedback = feedbackData.filter(x => x.status !== OverreadFeedbackStatus.COMPLETED ||
      (x.status === OverreadFeedbackStatus.COMPLETED && (x.abstractorReceiptStatus === OverreadFeedbackStatus.REQUESTED && x.abstractorUserId === this.user.userId) ||
        (x.overreaderReceiptStatus === OverreadFeedbackStatus.REQUESTED && x.overreaderUserId === this.user.userId)));
            this.overreadFeedbackCount = inCompleteFeedback.length;
            this.setIsOverreadFeedbackEmpty();
            this.changeDetector.markForCheck();
          }
        })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  rolelistSet(): void {
    if (this.functionalRoleIds.length === 1) {
      this.clinicalFormGroup.get(this.roleInput.key).setValue(this.roleList[0].value);
    }
  }

  get hasSelectedRole(): boolean {
    return NumberHelper.isGreaterThan(this.selectedRole, 1);
  }

  getStatisticsData(): void {
    this.clinicalService
      .getStatisticsData()
      .subscribe(result => {
       this.headerStatistics = List(result);
       this.changeDetector.markForCheck();
      });
  }

  getNextChase(): void {
    if ((this.hasSelectedRole && this.isOverreadFeedbackEmpty) || this.isManagerOrAdminRole) {
      this.isRequestAdditionalWorkEnabled = false;
      this.clinicalService.getNextChaseId(this.selectedRole)
        .pipe(finalize(() => {
          this.isRequestAdditionalWorkEnabled = true;
          this.changeDetector.markForCheck();
        }))
        .subscribe(
          {
            next: (chaseId: number) => {
              this.sessionService.put("chase_prev_url", this.router.url);
              if (NumberHelper.isGreaterThan(chaseId, 0)) {
                this.router.navigate(["members", "chase", chaseId]);
              }
            },
          }
        );
    } else {
      this.messagingService.showToast("Please review your Overread Feedback");
    }
  }

  getHeaderItemClass(itemKey: string): string {
    return itemKey === this.getCurrentFilterName() ? "selected-header-item" : "";
  }

  applyFilter(filterName: string): void {
    this.setCurrentFilterName(filterName);
    this.selectedStat = filterName;

    if (filterName === "Completed Today") {
      this.router.navigateByUrl("/dashboard/completed");
    } else {
      this.chaseGridComponent.setValue("AssignedToUserId", this.authService.user.userId.toString());
      this.chaseGridComponent.setValue("CompletedByUserId", null);
      this.setIsOverreadFeedbackEmpty();
    }
    this.chaseGridComponent.request.startRecord = 1;
    this.chaseGridComponent.request.endRecord = 25;
    this.chaseGridComponent.refreshGrid.emit();
  }

  trackByIndex(index, item) {
    return index;
  }

  getClassForColumn(): string {
    return (this.stateName === DASHBOARD_MY_CHASES_GRID.attributeCode) ? "dashboard-chase-grid" : "";
  }

  createclinicalForm(): void {
    this.roleInput =
      new Dropdown({
        key: "roles",
        label: "Role",
        placeholder: "Select Role",
        appendTo: "body",
      });
    this.clinicalFormGroup = this.formService.createFormGroup([this.roleInput]);
  }

  get selectedRole(): number {
    return Number(this.clinicalFormGroup.get(this.roleInput.key).value);
  }

  checkClinicalGetNextEnabled(): void {
    this.clinicalService
      .getNextEnabled()
      .subscribe(result => {
        this.isClinicalGetNextEnabled = result;
        this.changeDetector.markForCheck();
      });
  }

  getNextChaseForMultipleRoles(): void {
    if (this.isOverreadFeedbackEmpty || this.isManagerOrAdminRole) {
      this.isRoleModalVisible = true;
      this.changeDetector.markForCheck();
    } else {
      this.messagingService.showToast("Please review your Overread Feedback");
    }
  }

  setIsOverreadFeedbackEmpty(): void {
    this.isOverreadFeedbackEmpty = this.overreadFeedbackCount === 0 || this.getCurrentFilterName() === "Completed";
  }

  getCurrentFilterName(): string {
    return this.localService.get(`FILTER_${this.stateName}`, null);
  }

  setCurrentFilterName(filterName: string): void {
    this.localService.put(`FILTER_${this.stateName}`, filterName);
  }

  closeRoleModal(): void {
    this.isRequestAdditionalWorkEnabled = true;
    this.changeDetector.markForCheck();
  }

  getStatisticsClass(item: ListItem): string {
    return item.key === this.selectedStat ? "active" : "";
  }
}
