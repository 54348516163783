export class DocumentPageDocumentHighlights {
    chaseDocumentId: string;
    documentPageIds: string[];
    checkChaseInDynamoDb: boolean;
    isChaseInDynamoDb: boolean;

    constructor(options: Partial<DocumentPageDocumentHighlights> = {}) {
        this.chaseDocumentId = options.chaseDocumentId;
        this.documentPageIds = options.documentPageIds;
        this.checkChaseInDynamoDb = options.checkChaseInDynamoDb || false;
        this.isChaseInDynamoDb = options.isChaseInDynamoDb !== undefined ? options.isChaseInDynamoDb : null;
    }
}
