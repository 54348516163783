import { Directive, EventEmitter, Input, OnInit, TemplateRef } from "@angular/core";
import { Table } from "primeng/table";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { BulkAction } from "../bulk-actions/bulk-action.model";
import { GridService } from "../grid.service";
import { GridConfiguration } from "../models/grid-configuration.model";
import { GridRequest } from "../models/grid-request.model";
import { GridView } from "./grid-views/grid-view.model";
import { GridViewsState } from "./grid-views/grid-views-state.model";

@Directive()
export abstract class GridMenuDirective implements OnInit {
  @Input() configuration: GridConfiguration = {} as any;
  @Input() views: GridViewsState = {} as any;
  @Input() onViewSelect: EventEmitter<GridView>;
  @Input() refreshViews: EventEmitter<GridView>;
  @Input() filterTemplate: TemplateRef<any>;
  @Input() selectedItems: any[];
  @Input() primeGrid: Table;
  @Input() actions: BulkAction[] = [];
  @Input() isMemberFile = false;
  @Input() request: GridRequest;
  @Input() totalRecords: number;

  options: SelectableInput[];
  isFiltersVisible = false;
  isClearFilterButtonVisible = true;
  selection = "";


  constructor(private service: GridService) { }

  ngOnInit() {
    this.options = this.service.getSortOptions(this.configuration.columns);
    if (this.hasSortOptions) {
      this.selection = this.options[0].value as string;
    }
  }


  get hasData(): boolean {
    return ArrayHelper.isAvailable(this.primeGrid.value);
  }

  get hasExport(): boolean {
    return this.configuration.showExportAction;
  }

  get hasFilterTemplate(): boolean {
    return this.filterTemplate != null;
  }

  get hasSortOptions(): boolean {
    return ArrayHelper.isAvailable(this.options);
  }

  get hasSelectedItems(): boolean {
    return ArrayHelper.isAvailable(this.selectedItems);
  }

  get disableExport(): boolean {
    return this.configuration.disableExportButton;
  }

  get exportButtonText(): string {
    return this.hasSelectedItems ? `Export (${(this.request?.isAllSelected && this.configuration?.selectAllPages) ? this.totalRecords : this.selectedItems.length})` : "Export All";
  }

  get enableFilter(): boolean {
    return this.hasFilterTemplate;
  }

  get showViews(): boolean {
    return this.configuration.showViews;
  }

  get viewStyle(): string {
    return this.isMemberFile ? "grid__menu__item grid__menu__item--member-views" : "grid__menu__item grid__menu__item--views";
  }

  protected get sortOption(): SelectableInput {
    return this.options.find(option => option.value === this.selection);
  }

  protected get sortField(): string {
    return this.sortOption.extra.field;
  }

  protected get isAscending(): number {
    return this.sortOption.extra.isAscendingMultiplier;
  }

  abstract sortGrid(): void;

  abstract filterGrid(): void;

  abstract export(): void;

  showFilters(): void {
    this.isFiltersVisible = true;
  }

  closeFilters(): void {
    setTimeout(() => {
      this.isFiltersVisible = false;
      this.filterGrid();
    });
  }

  trackByIndex(index, item) {
    return index;
  }
}
