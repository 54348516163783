import { Injectable } from "@angular/core";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { Attribute } from "../platform/modules/member/chase-detail/chase-detail-chart/attribute.model";
import { DateHelper } from "../utilities/contracts/date-helper";
import { StringHelper } from "../utilities/contracts/string-helper";
import { DynamicControl } from "./dynamic-control.model";
import { DynamicFormHelper } from "./dynamic-form-helper";
import { DynamicArray } from "./form-arrays/dynamic-array.model";

@Injectable({
  providedIn: "root",
})
export class FormService {
  updateDom = new Subject();

  createFormGroup(controls: DynamicControl[]): FormGroup {
    const formControls = controls.reduce(DynamicFormHelper.createControls, {});
    const form = new FormGroup(formControls);

    return form;
  }

  isDisabled(model: DynamicControl): boolean {
    const disabled = model.disabled;
    return disabled;
  }

  push(formArray: FormArray, model: DynamicArray): void {
    if (this.isDisabled(model)) {
      return;
    }

    model.push();
    const lastIndex = model.models.length - 1;
    formArray.push(model.models[lastIndex].create());
  }

  remove(index: number, formArray: FormArray, model: DynamicArray): void {
    if (this.isDisabled(model.models[index])) {
      return;
    }

    model.remove(index);
    formArray.removeAt(index);
  }

  getApiObject(control: AbstractControl): Attribute {
    const saveInfo = {
      ...(control as any).saveInfo,
      value: control.value,
    };
    const value = this.transform(saveInfo);
    saveInfo.value = value;
    return saveInfo;
  }

  transform(saveInfo: Attribute): string | number {
    let value = saveInfo.value;
    if (saveInfo.dataType === "date") {
      value = DateHelper.format(value as string);
    } else if (saveInfo.dataType === "icdcode") {
      value = StringHelper.clean(value as string).toUpperCase();
    }

    return value;
  }

  isKeyDownEnter(event: KeyboardEvent): boolean {
       return  (event && event.type === "keydown" && event.key === "Enter");
  }

  markAllAsTouched(form: FormGroup): void {
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    });
    this.updateDom.next();
  }
}
