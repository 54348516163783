<div class="page-entry">
  <div class="page-entry-header bold">
    PLEASE SELECT PAGES TO INCLUDE IN AUDIT PACKAGE
  </div>
  <div class="page-entry-inputs">
    <div>
      <div *ngIf="pageEntryErrors"
           class="ellipsis">Page Entries missing or incorrect.</div>
      <div class="audit-page-entry__page-entry-label bold">
          <label>Page Entries:</label>
          <div class="button-container button-container__add" (click)="addPageRange()" title='Add a new Page Entry'>+</div>
      </div>
      <div class="page-item"
           *ngFor="let item of pageEntryControls; let i=index; trackBy: trackByIndex"
           [formGroup]="item">
          <div *ngIf="hasPageEntryErrors(i)"class="ellipsis">{{ pageEntryItemErrors(i) }}</div>
          <div class="page-input-holder">
              Pages:
              <input type="text"
                     size="10"
                     pInputText
                     formControlName="StartPage"
                     placeholder="Start Page"
                     (blur)="setStartPageSelectionErrors(i)">
              <input type="text"
                     size="10"
                     pInputText
                     formControlName="EndPage"
                     placeholder="End Page"
                     (blur)="setEndPageSelectionErrors(i)">
      
              <div class="delete-container" title="Delete">
                  <div class="button-container button-container__delete" (click)="pagesListForm.get('page').removeAt(i)">×</div>
              </div>
          </div>
      </div>
      <div>
        <p-checkbox label="Include Header and Data Entry Screen"
                    [(ngModel)]="includeDataEntryPrintScreen"
                    binary="true">
        </p-checkbox>
      </div>
    </div>
  </div>
  <div class="page-entry-footer">
    <div *ngIf="hasNoChartPagesError"
         class="ellipsis">Selection requires a chase with pages</div>
    <app-button class="header-button"
                text="SAVE SELECTION"
                (onClick)="onSaveSelection()"
                [disabled]="disableSaveSelection">

    </app-button>
  </div>
</div>
