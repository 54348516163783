import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { InputSwitchModule } from "primeng/inputswitch";
import { ToolTipModule } from "../../../shared/tool-tip/tool-tip.module";
import { SwitchGroupComponent } from "./switch-group.component";

@NgModule({
  imports: [
    CommonModule,
    InputSwitchModule,
    ReactiveFormsModule,
    ToolTipModule,
  ],
  declarations: [
    SwitchGroupComponent,
  ],
  entryComponents: [
    SwitchGroupComponent,
  ],
  exports: [
    SwitchGroupComponent,
  ],
})
export class SwitchGroupModule { }
