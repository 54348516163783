<div
  [formGroup]="formGroup"
  class="control control--group"
  [ngClass]="showHighlighterClass ? highlighter.className() : ''"
  (keydown.enter)="remove($event)"
  [hidden]="hidden">
  <div *ngIf="model.hasHeader" class="control__header control__header--save-group">
    <div class="control__header__label">{{ model.header }}</div>
    <div *ngIf="hasError" class="control__header__error" [title]="error">{{ error }}</div>
  </div>
  <form-factory
    *ngFor="let model of model.controls; trackBy: trackByIndex"
    [formGroup]="control"
    [model]="model"
    (onChange)="handleChangeEvents($event)"
    (onFocus)="highlighter.onFocus()"
    (onBlur)="highlighter.onBlur()"></form-factory>
  <button
    class="control__delete"
    title="Clear"
    *ngIf="!model.isAdmin && !model.isHideClearButton"
    [disabled]="model.disabled"
    tabindex="-1"
    (click)="remove($event)"
    >
    -
  </button>

</div>