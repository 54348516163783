<app-server-grid [configuration]="configuration"
                 [(request)]="request"
                 [views]="views"
                 [refreshViews]="refreshViews"
                 [(selection)]="selection"
                 [actions]="actions"
                 [isLoadOnInit]="false"
                 [refresh]="refreshGrid"
                 [filterTemplate]="chaseFilterTemplate"
                 [actionTemplate]="chaseActionTemplate"
                 [filterHeaderText]="filterHeaderText"
                 [isMemberFile]="showNextFilters"
                 exportDataFromUrl=true
                 (isSavedQueryDeleted)="deletedSavedQuery($event)"
                 (isSavedQueryUpdated)="updatedSavedQuery($event)">

  <ng-template #chaseActionTemplate let-rowData let-localActionPopover="actionPopover">
    <div class="action-template-container">
      <app-button *ngFor="let action of threeDotActions; trackBy: trackByIndex"
                  [text]="action.name"
                  (onClick)="action.action(rowData); localActionPopover.hide();"
                  [disabled]="isActionDisabled(rowData, action.name)"></app-button>
    </div>
  </ng-template>

  <ng-template #chaseFilterTemplate let-form>
    <form [formGroup]="form" class="ui-modal-width" #chaseForm="ngForm">
      <app-tab-menu orientation="left" (activeIndexChange)="onActiveIndexChange($event)">

        <app-tab-panel header="Address">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="addressIdInput"></form-factory>
            <form-factory [formGroup]="form" [model]="addressGroupIdInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel *ngIf="assignedToFilter.show" header="Assigned To">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="assignedToInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel class="checkbox-group__fourths" header="Chase Compliance">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="complianceInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Chase ID / Client Chase Key">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="chaseIdInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel *ngIf="chartAcquiredFilter.show" header="Chart Acquired Date">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="chartAcquiredInput" (onChange)="formatChartAcquiredDate()"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Client">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="clientInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Date Assigned">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="dateAssignedInput" (onChange)="formatDateAssigned()">
            </form-factory>
          </div>
        </app-tab-panel>
        <app-tab-panel header="DVA Event Loaded">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="dvaEventLoadedInput"> </form-factory>
          </div>
        </app-tab-panel> 
        <app-tab-panel header="Eve Hits">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="eveHitsInput"> </form-factory>
          </div>
        </app-tab-panel> 
        <app-tab-panel class="checkbox-group__halves" header="Health Plans">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="plansInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel *ngIf="lastCoderFilter.show" header="Last Coded By">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="lastCoderInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel class="checkbox-group__fourths" header="Measures">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="measuresInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Member">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="memberFirstNameInput"></form-factory>
            <form-factory [formGroup]="form" [model]="memberLastNameInput"></form-factory>
            <form-factory [formGroup]="form" [model]="memberDobInput" (onChange)="formatMemberDobDate()"></form-factory>
            <form-factory [formGroup]="form" [model]="memberKeyInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel class="checkbox-group" header="Pend Codes">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendCodesInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel class="checkbox-group__halves" header="Pend Status">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pendStatusInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Products">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="lobInput"></form-factory>           
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12 product__dropdown" [ngClass]="{'selected' : productSelected}" >
            <form-factory [formGroup]="form" (onChange)="productSelection()" [model]="productsInput"></form-factory>     
          </div>    
        </app-tab-panel>

        <app-tab-panel class="checkbox-group__halves" header="Projects" selected="true">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="projectsInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Providers">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="providersInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel header="Retriever" *ngIf="showCoRetrievalOwnerFilter">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="coRetrievalOwnerInput"> </form-factory>
          </div>
        </app-tab-panel> 

        <app-tab-panel header="Risk" *ngIf="showRiskFilter">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="encounterFoundInput"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="hccDiscrepencyInput"></form-factory>
          </div>
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="pursuitInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel class="checkbox-group__fourths" header="Sample Compliance">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="sampleComplianceInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel class="checkbox-group__halves" header="Status">
          <div class="ui-g-12 ui-md-12 ui-lg-12">
            <form-factory [formGroup]="form" [model]="statusesInput"></form-factory>
          </div>
        </app-tab-panel>

        <app-tab-panel *ngIf="tagsInputFilter.show" header="Tags">
          <label class="tag-label">Tags</label>
          <div class="tag__filterform">
            <div class="ui-g-12 ui-md-12 ui-lg-12">
              <div class="ui-g-2 ui-md-2 ui-lg-2">
                <form-factory class="tag__operatorform"
                              [formGroup]="form"
                              [model]="tagsSearchOperatorInput"
                              (onChange)="getSearchOperatorValue($event)">
                </form-factory>
              </div>
              <div class="ui-g-10 ui-md-10 ui-lg-10">
                <app-button *ngIf="overlayPanelVisible && isShowCloseIcon"
                            class="tag-content__item tag-content__item--multitag-close-icon"
                            classes="transparent small"
                            icon="times-circle"
                            (onClick)="resetTagSearchInput()">
                </app-button>
                <form-factory class="tag__panelform"
                              [formGroup]="form"
                              [model]="tagsInput"
                              (onPanelShow)="onShowEvent(true)"
                              (onPanelHide)="onShowEvent(false)"
                              (keyup)="onKeyUp($event)">
                </form-factory>
              </div>
            </div>

          </div>
        </app-tab-panel>

      </app-tab-menu>
    </form>
  </ng-template>

  <ng-template #chaseIdColumn let-rowData>
    <app-chase-grid-chase-id [isOverreadFeedbackEmpty]="isOverreadFeedbackEmpty" [data]="rowData"
     [chaseIdUrl]="chaseIdRouteUrl"></app-chase-grid-chase-id>
  </ng-template>

  <ng-template #commentsColumn let-rowData>
    <app-grid-comments [entityId]="rowData.chaseId" entityType="chase" [isCommentPresent]="rowData.commentExists"></app-grid-comments>
  </ng-template>

</app-server-grid>

<app-pend-modal [(visible)]="isPendModalVisible"
                (onHide)="closeModal()"
                (onUpdate)="refreshGrid.emit()"
                [(chases)]="selectedChases"
                [clinical]="clinical"
                [pageType]="pageType"
                [hasClinicalNonClinicalChases]="hasClinicalNonClinicalChases"
                [selectedChasesStatus]="selectedChasesStatus">
</app-pend-modal>

<app-chase-assignment-modal [(visible)]="isAssignToChaseModalVisible"
                            (onHide)="closeModal()"
                            (onUpdate)="refreshGrid.emit()"
                            [(chases)]="selectedChaseIdsForAssignment"
                            [pageType]="pageType"
                            [isUnassignEnabled]="isUnassignEnabled">
</app-chase-assignment-modal>

<app-copy-chart-modal [(visible)]="isCopyChartModelVisible"
                      (onHide)="closeModal()"
                      (onUpdate)="refreshGrid.emit()"
                      [(chases)]="selectedChases">
</app-copy-chart-modal>

<app-manage-tag-modal [(visible)]="isManageTagModalVisible"
                      (onHide)="closeModal()"
                      [(selectedData)]="selectedChases"
                      [entityName]="entityName"
                      [totalEntityCount]="totalEntityCount"
                      [tagType]="tagType"
                      (onUpdate)="refreshGrid.emit()">
</app-manage-tag-modal>

<app-update-retriever-model [(visible)]="isRetrieverModalVisible" [updateRetrieverData]="retrieverUpdateModel"
(onHide)="closeModal()" [totalEntityCount]="totalEntityCount" (onUpdate)="refreshGrid.emit()">
</app-update-retriever-model>
