import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { FormService } from "../../form.service";
import { SwitchGroup } from "./switch-group.model";

@Component({
  selector: "form-switch-group",
  templateUrl: "./switch-group.component.html",
  styleUrls: ["./switch-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchGroupComponent extends DynamicControlDirective<SwitchGroup> implements OnInit, OnDestroy {
  private sink = new SubSink();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formService: FormService
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.control.statusChanges.subscribe(() => this.formService.updateDom.next()),
      this.formService.updateDom.subscribe(() => this.changeDetector.markForCheck())
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  get hasSubLabel(): boolean {
    return StringHelper.isAvailable(this.model.subLabel);
  }

  get hasTooltip(): boolean {
    return StringHelper.isAvailable(this.model.tooltip);
  }

  get hasPreceedingOffLabel(): boolean {
    return StringHelper.isAvailable(this.model.preceedingOffLabel);
  }

  get hasFollowingOnLabel(): boolean {
    return StringHelper.isAvailable(this.model.followingOnLabel);
  }

  get classes(): string {
    return this.getClasses("switch");
  }


}
