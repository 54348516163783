import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { combineLatest } from "rxjs";
import { SubSink } from "subsink";
import { DynamicFormEvent } from "../../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { ConfirmNoPageNumber } from "../../../../../../../dynamic-forms/inputs/confirm-no-page-number/confirm-no-page-number.model";
import { DynamicInput } from "../../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { TextboxType } from "../../../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../../../utilities/contracts/object-helper";
import { RegExHelper } from "../../../../../../../utilities/reg-Ex-Helper";
import { DynamicEntityAttribute } from "../../../../../../api/member-validation/dynamic-entity-attribute.model";
import { ChaseDetailState } from "../../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../../chase-detail-state.service";
import { CHART_PAGE_NUMBER, SERVICE_CODE } from "../../attributes";
import { RiskEntity } from "../../risk/risk-entity.model";
import { RiskHelper } from "../../risk/risk-helper.model";
import { RiskState } from "../../risk/risk-state.model";
import { RiskService } from "../../risk/risk.service";

@Component({
  selector: "member-rxcm-medical-procedure",
  templateUrl: "./medical-procedure.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalProcedureComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private chaseId: number;
  selectedDiagnosis: RiskEntity;
  hasSelectedDiagnosis: boolean;

  form: FormGroup;
  saveGroup: SaveGroup;
  private pageNumberInput: Textbox;
  private medicalProcedureInput: ConfirmNoPageNumber;

  constructor(
    private riskService: RiskService,
    private chaseDetailStateService: ChaseDetailStateService,
    private readonly formService: FormService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializeForm();

    this.sink.add(
      combineLatest([
        this.riskService.data,
        this.chaseDetailStateService.state,
      ]).subscribe(([riskState, chaseState]: any) => {
        this.chaseId = riskState.chaseId;
        this.hasSelectedDiagnosis = riskState.hasSelectedDiagnosisIndex;
        this.updateForm(riskState, chaseState);
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  private initializeForm(): void {
    // INPUTS
    this.pageNumberInput = new Textbox({
      key: "pageNumber",
      label: "Page Number",
      type: TextboxType.NUMBER,
      validators: [
        Validators.required,
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        required: "Enter a page number greater than 1",
        min: "Enter a page number greater than 1",
        pattern: "The page number must be an integer",
      },
    });

    this.medicalProcedureInput = new ConfirmNoPageNumber({
      key: "medicalProcedure",
      label: "Actual Value",
      type: TextboxType.TEXT,
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Service Code is required",
      },
    });

    // SAVE GROUPS
    this.saveGroup = new SaveGroup({
      key: "MedicalProcedure",
      header: "HCPCS Code",
      controls: [
        this.pageNumberInput,
        this.medicalProcedureInput,
      ],
    });
    this.setupSaveGroups({
      saveGroup: this.saveGroup,
      inputs: [this.pageNumberInput, this.medicalProcedureInput],
    });

    // CREATE FORM
    this.form = this.formService.createFormGroup([this.saveGroup]);
  }

  handleChange(event: DynamicFormEvent): void {
    switch (event.type) {
      case "save":
        this.saveAttributesByInputs(event.model.controls);
        break;
      case "clear":
      case "delete":
        if (!this.formService.isKeyDownEnter(event.originalEvent)) {
          this.deleteAttributesByInputs(event.model.controls);
        }
        break;
      default:
      case "compliance":
        break;
    }
  }

  private updateForm(state: RiskState, chaseState: ChaseDetailState): void {
    if (state.hasSelectedEncounterIndex && !ObjectHelper.isEmpty(state.selectedDiagnosis)) {
      this.selectedDiagnosis = state.selectedDiagnosis;
      const singleEntity = this.selectedDiagnosis;
      const chaseId = state.chaseId;
      const workflowStatusName = state.workflowStatusName.toLowerCase();
      const entityId = this.selectedDiagnosis.entityId;
      const entityTypeId = this.selectedDiagnosis.entityTypeId;
      const parentEntityId = this.selectedDiagnosis.parentEntityId;
      const enableByMemberValidation = state.isEnabled || chaseState.isEnabled;

      this.setupAttribute({
        chaseId,
        entityId,
        parentEntityId,
        entityTypeId,
        enableByMemberValidation,
        workflowStatusName,
        singleEntity,
        input: this.pageNumberInput,
        ATTRIBUTE_DATA: CHART_PAGE_NUMBER,
      });

      this.setupConfirmNoPageAttribute({
        chaseId,
        entityId,
        parentEntityId,
        entityTypeId,
        enableByMemberValidation,
        workflowStatusName,
        singleEntity,
        input: this.medicalProcedureInput,
        ATTRIBUTE_DATA: SERVICE_CODE,
      });
    }
  }

  private setupConfirmNoPageAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    entityId,
    parentEntityId,
    entityTypeId,
    enableByMemberValidation,
    workflowStatusName,
  }: any): void {
    const attributes = RiskHelper.getAttributes(ATTRIBUTE_DATA.attributeCode, singleEntity);
    const adminAttribute = attributes.find(a => a.isAdmin) || {} as any;
    const attribute = {
      ...attributes.find(a => !a.isAdmin),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId,
      entityTypeId,
      parentEntityId,
    };

    input.saveInfo = attribute;
    input.value = attribute.value;
    input.adminValue = adminAttribute.value;
    input.disabled = !enableByMemberValidation;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    enableByMemberValidation ? control.enable() : control.disable();
  }

  private setupAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    entityId,
    parentEntityId,
    entityTypeId,
    enableByMemberValidation,
    workflowStatusName,
  }: any): void {
    const attribute = {
      ...RiskHelper.getAttribute(ATTRIBUTE_DATA.attributeCode, singleEntity),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId,
      entityTypeId,
      parentEntityId,
    };

    input.saveInfo = attribute;
    input.value = attribute.value;
    input.disabled = !enableByMemberValidation || attribute.isAdmin;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    enableByMemberValidation ? control.enable() : control.disable();
  }

  private saveAttributesByInputs(controls: DynamicInput[]): void {
    const attributes = controls
      .filter(this.isAvailableAttribute.bind(this))
      .map(this.getSaveAttribute.bind(this)) as DynamicEntityAttribute[];
    this.saveAttributes(attributes);
  }

  private deleteAttributesByInputs(controls: DynamicInput[]): void {
    const allControlsDisabled = controls.every(a => a.disabled);
    if (!allControlsDisabled) {
      const attributesToDelete = controls
        .filter(this.isAvailableAttribute.bind(this))
        .map(this.getDeleteAttribute.bind(this)) as DynamicEntityAttribute[];
      this.saveAttributes(attributesToDelete);
    }
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]) {
    this.riskService
      .save(attributes)
      .subscribe(
        newAttributes => this.riskService.setDiagnosisAttributes(this.riskService.data.value.selectedDiagnosisIndex, newAttributes),
        (e: HttpErrorResponse) => this.form.setErrors({ saveError: e.error })
      );
  }

  private getDeleteAttribute(control: DynamicInput): DynamicEntityAttribute {
    const deleteAttribute = this.getSaveAttribute(control);
    if (!deleteAttribute.isAdmin) {
      deleteAttribute.value = "";
      deleteAttribute.pageNumber = null;
      deleteAttribute.validationId = null;
      deleteAttribute.validationNote = "";
    }
    return deleteAttribute;
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    saveAttribute.value = this.form.get(control.getMasterKey()).value;
    return saveAttribute;
  }

  private isAvailableAttribute(attribute: DynamicInput): boolean {
    return attribute != null
      && attribute.saveInfo != null
      && NumberHelper.isGreaterThan(attribute.saveInfo.attributeId, 0)
      && !attribute.disabled;
  }

  private setupSaveGroups({
    saveGroup,
    inputs,
  }: any): void {
    saveGroup.controls = inputs;
    inputs.forEach(a => a.parent = saveGroup);
  }

}
