import { DocumentPageAnnotations } from "./document-page-annotations.model";

export class DocumentPageUserHighlightUpdate {
  chaseDocumentId: string;
  documentPageid: string;
  userHighlight: DocumentPageAnnotations;

  constructor(options: DocumentPageUserHighlightUpdate) {
      this.chaseDocumentId = options.chaseDocumentId;
      this.documentPageid = options.documentPageid;
      this.userHighlight = options.userHighlight;
  }
}
