import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { BASE_API_URL } from "../../../../../../core/environment.tokens";
import { BulkAction } from "../../../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridPipeName } from "../../../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../shared/grid/models/grid-configuration.model";
import { GridRequest } from "../../../../../../shared/grid/models/grid-request.model";
import { PopoverComponent } from "../../../../../../shared/panel/popover/popover.component";
import { DateFormats } from "../../../../../../utilities/contracts/helper-types";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { BulkOutreachService } from "../bulk-outreach.service";

@Component({
  selector: "app-bulk-outreach-archive",
  templateUrl: "./bulk-outreach-archive.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkOutreachArchiveComponent implements OnInit {
  serverGridConfiguration = new GridConfiguration();
  serverRequest: GridRequest;
  actions: BulkAction[];

  constructor(
    private service: BulkOutreachService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string
  ) { }

  ngOnInit() {
    this.getGrid();
    this.getActions();
  }

  private getActions(): void {
    this.actions = [
      new BulkAction({
        name: "View",
        action: this.printProviderPacket.bind(this),
      }),
    ];
  }

  private printProviderPacket(rowData?: any): void {
    rowData.providerPacketsFiles.forEach((fileName, index) => {
      let filePath = "";
      setTimeout(() => {
        if (rowData.bulkOutreachType === "Fax") {
          filePath = this.parseXml(rowData.filePath, fileName);
        }
        this.service.downloadFile(fileName, filePath);
      },         index * 2000);
    });

  }

  private getGrid(): void {
    this.serverGridConfiguration.columns = [
      new GridColumnDefinition({ field: "bulkOutreachId ", header: "JOB ID" }),
      new GridColumnDefinition({ field: "projectCount", header: "PROJECTS" }),
      new GridColumnDefinition({ field: "addressCount ", header: "AIDs" }),
      new GridColumnDefinition({ field: "chaseCount", header: "CHASES" }),
      new GridColumnDefinition({ field: "bulkOutreachType", header: "TYPE" }),
      new GridColumnDefinition({ field: "senderName", header: "SENDER" }),
      new GridColumnDefinition({ field: "fromFaxNumber", header: "FROM" }),
      new GridColumnDefinition({ field: "completionDateTime", header: "REQUEST COMPLETED", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "filePathCount", header: "PACKETS GENERATED" }),
    ];
    this.serverGridConfiguration.stateName = "bulk_outreach_archive";
    this.serverGridConfiguration.pageSize = 10;
    this.serverGridConfiguration.showActionColumn = true;
    this.serverRequest = new GridRequest({
      url: `${this.baseApiUrl}bulkoutreach/request/archive`,
    });
  }

  isDisabledAction(rowData?: any): boolean {
    return !StringHelper.isAvailable(rowData.filePath);
  }

  onActionClick(action: BulkAction, localActionPopover: PopoverComponent, rowData: any): void {
    action.action(rowData);
    localActionPopover.hide();
  }
  parseXml(xmlString: string, fileName: string): string | null {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");
    const pathElement = xmlDoc.getElementsByTagName("filepath");
    if (pathElement.length > 0) {
      for (const path of Array.from(pathElement)) {
        const filePath = path.textContent;
        if (filePath && filePath.includes(fileName)) {
          return filePath;
        }
      }
    }
    return "";
  }
}
