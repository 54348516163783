import { Directive, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { ArrayHelper } from "../utilities/contracts/array-helper";
import { StringHelper } from "../utilities/contracts/string-helper";
import { DynamicControl } from "./dynamic-control.model";
import { DynamicFormEvent } from "./dynamic-form-event.model";

@Directive()
export abstract class DynamicControlDirective<T extends DynamicControl> {
  @Input() formGroup: FormGroup;
  @Input() model: T;
  @Input() customTemplate: TemplateRef<any>;

  @Output() onChange = new EventEmitter<DynamicFormEvent>();
  @Output() onClick = new EventEmitter<DynamicFormEvent>();
  @Output() onInput = new EventEmitter<DynamicFormEvent>();
  @Output() onKeyUp = new EventEmitter<DynamicFormEvent>();
  @Output() onFocus = new EventEmitter<any>();
  @Output() onBlur = new EventEmitter<any>();
  @Output() onPanelShow = new EventEmitter<DynamicFormEvent>();
  @Output() onPanelHide = new EventEmitter<DynamicFormEvent>();

  get control(): AbstractControl {
    return this.formGroup.get(this.model.key);
  }

  get hidden(): boolean {
    return this.model.hidden;
  }

  get error(): string {
    const keys = Object.keys(this.control.errors || {});
    if (!ArrayHelper.isAvailable(keys)) {
      return "";
    }

    const firstError = keys[0];
    let message;
    if (this.model && this.model.errorMessages) {
      message = this.model.errorMessages[firstError];
    }

    if (!StringHelper.isAvailable(message)) {
      message = this.control.errors[firstError];
    }

    if (typeof message !== "string") {
      // TODO: Force error messages to be present?
      message = "";
    }

    if (message?.includes("ChartPageNumber")) {
      return message.replace("ChartPageNumber", "Page #");
    }

    return message;
  }

  get hasError(): boolean {
    return this.control
      && this.control.invalid
      && StringHelper.isAvailable(this.error)
      && (this.control.dirty || this.control.touched);
  }


  protected getClasses(inputType: string): string {
    let classes = `control__input control__input--${inputType}`;

    if (this.model.isAdmin) {
      classes = `${classes} control__input--admin`;
    } else if (this.model.isChanged) {
      classes = `${classes} control__input--${this.model.workflowStatusName}`;
    } else if (this.model.isNlp) {
      classes = `${classes} control__input--nlp`;
    } else if (this.model.isSupplemental) {
      classes = `${classes} control__input--supplemental`;
    } else if (this.model.isDva) {
      classes = `${classes} control__input--dva`;
    }

    if (this.model.readonly && !this.model.isAdmin) {
      classes = `${classes} control__input--readOnly`;
    }

    return classes;
  }

  getLabel(label: string, useTitleCase: boolean): string {
    if (useTitleCase) {
      return StringHelper.toTitleCase(label);
    }
    return label;
  }
}
