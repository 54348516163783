import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewChild } from "@angular/core";
import { TabView } from "primeng/tabview";
import { TabPanelComponent } from "./tab-panel.component";

@Component({
  selector: "app-tab-menu",
  templateUrl: "./tab-menu.component.html",
  styleUrls: ["./tab-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TabView],
})
export class TabMenuComponent implements AfterContentInit, AfterViewInit {
  @Input() orientation = "left";
  @Output() activeIndexChange = new EventEmitter<{ index: number; header: string }>(true);

  @ViewChild(TabView, { static: true }) primeTabMenu: TabView;
  @ContentChildren(TabPanelComponent) tabPanels: QueryList<TabPanelComponent>;
  // @ContentChildren(TabPanelComponent, { descendants: true }) tabPanels: QueryList<TabPanelComponent>;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterContentInit() {
    const hasTabView = this.primeTabMenu != null;
    const hasTabMenu = this.tabPanels != null && this.tabPanels.length > 0;
    if (hasTabView && hasTabMenu) {
      const hasSelected = this.tabPanels.some(a => a.selected);
      if (!hasSelected) {
        this.tabPanels.first.selected = true;
      }

      const tabMenuAfterContentInit = this.primeTabMenu.ngAfterContentInit;
      this.primeTabMenu.ngAfterContentInit = () => {
        this.primeTabMenu.tabPanels.reset(
          this.tabPanels.map(a => a.primeTabPanel)
        );
        tabMenuAfterContentInit.call(this.primeTabMenu);
      };
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }


  trackByIndex(index, item) {
    return index;
  }

  onTabChange(event): void {
    const activeIndex = event.index; // Get active tab index
    const header = this.primeTabMenu.tabs[activeIndex]?.header || "";
    this.activeIndexChange.emit({ index: activeIndex, header });
  }

  setActiveTab(tabName: string): void {
    if (this.primeTabMenu) {
      const tabIndex = this.primeTabMenu.tabs.findIndex(tab => tab.header === tabName);

      if (tabIndex !== -1) {
        this.primeTabMenu.tabs.forEach(tab => (tab.selected = false));
        this.primeTabMenu.tabs[tabIndex].selected  = true;
        this.cd.detectChanges();
      }
    }
  }
}
