<app-detail-main-template>
  <div container-header>
    <div class="header bold">APPROVE PURSUITS</div>
  </div>
  <div container-body>
    <div>
      <app-approval-center-grid [url]="url"
                                [stateName]="stateName"
                                [additionalColumns]="additionalColumns"
                                [additionalFilters]="additionalFilters"
                                [approvalRequestType]="approvalRequestType"
                                [viewAttributeId]="viewAttributeId"
                                [showViews]="true">
      </app-approval-center-grid>  
    </div>
  </div>
</app-detail-main-template>
