import { mapUserToken } from "../../auth/user-token.model";
import { mapDischargeCalculatedDates } from "../../dynamic-forms/form-groups/discharge-group/discharge-calculated-dates.model";
import { mapDatesBetween } from "../../dynamic-forms/form-groups/ppc/dates-between/dates-between.model";
import { mapPpcDates } from "../../dynamic-forms/form-groups/ppc/ppc-dates.model";
import { mapSelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { mapDocumentUploadResponse } from "../../platform/api/document-upload/document-upload-response.model";
import { mapDocumentValidateResponse } from "../../platform/api/document-upload/document-validate-response.model";
import { mapDynamicEntity } from "../../platform/api/member-validation/dynamic-entity.model";
import { mapEntityError } from "../../platform/api/member-validation/entity-error.model";
import { mapVendorExclusion } from "../../platform/api/vendor/vendorExclusion.model";
import { mapAnalyticsItem } from "../../platform/modules/analytics/models/analytics-item.model";
import { mapAnalyticsDashboardCount } from "../../platform/modules/analytics/models/dashboard-count.model";
import { mapApprovalCenterItemEntity } from "../../platform/modules/approval-center/approval-center-item.model";
import { mapDocumentData } from "../../platform/modules/event-lake/document-data.model";
import { mapOcrResult } from "../../platform/modules/event-lake/ocr-results/ocr-result.model";
import { mapOcrPageSection } from "../../platform/modules/event-lake/ocr-section/ocr-page-section.model";
import { mapOcrPageWorkflowProgress } from "../../platform/modules/event-lake/workflow-progress/ocr-page-workflow.model";
import { mapDocumentQueueResponse } from "../../platform/modules/invoice/invoices/documentqueue-response.model";
import { mapInvoiceChaseValidation } from "../../platform/modules/invoice/invoices/model/invoice-chase-validation.model";
import { mapInvoiceDetail } from "../../platform/modules/invoice/invoices/model/invoice-detail.model";
import { mapVendorDetail } from "../../platform/modules/invoice/vendor-detail/vendor-detail.model";
import { mapArchiveChaseDetail } from "../../platform/modules/member/archive-chase-detail/archive-chase-detail.model";
import { mapChartFilter } from "../../platform/modules/member/chase-detail-v2/chart-filter.model";
import { mapChartFilters } from "../../platform/modules/member/chase-detail-v2/chart-filters.model";
import { mapEveIcd } from "../../platform/modules/member/chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/eve/eve-icd.model";
import { mapClinicalTerm } from "../../platform/modules/member/chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/eve-clinical-terms/clinical-term.model";
import { mapEncounterSummary } from "../../platform/modules/member/chase-detail-v2/chase-detail-v2-chart/risk/risk-submit/summary/summary.model";
import { mapChaseDetailAuditLog } from "../../platform/modules/member/chase-detail/chase-detail-audit-log/chase-detail-audit-log.model";
import { mapExlcusionAndContra } from "../../platform/modules/member/chase-detail/chase-detail-chart/exclusion-and-contra/exclusion-and-contra.model";
import { mapNonCompliantInfo } from "../../platform/modules/member/chase-detail/chase-detail-chart/exclusion-and-contra/non-compliant-info.model";
import { mapCatalyticLocations } from "../../platform/modules/member/chase-detail/chase-detail-chart/nlp-hedis/catalytic-locations.model";
import { mapCatalyticNumerator } from "../../platform/modules/member/chase-detail/chase-detail-chart/nlp-hedis/catalytic-numerator.model";
import { mapCatalyticResult } from "../../platform/modules/member/chase-detail/chase-detail-chart/nlp-hedis/catalytic-result.model";
import { mapCatalyticSupportingLocation } from "../../platform/modules/member/chase-detail/chase-detail-chart/nlp-hedis/catalytic-supporting-location.model";
import { mapOverreadFeedbackDetail } from "../../platform/modules/member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-detail.model";
import { mapOverreadFeedbackQueryItem } from "../../platform/modules/member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-query-item.model";
import { mapDiagnosis } from "../../platform/modules/member/chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { mapDiseaseDetail } from "../../platform/modules/member/chase-detail/chase-detail-chart/risk/diagnosis/disease-detail.model";
import { mapEncounter } from "../../platform/modules/member/chase-detail/chase-detail-chart/risk/encounter/encounter.model";
import { mapRiskEntity } from "../../platform/modules/member/chase-detail/chase-detail-chart/risk/risk-entity.model";
import { mapChaseDetailHealthCard } from "../../platform/modules/member/chase-detail/chase-detail-health-card/chase-detail-health-card.model";
import { mapChaseDetailHealthCardIcd } from "../../platform/modules/member/chase-detail/chase-detail-health-card/health-card-icd";
import { mapChaseDetailInfoPursuitItem } from "../../platform/modules/member/chase-detail/chase-detail-info/chase-detail-info-pursuits/chase-detail-info-pursuit-item/chase-detail-info-pursuit-item-model";
import { mapGapCompliance } from "../../platform/modules/member/chase-detail/chase-detail-info/gap-compliance.model";
import { mapChaseDetail } from "../../platform/modules/member/chase-detail/chase-detail.model";
import { mapMemberSearchResult } from "../../platform/modules/member/create-new-chase/create-new-chase-member/member-search-result.model";
import { mapMemberDetail } from "../../platform/modules/member/member-detail/member-detail.model";
import { mapBulkOutReachChaseValidation } from "../../platform/modules/project/bulk-updates/bulk-chase-validation.model";
import { mapAddressBulkUpdateItem } from "../../platform/modules/project/bulk-updates/bulk-update-address/address-bulk-update-item";
import { mapHccModel } from "../../platform/modules/project/hcc.model";
import { mapFileDetail } from "../../platform/modules/project/project-config/models/file-detail.model";
import { mapMeasures } from "../../platform/modules/project/project-config/models/measures.model";
import { mapProjectFileResponse } from "../../platform/modules/project/project-files/project-file/project-file-response.model";
import { mapProjectTemplateModel } from "../../platform/modules/project/project-template.model";
import { mapAddressDetailInfoEdit } from "../../platform/modules/retrieval/address-detail/address-detail-info/address-detail-info-edit/address-detail-info-edit.model";
import { mapAddressDetail } from "../../platform/modules/retrieval/address-detail/address-detail.model";
import { mapAddressSearchResult } from "../../platform/modules/retrieval/address-search/address-search-result.model";
import { mapAppointment } from "../../platform/modules/retrieval/appointment/appointment.model";
import { mapBulkUserResponse } from "../../platform/modules/service-org-admin/bulk-user-upload/bulk-user-response.model";
import { mapPendCodeItems } from "../../platform/modules/service-org-admin/service-org-config/model/custom-pend-code.model";
import { mapDocumentRouting } from "../../platform/modules/service-org-admin/service-org-config/model/document-routing.model";
import { mapPendCodeCount } from "../../platform/modules/service-org-admin/service-org-config/model/Pend-Code-Count.model";
import { mapPendCodeList } from "../../platform/modules/service-org-admin/service-org-config/model/Pend-Code-List.model";
import { mapServiceOrgAttribute } from "../../platform/modules/service-org-admin/service-org-config/model/service-org-attribute.model";
import { mapServiceOrgConfigModel } from "../../platform/modules/service-org-admin/service-org-config/model/service-org-config.model";
import { mapSsoConfiguration } from "../../platform/modules/service-org-admin/service-org-config/model/sso-configuration.model";
import { mapTags } from "../../platform/modules/service-org-admin/service-org-config/model/Tags.model";
import { mapTotalTagCount } from "../../platform/modules/service-org-admin/service-org-config/model/total-tag-count.model";
import { mapAnnotation } from "../../platform/widget/annotations/annotation.model";
import { mapProviderCustomDetail } from "../../provider/provider-custom-detail.model";
import { mapProvider } from "../../provider/provider.model";
import { mapBoundingBox } from "../../shared/document/document-page-viewer/bounding-box.model";
import { mapDocumentOcrMatch } from "../../shared/document/document-page-viewer/document-ocr-match.model";
import { mapDocumentPageNlpMatches } from "../../shared/document/document-page-viewer/document-page-nlp/document-page-nlp-matches.model";
import { mapNlpWord } from "../../shared/document/document-page-viewer/document-page-nlp/nlp-word.model";
import { mapDocumentPageOcrMatches } from "../../shared/document/document-page-viewer/document-page-ocr-matches.model";
import { mapOcrMatch } from "../../shared/document/document-page-viewer/ocr-match.model";
import { mapOcrWord } from "../../shared/document/document-page-viewer/ocr-word.model";
import { mapDocumentRequest } from "../../shared/document/document-request.model";
import { mapGridView } from "../../shared/grid/grid-menu/grid-views/grid-view.model";
import { mapGridViewsState } from "../../shared/grid/grid-menu/grid-views/grid-views-state.model";
import { mapSaveQuery } from "../../shared/grid/save-query/save-query.model";
import { mapProviderPacketLogoResponseModel } from "../../shared/logo/provider-packet-logo-response.model";
import { mapServiceOrgLogoResponseModel } from "../../shared/logo/service-org-logo-response.model";
import { mapMemberCentricChaseSearchResult } from "../../shared/membercentric-doc-attachment/membercentric-chase.model";
import { mapMenuItems } from "../../shared/menu/menu-item.model";
import { mapMasterProvider } from "../../shared/national-provider-grid/national-provider.model";
import { mapChasePendItems } from "../../shared/pend/chase-pend-item.model";
import { mapTagAddressNotes } from "../../shared/tags/model/tag-address-notes.model";
import { mapClientOrgModel } from "../user/client-org-model";
import { IAutoMapper } from "./interfaces";
import { mapRetrievalCallRecordDetailItems } from "./mappings/address-call-record-detail";
import { mapRetrievalContactHistoryItems } from "./mappings/address-contact-history.mappings";
import { mapEditAddressModel } from "./mappings/address-model.mappings";
import { mapAdressesQueueItems } from "./mappings/addresses-queue-item.mappings";
import { mapNotificationItems } from "./mappings/alert-notification-item.mappings";
import { mapAlertNotification } from "./mappings/alert-notification.mappings";
import { mapAssignedAddressItems } from "./mappings/assigned-address-item.mappings";
import { mapAuthorizationLetterAttribute } from "./mappings/authorization-letter-attribute.mappings";
import { mapBulkOutReachQueryResult } from "./mappings/bulk-outreach-query-result.mappings";
import { mapBulkUpdateItem } from "./mappings/bulk-update-item.mappings";
import { mapChartLakeReviewItems } from "./mappings/chart-lake-review.mappings";
import { mapChaseDocumentPages } from "./mappings/chase-document-page.mappings";
import { mapChasePageHistory } from "./mappings/chase-page-history.mapping";
import { mapChaseQueryItems } from "./mappings/chase-query-item.mappings";
import { mapChaseListItems } from "./mappings/chase-search-item.mappings";
import { mapClientNodeModel } from "./mappings/client-node.mapping";
import { mapClinicalChaseItems } from "./mappings/clinical-chase-item.mappings";
import { mapClinicalMeasureItems } from "./mappings/clinical-measure-list-item.mappings";
import { mapClinicalQueueItems } from "./mappings/clinical-queue-item.mappings";
import { mapCommentItems } from "./mappings/comment-item.mappings";
import { mapDataSetItems } from "./mappings/data-set.mappings";
import { mapDocumentApprovalSearchResponse } from "./mappings/document-approval-search-results.mappings";
import { mapDocumentListItems } from "./mappings/document-list-item.mappings";
import { mapDocumentPage } from "./mappings/document-page.mapping";
import { mapDocumentPages } from "./mappings/document-pages.mapping";
import { mapDocumentReviewerChaseItems } from "./mappings/document-reviewer-chase-item.mappings";
import { mapDropdownListItem } from "./mappings/dropdown-list-item.mappings";
import { mapDynamicEntityAttribute } from "./mappings/dynamic-entity-attribute.mappings";
import { mapEditEmrModel } from "./mappings/emr-edit-model.mappings";
import { mapEditFtModel } from "./mappings/ft-edit-model.mappings";
import { mapJobQueueItem } from "./mappings/jobqueue-item.model.mapping";
import { mapJobQueueUnRead } from "./mappings/jobqueue-unread.mapping";
import { mapListItems } from "./mappings/list-item.mappings";
import { mapLookupModel } from "./mappings/lookup-model-mappings";
import { mapMappingDocument } from "./mappings/mapping-document.mappings";
import { mapMemberWithChasesItems } from "./mappings/member-item.mappings";
import { mapMemberListItems } from "./mappings/member-list-item.mappings";
import { mapMemberTimelineItems } from "./mappings/member-timeline-item-mappings";
import { mapMemberValidation } from "./mappings/member-validation.mappings";
import { mapPendDocumentItem } from "./mappings/pend-document-list-item.mappings";
import { mapPendListItem } from "./mappings/pend-item.mappings";
import { mapPendTimelineItems } from "./mappings/pend-timeline-item-mappings";
import { mapPendsAttemptHistoryItems } from "./mappings/pends-attempt-history.mappings";
import { mapProjectAttributes } from "./mappings/project-attribute.mappings";
import { mapProjectFile } from "./mappings/project-file.mappings";
import { mapProjectNodeModel } from "./mappings/project-node.mapping";
import { mapProviderReceiptResponseModel } from "./mappings/provide-receipt-response.model.mappings";
import { mapProviderPacketResponse } from "./mappings/provider-packet-response-packet-item.mappings";
import { mapProviderPacketModel } from "./mappings/provider-packet.model.mappings";
import { mapProviderListItems } from "./mappings/provider-search-item.mappings";
import { mapReportingStatusModel } from "./mappings/reporting-status-model.mappings";
import { mapRetrievalTimelineItems } from "./mappings/retrieval-timeline-item-mappings";
import { mapRoleModel } from "./mappings/role-model.mappings";
import { mapServiceOrgNodeModel } from "./mappings/service-org-node.mapping";
import { mapTimelineItems } from "./mappings/timeline-item.mappings";
import { mapUpdatePendItems } from "./mappings/update-pend-item.mapping";
import { mapUserAttributeDisplayModel } from "./mappings/user-attribute-display.mappings";
import { mapUserAttributeModel } from "./mappings/user-attribute.mappings";
import { mapUserGroupModel } from "./mappings/user-group-model.mappings";
import { mapUserModel } from "./mappings/user-model.mapping";
import { mapUserRoleModel } from "./mappings/user-role-model.mappings";
import { mapWorkflowStatusModel } from "./mappings/workflow-status-model.mappings";
import { mapWorkflowSubmitResponse } from "./mappings/workflow-submit-response.mapping";


export const createMappings = (automapper: IAutoMapper) => {
  mapAddressBulkUpdateItem(automapper);
  mapAddressDetail(automapper);
  mapAddressDetailInfoEdit(automapper);
  mapAddressSearchResult(automapper);
  mapAdressesQueueItems(automapper);
  mapAlertNotification(automapper);
  mapAnalyticsDashboardCount(automapper);
  mapAnalyticsItem(automapper);
  mapAnnotation(automapper);
  mapAppointment(automapper);
  mapApprovalCenterItemEntity(automapper);
  mapArchiveChaseDetail(automapper);
  mapAssignedAddressItems(automapper);
  mapAuthorizationLetterAttribute(automapper);
  mapBoundingBox(automapper);
  mapBulkOutReachChaseValidation(automapper);
  mapBulkOutReachQueryResult(automapper);
  mapBulkUpdateItem(automapper);
  mapBulkUserResponse(automapper);
  mapCatalyticLocations(automapper);
  mapCatalyticNumerator(automapper);
  mapCatalyticResult(automapper);
  mapCatalyticSupportingLocation(automapper);
  mapChartFilter(automapper);
  mapChartFilters(automapper);
  mapChartLakeReviewItems(automapper);
  mapChaseDetail(automapper);
  mapChaseDetailAuditLog(automapper);
  mapChaseDetailHealthCard(automapper);
  mapChaseDetailHealthCardIcd(automapper);
  mapChaseDetailInfoPursuitItem(automapper);
  mapChaseDocumentPages(automapper);
  mapChaseListItems(automapper);
  mapChaseQueryItems(automapper);
  mapChasePageHistory(automapper);
  mapChasePendItems(automapper);
  mapClientNodeModel(automapper);
  mapClientOrgModel(automapper);
  mapClinicalChaseItems(automapper);
  mapClinicalMeasureItems(automapper);
  mapClinicalQueueItems(automapper);
  mapClinicalTerm(automapper);
  mapCommentItems(automapper);
  mapDataSetItems(automapper);
  mapDatesBetween(automapper);
  mapDiagnosis(automapper);
  mapDischargeCalculatedDates(automapper);
  mapDiseaseDetail(automapper);
  mapDocumentApprovalSearchResponse(automapper);
  mapDocumentData(automapper);
  mapDocumentListItems(automapper);
  mapDocumentOcrMatch(automapper);
  mapDocumentPage(automapper);
  mapDocumentPages(automapper);
  mapDocumentPageNlpMatches(automapper);
  mapDocumentPageOcrMatches(automapper);
  mapDocumentQueueResponse(automapper);
  mapDocumentRequest(automapper);
  mapDocumentReviewerChaseItems(automapper);
  mapDocumentRouting(automapper);
  mapDocumentUploadResponse(automapper);
  mapDocumentValidateResponse(automapper);
  mapDropdownListItem(automapper);
  mapDynamicEntity(automapper);
  mapDynamicEntityAttribute(automapper);
  mapEditAddressModel(automapper);
  mapEditEmrModel(automapper);
  mapEditFtModel(automapper);
  mapEncounter(automapper);
  mapEncounterSummary(automapper);
  mapEntityError(automapper);
  mapEveIcd(automapper);
  mapExlcusionAndContra(automapper);
  mapFileDetail(automapper);
  mapGapCompliance(automapper);
  mapGridView(automapper);
  mapGridViewsState(automapper);
  mapHccModel(automapper);
  mapInvoiceChaseValidation(automapper);
  mapInvoiceDetail(automapper);
  mapJobQueueItem(automapper);
  mapJobQueueUnRead(automapper);
  mapListItems(automapper);
  mapLookupModel(automapper);
  mapMappingDocument(automapper);
  mapMasterProvider(automapper);
  mapMeasures(automapper);
  mapMemberCentricChaseSearchResult(automapper);
  mapMemberDetail(automapper);
  mapMemberListItems(automapper);
  mapMemberSearchResult(automapper);
  mapMemberTimelineItems(automapper);
  mapMemberValidation(automapper);
  mapMemberWithChasesItems(automapper);
  mapMenuItems(automapper);
  mapNlpWord(automapper);
  mapNonCompliantInfo(automapper);
  mapNotificationItems(automapper);
  mapOcrMatch(automapper);
  mapOcrPageSection(automapper);
  mapOcrPageWorkflowProgress(automapper);
  mapOcrResult(automapper);
  mapOcrWord(automapper);
  mapOverreadFeedbackDetail(automapper);
  mapOverreadFeedbackQueryItem(automapper);
  mapPendCodeCount(automapper);
  mapPendCodeItems(automapper);
  mapPendCodeList(automapper);
  mapPendDocumentItem(automapper);
  mapPendListItem(automapper);
  mapPendsAttemptHistoryItems(automapper);
  mapPendTimelineItems(automapper);
  mapPpcDates(automapper);
  mapProjectAttributes(automapper);
  mapProjectFile(automapper);
  mapProjectNodeModel(automapper);
  mapProjectFileResponse(automapper);
  mapProjectTemplateModel(automapper);
  mapProvider(automapper);
  mapProviderCustomDetail(automapper);
  mapProviderListItems(automapper);
  mapProviderPacketLogoResponseModel(automapper);
  mapProviderPacketModel(automapper);
  mapProviderPacketResponse(automapper);
  mapProviderReceiptResponseModel(automapper);
  mapReportingStatusModel(automapper);
  mapRetrievalCallRecordDetailItems(automapper);
  mapRetrievalContactHistoryItems(automapper);
  mapRetrievalTimelineItems(automapper);
  mapRiskEntity(automapper);
  mapRoleModel(automapper);
  mapSaveQuery(automapper);
  mapSelectableInput(automapper);
  mapServiceOrgAttribute(automapper);
  mapServiceOrgConfigModel(automapper);
  mapServiceOrgLogoResponseModel(automapper);
  mapServiceOrgNodeModel(automapper);
  mapSsoConfiguration(automapper);
  mapTagAddressNotes(automapper);
  mapTags(automapper);
  mapTimelineItems(automapper);
  mapTotalTagCount(automapper);
  mapUpdatePendItems(automapper);
  mapUserAttributeDisplayModel(automapper);
  mapUserAttributeModel(automapper);
  mapUserGroupModel(automapper);
  mapUserModel(automapper);
  mapUserRoleModel(automapper);
  mapUserToken(automapper);
  mapVendorDetail(automapper);
  mapVendorExclusion(automapper);
  mapWorkflowStatusModel(automapper);
  mapWorkflowSubmitResponse(automapper);

};
