/* tslint:disable:max-file-line-count */
import { CdkDrag } from "@angular/cdk/drag-drop";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, finalize, skip } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../auth/auth.service";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { LoaderService } from "../../../../../core/interceptor/loader/loader.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../../../core/storage/local.service";
import { SessionService } from "../../../../../core/storage/session.service";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { TextboxType } from "../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ORIGINAL_PAGE_HEIGHT, REDUCED_PAGE_HEIGHT } from "../../../../../shared/document/document-constants";
import { CacheTracker } from "../../../../../shared/document/document-page-viewer/cache-tracker.model";
import { DocumentOcrMatch } from "../../../../../shared/document/document-page-viewer/document-ocr-match.model";
import {
  AnnotationSource
} from "../../../../../shared/document/document-page-viewer/document-page-annotations/annotation-source-enum";
import {
  Annotations
} from "../../../../../shared/document/document-page-viewer/document-page-annotations/annotations.model";
import {
  ChaseDocumentPages
} from "../../../../../shared/document/document-page-viewer/document-page-annotations/chase-document-pages.model";
import {
  DocumentPageAnnotations
} from "../../../../../shared/document/document-page-viewer/document-page-annotations/document-page-annotations.model";
import { DocumentPageDocumentHighlights } from "../../../../../shared/document/document-page-viewer/document-page-annotations/document-page-document-highlights.model";
import { DocumentPageHighlightsResponse } from "../../../../../shared/document/document-page-viewer/document-page-annotations/document-page-highlights-response.model";
import { DocumentPageUserHighlightUpdate } from "../../../../../shared/document/document-page-viewer/document-page-annotations/document-page-user-highlight-update.model";
import {
  DocumentPageNlpMatches
} from "../../../../../shared/document/document-page-viewer/document-page-nlp/document-page-nlp-matches.model";
import {
  DocumentPageOcrMatches
} from "../../../../../shared/document/document-page-viewer/document-page-ocr-matches.model";
import {
  DocumentPageViewerComponent
} from "../../../../../shared/document/document-page-viewer/document-page-viewer.component";
import { DocumentViewerState } from "../../../../../shared/document/document-page-viewer/document-viewer-state.model";
import { OcrWord } from "../../../../../shared/document/document-page-viewer/ocr-word.model";
import { DocumentPageService } from "../../../../../shared/document/document-page.service";
import { MenuService } from "../../../../../shared/menu/menu.service";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../utilities/contracts/object-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { debounceTimeAfterFirst } from "../../../../../utilities/debounce-time-after";
import { LoaderHelper } from "../../../../../utilities/loader-helper.class";
import { MedicalRecordUploadService } from "../../../../api/medical-record-upload/medical-record-upload.service";
import { PlatformService } from "../../../../platform.service";
import { AnnotationService } from "../../../../widget/annotations/annotation.service";
import { ProjectConfiguration } from "../../../project/project-config/project-configuration.model";
import { DirectoryUserRole } from "../../../retrieval/directory-user-role";
import { DocumentPage } from "../../../retrieval/retreival-document-review/document-page.model";
import { DocumentPages } from "../../../retrieval/retreival-document-review/document-pages.models";
import { PageTypeName } from "../../../retrieval/retreival-document-review/page-type.enum";
import { RetrievalDocumentServiceService } from "../../../retrieval/retrieval-document-service.service";
import { Diagnosis } from "../../chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { RiskService } from "../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail/chase-detail-state.service";
import { ProjectRetrievalType } from "../../create-new-chase/project-retrieval-type.enum";
import {
  LOCALSTORAGE_CURRENTDXPAGENUMBER,
  LOCALSTORAGE_DIAGNOSISINDEX,
  LOCALSTORAGE_DOCUMENTIDS,
  LOCALSTORAGE_ENCOUNTERID,
  LOCALSTORAGE_ENCOUNTERSTARTDATE,
  LOCALSTORAGE_ISWINDOWOPEN,
  LOCALSTORAGE_PAGEAFTERSCREENSYNC,
  LOCALSTORAGE_PAGEBEFORESCREENSYNC,
  LOCALSTORAGE_PAGENUMBER,
  LOCALSTORAGE_PAGERELOAD,
  LOCALSTORAGE_SELECTEDDIAGNOSIS,
  LOCALSTORAGE_SELECTEDDIAGNOSISDX,
  LOCALSTORAGE_TABSELECTED
} from "../chase-detail-v2-chart/local-storage-keys";
import { Tab } from "../chase-detail-v2-chart/risk/risk-menu/tab-menu.enum";
import { ChaseDocumentPageType } from "../chase-document-page-types.enum";
import { DocumentViewerSessionService } from "../document-viewer-session.service";


@Component({
  selector: "app-chase-document-page-viewer",
  templateUrl: "./chase-document-page-viewer.component.html",
  styleUrls: ["./chase-document-page-viewer.component.scss"],
  providers: [RetrievalDocumentServiceService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseDocumentPageViewerComponent extends LoaderHelper implements OnInit, OnChanges, DoCheck, OnDestroy, AfterViewInit {
  constructor(
    private annotationService: AnnotationService,
    private localService: LocalService,
    private medicalRecordUploadService: MedicalRecordUploadService,
    private pageService: DocumentPageService,
    private platformService: PlatformService,
    private readonly automapper: AutomapperService,
    private readonly cd: ChangeDetectorRef,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly documentViewerSessionService: DocumentViewerSessionService,
    private readonly formService: FormService,
    private readonly loaderService: LoaderService,
    private readonly messagingService: MessagingService,
    private readonly retrievalDocumentService: RetrievalDocumentServiceService,
    private readonly riskService: RiskService,
    protected readonly zone: NgZone,
    private sessionService: SessionService,
    public menuService: MenuService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private renderer: Renderer2
  ) {
    super(zone);
  }

  get showSubmitHeader(): boolean {
    return (this.isSubmitTabSelected && !this.isDuplicateWindowOpen) ||
      (this.isSubmitTabSelected && this.isSplitScreenMainTab);
  }

  /* #region State Properties */
  get cleanSourceDocumentTypeId(): number {
    return Number(this.sourceDocumentTypeId);
  }

  get cleanDocumentId(): number {
    return Number(DocumentPageViewerComponent.documentId.getValue());
  }

  get cleanDocumentTypeId(): number {
    return this.state.documentTypeId;
  }

  get totalPages(): number {
    return this.state?.totalPages;
  }

  set totalPages(value: number) {
    this.state.totalPages = value;
  }

  get hasPages(): boolean {
    return ArrayHelper.isAvailable(this.state.pages);
  }

  get pages$(): BehaviorSubject<(DocumentPage | null)[]> {
    return this.state.pages$;
  }

  get pages(): DocumentPage[] {
    return this.state.pages;
  }

  get currentPageNumber(): number {
    return this.state.currentPageNumber;
  }

  set currentPageNumber(value: number) {
    this.state.currentPageNumber = value;
    this.updateViewedCurrentPage();
    this.zoomLevel = this.minZoomLevel;
    this.currentPageNumberForm.get(this.currentPageNumberInput.key).setValue(this.currentPageNumber);
    this.cd.markForCheck();
  }

  get hasCurrentPage(): boolean {
    return this.state.hasCurrentPage;
  }

  get currentPage(): DocumentPage {
    return this.state.currentPage;
  }

  set currentPage(value: DocumentPage) {
    if (value != null) {
      value.viewed = true;
      this.documentViewerSessionService.add(
        this.cleanSourceDocumentTypeId,
        this.cleanDocumentId,
        value
      );
    }
  }

  get highlightBtnClass(): string {
    return this.annotationToggle ? "active--annotation" : "";
  }

  get disableChartFunctions(): boolean {
    return this.annotationToggle && !this.isReadOnly && this.annotationsEnabled;
  }

  get showExpandedView(): boolean {
    return this.isExpandedViewFromToggle || this.isExpandedViewFromUrl || this.isSplitScreenMainTab;
  }

  get annotationsEnabled(): boolean {
    return true;
  }

  get isCurrentPageLoaded(): boolean {
    return NumberHelper.isAvailable(this.currentPageNumber);
  }

  get addressId(): number {
    return this.chaseDetailState.masterDocumentSourceId;
  }

  get projectId(): number {
    return this.chaseDetailState?.project?.projectId;
  }

  get imageWidthSize(): number {
    const imageSize = document.querySelector("#documentsImg") as HTMLElement;
    return imageSize.offsetWidth;
  }

  get zoomPercentage(): number {
    const minZoomPercentage = this.isWindowOpen ? 60 : 100;
    const zoomPercentageDelta = 20;
    return minZoomPercentage + (this.localService.get("zoomLevel", 0) * zoomPercentageDelta);
  }

  get zoomStyle(): any {
    const imageSize = document.querySelector("#documentsImg") as HTMLElement;
    this.margin = Number(this.zoomPercentage) / this.ratio === 1 ? 0
      : this.isWindowOpen ? (this.canvasWidth - imageSize.offsetWidth) / 2 : Number(this.zoomPercentage) / this.ratio;
    this.margin = this.margin < 0 ? 0 : this.margin;
    const newMargin = (this.margin / this.canvasWidth) * 100;
    const width = this.isWindowOpen ? "auto" : `${this.zoomPercentage}%`;
    this.itemSizeHeight = this.itemSize * ((this.zoomPercentage !== 100 ? this.zoomPercentage + 40 : (this.isWindowOpen ? this.zoomPercentage + 40 : this.zoomPercentage)) / 100);
    return {
      width,
      height: `${this.itemSizeHeight * 0.999}px`,
      top: "0",
      left: "0",
      margin: `0 ${this.margin < 5 ? 0 : newMargin}% 0`,
    };
  }

  get hasOcrSearchResults(): boolean {
    return this.documentOCRMatch && this.documentOCRMatch.hasPages && this.hasPages;
  }

  get ocrCurrentPage(): DocumentPageOcrMatches {
    return this.hasOcrSearchResults ? this.documentOCRMatch.currentPage : null;
  }

  get ocrDocumentResultsSummary(): string {
    return this.hasOcrSearchResults
      ? `${this.documentOCRMatch.currentOcrPageNumberIndex + 1} / ${this.documentOCRMatch.pages.length}`
      : "";
  }

  get ocrDocumentPageWordsSummary(): string {
    return this.hasOcrSearchResults
      ? `${this.ocrCurrentPage.currentIndex + 1} / ${this.ocrCurrentPage.ocrMatches.length}`
      : "";
  }

  /* #endregion OCR */

  /* #region Risk NLP */
  get isNlpEnabled(): boolean {
    return (this.chaseDetailState.isDataEntryWorkflow) &&
      this.chaseDetailState.isMeasureCodesToEnableNlp &&
      this.isEnableMrrBot;
  }

  get isEnableMrrBot(): boolean {
    return this.enableMrrBot === "1";
  }

  get displayOcrBlocks(): boolean {
    return this.chaseDetailState.displayOcrBlocks;
  }

  get isDataEntryWorkflow(): boolean {
    return this.chaseDetailState.isDataEntryWorkflow;
  }

  get enableOptimizeImageButton(): boolean {
    return !this.isHighResolutionImage && this.isOptimizedImagesAvailable && !this.sessionOptimizeImageSaved;
  }

  get sessionOptimizeImageName(): string {
    return `${this.SHOWOPTIMIZEIMAGE}_${this.chaseId}`;
  }

  get sessionOptimizeImageSaved(): boolean {
    return this.sessionService.get(this.sessionOptimizeImageName, false);
  }

  get isDiagnosisOpen(): boolean {
    return this.isDiagnosisSelected = !ObjectHelper.isEmpty(this.selectedDiagnosis);
  }

  get isDuplicateWindowOpen(): boolean {
    return this.localService.get(LOCALSTORAGE_ISWINDOWOPEN.key, null) === "1";
  }

  @Input() set tabSelected(value: string) {
    this.pTabSelected = value;
    this.localService.put(LOCALSTORAGE_TABSELECTED.key, value);
    switch (value) {
      case "encounters":
        this.chaseDocumentPages = this.encounterDocumentPages;
        break;
      case "member":
        this.chaseDocumentPages = this.encounterDocumentPages;
        break;
      case "submit":
        this.chaseDocumentPages = this.userDocumentPages;
        break;
      case "dx-review":
        this.chaseDocumentPages = this.hideHighlightsOnTabSelected(this.diagnosisDocumentPages);
        break;
      default:
        break;
    }

  }

  get tabSelected(): string {
    return this.pTabSelected;
  }

  get isSubmitTabSelected(): boolean {
    return this.tabSelected === "submit";
  }

  @Input() set showHighlightIcon(value: boolean) {
    if (value != null) {
      this.isReadOnly = !value;
    }
  }

  get minBuffer(): number {
    return this.isWindowOpen ? this.itemSizeHeight * this.itemSizeHeight : this.itemSizeHeight * 2;
  }

  get maxBuffer(): number {
    return this.isWindowOpen ? this.itemSizeHeight * this.itemSizeHeight : this.itemSizeHeight * 3;
  }

  get displayHighlights(): boolean {
    return this.isDxCodingReviewMode || !this.isReadOnly || this.chaseDetailState.isRiskProject || this.chaseDetailState.isIVAProject || (!this.isReadOnly && this.chaseDetailState.isMemberCentric);
  }

  get isReloadPage(): boolean {
    return this.localService.get(LOCALSTORAGE_PAGERELOAD.key, "0") === "0";
  }

  get isDxCodingReviewMode(): boolean {
    return this.chaseDetailState?.projectConfiguration?.codingReviewMode === "Diagnosis";
  }

  // Using subject so it is easy to reload component from parent.
  static documentId: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  margin = 0;
  isWindowOpen = this.localService.get(LOCALSTORAGE_ISWINDOWOPEN.key, null) === "1";
  ratio: number = this.isWindowOpen ? 7 : this.zoomPercentage;

  private sink = new SubSink();
  readonly MAX_ICD_DESCRIPTION_LENGTH = 45;
  private readonly THUMBNAILKEY = "THUMBNAIL_FILTER";
  private readonly SHOWOPTIMIZEIMAGE = "SHOW_OPTIMIZE_IMAGE";
  private encounterStartDate: string;
  isViewChanged = false;
  previousChase: number = null;
  isNextChase = false;

  @Input() chaseId: number;
  @Input() sourceDocumentTypeId: number;
  @Input() maxPages = 3;
  @Input() isReadOnly = false;
  @Input() isOcrDataAvailable = false;
  @Input() status: string;
  @Input() isDocApproval = false;
  @Input() isMaximized = false;
  @Input() disableMaximize = false;
  @Input() pageTypeId: number;
  @Input() isArchived = false;
  @Input() isExpandedViewFromUrl = false;
  @Input() showChartThumbnails = false;
  @Input() jumpPageNumber = 1;
  @Input() isSplitScreenEnabled = false;
  @Input() isPopOutBtnEnabled = true;
  @Input() isSplitScreenMainTab = false;
  @Input() documentPageIds: number[] = [];
  @Input() allEvidencesDocumentPageIds: number[] = [];
  @Input() duplicateTab = false;
  @Input() mainTab = false;
  @Output() isMaximizedChange = new EventEmitter<boolean>(true);

  @ViewChild(CdkVirtualScrollViewport, {static: true}) private viewport: CdkVirtualScrollViewport;
  @ViewChildren(CdkDrag) private images: QueryList<CdkDrag>;
  @ViewChild("ocrSearchContainer") ocrSearchContainer: ElementRef;
  @ViewChild("ocrSearchResults") ocrSearchResults: ElementRef;
  @ViewChildren(CdkVirtualScrollViewport) viewports: QueryList<CdkVirtualScrollViewport>;

  private pTabSelected: string;
  private state: DocumentViewerState;
  private cacheTracker: CacheTracker;
  private readonly PAGE_THRESHOLD = 21000;

  itemSize: number = this.isWindowOpen ? window.innerHeight : ORIGINAL_PAGE_HEIGHT;
  itemSizeHeight = ORIGINAL_PAGE_HEIGHT;
  currentPageNumberForm: FormGroup;
  currentPageNumberInput = new Textbox({key: "CurrentPageNumber"});
  searchPhraseForm: FormGroup;
  searchPhraseInput = new Textbox({key: "SearchPhrase"});
  chaseDetailState: ChaseDetailState;
  isMemberProjectRetrievalType = false;
  zoomLevel = 0;
  currentZoomLevel = 1;
  pageMarginBottom = 0;
  private minZoomLevel = 0;
  private maxZoomLevel = 8;
  isOcrFocus = false;
  isOcrSearchInProgress = false;
  documentOCRMatch: DocumentOcrMatch;
  showOcrSearchIcon = true;
  ocrSearchValue: string;
  isOcrSearchEnabled = true;
  annotationToggle = true;
  documentPageAnnotations: DocumentPageAnnotations;
  userDocumentPages: ChaseDocumentPages[] = [];
  chaseDocumentPages: ChaseDocumentPages[] = [];
  memberDocumentPages: ChaseDocumentPages[] = [];
  encounterDocumentPages: ChaseDocumentPages[] = [];
  diagnosisDocumentPages: ChaseDocumentPages[] = [];
  annotations: Annotations[];
  canvasWidth: number;
  canvasHeight = this.itemSize - 2;
  diagnoses: Diagnosis[];
  selectedDiagnosis: Diagnosis;
  projectConfiguration: ProjectConfiguration;
  enableMrrBot = "";
  nlpPhrase: AbstractControl;
  memberBtnActive = false;
  expandedView = false;
  expandedLabelView = false;
  memberNlpMatch: DocumentPageNlpMatches;
  isNlpMemberEnabled = false;
  isMemberValid = false;
  clearCache = false;
  cdnBucketFolders = [];
  isExpandedViewFromToggle = false;
  isBucketFolderChecked = false;
  isHighResolutionImage = false;
  isOptimizedImagesAvailable = false;
  getAnnotations = true;
  allSelectedThumbnailItems: any;
  resetMoveBackToggleButtonSwitch = true;
  skipCheck = false;
  selectedFilterValues: string[] = [];
  isDiagnosisSelected = false;
  selectedWidget = "";
  private currentIndex = 0;
  showChaseOpenWarning = false;
  openChaseAndUserInfo = "openChaseAndUserInfo";
  globalLocalStoreChaseId = null;
  globalLocalStoreUserId = null;
  highlightPageIds: string[] = [];
  isHighlightsFromDynamoDB = false;
  documentPageIdsAvailable = false;
  processedHighlightPageIds = new Map<string, boolean>();

  @Output() onSelectionthumbnailEvent = new EventEmitter<any>();
  @Output() hasNoDocumentPages = new EventEmitter<boolean>(false);
  @Output() isOpenSplitScreenUrl = new EventEmitter<boolean>(true);

  private previousPageClicked = false;
  private nextPageClicked = false;
  private pageJumpClicked = false;
  private dxCardPageJump = false;
  @Output() selectedFilters = new EventEmitter<any>();
  isPageNumberUpdate = false;

  @HostListener("window:resize")
  windowResize() {
    if (this.annotationsEnabled) {
      if (this.canvasWidth !== this.viewport?.elementRef.nativeElement.clientWidth) {
        this.canvasWidth = this.viewport?.elementRef.nativeElement.clientWidth;
      }
    }
  }

  ngOnInit() {
    this.localService.put("zoomLevel", this.zoomLevel);
    this.state = new DocumentViewerState();
    this.cacheTracker = new CacheTracker();
    if (this.isReloadPage && !this.isWindowOpen) {
      this.localService.delete(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key);
      this.localService.delete(LOCALSTORAGE_PAGEBEFORESCREENSYNC.key);
    }
    this.currentPageNumberInput = new Textbox({
      key: "CurrentPageNumber",
      type: TextboxType.NUMBER,
      value: 1,
      disabled: true,
    });
    this.currentPageNumberForm = this.formService.createFormGroup([this.currentPageNumberInput]);

    this.searchPhraseInput = new Textbox({
      key: "SearchPhrase",
      placeholder: this.isOcrDataAvailable ? "Search" : "Search Not Available Yet",
      disabled: !this.isOcrDataAvailable,
    });
    this.searchPhraseForm = this.formService.createFormGroup([this.searchPhraseInput]);

    if (this.router?.url.indexOf("dx-review") > -1) {
      this.tabSelected = Tab.DxReview;
    }

    this.sink.add(
      this.pageService.outsidePage$.subscribe(page => {
        this.pageService.chartPageChange = page;
        this.gotoPage(page - 1);
        this.cd.markForCheck();
      }),
      combineLatest([
        this.riskService.data,
        this.chaseDetailStateService.state,
      ]).subscribe(([riskState, chaseDetailState]: any[]) => {
        this.chaseDetailState = chaseDetailState;
        this.chaseDetailStateService.chaseBeyondMrr(this.chaseDetailState.isBeyondMRR);
        if (ArrayHelper.isAvailable(this.chaseDetailState.memberCentricChases)) {
          this.chaseDetailStateService.setMemberChases(this.chaseDetailState.memberCentricChases);
        }
        if (this.tabSelected === "member") {
          riskState.setSelectedDiagnosisIndex(null);
        }
        const diagnosisEntities = riskState.hasSelectedEncounterIndex ? riskState.selectedEncounter.diagnoses : [];
        this.diagnoses = this.automapper.mapMany("RiskData", "Diagnosis", diagnosisEntities);
        this.selectedDiagnosis = riskState.hasSelectedDiagnosisIndex ? this.diagnoses[riskState.selectedDiagnosisIndex] : {} as any;
        if (riskState?.hasSelectedDiagnosisIndex && (!this.isWindowOpen || this.isWindowOpen)) {
          this.localService.put(LOCALSTORAGE_DIAGNOSISINDEX.key, riskState.selectedDiagnosisIndex);
          if (StringHelper.isAvailable(riskState?.selectedEncounter?.entityId)) {
            this.localService.put(LOCALSTORAGE_ENCOUNTERID.key, riskState.selectedEncounter.entityId);
          }
          this.localService.put(LOCALSTORAGE_SELECTEDDIAGNOSIS.key, this.selectedDiagnosis);
        } else if (!this.isDuplicateWindowOpen) {
          this.localService.put(LOCALSTORAGE_DIAGNOSISINDEX.key, -1);
        }
        this.isMemberValid = riskState.isEnabled;

        this.projectConfiguration = this.chaseDetailState.projectConfiguration;
        this.updateProjectConfiguration();

        if (this.isSplitScreenMainTab && this.selectedDiagnosis) {
          this.chaseDetailStateService.thumbnailLabelFilterByDiagnosis.next(this.selectedDiagnosis);
        }

        // Update the annotation status only when page ids are available.
        if (this.documentPageIdsAvailable) {
          this.updateGetAnnotationStatus();
        }

        if (this.getAnnotations && this.chaseDetailState?.chaseId && this.documentPageIdsAvailable) {
          // Determine the highlight source for this chase only on the initial api call.
          this.getDynamoDbHighlights(true);
          this.getAnnotations = !this.annotationsEnabled;
        }

        this.cd.markForCheck();
      }),
      DocumentPageViewerComponent.documentId
        .pipe(debounceTime(500) /* do this to handle double subscribe firing when value changed */)
        .subscribe(documentId => {
          this.initialLoad();
          if (documentId > 0) {
            this.verifyOptimizedImagesAreAvailable();
          }
        }),
      this.documentViewerSessionService.currentPageNumber$
        .pipe(debounceTimeAfterFirst(50))
        .subscribe(pageNumber => {
          this.currentPageNumber = pageNumber;
          if (this.images) {
            setTimeout(() => this.images.forEach(a => a.reset()));
          }
          // this.gotoPage(pageNumber - 1);
        }),
      this.searchPhraseForm.get(this.searchPhraseInput.key).valueChanges
        .subscribe(value => {
          if (this.ocrSearchValue !== value) {
            this.clearOcrSearch();
          }
          this.ocrSearchValue = value;
        }),
      this.documentViewerSessionService.catalyticNumeratorState
        .subscribe(numerator => {
          if (+numerator.medicalRecordPageNumber) {
            if (!this.annotationToggle) {
              this.toggleAnnotation();
            }
            this.gotoPage(+numerator.medicalRecordPageNumber);
          }
        }),
      this.documentViewerSessionService.dataEntryPageNumber$
        .subscribe(pageNumber => {
          if (NumberHelper.isAvailable(pageNumber)) {
            this.pageService.chartPageChange = pageNumber;
            this.currentPageNumber = pageNumber;
            if (this.isDuplicateWindowOpen && !this.duplicateTab) {
              this.localService.put(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key, pageNumber);
            }
            this.gotoPage(pageNumber - 1);
            this.previousPageClicked = false;
          }
        }),
      this.documentViewerSessionService.selectedDiagnosisIcdDescription
        .subscribe(icdDescription => {
          if (StringHelper.isAvailable(icdDescription)) {
            if (icdDescription.length > this.MAX_ICD_DESCRIPTION_LENGTH) {
              const displayIcdDescription = `${icdDescription.slice(0, this.MAX_ICD_DESCRIPTION_LENGTH)}...`;
              this.nlpPhrase.setValue(displayIcdDescription);
            } else {
              this.nlpPhrase.setValue(icdDescription);
            }
            this.cd.markForCheck();
          }
        }),
      this.chaseDetailStateService.selectedEncounter$
        .pipe(filter(encounter => !ObjectHelper.isEmpty(encounter)))
        .subscribe(encounter => {
          this.selectedDiagnosis = undefined;
          this.localService.delete(LOCALSTORAGE_SELECTEDDIAGNOSIS.key);
          this.encounterStartDate = DateHelper.format(encounter.startDate);

          if ((this.isSplitScreenMainTab && this.isDuplicateWindowOpen) || !this.isDuplicateWindowOpen) {
            this.localService.put(LOCALSTORAGE_DIAGNOSISINDEX.key, -1);
            if (StringHelper.isAvailable(this.encounterStartDate)) {
              this.localService.put(LOCALSTORAGE_ENCOUNTERSTARTDATE.key, this.encounterStartDate);
            }
          }
        }),
      this.documentViewerSessionService.isMemberValidated
        .subscribe(isMemberValidated => {
          if (isMemberValidated && this.memberBtnActive && this.isNlpEnabled) {
            this.getMemberData();
          }
        }),
      this.menuService.selectedWidget$
        .subscribe(widget => {
          this.selectedWidget = widget;
        }),
      this.viewport?.scrolledIndexChange.pipe(
        skip(1),
        distinctUntilChanged(),
        filter(index => NumberHelper.isAvailable(index)),
        debounceTime(500)
      ).subscribe(index => this.updatePageAndIndex(index))
    );

    this.chaseId = Number(this.chaseId);
    if (this.isArchived) {
      this.sourceDocumentTypeId = 5;
      DocumentPageViewerComponent.documentId.next(this.chaseId);
    } else if (NumberHelper.isGreaterThan(this.chaseId, 0) && this.isDocApproval) {
      this.pageTypeId = PageTypeName.Approval;
      this.sourceDocumentTypeId = 4;
      DocumentPageViewerComponent.documentId.next(this.chaseId);
    } else if (NumberHelper.isGreaterThan(this.chaseId, 0)) {
      this.sourceDocumentTypeId = 2;
      DocumentPageViewerComponent.documentId.next(this.chaseId);
    }
    this.jumpPageNumber = Number(this.jumpPageNumber);
    this.chaseDetailStateService.encounterDetails.subscribe(data => {
      if (data) {
        this.chaseDocumentPages = this.diagnosisDocumentPages;
      }
    });
    this.platformService.isEnableWidgetSection.next(true);


    if (this.isRoleAbstractorOrOverreader(this.authService?.user.directoryRoleIds)) {
      this.handlePopupEvent();
    }

  }

  private isRoleAbstractorOrOverreader(arr: number[]): boolean {
    return (arr.length >= 1 && (arr.includes(DirectoryUserRole.Abstractor) || arr.includes(DirectoryUserRole.Overreader)));
  }


  private handlePopupEvent(): void {
    this.storageHandler();

    window.addEventListener("beforeunload", () => {
      this.removeOpenChaseInfo();
    });
  }

  private updateGetAnnotationStatus() {
    this.isNextChase = this.previousChase !== this.chaseDetailState.chaseId;
    this.previousChase = this.chaseDetailState.chaseId;
    this.getAnnotations = this.isNextChase;
  }

  private hideHighlightsOnTabSelected(diagnosisPages: any): ChaseDocumentPages[] {
    return diagnosisPages.map(val => val.annotations.splice(0, diagnosisPages.annotations));
  }

  private updatePageService(pageNumber: number): void {
    const match = this.pages?.find(page => page?.pageNumber === pageNumber);
    if (match) {
      const event = {page: pageNumber, documentPageId: match.documentPageId};
      this.pageService?.updateDocumentPageInfo(event);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.mainTab) {
      this.isSplitScreenMainTab = true;
    }
    this.splitScreenViewOptimized();
    if (changes.isOcrDataAvailable) {
      if (changes.isOcrDataAvailable.isFirstChange()) {
        return;
      }

      if (changes.isOcrDataAvailable.currentValue !== changes.isOcrDataAvailable.previousValue) {
        if (this.isOcrDataAvailable) {
          this.searchPhraseForm.get(this.searchPhraseInput.getMasterKey()).enable();
          this.searchPhraseInput.placeholder = "Search";
        }
      }
    }

    if (changes.chaseId) {
      if (changes.chaseId.isFirstChange()) {
        return;
      }

      if (changes.chaseId.currentValue !== changes.chaseId.previousValue) {
        if (!this.chaseDetailState.isMemberCentric) {
          this.ngOnInit();
        }
      }
    }

    if (changes.jumpPageNumber) {
      if (this.jumpPageNumber && this.isDuplicateWindowOpen) {
        this.pageJumpClicked = true;
        this.gotoPage(this.jumpPageNumber - 1);
      }
    }
  }

  ngDoCheck() {
    if (this.annotationsEnabled) {
      if (this.canvasWidth !== this.viewport.elementRef?.nativeElement.clientWidth) {
        this.canvasWidth = this.viewport.elementRef?.nativeElement.clientWidth;
      }
    }
  }

  splitScreenViewOptimized() {
    const pageViewer = document.getElementById("pageViewer");
    const commandBar = document.getElementById("commandBar");
    if (pageViewer && this.isDuplicateWindowOpen) {
      pageViewer.style.display = "none";
    }
    if (commandBar && this.isDuplicateWindowOpen) {
      commandBar.style.display = "none";
    }
    if (pageViewer && this.duplicateTab) {
      pageViewer.style.display = "block";
    }
    if (commandBar && this.duplicateTab) {
      commandBar.style.display = "block";
    }
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
    this.cleanCacheAfterNGDestory();
    this.platformService.isEnableWidgetSection.next(false);
    this.localService.delete(LOCALSTORAGE_SELECTEDDIAGNOSISDX.key);
    this.removeOpenChaseInfo();
  }

  private removeOpenChaseInfo(): void {
    const localChaseUserInfo = this.localService.get(this.openChaseAndUserInfo, null);
    if (localChaseUserInfo) {
      const parsedLocalChaseUserInfo = JSON.parse(localChaseUserInfo);
      const localStoreChaseId = parsedLocalChaseUserInfo.openChaseId;
      if (localStoreChaseId === this.chaseId) {
        this.localService.delete(this.openChaseAndUserInfo);
      }
    }
  }

  hasPageAndSource(page: DocumentPage): boolean {
    return page && StringHelper.isAvailable(page.image);
  }

  private updatePageAndIndex(index: number): void {
    this.currentIndex = index;
    this.updateViewportTransform();
    let pageNumber = index + 1;
    if (ArrayHelper.isAvailable(this.state.pages)) {
      this.loadMorePages(index);
      this.pageService.chartPageChange = (index);
    }
    this.chaseDetailStateService.setPageChangeValue(true);
    this.documentViewerSessionService.setCurrentPageNumber(pageNumber);
    this.handlePageNumberState(pageNumber);
    this.checkZoomLevel();
    return;
    if (this.previousPageClicked) {
      this.previousPageClicked = false;
      if (!(this.isDuplicateWindowOpen && this.duplicateTab)) {
        // case: normal view back issue
        this.documentViewerSessionService.setCurrentPageNumber(pageNumber);
        // this.viewport?.scrollToIndex(this.currentPageNumber - 1);
      }
    } else {
      if (this.isDuplicateWindowOpen && this.duplicateTab && this.nextPageClicked) {
        if (this.currentPageNumber > pageNumber) {
          // case: split screen forward issue
          pageNumber = pageNumber + 1;
          this.nextPageClicked = false;
        }
      }
      if (this.currentPageNumber > pageNumber && !this.duplicateTab) {
        // case: normal view scroll back issue
        // this.gotoPage(pageNumber + 1);
        this.documentViewerSessionService.setCurrentPageNumber(pageNumber);
      } else {
        if (this.currentPageNumber > pageNumber && this.duplicateTab && this.pageJumpClicked) {
          // split screen page jump case when 1 page less shows
          // pageNumber = pageNumber + 1;
          this.pageJumpClicked = false;
        }
        if (this.currentPageNumber > pageNumber && this.duplicateTab && !this.pageJumpClicked) {
          // scroll back issue split
          // this.gotoPage(pageNumber + 1);
          this.documentViewerSessionService.setCurrentPageNumber(pageNumber);
        } else {
          // case: forward scroll
          if (this.dxCardPageJump && pageNumber > this.currentPageNumber) {
            pageNumber = this.currentPageNumber;
            this.dxCardPageJump = false;
          }
          this.documentViewerSessionService.setCurrentPageNumber(pageNumber);
          this.currentPageNumberForm.get(this.currentPageNumberInput.key)?.setValue(pageNumber);
        }
      }
    }
    this.handlePageNumberState(pageNumber);
    this.checkZoomLevel();
  }

  onMouseOver(): void {
    this.dxCardPageJump = false;
  }

  checkZoomLevel(): void {
    const zoomLevel = this.localService.get("zoomLevel", 0);
    const currentPage = this.localService.get(LOCALSTORAGE_CURRENTDXPAGENUMBER.key, 1);
    if (zoomLevel != null && currentPage != null) {
      if ((zoomLevel > 0 || zoomLevel < 0) && Number(currentPage) !== -1) {
        this.documentViewerSessionService.setCurrentPageNumber(currentPage);
        this.currentPageNumberForm.get(this.currentPageNumberInput.key)?.setValue(currentPage);
        this.localService.put(LOCALSTORAGE_CURRENTDXPAGENUMBER.key, -1);
      }
    }
  }

  onGotoPage(event: any): void {
    this.pageJumpClicked = true;
    const pageNumber = event.target.valueAsNumber;
    this.gotoPage(pageNumber - 1);
  }

  nextPage(): void {
    const index = this.currentPageNumber + 1;
    this.currentPageNumberForm.get(this.currentPageNumberInput.key)?.setValue(index);
    this.currentPageNumber = index;
    this.currentIndex++;
    this.gotoPage(this.currentIndex);
  }

  prevPage(): void {
    const index = this.currentPageNumber - 1;
    this.currentPageNumberForm.get(this.currentPageNumberInput.key)?.setValue(index);
    this.currentPageNumber = index;
    this.currentIndex--;
    this.gotoPage(this.currentIndex);
  }

  gotoPage(pageNumber: number): void {
    this.viewport?.scrollToIndex(pageNumber);
  }

  zoomIn(): void {
    if (this.currentZoomLevel < this.maxZoomLevel) {
      if (this.currentZoomLevel === 0) {
        this.currentZoomLevel = 1;
      }
      if (this.isWindowOpen) {
        this.ratio += 7;
      }
      this.currentZoomLevel += 0.1;
      this.updatePageMarginBottom();
      this.updateViewportTransform();
    }
  }

  /**
   * Updates the bottom margin of the page dynamically based on the current zoom level.
   *
   * - If the zoom level is `<= 1`, no additional margin is applied, and the margin is set to `0`.
   * - For zoom levels greater than `1`, the margin is calculated as:
   *   `(currentZoomLevel - 1) * scaleFactor`, where:
   *     - `scaleFactor` is `1600` if the item size matches the original page height.
   *     - Otherwise, `scaleFactor` is `1000`.
   *
   * This ensures proper spacing of items on the page during zoom adjustments.
  */
  updatePageMarginBottom(): void {
    this.pageMarginBottom = this.currentZoomLevel <= 1 ? 0 : Math.round(
      (this.currentZoomLevel - 1) * (this.itemSize === ORIGINAL_PAGE_HEIGHT ? 1600 : 1000)
    );
  }

  zoomOut(): void {
    if (this.currentZoomLevel > this.minZoomLevel) {
      this.currentZoomLevel -= 0.1;
      if (this.isWindowOpen) {
        this.ratio -= 7;
      }
      this.updatePageMarginBottom();
      this.updateViewportTransform();
    }
  }

  updateViewportTransform() {
    this.viewports.forEach(viewport => {
      const zoomTransform = `scale(${this.currentZoomLevel})`; // Use this.zoomLevel instead of this.zoomLevels
      const pageElements = viewport.elementRef.nativeElement.querySelectorAll(".document__page");
      pageElements.forEach((pageElement: HTMLElement) => {
        pageElement.style.transform = zoomTransform; // Apply the same zoom level to each page within the viewport

        const imageContainer = pageElement.querySelector(".text-center");

        // Apply margin bottom only when chart image is available.
        if (imageContainer && imageContainer.querySelector("#documentsImg")) {
            pageElement.style.marginBottom = `${this.pageMarginBottom}px`;
        }
      });
    });
  }

  rotate(direction: string) {
    this.messagingService.showToast("Page Rotation has started", SeverityType.SUCCESS);
    this.saveImage(this.currentPages[this.currentIndex], this.getRotationDegrees(direction));
  }

  private getRotationDegrees(direction: string): number {
    const rotateDirection = direction === "right" ? 1 : -1;
    const rotateDegreesDelta = 90;
    return rotateDegreesDelta * rotateDirection;
  }

  toggleAnnotation(): void {
    this.resetPagePosition();
    this.annotationToggle = !this.annotationToggle;
  }

  resetPagePosition(): void {
    this.images.forEach(image => image.reset());
  }

  removePages(begPage: number, endPage: number): void {
    // TODO: How does this method sync with the backend?
    const documentTypeId = this.cleanDocumentTypeId;
    this.state.removePages(begPage, endPage);

    // TODO: Update session service remove to just update the values.
    this.documentViewerSessionService.remove(
      this.cleanSourceDocumentTypeId,
      this.cleanDocumentId,
      documentTypeId,
      Number(begPage),
      Number(endPage)
    );

    this.cd.markForCheck();
  }

  getDocumentTotalCaptureAnnotations(): void {
    this.sink.add(
      this.retrievalDocumentService.getDocumentTotalCaptureAnnotations(this.chaseId)
        .subscribe(result => {
          if (result) {
            this.chaseDocumentPages = result.chaseDocumentPages;
            this.annotationToggle = this.displayHighlights;
            this.resetPagePosition();
            this.cd.markForCheck();
          }
        })
    );
  }

  getDocumentAnnotations(): void {
    this.sink.add(
      combineLatest([
        this.retrievalDocumentService.getDocumentNlpHighlights(this.chaseId),
        this.retrievalDocumentService.getDocumentUserHighlights(this.chaseId),
      ]).subscribe(([nlpHighlights, userHighlights]) => {
        this.setDocumentHighlights(nlpHighlights, AnnotationSource.Nlp);
        this.setDocumentHighlights(userHighlights, AnnotationSource.User);
      })
    );
  }

  /**
   * Converts the dynamodb highlights api response into `DocumentPageAnnotations` format.
   */
  processDynamoDBHighLights(dynamoDBHighlights: DocumentPageHighlightsResponse[]): void {
    if (!ArrayHelper.isAvailable(dynamoDBHighlights)) { return; }

    const chaseDocumentId = Number(dynamoDBHighlights[0]?.chaseDocumentId);

    const createEmptyAnnotations = () => new DocumentPageAnnotations({
      chaseDocumentId,
      chaseDocumentPages: [],
      memberDocumentPages: [],
      encounterDocumentPages: [],
      diagnosisDocumentPages: [],
    });

    const nlpHighlights = createEmptyAnnotations();
    const userHighlights = createEmptyAnnotations();

    // Stores the highlights from api response into `DocumentPageAnnotations`.
    const updateAnnotations = (source: any, target: DocumentPageAnnotations) => {
      Object.values(ChaseDocumentPageType).forEach(key => {
        if (source?.[key]?.length) {
          target[key].push(source[key][0]);
        }
      });
    };

    dynamoDBHighlights.forEach(element => {
      updateAnnotations(element.nlpHighlights, nlpHighlights);
      updateAnnotations(element.userHighlights, userHighlights);
    });

    this.setDocumentHighlights(nlpHighlights, AnnotationSource.Nlp, true);
    this.setDocumentHighlights(userHighlights, AnnotationSource.User, true);
  }

  private setAnnotationSource(documentHighlights: DocumentPageAnnotations, annotationSource: AnnotationSource): void {
    if (documentHighlights) {
      documentHighlights.chaseDocumentPages.forEach(chaseDocumentPage => chaseDocumentPage.annotationSource = annotationSource);
      if (annotationSource === AnnotationSource.Nlp) {
        documentHighlights.memberDocumentPages.forEach(memberDocumentPage => memberDocumentPage.annotationSource = AnnotationSource.Nlp);
        documentHighlights.encounterDocumentPages.forEach(encounterDocumentPage => encounterDocumentPage.annotationSource = AnnotationSource.Nlp);
        documentHighlights.diagnosisDocumentPages.forEach(diagnosisDocumentPage => diagnosisDocumentPage.annotationSource = AnnotationSource.Nlp);
      }
    }
  }

  /**
   * Append chase document pages if source is dynamoDB else replace existing documents.
   */
  appendUniqueDocuments(existingPages: any[], newPages: any[], append: boolean = false): ChaseDocumentPages[] {

    // Replace existing values instead of appending when highlight is from s3.
    if (!append) {
      return ArrayHelper.clean(newPages);
    }

    const key = "chaseDocumentPageId";
    const uniqueMap = new Map<string, any>();

    // Add existing pages
    existingPages.forEach(page => uniqueMap.set(page[key], page));

    // Add new pages (overwrite duplicates automatically)
    newPages.forEach(page => uniqueMap.set(page[key], page));

    return ArrayHelper.clean(Array.from(uniqueMap.values()));
  }

  private setDocumentHighlights(documentHighlights: DocumentPageAnnotations, annotationSource: AnnotationSource, append: boolean = false): void {
    this.setAnnotationSource(documentHighlights, annotationSource);
    if (documentHighlights) {
      if (annotationSource === AnnotationSource.Nlp) {
        this.chaseDocumentPages = this.appendUniqueDocuments(this.chaseDocumentPages, documentHighlights.chaseDocumentPages, append);
        this.memberDocumentPages = this.appendUniqueDocuments(this.memberDocumentPages, documentHighlights.memberDocumentPages, append);
        this.encounterDocumentPages = this.appendUniqueDocuments(this.encounterDocumentPages, documentHighlights.encounterDocumentPages, append);
        this.diagnosisDocumentPages = this.appendUniqueDocuments(this.diagnosisDocumentPages, documentHighlights.diagnosisDocumentPages, append);
      } else {
        this.userDocumentPages = this.appendUniqueDocuments(this.userDocumentPages, documentHighlights.chaseDocumentPages, append);
      }
      this.annotationToggle = this.displayHighlights;
      this.resetPagePosition();
      this.cd.markForCheck();
    }
  }

  getCatalyticAnnotations(): void {
    this.sink.add(
      this.documentViewerSessionService.catalyticDeletedNumerators
        .subscribe(deletedNumerators => {
          if (deletedNumerators != null) {
            this.saveNlpAnnotations(deletedNumerators);
            this.cd.markForCheck();
          }
        }),
      this.documentViewerSessionService.catalyticSavedNumeratorSet
        .subscribe(savedNumerators => {
          if (savedNumerators != null) {
            this.saveNlpAnnotationSet(savedNumerators);
            this.cd.markForCheck();
          }
        }),
      this.documentViewerSessionService.catalyticDeletedNumeratorSet
        .subscribe(deletedNumerators => {
          if (deletedNumerators != null) {
            this.deleteNlpAnnotationSet(deletedNumerators);
            this.cd.markForCheck();
          }
        })
    );
  }

  getPageAnnotationsForChase(documentPageId: number): Annotations[] {
    let chaseDocumentPages: ChaseDocumentPages;
    const filteredAnnots: Annotations[] = [];
    if (ArrayHelper.isAvailable(this.encounterDocumentPages)) {
      chaseDocumentPages = this.encounterDocumentPages.find(item => {
        return item.chaseDocumentPageId === documentPageId;
      });
      if (chaseDocumentPages != undefined) {
        chaseDocumentPages.annotations.map(data => {
          if (data.annotationSourceId !== AnnotationSource.Nlp) {
            filteredAnnots.push(data);
          }
        });
      }
    }
    return (filteredAnnots && ArrayHelper.isAvailable(filteredAnnots)) ? filteredAnnots : [];
  }

  getPageAnnotationsV2(documentPageId: number): Annotations[] {
    let annotations: Annotations[];

    if (this.isWindowOpen && this.isDuplicateWindowOpen) {
      this.tabSelected = this.localService.get(LOCALSTORAGE_TABSELECTED.key, null);
      this.encounterStartDate = this.localService.get(LOCALSTORAGE_ENCOUNTERSTARTDATE.key, "");
      this.selectedDiagnosis = this.localService.get(LOCALSTORAGE_SELECTEDDIAGNOSIS.key, undefined);
    }

    switch (this.tabSelected) {
      case "encounters": {
        annotations = !ObjectHelper.isEmpty(this.selectedDiagnosis)
          ? this.filterDiagnosisAndDosAnnotations(documentPageId, [...this.diagnosisDocumentPages, ...this.encounterDocumentPages]) // Diagnosis selected
          : this.filterEncounterAnnotations(documentPageId); // Encounter selected
        break;
      }
      case "member":
        annotations = this.filterAnnotations(documentPageId, this.memberDocumentPages);
        break;
      case "dx-review":
        annotations = this.filterDiagnosisAndDosAnnotations(documentPageId, [...this.diagnosisDocumentPages, ...this.encounterDocumentPages]);
        break;
      case "submit":
        annotations = this.filterAnnotations(documentPageId, this.chaseDocumentPages);
        break;
      default:
        break;
    }

    const userHighlights = this.getAnnotationsByPageId(documentPageId, this.userDocumentPages);
    annotations = ArrayHelper.isAvailable(annotations) ? [...annotations, ...userHighlights] : userHighlights;
    return ArrayHelper.clean(annotations);
  }


  private filterDiagnosisAndDosAnnotations(documentPageId: number, documentPages: ChaseDocumentPages[]): Annotations[] {
    return this.getAnnotationsForDiagnosisAndDosReview(documentPageId, documentPages);
  }

  private filterEncounterAnnotations(documentPageId: number): Annotations[] {
    return this.getAnnotationsByPageId(documentPageId, this.encounterDocumentPages)
      .filter(annotation =>
        annotation.dosFrom === this.encounterStartDate
      );
  }

  private filterAnnotations(documentPageId: number, documentPages: ChaseDocumentPages[]): Annotations[] {
    return this.filterData(this.getAnnotationsByPageId(documentPageId, documentPages));
  }

  private getAnnotationsForDiagnosisAndDosReview(documentPageId: number, documentPages: ChaseDocumentPages[]): Annotations[] {
    const annotations: Annotations[] = [];

    const documentPage = documentPages.filter(item => item?.chaseDocumentPageId === documentPageId);
    documentPage.map(page => {
      this.getAnnotationsPerPageForDiagnosisAndDosReview(page, annotations);
    });
    return annotations;
  }

  private getAnnotationsPerPageForDiagnosisAndDosReview(page: ChaseDocumentPages, annotations: Annotations[]) {
    const selectedDxDiagnosis = this.localService.get(LOCALSTORAGE_SELECTEDDIAGNOSISDX.key, null);
    if (selectedDxDiagnosis) {
      const selectedIcdCode = selectedDxDiagnosis?.code.replace(/\./g, "");
      page.annotations.map(annotation => {
        if (annotation && annotation.diagnosisCode || annotation.dosFrom) {
          const annoationIcdCode = annotation.diagnosisCode?.replace(/\./g, "");
          if (selectedIcdCode === annoationIcdCode || selectedDxDiagnosis.dosFrom === annotation.dosFrom) {
            annotations.push(annotation);
          }
        }
      });
    } else if (this.selectedDiagnosis) {
      page.annotations.map(annotation => {
        if (
          (annotation.diagnosisGroup === this.selectedDiagnosis.diagnosisCode && this.selectedDiagnosis.isEveDiagnosis)
          || (annotation.diagnosisCode === this.selectedDiagnosis.icd && this.selectedDiagnosis.isAdmin)
          || annotation.dosFrom
        ) {
          annotations.push(annotation);
        }
      });
    }
  }

  private getAnnotationsByPageId(documentPageId: number, documentPages: ChaseDocumentPages[]): Annotations[] {
    return documentPages
      ?.find(item => item?.chaseDocumentPageId === documentPageId)
      ?.annotations ?? [];
  }

  /**
   * @deprecated The method should not be used, keeping just for reference in case to rollback something
   */
  getPageAnnotations(documentPageId: number): Annotations[] {
    if (this.isWindowOpen && this.isDuplicateWindowOpen) {
      this.tabSelected = this.localService.get(LOCALSTORAGE_TABSELECTED.key, null);
    }
    const indexOfDiagnosis = this.localService.get(LOCALSTORAGE_DIAGNOSISINDEX.key, null);
    let chaseDocumentPages: ChaseDocumentPages;
    let pages: ChaseDocumentPages[];
    switch (this.tabSelected) {
      case "encounters": {
        pages = this.encounterDocumentPages;
        // for split screen to enable highlights for dx

        if (this.isWindowOpen && this.isDuplicateWindowOpen && indexOfDiagnosis !== -1) {
          const encounterFilterPages = this.localService.get(LOCALSTORAGE_DOCUMENTIDS.key, null);
          if (encounterFilterPages?.length > 0) {
            const encounterDocumentPages = [];
            pages.map(page => {
              if (ArrayHelper.isAvailable(encounterFilterPages.filter(x => x === page.chaseDocumentPageId))) {
                encounterDocumentPages.push(page);
              }
            });
            pages = encounterDocumentPages;
          } else {
            pages = [];
          }
          const selectedDiagnosisIndex = this.localService.get(LOCALSTORAGE_DIAGNOSISINDEX.key, null);

          if (Number(selectedDiagnosisIndex) >= 0) {
            this.riskService.data.subscribe(riskState => {
              const encounterId = this.localService.get(LOCALSTORAGE_ENCOUNTERID.key, null);
              const diagnosisEntities: any = riskState.encounters.filter(x => x.entityId === encounterId)[0].diagnoses;
              this.diagnoses = this.automapper.mapMany("RiskData", "Diagnosis", diagnosisEntities);
              this.selectedDiagnosis = this.diagnoses[selectedDiagnosisIndex];
            });
          }
        }
        if (this.selectedDiagnosis !== undefined && "isAdmin" in this.selectedDiagnosis) {
          pages = [];
          this.diagnosisDocumentPages.find(element => {
            const annotations = element.annotations.filter(el => {
              if (this.selectedDiagnosis.isEveDiagnosis) {
                return el.diagnosisGroup === this.selectedDiagnosis.diagnosisCode;
              } else if (this.selectedDiagnosis.isAdmin) {
                return el.diagnosisCode === this.selectedDiagnosis.icd;
              }
            });
            if (annotations.length > 0) {
              const documentPages: ChaseDocumentPages = new ChaseDocumentPages({
                chaseDocumentPageId: element.chaseDocumentPageId,
                annotations,
              });
              pages.push(documentPages);
            }
          });
        }
        break;
      }
      case "member":
        this.localService.put(LOCALSTORAGE_DIAGNOSISINDEX.key, -1);
        if (ArrayHelper.isAvailable(this.memberDocumentPages)) {
          pages = this.memberDocumentPages.map(chaseDocPage => ({
            chaseDocumentPageId: chaseDocPage.chaseDocumentPageId,
            annotationSource: chaseDocPage.annotationSource,
            annotations: this.filterData(chaseDocPage.annotations),
          }));
        }
        break;
      case "submit":
        pages = this.chaseDocumentPages.map(chaseDocPage => ({
          chaseDocumentPageId: chaseDocPage.chaseDocumentPageId,
          annotationSource: chaseDocPage.annotationSource,
          annotations: this.filterData(chaseDocPage.annotations),
        }));
        break;
      default:
        break;
    }
    if (ArrayHelper.isAvailable(pages)) {
      chaseDocumentPages = pages.find(item => {
        return item.chaseDocumentPageId === documentPageId;
      });
    }
    return (chaseDocumentPages && ArrayHelper.isAvailable(chaseDocumentPages.annotations)) ? chaseDocumentPages.annotations : [];
  }

  filterData(filteredAnnots: Annotations[]): Annotations[] {
    const check = {};
    const res: Annotations[] = [];
    filteredAnnots?.map((data, i) => {
      if (!check[`${filteredAnnots[i].startX} ${filteredAnnots[i].startY} ${filteredAnnots[i].widthX} ${filteredAnnots[i].heightY}`]) {
        check[`${filteredAnnots[i].startX} ${filteredAnnots[i].startY} ${filteredAnnots[i].widthX} ${filteredAnnots[i].heightY}`] = true;
        res.push(filteredAnnots[i]);
      }
    });
    return res;
  }

  saveDocumentAnnotations(event: ChaseDocumentPages, deletedHighlightId: string): void {
    const addedUserHighlight = new ChaseDocumentPages({
      chaseDocumentPageId: event.chaseDocumentPageId,
      annotations: event.annotations.filter(highlight => highlight.annotationSourceId !== AnnotationSource.Nlp),
    });
    const existingUserHighlights = this.userDocumentPages
      .filter(c => c.chaseDocumentPageId !== event.chaseDocumentPageId);

    const isAuditQuery = this.router.url.includes("auditquery");
    if (isAuditQuery) {
      const newHighlightIndex = addedUserHighlight.annotations.length - 1;
      if (newHighlightIndex >= 0) {
        addedUserHighlight.annotations[newHighlightIndex].isFromAuditQuery = true;
      }
    }

    this.documentPageAnnotations = new DocumentPageAnnotations({
      chaseDocumentId: this.chaseId,
      chaseDocumentPages: [...[addedUserHighlight], ...existingUserHighlights],
      encounterDocumentPages: [],
      memberDocumentPages: [],
      diagnosisDocumentPages: [],
    });

    if (this.isHighlightsFromDynamoDB) {

      // Add highlightId  before updating in DynamoDB when new highlight is created.
      if (!StringHelper.isAvailable(deletedHighlightId)) {
        const lastAddedIndex = addedUserHighlight.annotations.length - 1;
        if (lastAddedIndex >= 0 && !StringHelper.isAvailable(addedUserHighlight.annotations[lastAddedIndex].highlightId)) {
          addedUserHighlight.annotations[lastAddedIndex].highlightId = (crypto as any).randomUUID(); // Generates random uuid.
          addedUserHighlight.annotations[lastAddedIndex].annotationSourceId = AnnotationSource.User;
          addedUserHighlight.annotations[lastAddedIndex].annotationNlpSourceFilterGroup = AnnotationSource.User;
          addedUserHighlight.annotations[lastAddedIndex].annotationNlpSourceFilterSubGroup = AnnotationSource.User;
        }
      }

      const userHighlightUpdateRequest = new DocumentPageUserHighlightUpdate({
        chaseDocumentId: this.chaseId.toString(),
        documentPageid: event.chaseDocumentPageId.toString(),
        userHighlight: new DocumentPageAnnotations({
          chaseDocumentId: this.chaseId,
          chaseDocumentPages: [addedUserHighlight],
          encounterDocumentPages: [],
          memberDocumentPages: [],
          diagnosisDocumentPages: [],
        }),
      });

      this.retrievalDocumentService.updateUserDocumentHighlightsInDynamoDB(userHighlightUpdateRequest)
        .subscribe(
          () => this.setDocumentHighlights(this.documentPageAnnotations, AnnotationSource.User), err => this.messagingService.showToast("Highlights updation failed.", SeverityType.ERROR)
        );
    } else {
      const retrivalDocserviceCall = StringHelper.isAvailable(deletedHighlightId)
        ? this.retrievalDocumentService.deleteUserHighlight(this.chaseId, this.documentPageAnnotations, deletedHighlightId)
        : this.retrievalDocumentService.addDocumentUserHighlight(this.chaseId, this.documentPageAnnotations);

      retrivalDocserviceCall.subscribe(result => {
        this.setDocumentHighlights(result, AnnotationSource.User);
        if (result && StringHelper.isAvailable(deletedHighlightId)) {
          this.annotationService.refreshAnnotations(true);
        }
      });
    }
  }

  saveNlpAnnotations(event: string[]): void {
    if (ArrayHelper.isAvailable(this.chaseDocumentPages) && this.chaseDocumentPages.length > 1) {
      const chaseDocumentPages = this.chaseDocumentPages.map(item => {
        return {
          ...item,
          annotations: item.annotations.filter(x => event.indexOf(x.annotationKey) === -1).map(y => ({
            ...y,
            reviewedByUser: y.annotationSourceId === AnnotationSource.Nlp,
          })),
        };
      });

      this.saveAnnotations(chaseDocumentPages);
    }
  }

  saveNlpAnnotationSet(event: string[]): void {
    if (ArrayHelper.isAvailable(this.chaseDocumentPages) && this.chaseDocumentPages.length > 1) {
      const chaseDocumentPages = this.chaseDocumentPages.map(item => {
        return {
          ...item,
          annotations: item.annotations.map(x => ({...x, reviewedByUser: event.indexOf(x.annotationKey) > -1})),
        };
      });

      this.saveAnnotations(chaseDocumentPages);
    }
  }

  deleteNlpAnnotationSet(event: string[]): void {
    if (ArrayHelper.isAvailable(this.chaseDocumentPages) && this.chaseDocumentPages.length > 1) {
      const chaseDocumentPages = this.chaseDocumentPages.map(item => {
        return {
          ...item,
          annotations: item.annotations.filter(x => event.indexOf(x.annotationKey) === -1),
        };
      });

      this.saveAnnotations(chaseDocumentPages);
    }
  }

  openSplitScreenURL() {
    this.isSplitScreenMainTab = true;
    this.platformService.isEnableWidgetSection.next(false);
    this.isOpenSplitScreenUrl.emit(true);
  }

  private handlePageNumberState(pageNumber: any) {
    const pageNumberState = pageNumber;
    // Jump on selected thumbnail page number on second screen
    if (this.localService.get(LOCALSTORAGE_ISWINDOWOPEN.key, null) === "1"
      && this.localService.get(LOCALSTORAGE_PAGENUMBER.key, null) !== pageNumberState) {
      this.jumpPageNumber = Number(pageNumberState);
      this.localService.put(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key, pageNumberState);
      this.localService.put(LOCALSTORAGE_PAGENUMBER.key, pageNumberState);
    }

    if (!this.isWindowOpen && NumberHelper.isGreaterThan(pageNumberState, 0) && !this.isDuplicateWindowOpen) {
      this.localService.put(LOCALSTORAGE_PAGEBEFORESCREENSYNC.key, pageNumberState);
    }
  }

  private saveAnnotations(chaseDocumentPages: ChaseDocumentPages[]) {
    this.chaseDocumentPages = chaseDocumentPages;

    this.documentPageAnnotations = new DocumentPageAnnotations({
      chaseDocumentId: this.chaseId,
      chaseDocumentPages: this.chaseDocumentPages,
      encounterDocumentPages: this.encounterDocumentPages,
      memberDocumentPages: this.memberDocumentPages,
      diagnosisDocumentPages: this.diagnosisDocumentPages,
    });

    this.retrievalDocumentService.setDocumentAnnotations(this.chaseId, this.documentPageAnnotations).subscribe(result => {
      if (result) {
        this.chaseDocumentPages = result.chaseDocumentPages || [];
        this.memberDocumentPages = result.memberDocumentPages || [];
        this.encounterDocumentPages = result.encounterDocumentPages || [];
        this.diagnosisDocumentPages = result.diagnosisDocumentPages || [];
        this.cd.markForCheck();
      }
    });
  }

  private adjustPageSize() {
    this.itemSize = REDUCED_PAGE_HEIGHT;
    this.itemSizeHeight = REDUCED_PAGE_HEIGHT;
    this.canvasHeight = this.itemSize - 2;
  }

  /* tslint:disable:no-parameter-reassignment */
  private initialLoad(): void {
    // TODO: SRP. Get document meta data. Then get pages. This method will change to get document data to set the state only.
    const pagesToLoad = new Array(5).fill(0).map((n, index) => n = index + 1);
    pagesToLoad.forEach(pageNumber => this.state.setPage({pageNumber} as any));
    this.loaderService.isLoading.next(true);
    this.loadInitialDocumentPages(
      this.retrievalDocumentService,
      "getPages",
      pagesToLoad,
      this.cleanDocumentId,
      this.cdnBucketFolders,
      this.cleanSourceDocumentTypeId,
      this.sessionOptimizeImageSaved || this.isOptimizedImagesAvailable,
      this.pageTypeId,
      documentPages => {
        const length = (documentPages && ArrayHelper.isAvailable(documentPages.pages)) ? documentPages.pages[0].numberOfPages : 0;
        this.retrievalDocumentService.totalDocumentPages.next(length);
        this.chaseDetailStateService.setData({
          totalDocumentPages: length,
        });
        this.cacheTracker = new CacheTracker();
        this.state = new DocumentViewerState({
          documentId: this.cleanDocumentId,
          pages: Array.from<DocumentPage>({length}),

        });

        // Skip highlights api request as chase details might not be avilable yet.
        this.getNlpHighlightIdsToLoad(documentPages, true);

        if (length > this.PAGE_THRESHOLD) {
          this.adjustPageSize();
        }

        const documentPagesLength = documentPages.pages.length;
        const emptyArray: DocumentPage[] = new Array(length - documentPagesLength).fill({});
        const pagestToSet = [...documentPages.pages, ...emptyArray].map((page: DocumentPage, index) => {
          if (!StringHelper.isAvailable(page.image)) {
            return new DocumentPage({pageNumber: index + 1});
          }
          return page;
        });
        this.setPages(pagestToSet);

        if (!ArrayHelper.isAvailable(this.state.pages)) {
          this.hasNoDocumentPages.emit(true);
        } else {
          this.hasNoDocumentPages.emit(false);
        }

        // TODO: Delete this when page keys are saved in session
        // Remove viewed pages in session storage
        if (NumberHelper.isGreaterThan(this.cleanSourceDocumentTypeId, 0) && NumberHelper.isGreaterThan(this.cleanDocumentId, 0)) {
          this.documentViewerSessionService.delete(
            this.cleanSourceDocumentTypeId,
            this.cleanDocumentId,
            this.cleanSourceDocumentTypeId);
        }
        // Set Pages
        let screenSyncPageNumber = this.localService.get(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key, null);
        const pageBeforePageSync = this.localService.get(LOCALSTORAGE_PAGEBEFORESCREENSYNC.key, null);
        if (screenSyncPageNumber !== null && !this.isDuplicateWindowOpen && !this.isReloadPage) {
          this.localService.delete(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key);
        } else if (pageBeforePageSync !== null && this.duplicateTab && this.isReloadPage && NumberHelper.isGreaterThan(pageBeforePageSync, 0)) {
          screenSyncPageNumber = pageBeforePageSync;
          this.localService.put(LOCALSTORAGE_PAGEBEFORESCREENSYNC.key, -1);
        }
        this.currentPageNumber = NumberHelper.isGreaterThan(screenSyncPageNumber, 1) ? screenSyncPageNumber : 1;
        this.updatePageService(this.currentPageNumber);
        if (NumberHelper.isGreaterThan(screenSyncPageNumber, 1)) {
          this.documentViewerSessionService.updateDataEntryPageNumber(this.currentPageNumber);
        } else {
          this.viewport?.scrollToIndex(0);
        }
        this.enablePageNumberInput();
        if (!this.isMemberValid && this.isNlpEnabled) {
          this.getMemberData();
        }
        this.cd.markForCheck();
        this.isBucketFolderChecked = (documentPages && documentPages.isCdnBucketFolderChecked) ? documentPages.isCdnBucketFolderChecked : false;
        this.getCdnImages(documentPages);
        this.skipCheck = true;
        // Load next pages
        // if (!NumberHelper.isGreaterThan(screenSyncPageNumber, 1)) {
        //   this.subsequentLoad([2, 3]);
        // }
      },
      () => this.loaderService.isLoading.next(false),
      () => {
        this.messagingService.showToast("Document Pages not available", SeverityType.WARN);
        this.hasNoDocumentPages.emit(true);
      });
  }

  /* tslint:enable:no-parameter-reassignment */

  getCdnImages(documentPages: DocumentPages) {
    this.cdnBucketFolders = documentPages.s3ImagesFolders;
  }

  private subsequentLoad(pagesToLoad: number[]): void {
    if (this.skipCheck || (this.currentPageNumber > 0 && this.totalPages > 2)) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.skipCheck || (this.state.shouldLoadMore && !this.clearCache)) {
            this.skipCheck = false;
            pagesToLoad.forEach(pageNumber => this.setPage({pageNumber} as any));
            this.loadSubsequent(pagesToLoad)
              .pipe(finalize(() => this.loaderService.isLoading.next(false)))
              .subscribe(documentPages => {
                this.getCdnImages(documentPages);
                this.setPages(documentPages.pages);
                this.cleanCache();
                pagesToLoad[0] += 2;
                pagesToLoad[1] += 2;
                const loadPages = pagesToLoad[0] === this.totalPages ? [this.totalPages] : pagesToLoad;
                this.subsequentLoad(loadPages);
              });
          }
        })
      );
    } else if (this.currentPageNumber > 0 && this.totalPages === 2) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.state.shouldLoadMore && !this.clearCache) {
            this.setPage(2 as any);
            this.loadSubsequent([2])
              .pipe(finalize(() => this.loaderService.isLoading.next(false)))
              .subscribe(documentPages => {
                this.getCdnImages(documentPages);
                this.setPages(documentPages.pages);
                this.cleanCache();
              });
          }
        })
      );
    }
  }

  /**
   * Retrieves NLP highlight IDs from the provided document pages.
   * If `skipApiRequest` is false, it also triggers the API call to fetch highlights from DynamoDB.
   * The api request will be skipped only for the initial call.
   *
   * @param {DocumentPages} documentPages - The document pages containing highlight information.
   * @param {boolean} [skipApiRequest=false] - Whether to skip the API request for highlights.
   */
  getNlpHighlightIdsToLoad(documentPages: DocumentPages, skipApiRequest = false): void {

    this.documentPageIdsAvailable = true;

    if (documentPages && ArrayHelper.isAvailable(documentPages.pages)) {
      this.highlightPageIds = documentPages.pages.map(page => page.documentPageId.toString());
    }

    // Remove IDs of document pages where highlights are already present.
    if (!skipApiRequest && ArrayHelper.isAvailable(this.highlightPageIds)) {
      this.highlightPageIds = this.highlightPageIds.filter(documentPageId => !this.processedHighlightPageIds.get(documentPageId));
    }

    // Skip highlights api request as chase details may not be avilable yet.
    if (!skipApiRequest) {
      this.getDynamoDbHighlights();
    }
  }

  /**
   * Fetches NLP highlights for the given document pages.
   * If `this.isHighlightsFromDynamoDB` is true, it gets data from DynamoDB else gets from s3.
   * If `checkChaseInDynamoDb` is true, it also checks if the chase exists in DynamoDB.
   *
   * @param {boolean} [checkChaseInDynamoDb=false] - Whether to check if the chase exists in DynamoDB.
   */
  getDynamoDbHighlights(checkChaseInDynamoDb: boolean = false): void {

    const documentPageIds = this.highlightPageIds;
    const highlightOptions: Partial<DocumentPageDocumentHighlights> = {
      chaseDocumentId : this.chaseId.toString(),
      documentPageIds,
      checkChaseInDynamoDb,
    };

    /**
     * Includes the current chase availability status in the request to return it in the response,
     * preventing the need to recheck the status in DynamoDB for subsequent requests.
     * Not applicable for the initial call, as the availability status is unknown.
     */
    if (!checkChaseInDynamoDb) {
      highlightOptions.isChaseInDynamoDb = this.isHighlightsFromDynamoDB;
    }

    const documentPageHighLights = new DocumentPageDocumentHighlights(highlightOptions);

    // Store the document page IDs as processed to skip duplicate processing.
    if (this.isHighlightsFromDynamoDB) {
      this.updateProcessedDocumentPageIds(documentPageIds);
    }

    this.sink.add(
      this.retrievalDocumentService.getDocumentNlpHighlightsFromDynamoDB(documentPageHighLights).subscribe(response => {

          const hasHighlights = ArrayHelper.isAvailable(response?.highlights);

          if (checkChaseInDynamoDb && response?.isChaseInDynamoDb) {
            this.isHighlightsFromDynamoDB = true;
          }

          if (this.isHighlightsFromDynamoDB) {
            if (hasHighlights) {
              this.processDynamoDBHighLights(response.highlights);
            }
          } else {
            this.getDocumentTotalCaptureAnnotations();
            this.getDocumentAnnotations();
          }
        },                                                                                                 error => {
          this.messagingService.showToast("Error getting dynamodb highlights", SeverityType.ERROR);
        }
      )
    );
  }

  /**
   * Marks the specified document page IDs as processed for retrieving DynamoDB highlights.
   * @param {string[]} documentPageIds - An array of document page IDs to be marked as processed.
   */
  updateProcessedDocumentPageIds(documentPageIds: string[]): void {
    documentPageIds?.map(documentPageId => this.processedHighlightPageIds.set(documentPageId, true));
  }

  private tryLoading(): void {
    if (this.currentPageNumber > 0 && this.totalPages > 0) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.state.shouldLoadMore) {
            const pagesToLoad = this.state.getPagesToLoad();
            if (ArrayHelper.isAvailable(pagesToLoad)) {
              pagesToLoad.forEach(pageNumber => this.setPage({pageNumber} as any));
              this.loadSubsequent(pagesToLoad).subscribe(documentPages => {
                this.getCdnImages(documentPages);
                this.setPages(documentPages.pages);
                this.cleanCache();
              });
            }
          }
        })
      );
    }
  }

  updateTotalPages(deletedPages: number): void {
    if (NumberHelper.isGreaterThan(this.state.totalPages, 0)) {
      this.state.totalPages = (this.state.totalPages - deletedPages);
    }
  }

  private tryLoadingJump(): void {
    if (this.currentPageNumber > 0 && this.totalPages > 0) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          if (this.state.shouldLoadMore) {
            const pagesToLoad = this.state.getPagesToLoad();

            if (ArrayHelper.isAvailable(pagesToLoad)) {
              pagesToLoad.forEach(pageNumber => this.setPage({pageNumber} as any));
              this.loadSubsequent([this.currentPageNumber]).subscribe(documentPages => {
                this.getCdnImages(documentPages);
                this.setPages(documentPages.pages);

                this.cleanCache();

                for (let i = 0; i < pagesToLoad.length; i += 2) {
                  const start = i;
                  const end = i + 1;

                  if (pagesToLoad[start] && pagesToLoad[end]) {
                    this.loadSubsequent([pagesToLoad[start], pagesToLoad[end]]).subscribe(pageRange => {
                      this.getCdnImages(pageRange);
                      this.setPages(pageRange.pages);
                      this.cleanCache();
                    });
                  } else {
                    this.loadSubsequent([pagesToLoad[start]]).subscribe(pageSingle => {
                      this.getCdnImages(pageSingle);
                      this.setPages(pageSingle.pages);
                      this.cleanCache();
                    });
                  }
                }
              });
            }
          }
        })
      );
    }
  }

  private cleanCache(): void {
    if (this.cacheTracker.isLimitReached) {
      this.zone.runOutsideAngular(() => {
        const minTime = this.cacheTracker.currentTime;
        setTimeout(() => {
          for (let i = 0; i < this.pages.length; ++i) {
            if (this.pages[i] != null && this.pages[i].time < minTime) {
              this.pages[i] = null;
            }
          }
        });
      });
    }
  }

  private cleanCacheAfterNGDestory(): void {
    this.clearCache = true;

    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        for (let i = 0; i < this.pages.length; ++i) {
          this.pages[i] = null;
        }
      });
    });

    this.sessionService.delete(this.THUMBNAILKEY);
  }

  // private load(pages: number[]): Observable<DocumentPages> {
  //   const begPage = pages[0];
  //   const endPage = pages[pages.length - 1];

  //   return this.retrievalDocumentService.getPages(this.cleanDocumentId, this.sourceDocumentTypeId, begPage, endPage, this.sessionOptimizeImageSaved, this.pageTypeId,
  //     null,
  //     true, this.cdnBucketFolders);
  // }

  private loadSubsequent(pages: number[], includeImageString?: boolean): Observable<DocumentPages> {
    const begPage = pages[0];
    const endPage = pages[pages.length - 1];

    return this.retrievalDocumentService.getSubsequentPages(this.cleanDocumentId, this.sourceDocumentTypeId, begPage, endPage, this.sessionOptimizeImageSaved, this.pageTypeId,
                                                            includeImageString, this.isBucketFolderChecked,
                                                            null,
                                                            true, false, this.cdnBucketFolders);
  }

  private loadPageWithContent(pages: number[], includeImageString?: boolean): Observable<DocumentPages> {
    const begPage = pages[0];
    const endPage = pages[pages.length - 1];
    return this.retrievalDocumentService.getSubsequentPages(this.cleanDocumentId, this.sourceDocumentTypeId, begPage, endPage, this.sessionOptimizeImageSaved, this.pageTypeId,
                                                            includeImageString, this.isBucketFolderChecked, null, null, true, this.cdnBucketFolders);
  }

  private updateViewedCurrentPage(): void {
    if (this.hasCurrentPage) {
      this.currentPage.viewed = true; // NOTE: Need this for now but Obsolete?
      this.documentViewerSessionService.add(this.cleanSourceDocumentTypeId, this.cleanDocumentId, this.currentPage);
    }
  }

  private rotateImage(image: HTMLImageElement, degrees: number): string {
    // TODO: Use https://www.nuget.org/packages/SkiaSharp instead.
    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;
    const angle = (degrees === 90) ? 90 : 270;

    // create canvas
    const canvas = document.createElement("canvas");
    canvas.width = imageHeight;
    canvas.height = imageWidth;
    canvas.style.width = "20%";

    // get canvas context
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // center and rotate canvas
    ctx.translate(imageHeight / 2, imageWidth / 2);
    ctx.rotate(angle * (Math.PI / 180));

    // draw from center
    ctx.drawImage(image, -imageWidth / 2, -imageHeight / 2);

    return canvas.toDataURL("image/png", 1).split(",")[1];
  }

  private saveImage(pageToSave: DocumentPage, rotateDegrees: number): void {
    this.retrievalDocumentService.rotateDocumentPage(pageToSave.documentPageId, rotateDegrees)
      .subscribe(url => {
        if (!StringHelper.isAvailable(url)) {
          this.messagingService.showToast("Page Rotation has failed, please try again", SeverityType.ERROR);
          return;
        }
        this.updatePageAfterRotation(this.currentIndex, url);
        this.messagingService.showToast("Page Rotation has been updated", SeverityType.SUCCESS);
        return;
      });
  }

  private updatePageAfterRotation(currentPage: number, url: string): void {
    const page = this.currentPages[currentPage];
    if (this.currentPage.isCdnChartImageExist) {
      page.isCdnChartImageExist = false;
    }
    this.state.setPage(new DocumentPage({...page, image: url}));
    this.state.pages$.next(this.state.pages);
  }

  private validImageData({image}: DocumentPage): boolean {
    // NOTE: The number 100 is an arbitrary length.
    const validStringLength = StringHelper.isAvailable(image) && image.length > 100;
    return validStringLength;
  }

  private enablePageNumberInput(): void {
    this.currentPageNumberForm.get(this.currentPageNumberInput.getMasterKey()).enable();
    this.currentPageNumberInput.disabled = false;
    this.cd.markForCheck();
  }

  private setPage(page: DocumentPage): void {
    if (StringHelper.isAvailable(page.source)) {
      const time = performance.now();
      page.time = time;
      this.cacheTracker.add(time);
      this.state.setPage(page);
    }
  }

  private setPages(pages: DocumentPage[]): void {
    pages.forEach(this.setPage.bind(this));
    this.pages$.next(this.pages);
    this.cd.markForCheck();
  }

  trackByIndex(index, item) {
    return index;
  }

  /* #region OCR */
  getWordStyles(word: OcrWord): any {
    const isSelected = this.ocrCurrentPage.ocrMatches[this.ocrCurrentPage.currentIndex].ocrWords.includes(word);
    const width = this.isWindowOpen ? "auto" :
      `${(word.boundingBoxes[1].x - word.boundingBoxes[0].x) * (this.zoomPercentage)}%`;
    const imageSize = document.querySelector("#documentsImg") as HTMLElement;
    const updatedHeigh = Number(this.zoomStyle.height.slice(0, -2));
    if (this.isWindowOpen) {
      const newYHeight = (word.boundingBoxes[1].y - word.boundingBoxes[0].y) * updatedHeigh;
      const newXWidth = (word.boundingBoxes[1].x - word.boundingBoxes[0].x) * imageSize.offsetWidth;
      const newXInit = (word.boundingBoxes[0].x * imageSize.offsetWidth) + this.margin;
      const newYInit = (word.boundingBoxes[0].y * updatedHeigh);
      return {
        width: `${newXWidth}px`,
        height: `${newYHeight}px`,
        top: `${newYInit}px`,
        left: `${newXInit}px`,
        position: "absolute",
        backgroundColor: isSelected ? "#00FF11" : "#00F6FF",
        opacity: "0.5",
      };
    } else {
      return {
        width,
        height: `${(word.boundingBoxes[1].y - word.boundingBoxes[0].y) * updatedHeigh}px`,
        top: `${word.boundingBoxes[0].y * updatedHeigh}px`,
        left: `${word.boundingBoxes[0].x * (this.zoomPercentage)}%`,
        position: "absolute",
        backgroundColor: isSelected ? "#00FF11" : "#00F6FF",
        opacity: "0.5",
      };
    }
  }

  getOcrWords(page: DocumentPage): OcrWord[] {
    const ocrPage = this.getOcrPage(page);
    const phrases = ocrPage == null ? [] : ocrPage.ocrMatches;
    const words = phrases.reduce((acc, phrase) => {
      acc.push(...phrase.ocrWords);
      return acc;
    },                           []);
    return words;
  }

  getOcrPage(page: DocumentPage): DocumentPageOcrMatches {
    const ocrPage = this.hasOcrWords(page) ? this.documentOCRMatch.pages.find(a => a.pageNumber === page.pageNumber) : null;
    return ocrPage;
  }

  hasOcrWords({pageNumber}: DocumentPage): boolean {
    return this.hasOcrSearchResults && this.documentOCRMatch.pages.find(p => p.pageNumber === pageNumber) != null;
  }

  hasOcrWordsAndDocumentMetaData(page: DocumentPage): boolean {
    return this.hasPageAndSource(page) && this.hasOcrWords(page);
  }

  gotoNextOCRMatchPage(): void {
    this.documentOCRMatch.nextPage();
    this.scrollToOcrCurrentWord();
  }

  gotoPrevOCRMatchPage(): void {
    this.documentOCRMatch.prevPage();
    this.scrollToOcrCurrentWord();
  }

  gotoNextOCRMatchPageWord(): void {
    if (this.hasOcrSearchResults) {
      this.ocrCurrentPage.nextWord();
      this.scrollToOcrCurrentWord();
    }
  }

  gotoPrevOCRMatchPageWord(): void {
    if (this.hasOcrSearchResults) {
      this.ocrCurrentPage.prevWord();
      this.scrollToOcrCurrentWord();
    }
  }

  scrollToOcrCurrentWord() {
    const offset = this.ocrCurrentPage.offset(this.itemSize);
    this.viewport?.scrollToOffset(offset);
  }

  onOcrSearchPhraseFocus(input: HTMLElement, ocr: HTMLElement): void {
    this.resetPagePosition();
    this.isOcrFocus = true;
    const {left, bottom} = input.getBoundingClientRect();
    ocr.style.top = `${bottom - 2}px`;
    ocr.style.left = `${left}px`;
  }

  preventHide(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  showElement(element: HTMLElement): void {
    element.hidden = false;
  }

  fetchOcrMatches(ocr: HTMLElement): void {
    this.isOcrSearchInProgress = true;
    this.documentOCRMatch = null;
    const searchPhrase = this.searchPhraseForm.get(this.searchPhraseInput.key).value;
    this.showElement(ocr);

    this.retrievalDocumentService
      .getOCRMatches(this.cleanDocumentId, searchPhrase, this.sourceDocumentTypeId)
      .pipe(finalize(() => {
        this.isOcrSearchInProgress = false;
        this.cd.markForCheck();
      }))
      .subscribe(data => {
        this.documentOCRMatch = data;
        this.documentOCRMatch.firstViewed = false;
        const hasPages = ArrayHelper.isAvailable(this.documentOCRMatch.pages);
        this.documentOCRMatch.currentOcrPageNumberIndex = hasPages ? 0 : -1;
        if (hasPages) {
          this.scrollToOcrCurrentWord();
          this.showOcrSearchIcon = false;
        }
        this.cd.markForCheck();
      });
    this.cd.markForCheck();
  }

  resetOcrSearchInput(): void {
    this.searchPhraseForm.get(this.searchPhraseInput.key).reset();
    this.clearOcrSearch();
  }

  private clearOcrSearch(): void {
    this.documentOCRMatch = null;
    if (this.ocrSearchResults) {
      this.ocrSearchResults.nativeElement.hidden = true;
    }
    this.showOcrSearchIcon = true;
  }

  private updateProjectConfiguration(): void {
    if (this.projectConfiguration != null) {
      this.enableMrrBot =
        StringHelper.isAvailable(this.projectConfiguration.enableMrrBot) ? this.projectConfiguration.enableMrrBot : "0";

      this.isMemberProjectRetrievalType = Number(this.projectConfiguration.memberCentricChaseRetrieval) === ProjectRetrievalType.Member;

      this.medicalRecordUploadService.markMemberCentricProject(this.isMemberProjectRetrievalType);
    }
  }

  getMemberData(): void {
    this.memberBtnActive ? this.clearMemberNlpData() : this.getMemberNlpData();

    this.cd.markForCheck();
  }

  private getMemberNlpData(): void {
    this.retrievalDocumentService
      .getMemberMatches(this.chaseId)
      .pipe(finalize(() => {
        this.memberBtnActive = true;
        this.isNlpMemberEnabled = true;
        this.cd.markForCheck();
      }))
      .subscribe(data => {
        this.resetPagePosition();
        this.memberNlpMatch = data;
        this.cd.markForCheck();
      });
  }

  private clearMemberNlpData(): void {
    this.memberNlpMatch = null;
    this.memberBtnActive = false;
    this.isNlpMemberEnabled = false;
  }

  toggleThumbnailView(data: any): void {
    this.isExpandedViewFromToggle = data.view === "expand";
    this.isExpandedViewFromUrl = false;

    if (!this.isExpandedViewFromToggle) {
      this.isViewChanged = true;
      setTimeout(() => {
        // this.gotoPage(data.pageNumber);
      },         0);
    }

    this.cd.markForCheck();
  }

  /* #endregion DOS NLP */

  /* #region Optimize Images */
  optimizeChart(): void {
    this.isHighResolutionImage = true;
    this.sessionService.put(this.sessionOptimizeImageName, this.isHighResolutionImage);
    this.retrievalDocumentService.createOptimizeHiresMetrics(this.chaseId)
    .subscribe(result => {
      this.isOptimizedImagesAvailable = true;
    });
  }

  private verifyOptimizedImagesAreAvailable(): void {
    this.retrievalDocumentService
      .verifyOptimizedImagesAreAvailable(this.chaseId)
      .subscribe(result => {
        this.isOptimizedImagesAvailable = result;
        this.cd.markForCheck();
      });
  }

  getSource(page: any, src: string) {
    if (!StringHelper.isAvailable(src)) {
      return "";
    }
    return page.isCdnChartImageExist ? src.replace("data:image/png;base64,", "") : src;
  }

  /* #endregion Optimize Images */

  selectedFilter(value: any) {
    this.selectedFilterValues = value;
    this.selectedFilters.emit(value);
  }

  selectedthumbnailEvent(value: any) {
    this.onSelectionthumbnailEvent.emit(value);
  }

  selectedDocumentThumbnailItems(data: any): void {
    this.allSelectedThumbnailItems = data;
    this.cd.markForCheck();
  }

  resetToggleSwitch(value: any) {
    this.resetMoveBackToggleButtonSwitch = value;
  }

  isExpandedView(value: any) {
    this.expandedView = value;
  }

  isExpandedLabelView(value: any) {
    this.expandedLabelView = value;
  }

  highlightToDelete(event: any) {
    const userDocumentPage = this.userDocumentPages.find(p => p.chaseDocumentPageId === event.pageId);
    const newAnnotations = userDocumentPage.annotations.filter(a => a.createDate !== event.data.createDate);
    const deletedHighlightId = StringHelper.isAvailable(event.data.highlightId) ? event.data.highlightId : null;
    this.saveDocumentAnnotations({
        chaseDocumentPageId: event.pageId,
        annotationSource: AnnotationSource.User,
        annotations: newAnnotations,
      },
                                 deletedHighlightId
    );
  }

  private loadMorePages(pageNumber: number): void {
    // Need to add + 1 since the array starts at 0 but the pages start at 1
    const actualPageNumber = pageNumber + 1;
    const isInRange = this.isInRange(actualPageNumber, this.startRange, this.endRange, this.state.pages, "image");
    if (!isInRange || this.currentPages.length <= 0) {
      return;
    }
    const pagesToLoad = this.getPagesToLoad(actualPageNumber, this.lowerLoadRange, this.upperLoadRange, this.state.pages, "image");
    if (!ArrayHelper.isAvailable(pagesToLoad)) {
      return;
    }
    this.loadSubsequentDocumentPages(
      this.retrievalDocumentService,
      "getSubsequentPages",
      pagesToLoad,
      this.cleanDocumentId,
      this.cdnBucketFolders,
      this.sourceDocumentTypeId,
      this.sessionOptimizeImageSaved || this.isOptimizedImagesAvailable,
      this.pageTypeId,
      this.isBucketFolderChecked,
      (documentPages => {
        const startItemIndex = documentPages.pages[0] ? documentPages.pages[0].pageNumber : 0;
        const length = documentPages.pages?.length - 1;
        const lastItemIndex = documentPages.pages[length].pageNumber;
        const updatedPages = Array.from(this.state.pages);
        updatedPages.splice(startItemIndex, lastItemIndex, ...(documentPages.pages));
        this.setPages([...updatedPages]);
        this.isBucketFolderChecked = (documentPages && documentPages.isCdnBucketFolderChecked) ? documentPages.isCdnBucketFolderChecked : false;

        // Get highlights for subsequent pages. we can make the api call immediately as chaseId is available.
        if (this.isHighlightsFromDynamoDB) {
          this.getNlpHighlightIdsToLoad(documentPages);
        }
      }));
  }


  private storageHandler(): void {
    try {

      const userId = this.authService.user.userId;
      const openChaseId = this.chaseId;

      const openChaseAndUserInfoDetail = {userId, openChaseId};

      const openChaseAndUserInfoObj = JSON.stringify(openChaseAndUserInfoDetail);

      const localChaseUserInfo = this.localService.get(this.openChaseAndUserInfo, null);


      if (!localChaseUserInfo) {
        this.localService.put(this.openChaseAndUserInfo, openChaseAndUserInfoObj);
      } else {
        const parsedLocalChaseUserInfo = JSON.parse(localChaseUserInfo);
        this.globalLocalStoreChaseId = parsedLocalChaseUserInfo.openChaseId;
        this.globalLocalStoreUserId = parsedLocalChaseUserInfo.userId;

        if (
          this.globalLocalStoreChaseId !== this.chaseId
          && this.globalLocalStoreUserId === this.authService.userId
          && this.router.url.includes("members")
        ) {
          this.messagingService.showMessage(`To work on this chase, please close chase id ${this.globalLocalStoreChaseId}.`, SeverityType.WARN);
        }

      }
    } catch (error) {
      console.error("Error handling storage event:", error);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.globalLocalStoreChaseId !== this.chaseId
        && this.globalLocalStoreUserId === this.authService.userId
        && this.router.url.includes("members")
        && this.isRoleAbstractorOrOverreader(this.authService?.user.directoryRoleIds)
      ) {
        const elements = document.querySelectorAll(".ui-messages-close");
        elements.forEach(element => {
          this.renderer.setStyle(element, "display", "none");
        });
      }
      this.viewport?.scrollToIndex(this.currentPageNumber - 1);
      this.cd.detectChanges();
    },         3000);
  }

}
