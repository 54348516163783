import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MessagingService } from "../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../core/messaging/severity-type.enum";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { AuditPackageGenerateService } from "../../../../audit/audit-query/audit-package-generate/audit-package-generate.service";
import { AuditQueryGridService } from "../../../../audit/audit-query/audit-query-grid/audit-query-grid.service";
import { AuditQueryHeaderService } from "../../../../audit/audit-query/audit-query-header/audit-query-header.service";
import { ChaseDetailForAuditItem } from "../../../../audit/chase-detail-for-audit-item.model";
import { AuditPageEntries } from "./audit-page-entries.model";
import { AuditPageEntryService } from "./audit-page-entry.service";
import { PageEntryItem } from "./page-entry-item.model";

@Component({
  selector: "app-audit-page-entry",
  templateUrl: "./audit-page-entry.component.html",
  styleUrls: ["./audit-page-entry.component.scss"],
})
export class AuditPageEntryComponent implements OnInit, OnDestroy {
  @Input() chaseDetailsForAudit: ChaseDetailForAuditItem;
  @Input() hasNoDocumentPages: boolean;
  @Input() chaseDocumentPageCount = 0;
  @Output() includeCodingPrintScreen = new EventEmitter<number>();
  private unsubscribe = new Subject();
  pagesListForm: FormGroup;
  isAuditActionEdit = false;
  pageEntryList: PageEntryItem[] = [];
  pageEntryError = false;
  includeDataEntryPrintScreen = false;
  hasNoChartPagesError = false;

  constructor(private fb: FormBuilder,
              private auditPageEntryService: AuditPageEntryService,
              private auditQueryHeaderService: AuditQueryHeaderService,
              private auditQueryGridService: AuditQueryGridService,
              private auditPackageGenerateService: AuditPackageGenerateService,
              private messagingService: MessagingService,
              private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.pagesListForm = this.fb.group({
      page: this.fb.array([]),
    });

    this.isAuditActionEdit = this.chaseDetailsForAudit.action.toLowerCase() === "edit";

    this.auditPageEntryService.auditPages
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        if (NumberHelper.isAvailable(data)) {
          this.getPageEntriesForEdit(data);
        }
      });

    this.auditPageEntryService.auditPrintScreenComplete
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(printScreenResult => {
        if (printScreenResult) {
          this.messagingService.showToast("Coding Screenshot taken successfully.", SeverityType.SUCCESS);
          this.onSavePageSelectionComplete();
        }
      });

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getPageEntriesForEdit(auditPackageItemId: number): void {
    this.auditPageEntryService.getPageEntries(auditPackageItemId)
      .subscribe(data => {
        this.includeDataEntryPrintScreen = data.isDataEntryPrinting;
        this.pageEntryList = data.pageEntries;

        const pageEntryForArray = this.pagesListForm.get("page") as FormArray;
        this.pageEntryList.forEach(pageEntry => {
          pageEntryForArray.push(
            new FormGroup({
              StartPage: new FormControl(pageEntry.startPage),
              EndPage: new FormControl(pageEntry.endPage),
            })
          );
        });
        this.changeDetector.markForCheck();
      });
  }

  get pageEntryControls() {
    return (this.pagesListForm.get("page") as FormArray).controls;
  }

  addPageRange() {
    (this.pagesListForm.get("page") as FormArray).push(this.buildEmptyListPageRange());
  }

  buildEmptyListPageRange(): FormGroup {
    return new FormGroup({
      StartPage: new FormControl(),
      EndPage: new FormControl(),
    });
  }

  onSaveSelection(): void {

    if (this.hasNoDocumentPages) {
      this.hasNoChartPagesError = true;
      return;
    }
    this.validateIfPageEntryExist();

    if (!this.pageEntries.valid) {
      return;
    }

    if (!this.pageEntryErrors && this.pageEntries.valid) {
      const saveSelectionModel = new AuditPageEntries({
        auditPackageItemId: this.chaseDetailsForAudit.auditPackageItemId,
        auditPackageId: this.chaseDetailsForAudit.auditPackageId,
        pageEntries: this.pageEntryList,
        isDataEntryPrinting: this.includeDataEntryPrintScreen,
      });

      this.auditPageEntryService.savePageEntries(saveSelectionModel).subscribe(
        data => {
          this.messagingService.showToast("Page Entries saved successfully.", SeverityType.SUCCESS);
          this.changeDetector.markForCheck();

          if (saveSelectionModel.isDataEntryPrinting) {
            this.includeCodingPrintScreen.emit(data);
          } else {
            this.onSavePageSelectionComplete();
          }
        },
        err => {
          this.messagingService.showToast("Failed to save Page Entries.", SeverityType.ERROR);
          this.changeDetector.markForCheck();
          this.resetPageEntryAfterSaveSelection();
        }
      );
    }
  }

  onSavePageSelectionComplete(): void {
    this.resetPageEntryAfterSaveSelection();
    this.auditPageEntryService.codingScreenPrintScreenComplete(false);
    this.auditQueryHeaderService.changeTab("auditquery");

    // SetTimeout is used here to allow the previous dom to get destroyed before loading the new dom for audit query tab.
    setTimeout(() => {
      this.refreshAuditQueryGrid();
    },         500);
  }

  private refreshAuditQueryGrid(): void {
    this.auditQueryGridService.reloadAuditGridFromState();
    this.auditPackageGenerateService.refreshAuditPackageGrid(this.chaseDetailsForAudit.projectId);
  }

  get pageEntries(): FormArray {
    return this.pagesListForm.get("page") as FormArray;
  }

  private validateIfPageEntryExist(): void {

    if (this.isAuditActionEdit) {
      this.pageEntryList = [];
    }

    let pageEntryIndex = 0;
    for (const pageEntry of this.pageEntries.controls) {
      const pageItem = pageEntry as FormGroup;
      this.setStartPageSelectionErrors(pageEntryIndex);
      this.setEndPageSelectionErrors(pageEntryIndex);

      if (!pageItem.valid) {
        break;
      }

      const startPage = Number(pageItem.controls.StartPage.value);
      const endPage = Number(pageItem.controls.EndPage.value);

      const pageEntryObject = new PageEntryItem({
        startPage,
        endPage,
      });

      this.pageEntryList.push(pageEntryObject);

      pageEntryIndex += 1;
    }

  }

  get disableSaveSelection(): boolean {
    return (this.pagesListForm.get("page").value.length === 0);
  }

  hasPageEntryErrors(index: number): boolean {
    this.changeDetector.markForCheck();
    return this.pageEntries.at(index).get("StartPage").invalid || this.pageEntries.at(index).get("EndPage").invalid;
  }

  get pageEntryErrors(): boolean {
    return this.pageEntries.invalid;
  }

  setStartPageSelectionErrors(index: number): void {
    const startPageNumber = Number(this.pageEntries.at(index).get("StartPage").value);

    if (!NumberHelper.isGreaterThan(startPageNumber, 0)) {
      this.pageEntries.at(index).get("StartPage").setErrors({ saveError: "Start Page Number cannot be empty or 0." });
    } else if (NumberHelper.isGreaterThan(startPageNumber, this.chaseDocumentPageCount)) {
      this.pageEntries.at(index).get("StartPage").setErrors({ saveError: "Start Page Number cannot be greater than total pages." });
    } else {
      this.pageEntries.at(index).get("StartPage").setErrors(null);
    }

    this.changeDetector.markForCheck();
  }

  setEndPageSelectionErrors(index: number): void {
    const startPageNumber = Number(this.pageEntries.at(index).get("StartPage").value);
    const endPageNumer = Number(this.pageEntries.at(index).get("EndPage").value);

    if (!NumberHelper.isGreaterThan(endPageNumer, 0)) {
      this.pageEntries.at(index).get("EndPage").setErrors({ saveError: "End Page Number cannot be empty or 0." });
    } else if (NumberHelper.isGreaterThan(endPageNumer, this.chaseDocumentPageCount)) {
      this.pageEntries.at(index).get("StartPage").setErrors({ saveError: "End Page Number cannot be greater than total pages." });
    } else if (NumberHelper.isGreaterThan(startPageNumber, 0) && NumberHelper.isGreaterThan(startPageNumber, endPageNumer)) {
      this.pageEntries.at(index).get("StartPage").setErrors({ saveError: "Start page Number cannot be greater than End Page Number." });
    } else {
      this.pageEntries.at(index).get("EndPage").setErrors(null);
    }

    this.changeDetector.markForCheck();
  }

  pageEntryItemErrors(index: number): string {
    this.changeDetector.markForCheck();
    const errors = this.pageEntries.at(index).get("StartPage").errors || this.pageEntries.at(index).get("EndPage").errors;
    if (errors == null) {
      return "";
    }

    const keys = Object.keys(errors);
    if (!ArrayHelper.isAvailable(keys)) {
      return "";
    }

    const errorMessage = errors[keys[0]] || "";
    return errorMessage.toString();
  }

  resetPageEntryAfterSaveSelection(): void {
    this.pageEntryList = [];
    this.pagesListForm.get("page").reset();
    this.pagesListForm = this.fb.group({
      page: this.fb.array([]),
    });
  }

  trackByIndex(index, item) {
    return index;
  }

}
