import { Provider } from "../../../../provider/provider.model";
import { MemberCentricChase } from "../../../../shared/membercentric-doc-attachment/membercentric-chase.model";
import { TagItems } from "../../../../shared/tags/model/tag-items.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../../utilities/contracts/boolean-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { DynamicEntity } from "../../../api/member-validation/dynamic-entity.model";
import { EntityError } from "../../../api/member-validation/entity-error.model";
import { EntityType } from "../../../api/member-validation/entity-type.enum";
import { WorkflowStatusDb } from "../../../api/workflow/workflow-status-db.enum";
import { Member } from "../../clinical/member.model";
import { ChaseSubmissionItem } from "../../project/project-config/models/chase-submission-item.model";
import { ProjectConfiguration } from "../../project/project-config/project-configuration.model";
import { ProjectType } from "../../project/project-type.enum";
import { Project } from "../../project/project.model";
import { MEMBER_VALIDATION_RESULT } from "./chase-detail-chart/attributes";
import { RiskState } from "./chase-detail-chart/risk/risk-state.model";
import { ChaseDetailInfoPursuitItemModel } from "./chase-detail-info/chase-detail-info-pursuits/chase-detail-info-pursuit-item/chase-detail-info-pursuit-item-model";

export class ChaseDetailState {
  addressId: number; // TODO: is this always a number?
  assignedToUserId: number;
  assignedToName: string; // TODO: Add currentUserName?
  chaseEntityData?: DynamicEntity[];
  chaseDocumentAnnotation: boolean;
  chaseDocumentId: number;
  chaseDocumentPageCount: number;
  chaseId: number;
  chaseOverreadFeedback: boolean;
  chasePendId: number;
  chaseSourceAliasId: string;
  chaseSubmissionItem: ChaseSubmissionItem[];
  coRetrievalOwner: string;
  diagnosisValidationReasonCodes: string[];
  displayNlpResults: boolean;
  displayOcrBlocks: boolean;
  documentSourceTypeId: number;
  enrolleeId?: string;
  entities: DynamicEntity[];
  entityErrors: EntityError[];
  gapClosureEnabled: boolean;
  gapProviderOutreachEnabled: boolean;
  gapPursuitRequiresApproval: boolean;
  groupName: string;
  hccModelDisplayName: string;
  hccModelLineOfBusiness: string;
  hedis: null;
  isCorrectDob: boolean;
  isCorrectGender: boolean;
  isCorrectMember: boolean;
  isFirstPassBotEnabled: boolean;
  isMemberCentric: boolean;
  isPageLoadInit: boolean;
  isProjectEnabledForRiskNlp: boolean;
  lastCoder: string;
  masterDocumentSourceId: number;
  measureCode: string;
  measureId: number;
  measureYear: number;
  member: Member;
  memberCentricChases?: MemberCentricChase[];
  memberValidation: DynamicEntity;
  memberVerified: any;
  medicalRecordDateOfBirth?: string;
  negativeReasonCodeRequired?: boolean;
  numeratorList: string;
  ocrDataAvailable: boolean;
  owner: string;
  parentChaseId: number;
  pendCode: string;
  pendName: string;
  pendOwner: string;
  pendStatusId: number;
  pendStatusName: string;
  product: string;
  project: Project;
  projectConfiguration: ProjectConfiguration;
  projectTypeId: ProjectType;
  providers: Provider[];
  pursuitData?: ChaseDetailInfoPursuitItemModel[];
  reportingStatusName: string;
  risk: RiskState;
  tagItems?: TagItems[];
  totalDocumentPages: number;
  userDefinedValue: string;
  workflowStatus: WorkflowStatusDb;
  workflowStatusName: string;

  constructor(options: Partial<ChaseDetailState> = {}) {
    this.addressId = options.addressId;
    this.assignedToUserId = options.assignedToUserId;
    this.assignedToName = StringHelper.clean(options.assignedToName);
    this.chaseEntityData = ArrayHelper.clean(options.chaseEntityData);
    this.chaseDocumentAnnotation = BooleanHelper.tryGet(options.chaseDocumentAnnotation, false);
    this.chaseDocumentPageCount = options.chaseDocumentPageCount;
    this.chaseDocumentId = options.chaseDocumentId;
    this.chaseId = options.chaseId;
    this.chaseOverreadFeedback = BooleanHelper.tryGet(options.chaseOverreadFeedback, false);
    this.chasePendId = options.chasePendId;
    this.chaseSourceAliasId = StringHelper.clean(options.chaseSourceAliasId);
    this.chaseSubmissionItem = ArrayHelper.clean(options.chaseSubmissionItem);
    this.coRetrievalOwner = StringHelper.clean(options.coRetrievalOwner);
    this.diagnosisValidationReasonCodes = ArrayHelper.clean(options.diagnosisValidationReasonCodes);
    this.displayNlpResults = BooleanHelper.tryGet(options.displayNlpResults, false);
    this.displayOcrBlocks = BooleanHelper.tryGet(options.displayOcrBlocks, false);
    this.documentSourceTypeId = options.documentSourceTypeId;
    this.enrolleeId = StringHelper.clean(options.enrolleeId);
    this.entities = ArrayHelper.clean(options.entities);
    this.entityErrors = ArrayHelper.clean(options.entityErrors);
    this.gapClosureEnabled = BooleanHelper.tryGet(options.gapClosureEnabled, false);
    this.gapProviderOutreachEnabled = BooleanHelper.tryGet(options.gapProviderOutreachEnabled, false);
    this.gapPursuitRequiresApproval = BooleanHelper.tryGet(options.gapPursuitRequiresApproval, false);
    this.groupName = StringHelper.clean(options.groupName);
    this.hccModelDisplayName = options.hccModelDisplayName;
    this.hccModelLineOfBusiness = options.hccModelLineOfBusiness;
    this.hedis = options.hedis;
    this.isCorrectDob = options.isCorrectDob;
    this.isCorrectGender = options.isCorrectGender;
    this.isCorrectMember = options.isCorrectMember;
    this.isFirstPassBotEnabled = BooleanHelper.tryGet(options.isFirstPassBotEnabled, false);
    this.isMemberCentric = BooleanHelper.tryGet(options.isMemberCentric, false);
    this.isPageLoadInit = BooleanHelper.tryGet(options.isPageLoadInit, false);
    this.isProjectEnabledForRiskNlp = BooleanHelper.tryGet(options.isProjectEnabledForRiskNlp, false);
    this.lastCoder = StringHelper.clean(options.lastCoder);
    this.masterDocumentSourceId = options.masterDocumentSourceId;
    this.measureCode = StringHelper.clean(options.measureCode);
    this.measureId = options.measureId;
    this.measureYear = options.measureYear;
    this.member = options.member;
    this.memberCentricChases = ArrayHelper.clean(options.memberCentricChases);
    this.memberValidation = options.memberValidation || {} as any;
    this.memberVerified = options.memberVerified;
    this.medicalRecordDateOfBirth = StringHelper.clean(options.medicalRecordDateOfBirth);
    this.negativeReasonCodeRequired = BooleanHelper.tryGet(options.negativeReasonCodeRequired, true);
    this.numeratorList = StringHelper.clean(options.numeratorList);
    this.ocrDataAvailable = options.ocrDataAvailable;
    this.owner = options.owner;
    this.parentChaseId = options.parentChaseId;
    this.pendCode = StringHelper.clean(options.pendCode);
    this.pendName = StringHelper.clean(options.pendName);
    this.pendOwner = StringHelper.clean(options.pendOwner);
    this.pendStatusId = options.pendStatusId;
    this.pendStatusName = StringHelper.clean(options.pendStatusName);
    this.product = StringHelper.clean(options.product);
    this.project = options.project;
    this.projectConfiguration = options.projectConfiguration;
    this.projectTypeId = options.projectTypeId;
    this.providers = ArrayHelper.clean(options.providers);
    this.pursuitData = options.pursuitData;
    this.reportingStatusName = options.reportingStatusName;
    this.risk = options.risk;
    this.tagItems = options.tagItems;
    this.userDefinedValue = options.userDefinedValue;
    this.workflowStatus = options.workflowStatus;
    this.workflowStatusName = StringHelper.clean(options.workflowStatusName);
    this.totalDocumentPages = options.totalDocumentPages;
  }

  get formEnities(): DynamicEntity[] {
    return this.entities.filter(a => (
      a.entityTypeId !== EntityType.MEMBER_VALIDATION &&
      a.entityTypeId !== EntityType.MISCELLANEOUS &&
      a.entityTypeId !== EntityType.WORKFLOW
    ));
  }

  get hasChaseDocumentId(): boolean {
    return NumberHelper.isGreaterThan(this.chaseDocumentId, 0);
  }

  get hasGaps(): boolean {
    return StringHelper.isAvailable(this.numeratorList);
  }

  get hasMember(): boolean {
    return this.member != null;
  }

  get hasMemberValidation(): boolean {
    return this.memberValidationEntity != null && ArrayHelper.isAvailable(this.memberValidationEntity.attributes);
  }

  get hasPend(): boolean {
    return NumberHelper.isGreaterThan(this.chasePendId, 0);
  }

  get hasProject(): boolean {
    return this.project != null;
  }

  get isAdrv(): boolean {
    return this.measureCode === "ADRV";
  }

  get isDataEntryWorkflow(): boolean {
    return this.workflowStatus === WorkflowStatusDb.Abstraction
      || this.workflowStatus === WorkflowStatusDb.Overread
      || this.workflowStatus === WorkflowStatusDb.Overread2;
  }

  get isEnabled(): boolean {
    return this.isMemberValidated || this.isAdrv;
  }

  get isIVAProject(): boolean {
    return this.project?.projectTypeId === ProjectType.IVA;
  }

  get isMemberValidated(): boolean {
    return this.hasMemberValidation && this.validationResult();
  }

  validationResult(): boolean {
    const attribute = this.memberValidationEntity.attributes.filter(a => a.attributeId === MEMBER_VALIDATION_RESULT.attributeId);
    if (ArrayHelper.isAvailable(attribute)) {
     return Number(attribute[0].value) === 1;
    }
    return false;
  }

  get isMrr(): boolean {
    return this.workflowStatus === WorkflowStatusDb.Abstraction;
  }

  get isRiskProject(): boolean {
    return this.project?.projectTypeId === ProjectType.RISK;
  }

  get isMeasureCodesToEnableNlp(): boolean {
    return this.measureCode === "HCC" || this.measureCode === "HST" || this.measureCode === "NEC";
  }

  get isOverread(): boolean {
    return this.workflowStatus === WorkflowStatusDb.Overread;
  }

  get isOverread2(): boolean {
    return this.workflowStatus === WorkflowStatusDb.Overread2;
  }

  get memberValidationEntity(): DynamicEntity {
    return this.entities.find(a => a.entityTypeId === EntityType.MEMBER_VALIDATION);
  }

  get miscellaneousEntity(): DynamicEntity {
    return this.entities.find(a => a.entityTypeId === EntityType.MISCELLANEOUS);
  }

  get miscellaneousEntityFromChaseEntityData(): DynamicEntity {
    return this.chaseEntityData?.find(a => a.entityTypeId === EntityType.MISCELLANEOUS);
  }

  get workflowEntity(): DynamicEntity {
    return this.entities.find(a => a.entityTypeId === EntityType.WORKFLOW);
  }

  get signatureConfirmationEntity(): DynamicEntity {
    return this.entities.find(a => a.entityTypeId === EntityType.SIGNATURE_CONFIRMATION);
  }

  get isBeyondMRR(): boolean {
    return this.workflowStatus === WorkflowStatusDb.Overread
      || this.workflowStatus === WorkflowStatusDb.Overread2
      || this.workflowStatus === WorkflowStatusDb.Delivery
      || this.workflowStatus === WorkflowStatusDb.Completed;
  }

  get isVrcRequired(): boolean {
    return StringHelper.isAvailable(this.projectConfiguration.validationReasonCodeRequired) && this.projectConfiguration.validationReasonCodeRequired === "1";
  }
}
