import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "../../../auth/auth.service";
import { PopoverComponent } from "../../../shared/panel/popover/popover.component";
import { AlertNotification } from "../../notification/alert-notification.model";
import { NotificationService } from "../../notification/notification.service";

@Component({
  selector: "platform-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private intervalUpdateCount;
  notifications: AlertNotification[];
  read: boolean;
  unreadNotificationCount = 0;

  @ViewChild("overlay", { static: true }) private overlay: PopoverComponent;


  constructor(
    private readonly service: NotificationService,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.service.eventCallback$.subscribe(data => {
      this.unreadNotificationCount = data;
      this.changeDetector.markForCheck();
  });
    this.getUnreadNotificationCount();
    if ((this.authService.user.isEmployeeRole && this.authService.user.isAdminRole) || !this.authService.user.isEmployeeRole) {
      this.intervalUpdateCount = setInterval(() => {
        this.getUnreadNotificationCount();
      },                                     120000);
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalUpdateCount);
  }


  get hasUnreadNotifications(): boolean {
    return this.unreadNotificationCount > 0;
  }

  get unreadAndActiveClass(): string {
    const unread = this.hasUnreadNotifications ? "notifications__unread" : "";
    const active = this.overlay.visible ? "notifications__active" : "";
    return `${unread} ${active}`.trim();
  }

  get countText(): string {
    return this.hasUnreadNotifications ? this.unreadNotificationCount.toString() : "";
  }

  show(event: MouseEvent) {
    if (!this.overlay.visible) {
      this.getNotificationsAndShow(event);
      this.markAllAsRead();
    }
  }


  private getUnreadNotificationCount() {
    this.service
      .getNotificationCount()
      .subscribe(items => {
        this.unreadNotificationCount = items;
        this.changeDetector.markForCheck();
      });
  }

  private getNotificationsAndShow(event: MouseEvent): void {
    this.service
      .getNotification()
      .subscribe(items => {
        this.notifications = items;
        this.overlay.show(event);
        this.changeDetector.markForCheck();
      });
  }

  private markAllAsRead(): void {
    this.service.ReadNotification().subscribe();
    this.read = false;
    this.unreadNotificationCount = 0;
    this.changeDetector.markForCheck();
  }


  trackByIndex(index, item) {
    return index;
  }
}
