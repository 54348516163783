import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { List } from "immutable";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinct, distinctUntilChanged, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { DocumentRequestService } from "../../../../shared/document/document-request.service";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridStateService } from "../../../../shared/grid/grid-state.service";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { ServerGridComponent } from "../../../../shared/grid/server-grid/server-grid.component";
import { LandingFilterStateService } from "../../../../shared/kpi/kpi-filter/landing-filter/landing-filter-state.service";
import { ListItem } from "../../../../shared/list/list-item";
import { TagType } from "../../../../shared/tags/model/tag-type.enum";
import { TagService } from "../../../../shared/tags/tag.service";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { WorkflowAction } from "../../../api/workflow/workflow-action.enum";
import { WorkflowSubmitRequest } from "../../../api/workflow/workflow-submit.model";
import { WorkflowService } from "../../../api/workflow/workflow.service";
import { WorkflowStatus } from "../../../api/workflow/worklow-status.enum";
import { ProjectDocumentType } from "../../project/project-files/project-document-type.enum";
import { ProjectFileService } from "../../project/project-files/project-file.service";
import { DocumentItem } from "../document-item";
import { RetrievalDocumentServiceService } from "../retrieval-document-service.service";
import { DocumentIntakeStats } from "./document-intake-stats.model";

enum HeaderStat { Assigned = "1", UnAssigned = "2", TotalDocuments = "3", Active = "4", OnHold = "5", Removed = "6", Completed = "7", Processing = "8" }

@Component({
  selector: "app-retreival-document-review",
  templateUrl: "./retreival-document-review.component.html",
  styleUrls: ["./retreival-document-review.component.scss"],
  providers: [RetrievalDocumentServiceService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetreivalDocumentReviewComponent implements OnInit, OnDestroy {
  refreshGrid = new EventEmitter<boolean>(true);
  rowDataModel: any[];
  myPopover: EventEmitter<any>;
  documentItems = List<DocumentItem>();
  isShowCloseIcon = false;
  docStatsList = [];
  isAssignModelVisible = false;
  serverGridConfiguration = new GridConfiguration();
  serverGridActions: BulkAction[] = [];
  serverRequest: GridRequest;
  serverGridSelection: any;

  documentIntakeStats: DocumentIntakeStats;
  assignToUserList: SelectableInput[] = [];
  assignToUsers: Autocomplete;
  formGroup: FormGroup;
  holdDocumentReasonsList: SelectableInput[] = [];
  idSubscriber: Subscription;
  selectedDocumentId: number;
  isAssignDisabled = true;
  filters: any[];
  selectedDocumentIds: number[] = [];
  private userId: number;
  documentStatuses: SelectableInput[] = [];
  selectedHeaderStat: string;
  tagType = TagType.DOCUMENT;
  @ViewChild(ServerGridComponent, { static: true }) serverGridComponent: ServerGridComponent;
  isManageTagModalVisible = false;
  totalEntityCount: number;
  entityName: string;
  selectedDocuments: any[];   // Note: selectedDocuments is used for actions / bulk actions.
  selectedObjectId: number;
  view = 0; // 0 = Default, 1 = Archive, 2 = Processing. These match tabIndexes
  lastActiveStat = "Total Documents";
  lastArchiveStat = "Completed";
  allAvailableTags: SelectableInput[];
  activeViewColumns: GridColumnDefinition[];
  defaultTagSearchOperator = "OR";
  selectedTagSearchOperator: string;
  overlayPanelVisible = false;
  stateName = "retrieval_doc_intake";
  lastProcessingStat = "Processing";
  sink = new SubSink();
  searchTextChanged = new Subject<string>();


  get assignedToInput(): Dropdown {
    return this.getInput("assignedTo") as Dropdown;
  }

  get selectedAssignedTo(): string {
    const filter = this.getFilter("assignedTo");
    return filter.value;
  }

  set selectedAssignedTo(value: string) {
    const filter = this.getFilter("assignedTo");
    filter.value = value.toString();
  }

  get activeStatusInput(): CheckboxGroup {
    return this.getInput("activeDocumentStatesCSV") as CheckboxGroup;
  }

  set activeStatusInput(value: CheckboxGroup) {
    this.setInput("activeDocumentStatesCSV", value);
  }

  get statusInput(): CheckboxGroup {
    return this.getInput("documentStates") as CheckboxGroup;
  }

  set statusInput(value: CheckboxGroup) {
    this.setInput("documentStates", value);
  }

  get completedStatusInput(): CheckboxGroup {
    return this.getInput("completedDocumentStatesCSV") as CheckboxGroup;
  }

  set completedStatusInput(value: CheckboxGroup) {
    this.setInput("completedDocumentStatesCSV", value);
  }

  get removedStatusInput(): CheckboxGroup {
    return this.getInput("removedDocumentStatesCSV") as CheckboxGroup;
  }

  set removedStatusInput(value: CheckboxGroup) {
    this.setInput("removedDocumentStatesCSV", value);
  }
  get processingStatusInput(): CheckboxGroup {
    return this.getInput("processingDocumentStatesCSV") as CheckboxGroup;
  }

  set processingStatusInput(value: CheckboxGroup) {
    this.setInput("processingDocumentStatesCSV", value);
  }

  get viewInput(): string {
    const filter = this.getFilter("view");
    return filter.value;
  }

  set viewInput(value: string) {
    const filter = this.getFilter("view");
    filter.value = value.toString();
  }

  get filenameInput(): Textbox {
    return this.getInput("fileName") as Textbox;
  }

  get aidInput(): Textbox {
    return this.getInput("masterDocumentSourceId") as Textbox;
  }

  get confirmationInput(): Textbox {
    return this.getInput("uploadBatchCode") as Textbox;
  }

  get documentIdInput(): Textbox {
    return this.getInput("documentId") as Textbox;
  }

  get retrievalMethodInput(): CheckboxGroup {
    return this.getInput("retrievalTypeIds") as CheckboxGroup;
  }

  set retrievalMethodInput(value: CheckboxGroup) {
    this.setInput("retrievalTypeIds", value);
  }

  get assignedToUserIdInput(): Autocomplete {
    return this.getInput("assignedToUser") as Autocomplete;
  }
  set assignedToUserIdInput(value: Autocomplete) {
    this.setInput("assignedToUser", value);
  }

  get lastUpdatedInput(): Autocomplete {
    return this.getInput("lastUpdatedUser") as Autocomplete;
  }
  set lastUpdatedInput(value: Autocomplete) {
    this.setInput("lastUpdatedUser", value);
  }

  get selectedUser(): SelectableInput {
    if (this.formGroup == null) {
      return null;
    }

    return this.formGroup.get("assignToUsers").value;
  }
  set selectedUser(value: SelectableInput) {
    if (this.formGroup == null) {
      return;
    }

    this.formGroup.get("assignToUsers").setValue(value);
  }
  get tagsInputFilter(): GridFilter {
    return this.serverRequest.getFilter("TagIdsAsCsv");
  }

  get tagsInput(): TagSearchMultiselect {
    return this.getInput("TagIdsAsCsv") as TagSearchMultiselect;
  }

  set tagsInput(value: TagSearchMultiselect) {
    this.setInput("TagIdsAsCsv", value);
  }

  get tagsSearchOperatorInput(): Dropdown {
    return this.getInput("TagSearchOperator") as Dropdown;
  }

  set tagsSearchOperatorInput(value: Dropdown) {
    this.setInput("TagSearchOperator", value);
  }

  constructor(
    private service: RetrievalDocumentServiceService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private workflowService: WorkflowService,
    private messagingService: MessagingService,
    public router: Router,
    private changeDetector: ChangeDetectorRef,
    private landingFilterStateService: LandingFilterStateService,
    private readonly documentRequestService: DocumentRequestService,
    private userService: UserService,
    private readonly projectFileService: ProjectFileService,
    private readonly formService: FormService,
    private readonly tagService: TagService,
    private readonly gridStateService: GridStateService
  ) { }

  ngOnInit() {
    this.userId = this.userService.getUserToken().userId;

    this.view = Number(localStorage.getItem("activeTab"));
    if (this.view !== 0) {
      this.onViewClicked({ index: this.view });
    }

    this.initializeFormGroupControls();
    this.selectedHeaderStat = this.getSelectedAssignedToId("Total Documents");
    this.setServerGridRequest();

    this.getDocumentStats();
    this.getDocumentStatuses();
    this.getRetreivalTypes();
    this.getTagSearchOperator();

    this.setServerGridConfiguration();
    this.setServerGridConfigurationActions();

    this.sink.add(
      this.searchTextChanged
          .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(searchText => this.getAllTagsList(searchText)))
          .subscribe()
      );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
    localStorage.removeItem("activeTab");
  }

  get selectedUserId(): number {
    if (this.selectedUser == null) {
      return null;
    }

    return +this.selectedUser.value;
  }

  get hasSelectedUser(): boolean {
    return NumberHelper.isGreaterThan(this.selectedUserId, -1, true);
  }

  getDocuments(assignedToName: string): void {

    switch (this.view) {
      case 0:
        this.lastActiveStat = assignedToName;
        break;
      case 1:
        this.lastArchiveStat = assignedToName;
        break;
      case 2:
        this.lastProcessingStat = assignedToName;
        break;
      default:
        break;
    }

    this.selectedHeaderStat = this.getSelectedAssignedToId(assignedToName);

    const assigneeType = (this.selectedHeaderStat !== "4" && this.selectedHeaderStat !== "5") ?
      this.selectedHeaderStat : this.getSelectedAssignedToId("Total Documents");
    const activeStatusFilter = this.selectedHeaderStat === "5" ? [] : this.activeStatuses;
    const onHoldStatusFilter = this.selectedHeaderStat === "4" ? [] : this.onHoldStatuses;
    const completedStatusFilter = this.selectedHeaderStat === HeaderStat.Completed ? this.completedStatuses : [];
    const removedStatusFilter = this.selectedHeaderStat === HeaderStat.Removed ? this.removedStatuses : [];
    const processingStatusFilter = this.selectedHeaderStat === HeaderStat.Processing ? this.processingStatuses : [];
    this.filterDocumentGridByStats(activeStatusFilter, onHoldStatusFilter, completedStatusFilter, removedStatusFilter, processingStatusFilter, assigneeType);
  }


  isNonDocumentStatusStat(stat: string): boolean {
    return stat === HeaderStat.Assigned || stat === HeaderStat.UnAssigned || stat === HeaderStat.TotalDocuments;
  }

  private filterDocumentGridByStats(
    activeStatuses: SelectableInput[],
    onHoldStatuses: SelectableInput[],
    completedStatuses: SelectableInput[],
    removedStatuses: SelectableInput[],
    processingStatuses: SelectableInput[],
    AssigneeType: string): void {

    this.selectedAssignedTo = AssigneeType;
    this.setActiveStatusInput(activeStatuses);
    this.setOnHoldStatusInput(onHoldStatuses);
    this.setCompletedStatusInput(completedStatuses);
    this.setRemovedStatusInput(removedStatuses);
    this.setProcessingStatusInput(processingStatuses);
    this.setViewInput(this.view);
    this.onApplyFilter();
  }

  trackByIndex(index, item) {
    return index;
  }

  getAssignToUserList(data: DocumentItem[]): void {
    const documentIds = data.map(a => a.documentId);
    this.service
        .getDocumentIntakeAssignTo(documentIds)
        .subscribe(assignToUserList => {
          ArrayHelper.addItemToSelect(assignToUserList, "@Me", this.userId);
          let assignedToUserListForFilter = assignToUserList.filter(a => a.text !== "Unassigned");
          assignedToUserListForFilter = ArrayHelper.addItemToSelect(assignToUserList, "Unassigned", -1);
          this.assignedToUserIdInput = { ...this.assignedToUserIdInput, options: assignedToUserListForFilter } as any;
          this.lastUpdatedInput = { ...this.lastUpdatedInput, options: assignedToUserListForFilter } as any;
          this.assignToUsers = new Autocomplete({ ...this.assignToUsers, options: assignToUserList } as any);
          this.changeDetector.markForCheck();
      });
  }

  getDocumentStats() {
    this.docStatsList = [];
    this.service
      .getDocumentIntakeStats()
      .subscribe((data: any) => {
        this.documentIntakeStats = (data);
        this.docStatsList.push(new ListItem({ key: "Total Documents", value: this.documentIntakeStats.totalDocuments.toString() }));
        this.docStatsList.push(new ListItem({ key: "Assigned", value: this.documentIntakeStats.assigned.toString() }));
        this.docStatsList.push(new ListItem({ key: "Unassigned", value: this.documentIntakeStats.unAssigned.toString() }));
        this.docStatsList.push(new ListItem({ key: "Active (Non-Hold)", value: this.documentIntakeStats.active.toString() }));
        this.docStatsList.push(new ListItem({ key: "On Hold", value: this.documentIntakeStats.onHold.toString() }));
        this.docStatsList.push(new ListItem({ key: "Completed", value: this.documentIntakeStats.completed.toString() }));
        this.docStatsList.push(new ListItem({ key: "Removed", value: this.documentIntakeStats.removed.toString() }));
        this.docStatsList.push(new ListItem({ key: "Processing", value: this.documentIntakeStats.processing.toString() }));


        this.changeDetector.markForCheck();
      });
  }
  getDocumentStatuses(): void {
      this.documentRequestService
        .getDocumentStatuses(this.view)
        .subscribe(result => {
          this.documentStatuses = result;
          if (!this.gridStateService.hasKey(this.stateName)) {
            this.setOnHoldStatusInput(this.onHoldStatuses);
            this.setActiveStatusInput(this.activeStatuses);
            this.setCompletedStatusInput(this.completedStatuses);
            this.setRemovedStatusInput(this.removedStatuses);
            this.setProcessingStatusInput(this.processingStatuses);
            this.setViewInput(this.view);
            this.changeDetector.markForCheck();
          }

          this.onApplyFilter();
        });
  }

  private getRetreivalTypes(): void {
      this.service
        .getRetrievalTypes()
        .subscribe(result => {
          this.retrievalMethodInput = { ...this.retrievalMethodInput, options: result } as any;
          this.changeDetector.markForCheck();
        });
  }

  onKeyUp(event): void {
    this.searchTextChanged.next(event.target.value);
  }

  getAllTagsList(searchText: string): void {
    this.allAvailableTags = [];
    this.tagService.getAllTagsList(this.tagType, null, searchText)
        .subscribe(data => {
          this.allAvailableTags = data;
          this.tagsInput.useFilter = false;
          this.tagsInput.options = [...this.allAvailableTags];
          this.changeDetector.markForCheck();
        });
  }

  private getSelectedAssignedToId(assignedTo: string): string {
    switch (assignedTo) {
      case "Assigned":
        return HeaderStat.Assigned;

      case "Unassigned":
        return HeaderStat.UnAssigned;

      case "Active (Non-Hold)":
        return HeaderStat.Active;

      case "On Hold":
        return HeaderStat.OnHold;

      case "Removed":
        return HeaderStat.Removed;

      case "Completed":
        return HeaderStat.Completed;

      case "Processing":
        return HeaderStat.Processing;


      default:
      case "Total Documents":
        return HeaderStat.TotalDocuments;

    }
  }

  getFilterClass(view): string {

    if (view !== this.view) {
      return "hideFilter";
    }

    return "";
  }

  disableIfProcessingView(): boolean {

    if (this.isProcessingView) {
      return true;
    }

    return false;
  }

  getClass(item: ListItem): string {
    const id = this.getSelectedAssignedToId(item.key);
    const className = id === this.selectedHeaderStat ? "activeAssignedTo" : "clearAssignedTo";
    return className;
  }

  removeClick(item: DocumentItem) {
    this.sendWorkflowCancelled(item.documentId, result => {
      if (result === true) {
        this.refreshPage();
      } else {
        this.messagingService.showMessage("Submit Failed", SeverityType.ERROR);
      }
    });
  }

  downloadClick(item: DocumentItem) {
    if (this.userService.getUserToken().isReadOnlyRole) {
      this.messagingService.showMessage("This action cannot be performed by a Read-Only User.", SeverityType.ERROR);
    } else {
      this.projectFileService.downloadFile2(String(item.documentId), this.userId, "intake", ProjectDocumentType.Intake, item.fileName);
    }
  }

  // when TabView index changes get Statuses for tabView and Get Documents for first Stat on tabView
  onViewClicked(event): void {
    this.view = event.index;
    localStorage.setItem("activeTab", event.index);
    this.setServerGridConfigurationActions();
    this.serverGridSelection = [];

    // We have to get DocumentStatuses again as they change per view
    this.documentRequestService
        .getDocumentStatuses(this.view)
        .subscribe(result => {
          this.documentStatuses = result;

          switch (this.view) {
            case 0:
              this.getDocuments(this.lastActiveStat);
              break;
            case 1:
              this.getDocuments(this.lastArchiveStat);
              break;
            case 2:
              this.getDocuments(this.lastProcessingStat);
              break;
            default:
              break;
          }

          this.updateGridConfiguration();

      });

  }

  getActiveStats(): any {

    return this.docStatsList.filter(f => f.key !== "Completed" && f.key !== "Removed" && f.key !== "Processing");
  }

  getArchiveStats(): any {

    return this.docStatsList.filter(f => f.key === "Completed" || f.key === "Removed");
  }

  getProcessingStats(): any {

    return this.docStatsList.filter(f => f.key === "Processing");
  }

  refreshPage() {
    this.selectedAssignedTo = this.getSelectedAssignedToId("Total Documents");
    this.isAssignModelVisible = false;
    this.getDocumentStats();
    this.refreshGrid.emit();
  }

  sendWorkflowCancelled(documentId, callback) {
    const workflowSubmitRequest =
      new WorkflowSubmitRequest(
        documentId,
        WorkflowStatus.DocumentIntake,
        WorkflowAction.Cancelled,
        ""
      );

    this.workflowService.submit(workflowSubmitRequest)
        .subscribe((result: boolean) => {
          callback(result);
      });
  }

  assignToClick() {
    const workflowSubmitRequest =
      new WorkflowSubmitRequest(
        this.selectedDocumentId,
        WorkflowStatus.DocumentIntake,
        WorkflowAction.Assigned,
        `\{'assignToUserId':${this.selectedUserId}\}`,
        this.selectedDocumentIds
      );

    this.workflowService.submit(workflowSubmitRequest)
        .subscribe((result: boolean) => {
          if (result) {
            this.refreshPage();
            this.serverGridSelection = [];
            this.refreshGrid.emit();
          } else {
            this.messagingService.showMessage("Assignment Failed", SeverityType.ERROR);
          }
        });
  }

  gridDataLoaded(data: DocumentItem[]) {
    this.getAssignToUserList(data);
  }

  openAssignModel(rowData?: DocumentItem) {
    this.clearAssignModalData();
    let selectedRows = [];
    if (rowData !== undefined) {
      this.selectedDocumentIds.push(rowData.documentId);
    } else {
      selectedRows = this.serverGridSelection;
      selectedRows.forEach(item => {
        this.selectedDocumentIds.push(item.documentId);
      });
    }
    this.isAssignModelVisible = true;
    this.isAssignDisabled = true;
  }

  private clearAssignModalData(): void {
    this.selectedDocumentIds = [];
    this.selectedUser = null;
    this.assignToUserList = [];
    this.refreshGrid.emit();
    this.changeDetector.markForCheck();
  }

  onApplyFilter = () => {

    this.serverRequest.startRecord = 1;
    this.refreshGrid.emit();

  }

  search(filters: GridFilter[]) {
    this.serverRequest = new GridRequest({
      url: `${this.baseApiUrl}document/search`,
      filters,
    });
  }
  private getFilter(key: string): GridFilter {
    if (this.serverRequest == null) {
      return null;
    }

    return this.serverRequest.getFilter(key);
  }

  private getInput(key: string): DynamicInput {
    if (this.serverRequest == null) {
      return null;
    }

    return this.serverRequest.getInput(key);
  }

  private setServerGridConfigurationActions(): void {
    this.serverGridActions = [
      new BulkAction({ name: "ASSIGN", action: () => this.openAssignModel(), showBulkAction: this.view !== 2 }),
      // new BulkAction({
      //  name: "Manage Tags",
      //  action: this.openManageTagModal,  /*TODO: This will be a part of next release*/
      //  disabled: this.hasTagManagement,
      // }),
    ];
  }

  private setInput(key: string, value: DynamicInput): void {
    if (this.serverRequest == null) {
      return null;
    }

    this.serverRequest.setInput(key, value);
  }

  private setServerGridConfiguration(): void {
    this.serverGridConfiguration = new GridConfiguration({
      stateName: this.stateName,
      columns: [
        new GridColumnDefinition({
          field: "fileName",
          header: "File Name",
          width: "320px",
          routeUrl: "/retrieval/review/intake/:documentId",
        }),
        new GridColumnDefinition({ field: "documentId", header: "Document ID" }),
        new GridColumnDefinition({ field: "retrievalMethod", header: "Retrieval Method" }),
        new GridColumnDefinition({ field: "createDateString", header: "Captured Date" }),
        new GridColumnDefinition({ field: "availableDateString", header: "Available Date" }),
        new GridColumnDefinition({ field: "numberOfPages", header: "Pages", width: "100px" }),
        new GridColumnDefinition({ field: "updatedBy", header: "Last Updated By" }),
        new GridColumnDefinition({ field: "assignedTo", header: "Assigned To" }),
        new GridColumnDefinition({ field: "notes", header: "Note" }),
        new GridColumnDefinition({ field: "status", header: "Status" }),
        new GridColumnDefinition({ field: "tagsText", header: "Tags" }),
        new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID", routeUrl: "/retrieval/addressdetail/:masterDocumentSourceId" }),
        new GridColumnDefinition({ field: "uploadBatchCode", width: "200px", header: "Confirmation Number" }),
      ],
      selectionMode: "multiple",
      disableUnusedFiltersFunc: true,
    });
    this.activeViewColumns = this.serverGridConfiguration.columns;
  }

  private setServerGridRequest(): void {
    const landingFilter = this.landingFilterStateService.get();
    this.landingFilterStateService.clear();

    this.serverRequest = new GridRequest({
      url: `${this.baseApiUrl}document/search`,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "assignedTo",
          value: "3",
          show: false,
        }),
        new GridFilter({
          input: new Autocomplete(),
          key: "assignedToUser",
          name: "Assigned To",
        }),
        new GridFilter({
          input: new Autocomplete(),
          key: "lastUpdatedUser",
          name: "Last Updated By",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "documentStates",
          name: "Document Status",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "fileName",
          name: "File Name",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "documentId",
          name: "Document ID",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "retrievalTypeIds",
          name: "Retrieval Method",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "ProjectId",
          value: (landingFilter.projectId || "").toString(),
          show: false,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "ClientId",
          value: (landingFilter.clientId || "").toString(),
          show: false,
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "activeDocumentStatesCSV",
          name: "Active Document Status CSV",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "completedDocumentStatesCSV",
          name: "Completed Document Status CSV",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "removedDocumentStatesCSV",
          name: "Removed Document Status CSV",
        }),
        new GridFilter({
          input: new CheckboxGroup(),
          key: "processingDocumentStatesCSV",
          name: "Processing Document Status CSV",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "view",
          show: false,
          value: this.view.toString(),
        }),
        new GridFilter({
          input: new TagSearchMultiselect({ placeholder: "Tags" }),
          key: "TagIdsAsCsv",
          name: "Tags",
          show: true,
        }),
        new GridFilter({
          input: new Textbox(),
          key: "masterDocumentSourceId",
          name: "AID",
        }),
        new GridFilter({
          input: new Textbox(),
          key: "uploadBatchCode",
          name: "Confirmation Number",
        }),
        new GridFilter({
          input: new Dropdown(),
          key: "TagSearchOperator",
          name: "Tag Operator",
          value: StringHelper.isAvailable(this.selectedTagSearchOperator) && ArrayHelper.isAvailable(this.tagsInput.selectedOptions)
            ? this.selectedTagSearchOperator : null,
          showChip: false,
        }),
      ],
    });
  }

  get activeStatuses(): SelectableInput[] {
    if (this.view !== 0) {

      return [];
    }

    return this.documentStatuses.filter(status => status.extra.documentStateCategory === "Active");
  }

  get onHoldStatuses(): SelectableInput[] {
    if (this.view !== 0) {

      return [];
    }

    return this.documentStatuses.filter(status => status.extra.documentStateCategory !== "Active");
  }

  get completedStatuses(): SelectableInput[] {
    if (this.view !== 1) {

      return [];
    }
    return this.documentStatuses.filter(status => status.extra.documentStateCategory === "Completed");
  }

  get removedStatuses(): SelectableInput[] {
    if (this.view !== 1) {

      return [];
    }

    return this.documentStatuses.filter(status => status.extra.documentStateCategory === "Removed");
  }

  get processingStatuses(): SelectableInput[] {
    if (this.view !== 2) {

      return [];
    }

    return this.documentStatuses.filter(status => status.extra.documentStateCategory === "System Processing");
  }
  get isAllSelected(): boolean {
    const activeStatusCount = ArrayHelper.isAvailable(this.activeStatusInput.value) ? this.activeStatusInput.value.length : 0;
    const onHoldStatusCount = ArrayHelper.isAvailable(this.statusInput.value) ? this.statusInput.value.length : 0;
    const completedStatusCount = ArrayHelper.isAvailable(this.completedStatusInput.value) ? this.completedStatusInput.value.length : 0;
    const removedStatusCount = ArrayHelper.isAvailable(this.removedStatusInput.value) ? this.removedStatusInput.value.length : 0;
    const processingStatusCount = ArrayHelper.isAvailable(this.processingStatusInput.value) ? this.processingStatusInput.value.length : 0;
    return (activeStatusCount + onHoldStatusCount + completedStatusCount + removedStatusCount + processingStatusCount) === this.documentStatuses.length;
  }

  get toggleButtonText(): string {
    return this.isAllSelected ? "Deselect All" : "Select All";
  }

  toggleAll(): void {
    if (this.isAllSelected) {
      this.setActiveStatusInput([]);
      this.setOnHoldStatusInput([]);
      this.setCompletedStatusInput([]);
      this.setRemovedStatusInput([]);
      this.setProcessingStatusInput([]);
      this.setViewInput(this.view);

    } else {
      this.setActiveStatusInput(this.activeStatuses);
      this.setOnHoldStatusInput(this.onHoldStatuses);
      this.setCompletedStatusInput(this.completedStatuses);
      this.setRemovedStatusInput(this.removedStatuses);
      this.setProcessingStatusInput(this.processingStatuses);
      this.setViewInput(this.view);

    }
  }

  private setViewInput(view: number) {
    this.viewInput = view.toString();
    this.changeDetector.markForCheck();
  }

  private setActiveStatusInput(selectedValues: SelectableInput[]) {
    this.activeStatusInput = { ...this.activeStatusInput, options: this.activeStatuses, value: selectedValues, label: "" } as any;
    this.changeDetector.markForCheck();
  }

  private setOnHoldStatusInput(selectedValues: SelectableInput[]) {
    this.statusInput = { ...this.statusInput, options: this.onHoldStatuses, value: selectedValues, label: "" } as any;
    this.changeDetector.markForCheck();
  }

  private setCompletedStatusInput(selectedValues: SelectableInput[]) {
    this.completedStatusInput = { ...this.completedStatusInput, options: this.completedStatuses, value: selectedValues, label: "" } as any;
    this.changeDetector.markForCheck();
  }

  private setRemovedStatusInput(selectedValues: SelectableInput[]) {
    this.removedStatusInput = { ...this.removedStatusInput, options: this.removedStatuses, value: selectedValues, label: "" } as any;
    this.changeDetector.markForCheck();
  }

  private setProcessingStatusInput(selectedValues: SelectableInput[]) {
    this.processingStatusInput = { ...this.processingStatusInput, options: this.processingStatuses, value: selectedValues, label: "" } as any;
    this.changeDetector.markForCheck();
  }

  initializeFormGroupControls(): void {
    this.assignToUsers = new Autocomplete({
      key: "assignToUsers",
      label: "User",
      filter: (item, query) => item.text.toLowerCase().indexOf(query.toLowerCase()) >= 0,
    });

    this.createAddressForm();
  }

  createAddressForm(): void {
    this.formGroup = this.formService.createFormGroup([
      this.assignToUsers]);
  }

  private setSelectedDocuments(rowData: any): void {
    this.selectedDocuments = ArrayHelper.isAvailable(rowData) ? rowData : [rowData];
  }

  openManageTagModal = (rowData: any): void => {
    this.setSelectedDocuments(rowData);
    if (ArrayHelper.isAvailable(this.selectedDocuments)) {
      this.totalEntityCount = this.selectedDocuments.length;
      this.entityName = "DOCUMENTS";
      this.isManageTagModalVisible = true;
    }
  }

  closeModal(): void {
    this.selectedDocuments = [];
    this.isManageTagModalVisible = false;
  }

  gridRefresh(): void {
    this.refreshGrid.emit();
  }

  onChangeObject(event): void {
    this.selectedObjectId = event;
  }

  get isActiveView(): boolean {

    return this.view === 0;
  }

  get isArchiveView(): boolean {

    return this.view === 1;
  }

  get isProcessingView(): boolean {

    return this.view === 2;
  }


  resetTagSearchInput(): void {
    this.checkIfTagFilterValueAvailable();
    this.changeDetector.markForCheck();
  }

  checkIfTagFilterValueAvailable(): void {
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.tagsInput.selectedOptions = [];
      this.selectedTagSearchOperator = this.defaultTagSearchOperator;
      this.tagsInput.filterPlaceHolder = "Search";
      this.isShowCloseIcon = false;
    }
  }

  onShowEvent(panelValue: boolean): void {
    this.overlayPanelVisible = panelValue;
    if (ArrayHelper.isAvailable(this.tagsInput.selectedOptions)) {
      this.isShowCloseIcon = true;
    }
  }

  updateGridConfiguration(alreadyInitialized = true) {
    // HACK: Archive View uses this method to update the columns
    this.serverGridConfiguration = new GridConfiguration({
      isInit: alreadyInitialized,
      columns: this.getColumns(),

      selectionMode: "multiple",
      disableUnusedFiltersFunc: true,
      showActionColumn: !this.isProcessingView,
    });
  }

  getColumns(): GridColumnDefinition[] {
    if (this.isArchiveView) {
      this.setServerGridConfiguration();
      const archiveViewColumns = [...this.serverGridConfiguration.columns];
      archiveViewColumns.splice(4, 0, new GridColumnDefinition({ field: "completedDateString", header: "Completed", show: this.view === 1, isSortableColumn: true }));
      const distinctColumns = archiveViewColumns.filter((column, i, columns) => {
        return columns.findIndex(a => a.header === column.header) === i;
      });
      return distinctColumns;
    } else if (this.isProcessingView) {
      const columns =
      [
        new GridColumnDefinition({
          field: "fileName",
          header: "File Name",
          width: "320px",
        }),
        new GridColumnDefinition({ field: "documentId", header: "Document ID" }),
        new GridColumnDefinition({ field: "retrievalMethod", header: "Retrieval Method" }),
        new GridColumnDefinition({ field: "createDateString", header: "Captured Date" }),
        new GridColumnDefinition({ field: "numberOfPages", header: "Pages", width: "100px" }),
        new GridColumnDefinition({ field: "masterDocumentSourceId", header: "AID", routeUrl: "/retrieval/addressdetail/:masterDocumentSourceId" }),
        new GridColumnDefinition({ field: "uploadBatchCode", width: "200px", header: "Confirmation Number" }),
        ];
      const filter = this.getFilter("assignedTo");
      filter.show = false;
      return columns;

    } else {
      return this.activeViewColumns;
    }
  }

  private getTagSearchOperator(): void {
      this.tagService
        .getTagSearchOperator()
        .subscribe(options => {
          this.tagsSearchOperatorInput = new Dropdown({ ...this.tagsSearchOperatorInput, options } as any);
          this.changeDetector.markForCheck();
        });
  }

  getSearchOperatorValue(event: any): void {
    this.selectedTagSearchOperator = event.value;
  }

  getStatisticsClass(item: ListItem, selectedSection: string): string {
    return item.key === selectedSection ? "active" : "";
  }
}
