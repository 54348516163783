import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-edps-report-dw",
  templateUrl: "./edps-report-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EdpsReportDwComponent {
 filters: string[] = ["Projects"];
 dashboardType = LookerRiskDashboardType.EDPS_REPORT_DW;
 hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.CUSTOMER_NAME];
}
