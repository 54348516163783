import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { debounceTime, finalize } from "rxjs/operators";
import { SubSink } from "subsink";
import { interceptorskipheader } from "../../../core/interceptor/loader.interceptor";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { GridDirective } from "../grid-component.model";
import { GridView } from "../grid-menu/grid-views/grid-view.model";
import { ServerGridMenuComponent } from "../grid-menu/server-grid-menu.component";
import { GridStateService } from "../grid-state.service";
import { ILazyLoadEvent } from "../models/api";
import { GridColumnDefinition } from "../models/grid-column-definition.model";
import { GridConfiguration } from "../models/grid-configuration.model";
import { GridFilter } from "../models/grid-filter.model";
import { GridRequest } from "../models/grid-request.model";
import { GridState } from "../models/grid-state.model";
import { SaveQueryComponent } from "../save-query/save-query.component";

@Component({
    selector: "app-server-grid",
    templateUrl: "./server-grid.component.html",
    styleUrls: ["../grid.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerGridComponent extends GridDirective implements OnInit, OnDestroy {
    private sink = new SubSink();
    @ViewChild(ServerGridMenuComponent, { static: true }) private menu: ServerGridMenuComponent;
    @ViewChild(SaveQueryComponent, { static: true }) private saveQueryComponent: SaveQueryComponent;
    @Input() isLoadOnInit = true;
    @Input() refresh = new EventEmitter<ILazyLoadEvent>();
    @Input() hideDisabledRows = false;
    @Input() filterHeaderText = "";
    @Input() isExcelExportDuplicateRow = false;
    @Input() excelExportExpandColumnName = "";
    @Input() filterFooterText = "";
    @Input() isMemberFilterValid = true;
    @Input() openFilterModalOnFlag = false;
    @Input() startCollapsed = false;
    @Input() isMemberQuery = false;
    @Input() onlyChartsInput = false;
    @Input() exportDataFromUrl = false;
    @Input() isChartLakeQuery = false;
    data: any[] = [];
    isLoading = false;
    loadingSubscription: Subscription;
    isSaveQueryModalVisible = false;
    isdeleteQueryModalVisible = false;
    @Output() dataLoaded = new EventEmitter<any>();
    @Output() onPageChanged: EventEmitter<boolean> = new EventEmitter();
    @Output() memberChartEmit: EventEmitter<boolean> = new EventEmitter();
    @Output() isSavedQueryDeleted = new EventEmitter<boolean>();
    @Output() isSavedQueryUpdated = new EventEmitter<number>();
    protected pConfiguration: GridConfiguration;
    @Input() get configuration(): GridConfiguration {
        return this.pConfiguration;
    }
    set configuration(value: GridConfiguration) {
        if (
            !value.isInit &&
            value.hasStateName &&
            this.gridStateService.hasKey(value.stateName) &&
            !value.isStateDisabled
        ) {
            const gridState = this.gridStateService.get(value.stateName);

            this.pConfiguration = new GridConfiguration({
                ...gridState.configuration,
                // TODO: Need to think about how to capture the state of columns when we allow users to hide/showthe columns. Currently, this will override any saved state.
                columns: value.columns,
            });
            this.pConfiguration.isInit = false;
        } else {
            this.pConfiguration = value;
        }
    }


    private pRequest: GridRequest;
    @Input() get request(): GridRequest {
        return this.pRequest;
    }
    set request(value: GridRequest) {
        if (this.configuration == null) {
            throw new Error("Need a configuration object before a request object.");
        }

        if (
            !this.configuration.isInit &&
            this.hasStateKey &&
            this.gridStateService.hasKey(this.stateKey) &&
            !this.configuration.isStateDisabled
        ) {
            const gridState = this.gridStateService.get(this.stateKey);
            this.pRequest = gridState.request;
        } else {
            this.pRequest = value;
        }

        if (this.request.form == null) {
            this.request.setForm();
        }
        this.requestChange.emit(this.request);


        this.configuration.isInit = true;
    }
    @Output() requestChange = new EventEmitter<GridRequest>();
    defaultPageSize: number;
    @Input() displayCheckedItemsInGridView: { value: string; disabled: boolean }[] = [];
    constructor(
        private readonly http: HttpClient,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly gridStateService: GridStateService
    ) {
        super();
    }

    ngOnInit() {
        if (this.refresh != null) {
            this.sink.add(
                this.refresh
                    .pipe(debounceTime(500))
                    .subscribe(this.loadData.bind(this))
            );
        }

        this.sink.add(
            this.onViewSelect.subscribe(this.updateConfiguration.bind(this))
        );
        this.defaultPageSize = this.pConfiguration.pageSize;
    }

    ngOnDestroy() {
        this.sink.unsubscribe();
    }

    get first(): number {
        if (this.stateKey && this.gridStateService.hasKey(this.stateKey)) {
            const first = (this.gridStateService.get(this.stateKey).request.startRecord);
            return first === 0 ? first : first - 1;
        } else {
            const request = this.request.createRequestObject();
            const startRecord = request?.startRecord;
            return startRecord === 0 ? 0 : startRecord - 1;
        }
    }

    get totalRecords(): number {
        if (ArrayHelper.isAvailable(this.data)) {
            const enabledRows = this.data.filter(x => !x.disabled);
            this.configuration.isHeaderCheckBoxDisabled = enabledRows.length === 0;
        }
        return ArrayHelper.isAvailable(this.data) ? this.data[0].recordCount ? this.data[0].recordCount : this.data.length : 0;
    }

    get paginationText(): string {
        return `${this.first + 1}-${(this.first + this.pageSize) > this.totalRecords ? this.totalRecords : this.first + this.pageSize}\u00A0\u00A0of\u00A0\u00A0${this.totalRecords}`;
    }

    get isLastPage(): boolean {
        return this.first === (this.totalRecords - this.pageSize) || (this.first + this.pageSize) > this.totalRecords;
    }

    get isFirstPage(): boolean {
        return this.first === 0;
    }

    get initialPageSize(): number {
        return this.defaultPageSize;
    }

    onLazyLoad(event: ILazyLoadEvent = {}): void {
        this.loadData(event);
    }

    loadData(event: ILazyLoadEvent = {}): void {
        this.setRequest(event);
        this.makeRequest();
        this.saveState();
    }

    showFilters(): void {
        this.menu.showFilters();
        this.changeDetector.markForCheck();
    }

    isFilterHasValue(filters: GridFilter[]): boolean {
      return filters.some(x => (typeof(x?.input?.value) === "string" ? StringHelper.isAvailable(x?.input?.value) : ArrayHelper.isAvailable(x?.input?.value)) && x.show);
    }

    pageSizeChange(): void {
        this.refresh.emit();
    }

    onNavigationChange(direction: boolean): void {
        if (direction) {
            this.nextPage();
        } else {
            this.prevPage();
        }
    }

    private setRequest(event: ILazyLoadEvent): void {
        if (typeof event.first === "number" && event.first >= 0) {
            this.request.startRecord = event.first + 1;
        } else if (this.first !== 0 && this.first < this.pageSize) {
            this.request.startRecord = 1;
        }
        if (event.first === undefined && this.request.startRecord === 0) {
            this.request.startRecord = 1;
        }
        this.request.endRecord = this.request.startRecord + this.pageSize - 1;

        if (StringHelper.isAvailable(event.sortField)) {
            this.request.sortField = event.sortField;
            this.request.sortDirection = event.sortOrder === -1 ? -1 : 1;
        }

        this.primeGrid.initialized = false;
        this.primeGrid.sortField = this.request.sortField;
        this.primeGrid.sortOrder = this.request.sortDirection;
        this.primeGrid.initialized = true;
    }

    private makeRequest(): void {
        if (this.isLoading) {
            this.loadingSubscription.unsubscribe();
        }

        if (!this.configuration.isInit) {
            setTimeout(() => this.makeRequest(), 50);
            return;
        }

        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const url = this.request.url;
        const headers = new HttpHeaders().set(interceptorskipheader, "");
        const request = this.request.createRequestObject();
        request.localTimeZone = localTimeZone;
        this.isLoading = true;
        this.loadingSubscription = this.http
            .post(url, request, { headers })
            .pipe(finalize(() => {
                this.isLoading = false;
                this.changeDetector.markForCheck();
            }))
            .subscribe((data: any) => {
                this.data = ArrayHelper.isAvailable(data) ? data : [];
                if (this.rowDisabledCondition != null) {
                    this.data.forEach(r =>
                        r.disabled = this.getRowDisabledCheck(r));
                }
                this.dataLoaded.emit(this.data);
            });
    }

    private updateConfiguration(gridView: GridView): void {
        this.configuration.columns.forEach(a => {
            const meta = gridView.columns[a.field] || { show: false };
            a.show = meta.show;
        });
        this.configuration.showGridHeader = true;
        this.configuration = new GridConfiguration(this.configuration);
        this.saveState();
        this.changeDetector.markForCheck();
    }

    private saveState(): void {
        const configurationWithoutTemplateProperty = new GridConfiguration({
            ...this.configuration,
            columns: this.configuration.columns.map(({ template, ...item }) => new GridColumnDefinition(item)),
        });

        if (configurationWithoutTemplateProperty.isInit && this.hasStateKey && !this.configuration.isStateDisabled) {
            setTimeout(() => {
                const state = new GridState({
                    configuration: new GridConfiguration({
                        ...this.configuration,
                        showGridHeader: !this.hasViews,
                    }),
                    request: this.request,
                    selectedView: this.hasViews ? this.views.selectedView : null,
                });
                this.gridStateService.put(this.stateKey, state);
            });
        }
    }

    private nextPage(): void {
        this.primeGrid.onPageChange({ first: this.first + this.pageSize, rows: this.pageSize });
    }

    private prevPage(): void {
        this.primeGrid.onPageChange({ first: this.first - this.pageSize, rows: this.pageSize });
    }

    paginate(event) {
        this.onPageChanged.emit(true);
    }

    getRowDisabledCheck(rowData: any): boolean {
        return this.rowDisabledCondition != null ? this.rowDisabledCondition(rowData) : false;
    }

    selectableRowData(rowData): any {
        return rowData.disabled ? null : rowData;
    }

    getRowClass(rowData: any): string {
        if (rowData.disabled && this.hideDisabledRows) {
            return "hide-disabled-row";
        } else if (rowData.disabled && !this.hideDisabledRows) {
            return "disable-row";
        } else if (rowData.rowClass && !rowData.disabled) {
            return rowData.rowClass;
        } else {
            return null;
        }
    }

    exportGridData() {
        this.menu.export();
    }

    markForCheck() {

        this.changeDetector.markForCheck();

    }

    onSelectionChange(selectedRowData): void {
        if (ArrayHelper.isAvailable(selectedRowData)) {
            this.selection = selectedRowData.filter(x => !x.disabled);
            this.changeDetector.markForCheck();
            this.request.isAllSelected = false;
            this.selectionChange.emit(this.selection);
        } else {
            this.selectionChange.emit(selectedRowData);
        }
    }

    memberChart(event: boolean) {
        this.memberChartEmit.emit(event);
    }

    get isSaveQueryVisible(): boolean {
        return this.pConfiguration.showSaveQuery;
    }

    get disableUnusedFilters(): boolean {
        return this.pConfiguration.disableUnusedFiltersFunc;
    }

    showSaveQueryModal() {
        this.isSaveQueryModalVisible = true;
        this.changeDetector.markForCheck();
    }

    updateQueryViewAndFilters() {
        this.saveQueryComponent.saveQuery();
    }

    get saveQueryAttributeId(): number {
        return this.pConfiguration.saveQueryAttributeId;
    }

    get savedQueryId(): number {
        return this.pConfiguration.saveQueryId;
    }

    get savedQueryName(): string {
        return this.pConfiguration.savedQueryName;
    }

    get isSavedQueryPublic(): boolean {
        return this.pConfiguration.isSavedQueryPublic;
    }

    get savedQueryView(): GridView {
        return this.pConfiguration.savedQueryView;
    }

    showDeleteSavedQueryModal(value: boolean): void {
        this.isdeleteQueryModalVisible = value;
        this.changeDetector.markForCheck();
    }

    deletedSavedQuery(value: boolean) {
        this.isdeleteQueryModalVisible = false;
        this.isSavedQueryDeleted.emit(value);
    }

    updatedSavedQuery(value: number) {
        this.isSaveQueryModalVisible = false;
        this.isSavedQueryUpdated.emit(value);
    }

    onClose() {
        this.isSaveQueryModalVisible = false;
        this.changeDetector.markForCheck();
    }

    onCloseDelete() {
        this.isdeleteQueryModalVisible = false;
        this.changeDetector.markForCheck();
    }

    onHeaderCheckboxToggle(event: any): void {
        this.request.isAllSelected = event.checked;
    }

}
