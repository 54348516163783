// tslint:disable:max-line-length
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../../../auth/auth.service";
import { AutomapperService } from "../../../core/automapper/automapper.service";
import { BASE_API_URL, DOCUMENT_SERVICE_API_URL } from "../../../core/environment.tokens";
import { interceptorskipheader } from "../../../core/interceptor/loader.interceptor";
import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { CommentItem } from "../../../shared/comments/comment-item/comment-item";
import { DocumentListItem } from "../../../shared/document/chase-document-list/document-list-item.model";
import { DocumentPageImage } from "../../../shared/document/document-page-viewer/document-image.model";
import { DocumentOcrMatch } from "../../../shared/document/document-page-viewer/document-ocr-match.model";
import {
  DocumentPageAnnotations
} from "../../../shared/document/document-page-viewer/document-page-annotations/document-page-annotations.model";
import { DocumentPageDocumentHighlights } from "../../../shared/document/document-page-viewer/document-page-annotations/document-page-document-highlights.model";
import { DocumentPageHighlightChaseAvailablityResponse } from "../../../shared/document/document-page-viewer/document-page-annotations/document-page-highlight-chase-availablity-response.model";
import { DocumentPageHighlightsResponse } from "../../../shared/document/document-page-viewer/document-page-annotations/document-page-highlights-response.model";
import { DocumentPageUserHighlightUpdate } from "../../../shared/document/document-page-viewer/document-page-annotations/document-page-user-highlight-update.model";
import {
  DocumentPageNlpMatches
} from "../../../shared/document/document-page-viewer/document-page-nlp/document-page-nlp-matches.model";
import { MenuItem } from "../../../shared/menu/menu-item.model";
import { ChasePendItem } from "../../../shared/pend/chase-pend-item.model";
import { DocumentPageRequest } from "../../../shared/pend/document-pageid-request.model";
import { TimelineItem } from "../../../shared/timeline/timeline-item.model";
import { MoveBackChasePageRequest } from "../member/move-back-chase-page-request.model";
import { BulkUpdate } from "../project/bulk-updates/bulk-update.model";
import { DocumentItem } from "./document-item";
import { CdnFolderModel } from "./retreival-document-review/cdn-folder.model";
import { ChasePageHistory } from "./retreival-document-review/chase-page-history.model";
import { DocumentIntakeStats } from "./retreival-document-review/document-intake-stats.model";
import { DocumentPages } from "./retreival-document-review/document-pages.models";
import {
  DocumentAssignmentSummary
} from "./retreival-document-review/retreival-document-intake/document-assignment-summary.model";
import { DocumentSummary } from "./retreival-document-review/retreival-document-intake/document-summary.model";

@Injectable({
  providedIn: "root",
})
export class RetrievalDocumentServiceService {

  totalDocumentPages = new BehaviorSubject<number>(0);

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    @Inject(DOCUMENT_SERVICE_API_URL) private readonly documentServiceApiUrl: string,
    private http: HttpClient,
    private readonly automapper: AutomapperService,
    private readonly authService: AuthService
  ) {
  }

  getDocumentAssingmentSummary(documentId: number, sourceDocumentTypeId: number): Observable<DocumentAssignmentSummary[]> {
    const url = `${this.baseApiUrl}document/page/assignments?documentId=${documentId}&sourceDocumentTypeId=${sourceDocumentTypeId}`;

    return this.http.get(url).pipe(
      map(this.toDocumentAssignmentSummarys)
    );
  }

  private toDocumentAssignmentSummarys = (items: any[]): DocumentAssignmentSummary[] => {
    return items.map(this.toDocumentAssignmentSummary);
  }

  private toDocumentAssignmentSummary = (item: any): DocumentAssignmentSummary => {
    return new DocumentAssignmentSummary(
      item.documentId,
      item.chaseId,
      item.startPage,
      item.endPage,
      item.span,
      item.docType,
      item.pageType,
      item.documentTypeId,
      item.documentAssignmentTypeId,
      item.pageTypeId,
      "",
      item.workingDocumentId,
      item.document2Id,
      item.document2TypeId,
      item.removable
    );
  }

  assignPagesToEntity(
    entity: string,
    entityKey: string,
    documentId: number,
    documentTypeId: number,
    documentPageIds: number[],
    pageTypeId: number,
    byPassMRQAStatus: boolean = false
  ): Observable<boolean> {

    const url
      = `${this.baseApiUrl}document/page/assign?entity=${entity}&entityKey=${entityKey}&documentId=${documentId}
&documentTypeId=${documentTypeId}&pageTypeId=${pageTypeId}&byPassMRQAStatus=${byPassMRQAStatus}`;

    return this.http
      .post(url, documentPageIds, {observe: "response"})
      .pipe(
        map((response: HttpResponse<any>): boolean => {
          if (response.ok) {
            return true;
          } else {
            return false;
          }
        }),
        catchError(this.handleError<boolean>("assignPagesToEntity"))
      );
  }

  submitWorkingDocumentPageRangesToEntity(pageAssignments: DocumentAssignmentSummary[], sourceDocumentTypeId: number): Observable<number> {

    const url
      = `${this.baseApiUrl}document/workingdocument/pageranges/submit?sourceDocumentTypeId=${sourceDocumentTypeId}`;

    return this.http
      .post(url, pageAssignments, {observe: "response"})
      .pipe(
        map((response: HttpResponse<any>): number => response.body),
        catchError(this.handleError<number>("submitWorkingDocumentPageRangesToEntity"))
      );
  }

  removeWorkingDocumentPageAssignments(pageAssignments: DocumentAssignmentSummary[], sourceDocumentTypeId: number): Observable<boolean> {

    const url
      = `${this.baseApiUrl}document/workingdocument/pageranges/remove?sourceDocumentTypeId=${sourceDocumentTypeId}`;

    return this.http
      .post(url, pageAssignments, {observe: "response"})
      .pipe(
        map((response: HttpResponse<any>): boolean => response.ok),
        catchError(this.handleError<boolean>("removeWorkingDocumentPageRangesToEntity"))
      );
  }

  assignPageRangeToWorkingDocument(
    entity: string,
    entityKey: string,
    documentId: number,
    documentTypeId: number,
    pageRangeStart: number,
    pageRangeEnd: number,
    pageTypeId: number,
    document2Id: number
  ): Observable<boolean> {

    const url
      = `${this.baseApiUrl}document/workingdocument/page/assignrange?entity=${entity}&entityKey=${entityKey}&documentId=${documentId}&documentTypeId=${documentTypeId}&pageRangeStart=${pageRangeStart}&pageRangeEnd=${pageRangeEnd}&pageTypeId=${pageTypeId}&document2Id=${document2Id}`;

    return this.http
      .post(url, {observe: "response"})
      .pipe(
        map((response: HttpResponse<any>): boolean => {
          // TODO: JASON Ask team why response coming back as boolean rather than response.ok like other calls
          if (response) {
            return true;
          } else {
            return false;
          }
        }),
        catchError(this.handleError<boolean>("assignPageRangeToEntity"))
      );
  }

  assignPageRangeToEntity(
    entity: string,
    entityKey: string,
    documentId: number,
    documentTypeId: number,
    pageRangeStart: number,
    pageRangeEnd: number,
    pageTypeId: number,
    byPassMRQAStatus: boolean = false
  ): Observable<boolean> {

    const url
      = `${this.baseApiUrl}document/page/assignrange?entity=${entity}&entityKey=${entityKey}&documentId=${documentId}
&pageRangeStart=${pageRangeStart}&pageRangeEnd=${pageRangeEnd}&documentTypeId=${documentTypeId}&pageTypeId=${pageTypeId}&byPassMRQAStatus=${byPassMRQAStatus}`;

    return this.http
      .post(url, {observe: "response"})
      .pipe(
        map((response: HttpResponse<any>): boolean => {
          // TODO: JASON Ask team why response coming back as boolean rather than response.ok like other calls
          if (response) {
            return true;
          } else {
            return false;
          }
        }),
        catchError(this.handleError<boolean>("assignPageRangeToEntity"))
      );
  }

  validateChaseStatusForDocAttachment(chaseId: number): Observable<string> {
    const url = `${this.baseApiUrl}document/validatechasefordocattachment?chaseId=${chaseId}`;
    return this.http.get(url) as Observable<string>;
  }

  deletePagesToEntity(
    entity: string,
    entityKey: string,
    documentId: number,
    documentTypeId: number,
    begPage: number,
    endPage: number,
    sourceDocumentTypeId: number
  ): Observable<boolean> {

    const url = `
      ${this.baseApiUrl}document/page/remove?entity=${entity}&entityKey=${entityKey}&documentId=${documentId}
&documentTypeId=${documentTypeId}&begPage=${begPage}&endPage=${endPage}&sourceDocumentTypeId=${sourceDocumentTypeId}`;

    return this.http
      .post(url, {}, {observe: "response"})
      .pipe(
        map((response: HttpResponse<any>): boolean => {
          if (response.ok) {
            return true;
          } else {
            return false;
          }
        }),
        catchError(this.handleError<boolean>("assignPagesToEntity"))
      );
  }

  deleteChasePages(
    chaseId: number,
    documentTypeId: number,
    documentPageIds: number[],
    pageTypeId: number
  ): Observable<boolean> {
    const url =
      `${this.baseApiUrl}document/page/delete?&chaseId=${chaseId}&documentTypeId=${documentTypeId}&pageTypeId=${pageTypeId}`;

    return this.http
      .post(url, documentPageIds, {observe: "response"})
      .pipe(
        map((response: HttpResponse<any>): boolean => response.ok),
        catchError(this.handleError<boolean>("deleteChasePages"))
      );
  }

  moveChasePageRange(
    sourceChaseId: number,
    targetChaseId: number,
    documentTypeId: number,
    pageRangeStart: number,
    pageRangeEnd: number,
    pageTypeId: number
  ): Observable<boolean> {
    const url = `
      ${this.baseApiUrl}document/page/moverange?&sourcechaseId=${sourceChaseId}&targetchaseId=${targetChaseId}&documentTypeId=${documentTypeId}&pageRangeStart=${pageRangeStart}&pageRangeEnd=${pageRangeEnd}&pageTypeId=${pageTypeId}`;

    return this.http
      .post(url, null, {observe: "response"})
      .pipe(
        map((response: HttpResponse<any>): boolean => response.ok),
        catchError(this.handleError<boolean>("moveChasePages"))
      );
  }

  createChasePendPages(
    chasePendModel: ChasePendItem,
    documentTypeId: number,
    pageTypeId: number
  ): Observable<HttpResponse<any>> {
    const url = `${this.baseApiUrl}document/page/pend/assign?&documentTypeId=${documentTypeId}&pageTypeId=${pageTypeId}`;
    return this.http.post(url, chasePendModel, {observe: "response"});
  }

  private handleError<T>(operation: any, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed: ${error.message}`); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getDocumentListItems(chaseId: number, maxDocuments: number, includePreview: boolean): Observable<DocumentListItem[]> {
    const url = `${this.baseApiUrl}chasedocument/list?chaseId=${chaseId}&maxRecords=${maxDocuments}&includePreview=${includePreview}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "DocumentListItem"))
    );
  }


  getPages(
    documentId: number,
    sourceDocumentTypeId: number,
    begPage: number,
    endPage: number,
    isHighResolutionImage: boolean,
    pageTypeId?: number,
    documentPageIds?: number[],
    isRisk20Project?: boolean,
    s3ImagesFolders?: CdnFolderModel[]): Observable<DocumentPages> {

    const url = `${this.baseApiUrl}document/page/list`;

    const headers = new HttpHeaders().set(interceptorskipheader, "");
    const includeImageString = false;
    const data = {
      documentId,
      sourceDocumentTypeId,
      begPage,
      endPage,
      isHighResolutionImage,
      pageTypeId,
      documentPageIds: documentPageIds ?? [],
      isRisk20Project,
      includeImageString,
      s3ImagesFolders,
    };
    return this.http.post(url, data, {headers}).pipe(
      map(this.automapper.curry("default", "DocumentPages"))
    );
  }

  getSubsequentPages(
    documentId: number,
    sourceDocumentTypeId: number,
    begPage: number,
    endPage: number,
    isHighResolutionImage: boolean,
    pageTypeId?: number,
    includeImageString?: boolean,
    isCdnBucketFolderChecked?: boolean,
    documentPageIds?: number[],
    isRisk20Project?: boolean,
    includeImageContent?: boolean,
    s3ImagesFolders?: CdnFolderModel[]): Observable<DocumentPages> {
    const url = `${this.baseApiUrl}document/page/list`;

    const headers = new HttpHeaders().set(interceptorskipheader, "");
    const data = {
      documentId,
      sourceDocumentTypeId,
      begPage,
      endPage,
      isHighResolutionImage,
      pageTypeId,
      includeImageString,
      isCdnBucketFolderChecked,
      isRisk20Project,
      documentPageIds: documentPageIds ?? [],
      includeImageContent,
      s3ImagesFolders,
    };
    return this.http.post(url, data, {headers}).pipe(
      map(this.automapper.curry("default", "DocumentPages"))
    );
  }

  getDocumentThumbnails(
    documentId: number,
    sourceDocumentTypeId: number,
    begPage: number,
    endPage: number,
    pageTypeId?: number,
    isCdnBucketFolderChecked?: boolean,
    documentPageIds?: number[],
    isRisk20Project?: boolean,
    isNoEvidenceFilterSelected = false,
    selectedTabText?: string,
    s3ImagesFolders?: CdnFolderModel[]): Observable<DocumentPages> {

    const url = `${this.baseApiUrl}document/thumbnail/list`;

    const headers = new HttpHeaders().set(interceptorskipheader, "");
    const data = {
      documentId,
      sourceDocumentTypeId,
      begPage,
      endPage,
      pageTypeId,
      isCdnBucketFolderChecked,
      isRisk20Project,
      isNoEvidenceFilterSelected,
      selectedTabText,
      documentPageIds: documentPageIds ?? [],
      s3ImagesFolders,
      includeImageString: false,
    };
    return this.http.post(url, data, {headers}).pipe(
      map(this.automapper.curry("default", "DocumentPages"))
    );
  }

  getDocumentThumbnailsWithDocQueueId(
    documentId: number,
    sourceDocumentTypeId: number,
    begPage: number,
    endPage: number,
    documentQueueId?: number,
    pageTypeId?: number,
    isCdnBucketFolderChecked?: boolean,
    documentPageIds?: number[],
    isRisk20Project?: boolean,
    groupName?: string,
    selectedDiagnosisType?: string,
    isNoEvidenceFilterSelected = false,
    selectedTabText?: string,
    isSplitScreenMainTab = false,
    diagnosisFilterSelected = false,
    encounterStartDate?: string,
    encounterEndDate?: string,
    filterOnlyByDocumentPageIds = false,
    isProjectEnabledForRiskNlp = false,
    s3ImagesFolders?: CdnFolderModel[]): Observable<DocumentPages> {

    const url = `${this.baseApiUrl}document/thumbnail/list`;
    const data = {
      documentId,
      sourceDocumentTypeId,
      begPage,
      endPage,
      pageTypeId,
      documentQueueId,
      isCdnBucketFolderChecked,
      isRisk20Project,
      groupName,
      selectedDiagnosisType,
      isNoEvidenceFilterSelected,
      selectedTabText,
      isSplitScreenMainTab,
      diagnosisFilterSelected,
      encounterStartDate,
      encounterEndDate,
      isProjectEnabledForRiskNlp,
      filterOnlyByDocumentPageIds,
      documentPageIds: documentPageIds ?? [],
      s3ImagesFolders,
      includeImageString: false,
    };
    const headers = new HttpHeaders().set(interceptorskipheader, "");

    return this.http.post(url, data, {headers}).pipe(
      map(this.automapper.curry("default", "DocumentPages"))
    );
  }

  getChasePagesHistory(chaseId: number, maxRecords: number): Observable<ChasePageHistory[]> {
    const url = `${this.baseApiUrl}chasedocument/pages/history?chaseId=${chaseId}&maxRecords=${maxRecords}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "ChasePageHistory"))
    );
  }

  // Get a single Document summary by documentId and sourceDocumentTypeId, can be used for DocumentQueue (file) or Chase
  getDocumentSummary(documentId: number, sourceDocumentTypeId: number): Observable<DocumentSummary> {
    const url = `${this.baseApiUrl}document/summary?documentId=${documentId}&sourceDocumentTypeId=${sourceDocumentTypeId}`;
    return this.http.get(url).pipe(
      map((item: any) => {
        return new DocumentSummary(
          item.documentId,
          item.sourceDocumentTypeId,
          item.fileName,
          item.numberOfPages,
          item.description,
          item.ocrDataAvailable,
          item.documentStateGroupId,
          item.documentStateId,
          item.masterDocumentSourceId
        );
      })
    );
  }

  // Get Document Intake Stats
  getDocumentIntakeStats(): Observable<DocumentIntakeStats> {
    const url = `${this.baseApiUrl}document/intake/stats`;
    return this.http.get(url).pipe(
      map((item: any) => {
        return new DocumentIntakeStats(
          item.totalDocuments,
          item.assigned,
          item.unAssigned,
          item.active,
          item.onHold,
          item.removed,
          item.completed,
          item.processing
        );
      })
    );
  }

  getDocumentIntakeAssignTo(documentIds): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}document/intake/assignto`;
    return this.http.post(url, documentIds).pipe(
      map(this.automapper.curryMany("DropDownListItem", "SelectableInput"))
    );
  }

  getDocumentItems(status: string, maxDocuments: number): Observable<DocumentItem[]> {
    const url = `${this.baseApiUrl}document/list?status=${status}&maxRecords=${maxDocuments}`;
    return this.http.get(url).pipe(
      map(this.toDocumentItems)
    );
  }

  private toDocumentItems = (items: any[]): DocumentItem[] => {
    return items.map(this.toDocumentItem);
  }

  private toDocumentItem = (item: any): DocumentItem => {
    return new DocumentItem(
      item.documentId,
      item.fileName,
      item.numberOfPages,
      item.createDate,
      item.assignedTo,
      item.notes,
      item.status
    );
  }

  getNextAssigned(roleId: number): Observable<number> {
    const url = `${this.baseApiUrl}document/nextassigned?functionalRoleId=${roleId}`;
    return this.http.get(url) as Observable<number>;
  }

  updateDocumentImage(documentPageId: number, documentPage: DocumentPageImage, rotateDegrees: number): Observable<any> {
    const url = `${this.baseApiUrl}document/image/update?documentpageid=${documentPageId}&rotatedegrees=${rotateDegrees}`;
    return this.http.post(url, documentPage, {observe: "response"}).pipe(map((response: HttpResponse<any>): any => response.ok));
  }

  submitChartDownloadRequest(chaseId: number): Observable<boolean> {
    const url = `${this.baseApiUrl}document/chart/downloadrequest?chaseid=${chaseId}`;
    return this.http.post(url, null, {observe: "response"}).pipe(map((response: HttpResponse<boolean>): any => response.ok));
  }

  getRetrievalTypes(): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}document/retrieval/types`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "SelectableInput"))
    );
  }

  getAssigendPagesCount(documentId: number): Observable<number> {
    const url = `${this.baseApiUrl}document/page/count?documentId=${documentId}`;
    return this.http.get(url, {observe: "response"}).pipe(
      map((response: HttpResponse<any>): any => response.ok ? response.body : 0));
  }

  getOCRMatches(documentId: number, searchPhrase: string, sourceDocumentTypeId: number): Observable<DocumentOcrMatch> {
    const url = `${this.baseApiUrl}document/ocrmatch/list?documentId=${documentId}&searchPhrase=${encodeURIComponent(searchPhrase)}&sourcedocumenttypeid=${sourceDocumentTypeId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DocumentOcrMatch"))
    );
  }

  getDocumentNlpHighlights(chaseId: number): Observable<DocumentPageAnnotations> {
    const url = `${this.baseApiUrl}document/risk/nlp/highlights?chaseId=${chaseId}`;
    return this.http.get(url) as Observable<DocumentPageAnnotations>;
  }

  getDocumentUserHighlights(chaseId: number): Observable<DocumentPageAnnotations> {
    const url = `${this.baseApiUrl}document/user/highlights?chaseId=${chaseId}`;
    return this.http.get(url) as Observable<DocumentPageAnnotations>;
  }

  getAuthorizationToken(): string {
    return this.authService.user?.token;
  }

  getCommonDynamoDBRequestHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getAuthorizationToken()}`,
    });
  }

  // Gets document highlights from dynamodb based on the chaseId and pageIds
  getDocumentNlpHighlightsFromDynamoDB(documentPageDocumentHighlights: DocumentPageDocumentHighlights): Observable<DocumentPageHighlightChaseAvailablityResponse> {
    const headers = this.getCommonDynamoDBRequestHeaders();
    const url = `${this.documentServiceApiUrl}documenthighlights/getrecords`;
    return this.http.post(url, documentPageDocumentHighlights, { headers }) as Observable<DocumentPageHighlightChaseAvailablityResponse>;
  }

  // Handles addition and deletion of dynamoDB highlights.
  updateUserDocumentHighlightsInDynamoDB(documentPageUserHighlights: DocumentPageUserHighlightUpdate): Observable<DocumentPageHighlightsResponse[]> {
    const headers = this.getCommonDynamoDBRequestHeaders();
    const url = `${this.documentServiceApiUrl}documenthighlights`;
    return this.http.put(url, documentPageUserHighlights, { headers }) as Observable<DocumentPageHighlightsResponse[]>;
  }

  /**
   * @deprecated Use getDocumentNlpHighlights and getDocumentUserHighlights instead
   * */
  getDocumentAnnotations(chaseId: number): Observable<DocumentPageAnnotations> {
    const url = `${this.baseApiUrl}document/annotations/chasedocument?chaseDocumentId=${chaseId}`;
    return this.http.get(url) as Observable<DocumentPageAnnotations>;
  }

  addDocumentUserHighlight(chaseId: number, documentPageAnnotations: DocumentPageAnnotations): Observable<DocumentPageAnnotations> {
    const url = `${this.baseApiUrl}document/user/highlight?chaseId=${chaseId}`;
    return this.http.post(url, documentPageAnnotations) as Observable<DocumentPageAnnotations>;
  }

  deleteUserHighlight(chaseId: number, documentPageAnnotations: DocumentPageAnnotations, deletedHighlightId: string): Observable<DocumentPageAnnotations> {
    const url = `${this.baseApiUrl}document/user/highlight?chaseId=${chaseId}&deletedHighlightId=${deletedHighlightId}`;
    return this.http.request("DELETE", url, {body: documentPageAnnotations}) as Observable<DocumentPageAnnotations>;
  }

  /**
   * @deprecated Use addDocumentUserHighlight and deleteDocumentUserHighlight instead
   * */
  setDocumentAnnotations(chaseId: number, documentPageAnnotations: DocumentPageAnnotations, deletedHighlightId: string = null): Observable<DocumentPageAnnotations> {
    const url = `${this.baseApiUrl}document/annotations/chasedocument?chaseDocumentId=${chaseId}&deletedHighlightId=${deletedHighlightId}`;
    return this.http.post(url, documentPageAnnotations) as Observable<DocumentPageAnnotations>;
  }

  getChaseComments(chaseId: number): Observable<CommentItem[]> {
    const url = `${this.baseApiUrl}Chase/Comments?chaseId=${chaseId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "CommentItem"))
    );
  }

  saveChaseComment(commentText: string, chaseId: number) {
    const url = `${this.baseApiUrl}Chase/Comment?chaseId=${chaseId}&commentText=${encodeURIComponent(commentText)}`;
    return this.http.post(url, {}).pipe(
      catchError(this.handleError<boolean>("saveChaseComment"))
    );
  }

  getChaseTimelineActivityItems(chaseId: number): Observable<TimelineItem[]> {
    const url = `${this.baseApiUrl}timeline/chaseTimelineActivity?chaseId=${chaseId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "TimelineItem"))
    );
  }

  rotateDocumentPage(documentPageId: number, rotateDegrees: number): Observable<string> {
    const params = new HttpParams().append("documentPageId", documentPageId.toString()).append("rotateDegrees", rotateDegrees.toString());
    const url = `${this.baseApiUrl}document/page/rotate`;
    return this.http.post<string>(url, null, {params});
  }

  getDocumentComments(documentId: number): Observable<CommentItem[]> {
    const url = `${this.baseApiUrl}document/comments?documentId=${documentId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "CommentItem"))
    );
  }

  saveDocumentComment(documentId: number, commentText: string): Observable<null> {
    const url = `${this.baseApiUrl}document/comment/add?documentId=${documentId}&commentText=${encodeURIComponent(commentText)}`;
    return this.http.post(url, {}) as Observable<null>;
  }

  getDocumentTimelineActivity(documentId: number): Observable<TimelineItem[]> {
    const url = `${this.baseApiUrl}timeline/documentTimelineActivity?documentId=${documentId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "TimelineItem"))
    );
  }

  getNlpMatches(
    chaseId: number,
    encounterId: number,
    diagnosisId: number,
    diagnosisCode: string,
    dosFrom: string,
    dosThrough: string): Observable<DocumentPageNlpMatches> {
    const url = `${this.baseApiUrl}chase/diagnosis/nlp/highlights?chaseId=${chaseId}&encounterId=${encounterId}&diagnosisId=${diagnosisId}&diagnosisCode=${diagnosisCode}&dosFrom=${dosFrom}&dosThrough=${dosThrough}`;
    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DocumentPageNlpMatches"))
    );
  }

  getNegativeExclusionMatches(chaseId: number): Observable<DocumentPageNlpMatches> {
    const url = `${this.baseApiUrl}chase/diagnosis/nlp/exclusion/highlights?chaseId=${chaseId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DocumentPageNlpMatches"))
    );
  }

  getOcrMappingMatches(chaseId: number): Observable<DocumentPageNlpMatches> {
    const url = `${this.baseApiUrl}chase/diagnosis/nlp/template/highlights?chaseId=${chaseId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DocumentPageNlpMatches"))
    );
  }

  getMemberMatches(chaseId: number): Observable<DocumentPageNlpMatches> {
    const url = `${this.baseApiUrl}chase/diagnosis/nlp/member/highlights?chaseId=${chaseId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DocumentPageNlpMatches"))
    );
  }

  getDosMatches(
    chaseId: number,
    encounterId: number,
    diagnosisId: number,
    diagnosisCode: string,
    dosFrom: string,
    dosThrough: string): Observable<DocumentPageNlpMatches> {
    const url = `${this.baseApiUrl}chase/diagnosis/nlp/dos/highlights?chaseId=${chaseId}&encounterId=${encounterId}&diagnosisId=${diagnosisId}&diagnosisCode=${diagnosisCode}&dosFrom=${dosFrom}&dosThrough=${dosThrough}`;
    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "DocumentPageNlpMatches"))
    );
  }

  changeWorkingDocumentPageAssignments(sourcePageAssignment: DocumentAssignmentSummary, sourceDocumentTypeId: number, targetPageAssignment: DocumentAssignmentSummary): Observable<boolean> {

    const url
      = `${this.baseApiUrl}document/workingdocument/page/changerange?sourceDocumentTypeId=${sourceDocumentTypeId}`;

    const workingPageAssignments = [];
    workingPageAssignments.push(sourcePageAssignment);
    workingPageAssignments.push(targetPageAssignment);

    return this.http
      .post(url, workingPageAssignments, {observe: "response"})
      .pipe(
        map((response: HttpResponse<any>): boolean => response.ok),
        catchError(this.handleError<boolean>("changeWorkingDocumentPageRangesToEntity"))
      );
  }

  moveBackChasePages(moveBackChasePageRequest: MoveBackChasePageRequest): Observable<HttpResponse<null>> {
    const url = `${this.baseApiUrl}document/page/moveback`;
    return this.http.post(url, moveBackChasePageRequest) as Observable<HttpResponse<null>>;
  }

  moveChases(moveBackChases: BulkUpdate): Observable<HttpResponse<null>> {
    const url = `${this.baseApiUrl}bulkupdate/validate/chases/movereassign`;
    return this.http.post(url, moveBackChases) as Observable<HttpResponse<null>>;
  }

  createOptimizeHiresMetrics(chaseId: number): Observable<boolean> {
    const url = `${this.baseApiUrl}chasedocument/optimizehighresimagesmetrics?chaseId=${chaseId}`;
    return this.http.get(url) as Observable<boolean>;
  }

  verifyOptimizedImagesAreAvailable(chaseId: number): Observable<boolean> {
    const url = `${this.baseApiUrl}chasedocument/hashighresimages?chaseId=${chaseId}`;
    return this.http.get(url) as Observable<boolean>;
  }

  checkOverlappingChasePageRange(chaseId: number, documentId: number, startPage: number, endPage: number, pageTypeId: number, document2Id: number): Observable<any> {
    const url = `${this.baseApiUrl}document/workingdocument/checkoverlapchasepagerange?chaseId=${chaseId}&documentId=${documentId}&startPage=${startPage}&endPage=${endPage}&pageTypeId=${pageTypeId}&document2Id=${document2Id}`;
    return this.http.get(url, {observe: "response"}).pipe(
      map((response: HttpResponse<any>): boolean => response.ok),
      catchError(this.handleError<boolean>("checkOverlappingChasePageRange"))
    );
  }

  getDocumentTotalCaptureAnnotations(chaseId: number): Observable<DocumentPageAnnotations> {
    const url = `${this.baseApiUrl}document/totalcapture/annotations?chaseId=${chaseId}`;
    return this.http.get(url) as Observable<DocumentPageAnnotations>;
  }

  getMenuItems(): Observable<MenuItem[]> {

    const menuItems = [
      {description: "Review"},
      {description: "Identification"},
    ];

    return of(menuItems).pipe(
      map(this.automapper.curryMany("default-sub-menu", "MenuItem"))
    );
  }

  getDocumentPageIds(
    chasePendModel: DocumentPageRequest
  ): Observable<HttpResponse<any>> {
    const url = `${this.baseApiUrl}document/getdocumentpagedetails`;
    return this.http.post(url, chasePendModel, {observe: "response"});
  }

}
