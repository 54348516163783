<div [formGroup]="formGroup" class="control control--input control--switch" [hidden]="hidden">
  <div class="switch-container">
    <label *ngIf="model.hasLabel" 
           class="control__header__label" 
           [for]="model.key" 
           [title]="model.label">
      {{ getLabel(model.label, useTitleCase) }}
    </label>

    <p-inputSwitch [name]="model.key"
                   [inputId]="model.key"
                   [styleClass]="classes"
                   (onChange)="onChange.emit($event); onInput.emit($event);"
                   [formControlName]="model.key" >
    </p-inputSwitch>
  </div>
</div>