import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { SubSink } from "subsink";
import { DynamicEntity } from "../../../platform/api/member-validation/dynamic-entity.model";
import { ChaseDetailStateService } from "../../../platform/modules/member/chase-detail/chase-detail-state.service";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { DynamicFormEvent } from "../../dynamic-form-event.model";
import { HighlightService } from "../../highlighter/highlight.service";
import { DynamicInput } from "../../inputs/dynamic-input.model";
import { NegativeReasonCodeService } from "../negative-reason-code-group/negative-reason-code.service";
import { ConditionalNrcGroup } from "./conditional-nrc-group.model";

@Component({
  selector: "form-conditional-nrc-group",
  templateUrl: "./conditional-nrc-group.component.html",
  styleUrls: [
    "../dynamic-group.scss",
    "../../highlighter/highlight.scss",
    "./conditional-nrc-group.component.scss",
  ],
  providers: [
    HighlightService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionalNrcGroupComponent extends DynamicControlDirective<ConditionalNrcGroup> implements OnInit, OnDestroy {
  private sink = new SubSink();
  userDefinedValue: string;
  memberDateOfBirth: Date;
  medicalRecordDateOfBirth: string;
  measureYear: number;
  measureCode: string;
  miscellaneousEntity: DynamicEntity;

  save$: Subject<number> = new Subject<number>();

  constructor(
    private readonly service: NegativeReasonCodeService,
    readonly highlighter: HighlightService,
    private chaseDetailStateService: ChaseDetailStateService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.sink.add(
      this.save$.pipe(debounceTime(250)).subscribe(index => {
        this.save(index);
      }),
      this.chaseDetailStateService.state.subscribe(state => {
        this.measureCode = state.measureCode;
        this.measureYear = state.measureYear;
        this.memberDateOfBirth = state?.member?.memberDateOfBirth;
        this.medicalRecordDateOfBirth = state.medicalRecordDateOfBirth;
        this.userDefinedValue = state?.userDefinedValue?.toLowerCase();
        this.miscellaneousEntity = state.miscellaneousEntityFromChaseEntityData;

        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  save(index: number): void {
    if (this.control.dirty) {
      const model = this.model.controls[index];
      const negativeReasonCode = (this.control as FormGroup).controls[model.key].value.extra;
      const chaseId = (model as DynamicInput).saveInfo.chaseId;
      this.service.saveNegativeReasonCode(chaseId, negativeReasonCode).subscribe({
        next: () => {
          this.onChange.emit(new DynamicFormEvent({
                                                    key: this.model.key,
                                                    type: "compliance",
                                                    value: this.control.value,
                                                    control: this.control,
                                                    model: this.model,
                                                  }));
        },
        error: () => (this.control as FormGroup).controls[model.key].setValue(null),
      });
    }
  }

  get isW30(): boolean {
    return this.measureCode === "W30";
  }

  get calculatedDateOfBirth(): Date {
    return StringHelper.isAvailable(this.medicalRecordDateOfBirth) ? new Date(this.medicalRecordDateOfBirth) : this.memberDateOfBirth;
  }

  get rate1Date(): Date {
    return new Date(this.calculatedDateOfBirth.getFullYear() + 1, this.calculatedDateOfBirth.getMonth(), this.calculatedDateOfBirth.getDate() + 90);
  }

  get rate2Date(): Date {
    return new Date(this.calculatedDateOfBirth.getFullYear() + 2, this.calculatedDateOfBirth.getMonth(), this.calculatedDateOfBirth.getDate() + 180);
  }

  get rate(): string {
    if (this.rate1Date.getFullYear() === this.measureYear) {
      return "Rate1";
    } else if (this.rate2Date.getFullYear() === this.measureYear) {
      return "Rate2";
    } else {
      return "RateNotFound";
    }
  }

  get isAvailable(): boolean {
    return this.isW30 ? this.model.key.includes(this.rate) : this.showNephropathyEvents;
  }

  get showNephropathyEvents(): boolean {
    return this.userDefinedValue?.includes("lob=medicare");
  }

  trackByIndex(index, item) {
    return index;
  }
}
