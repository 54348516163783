import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { MenuItem } from "../../../../../shared/menu/menu-item.model";
import { BulkChaseValidation } from "../bulk-chase-validation.model";
import { BulkOutReachQueryResult } from "./bulk-outreach-new-bulk-action/bulk-outreach-query-result.model";
import { BulkOutReachRequest } from "./bulk-outreach-new-bulk-action/bulk-outreach-request.model";

@Injectable()
export class BulkOutreachService {

  constructor(
    private automapper: AutomapperService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient
  ) { }

  getMenuItems(): Observable<MenuItem[]> {
    const menuItems = [
      {
        description: "New Bulk Action",
        path: "./",
      },
      {
        description: "Pending Actions",
        path: "pendingActions",
      },
      {
        description: "Archive",
        path: "archive",
      },

    ];

    return of(menuItems).pipe(
      map(this.automapper.curryMany("default-sub-menu", "MenuItem"))
    );
  }

  previewBulkOutreachRequest(bulkOutReachRequest: BulkOutReachRequest): Observable<BulkOutReachQueryResult> {
    const url = `${this.baseApiUrl}bulkoutreach/request/preview`;
    return this.http.post(url, bulkOutReachRequest).pipe(
      map(this.automapper.curry("default", "BulkOutReachQueryResult")));
  }

  createBulkOutreachRequest(bulkOutReachRequest: BulkOutReachRequest): Observable<any> {
    const url = `${this.baseApiUrl}bulkoutreach/request`;
    return this.http.post(url, bulkOutReachRequest);
  }

  validateChasesIds(chaseIds: number[]): Observable<BulkChaseValidation[]> {
    const url = `${this.baseApiUrl}bulkoutreach/chases/validate`;
    return this.http.post(url, chaseIds).pipe(
      map(this.automapper.curryMany("default", "BulkChaseValidation")));
  }

  downloadInvalidChases(invalidChases: string, userId: number) {
    this.http.get(`${this.baseApiUrl}file/bulkoutreach/validation/download?invalidChases=${invalidChases}&callerUserId=${userId}`,
                  { responseType: "blob" as "json", observe: "response" as "body" }).subscribe(
        (response: any) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(response.body);
          const disposition = response.headers.get("content-disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            let dispositionFileName = "";

            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              dispositionFileName = matches[1].replace(/['"]/g, "");
            }
            downloadLink.download = dispositionFileName;
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      );
  }

  downloadFile(fileName = "", filepath = "") {
    const url = filepath === "" ? `${this.baseApiUrl}file/download?fileType=providerPacket&fileId=${fileName}` : `${this.baseApiUrl}file/download?fileType=faxProviderPacket&fileId=${fileName}&filepath=${filepath}`;
    this.http.get(url,
                  { responseType: "blob" as "json", observe: "response" as "body" }).subscribe(
        (response: any) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(response.body);
          const disposition = response.headers.get("content-disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            let dispositionFileName = fileName;

            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              dispositionFileName = matches[1].replace(/['"]/g, "");
            }
            downloadLink.download = dispositionFileName;
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      );
  }
}
