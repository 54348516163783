<div class="grid__menu" *ngIf="configuration.showMenu">

  <div class="grid__menu__item grid__menu__item--sort">
    <label class="sort__label">Sort:</label>
    <p-dropdown
      appendTo="body"
      class="sort__dropdown"
      styleClass="control__input control__input--dropdown"
      [options]="options"
      placeholder="Select..."
      [(ngModel)]="selection"
      (onChange)="sortGrid($event)">
    </p-dropdown>
  </div>

  <app-button
    *ngIf="hasExport && !isMemberFile"
    class="grid__menu__item grid__menu__item--export"
    classes="small secondary bold"
    [text]="exportButtonText"
    (onClick)="export()"
    [disabled]="!hasData || disableExport"
    id="export">
  </app-button>

  <app-button
    *ngIf="!isMemberFile"
    class="grid__menu__item grid__menu__item--filter"
    classes="transparent small"
    (onClick)="showFilters()"
    styleType="pi"
    type="pi"
    icon="filter"
    [disabled]="!enableFilter">
  </app-button>

  <div *ngIf="isMemberFile" class="grid__menu__item--member">
    <app-bulk-actions-dropdown class="grid__menu__item bulk-action"
                               [actions]="actions"
                               [selectedItems]="selectedItems"
                               [hasData]="hasData">
    </app-bulk-actions-dropdown>

    <app-button class="button grid__menu__item grid__menu__item--filters"
                classes="large"
                (onClick)="showFilters()"
                styleType="pi"
                type="pi"
                icon="filter"
                [disabled]="!enableFilter">
    </app-button>
  </div>

  <div *ngIf="displayPageControls" class="grid__menu__item grid__menu__item--paging">
    <span class="nav-button" *ngIf="!isFirstPage" (click)="navigatePage(false)"><</span>
    {{ paginationText }}
    <span class="nav-button" *ngIf="!isLastPage" (click)="navigatePage(true)">></span>
  </div>

  <!-- TODO: Remove viewStyle class getter when app-bulk-actions-dropdown is added to all grids -->
  <app-grid-views
    *ngIf="showViews"
    [class]="viewStyle"
    [configuration]="configuration"
    [views]="views"
    [refreshViews]="refreshViews"
    [onViewSelect]="onViewSelect"
    [displayCheckedItemsInGridView]="displayCheckedItemsInGridView">
  </app-grid-views>

</div>

<app-modal class="grid__filter"  [isMemberQuery]="isMemberQuery" [onlyChartsInput]="onlyChartsInput" (memberChart)="memberChart($event)" [(visible)]="isFiltersVisible" (onCancel)="clearUnusedFilters()" [header]="filterHeaderText">
  <ng-template [ngTemplateOutlet]="filterTemplate" [ngTemplateOutletContext]="{ $implicit: filterForm }"></ng-template>

  <footer>
    <app-button
      *ngIf="isClearFilterButtonVisible"
      classes="unselected-btn"
      (onClick)="clearFilters()"
      text="Clear">
    </app-button>
    <app-button (onClick)="closeFilters()" classes="primary-action" text="Run Query" [disabled]="!isFilterFormValid"></app-button>
    <div class="grid__filter--footer-text">{{ filterFooterText }}</div>
  </footer>
</app-modal>
