import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { DynamicFormEvent } from "../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { StandardGroup } from "../../../../../../dynamic-forms/form-groups/standard-group/standard-group.model";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Confirm } from "../../../../../../dynamic-forms/inputs/confirm/confirm.model";
import { DynamicInput } from "../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { WorkflowStatusDb } from "../../../../../api/workflow/workflow-status-db.enum";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";
import { CLAIM_ID, EDGE_PROVIDER_SOURCE_ALIAS_ID, FILL_DATE, MEMBER_DATE_OF_BIRTH, MEMBER_FIRST_NAME, MEMBER_GENDER, MEMBER_LAST_NAME, NATIONAL_DRUG_CODE, PROCESSED_DATE } from "../attributes";
import { ChartService } from "../chart.service";
import { RiskHelper } from "../risk/risk-helper.model";


@Component({
  selector: "member-rxcp",
  templateUrl: "./rxcp.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RxcpComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private hasChaseId = false;
  enabled = false;
  memberValidationEntity: any;
  form: FormGroup;
  previousChase: number = null;
  nextChase: boolean;

  firstNameSaveGroup: SaveGroup;
  private firstNameInput: Confirm;

  lastNameSaveGroup: SaveGroup;
  private lastNameInput: Confirm;

  dobSaveGroup: SaveGroup;
  private dobInput: Confirm;

  genderSaveGroup: SaveGroup;
  private genderInput: Confirm;

  memberStandardGroup: StandardGroup;
  private insuredMemberIdInput: Textbox;
  private internalMemberIdInput: Textbox;

  claimIdSaveGroup: SaveGroup;
  private claimIdInput: Confirm;

  prescriptionFillSaveGroup: SaveGroup;
  private prescriptionFillInput: Confirm;

  nationalDrugCodeSaveGroup: SaveGroup;
  private nationalDrugCodeInput: Confirm;

  constructor(
    private chaseDetailStateService: ChaseDetailStateService,
    private formService: FormService,
    private chartService: ChartService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializeForm();

    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        this.nextChase = this.previousChase !==  state.chaseId;
        this.previousChase = state.chaseId;
        if ((!this.hasChaseId || this.nextChase) && NumberHelper.isGreaterThan(state.chaseId, 0)) {
          this.hasChaseId = true;
          this.chartService.getEntities(state.chaseId).subscribe(entities => this.chaseDetailStateService.setData({ entities }));
        }

        this.enabled = state?.isEnabled;

        if (ArrayHelper.isAvailable(state.entities)) {
          this.updateForm(state);
        }

        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  handleChange(event: DynamicFormEvent): void {
    switch (event.type) {
      case "save":
        this.saveAttributesByInputs(event.model.controls);
        break;
      case "clear":
      case "delete":
        if (!this.formService.isKeyDownEnter(event.originalEvent)) {
          this.deleteAttributesByInputs(event.model.controls);
        }
        break;
      default:
      case "compliance":
        break;
    }
  }

  private saveAttributesByInputs(controls: DynamicInput[]): void {
    const attributes = controls
      .filter(this.isAvailableAttribute.bind(this))
      .map(this.getSaveAttribute.bind(this)) as DynamicEntityAttribute[];
    this.saveAttributes(attributes);
  }

  private deleteAttributesByInputs(controls: DynamicInput[]): void {
    const allControlsDisabled = controls.every(a => a.disabled);
    if (!allControlsDisabled) {
      const attributesToDelete = controls
        .filter(this.isAvailableAttribute.bind(this))
        .map(this.getDeleteAttribute.bind(this)) as DynamicEntityAttribute[];
      this.saveAttributes(attributesToDelete);
    }
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]) {
    this.chartService
      .save2(attributes)
      .subscribe(
        entities => this.chaseDetailStateService.setData({ entities }),
        (e: HttpErrorResponse) => this.form.setErrors({ saveError: e.error })
      );
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    saveAttribute.value = this.form.get(control.getMasterKey()).value;
    return saveAttribute;
  }

  private getDeleteAttribute(control: DynamicInput): DynamicEntityAttribute {
    const deleteAttribute = this.getSaveAttribute(control);
    if (!deleteAttribute.isAdmin) {
      deleteAttribute.value = "";
      deleteAttribute.pageNumber = null;
      deleteAttribute.validationId = null;
      deleteAttribute.validationNote = "";
    }
    return deleteAttribute;
  }

  private isAvailableAttribute(attribute: DynamicInput): boolean {
    return attribute != null && attribute.saveInfo != null && NumberHelper.isGreaterThan(attribute.saveInfo.attributeId, 0);
  }

  private initializeForm(): void {
    // INPUTS
    this.insuredMemberIdInput = new Textbox({
      key: "InsuredMemberId",
      label: "Insured Member ID",
      isAdmin: true,
      disabled: true,
    });

    this.firstNameInput = new Confirm({
      key: "MemberFirstName",
      label: "First Name",
      validators: [Validators.required],
      errorMessages: {
        required: "First Name is required",
      },
    });

    this.lastNameInput = new Confirm({
      key: "MemberLastName",
      label: "Last Name",
      validators: [Validators.required],
      errorMessages: {
        required: "Last Name is required",
      },
    });

    this.dobInput = new Confirm({
      key: "MemberDateOfBirth",
      label: "DOB",
      validators: [Validators.required],
      errorMessages: {
        required: "DOB is required",
      },
    });

    this.genderInput = new Confirm({
      key: "MemberGender",
      label: "Gender",
      validators: [Validators.required],
      errorMessages: {
        required: "Gender is required",
      },
    });

    this.internalMemberIdInput = new Textbox({
      key: "InternalMemberId",
      label: "Internal Member ID",
      isAdmin: true,
      disabled: true,
    });

    this.claimIdInput = new Confirm({
      key: "ClaimId",
      label: "Client Internal Claim ID",
      validators: [Validators.required],
      errorMessages: {
        required: "Claim ID is required",
      },
    });


    this.prescriptionFillInput = new Confirm({
      key: "PrescriptionFill",
      label: "Prescription Fill Date",
      validators: [Validators.required],
      errorMessages: {
        required: "Prescription Fill date is required",
      },
    });


    this.nationalDrugCodeInput = new Confirm({
      key: "NationalDrugCode",
      label: "National Drug Code",
      validators: [Validators.required],
      errorMessages: {
        required: "National Drug Code is required",
      },
    });

    // SAVE GROUPS
    this.memberStandardGroup = new StandardGroup({
      key: "InsureMemberStandardGroup",
      controls: [
        this.insuredMemberIdInput,
        this.internalMemberIdInput,
    ],
    });
    this.insuredMemberIdInput.parent = this.memberStandardGroup;
    this.internalMemberIdInput.parent = this.memberStandardGroup;

    this.firstNameSaveGroup = new SaveGroup({ key: "FirstNameSaveGroup", header: "First Name" });
    this.setupSaveGroups({
      saveGroup: this.firstNameSaveGroup,
      inputs: [this.firstNameInput],
    });

    this.lastNameSaveGroup = new SaveGroup({ key: "LastNameSaveGroup", header: "Last Name" });
    this.setupSaveGroups({
      saveGroup: this.lastNameSaveGroup,
      inputs: [this.lastNameInput],
    });

    this.dobSaveGroup = new SaveGroup({ key: "dobSaveGroup", header: "DOB" });
    this.setupSaveGroups({
      saveGroup: this.dobSaveGroup,
      inputs: [this.dobInput],
    });

    this.genderSaveGroup = new SaveGroup({ key: "genderSaveGroup", header: "Gender" });
    this.setupSaveGroups({
      saveGroup: this.genderSaveGroup,
      inputs: [this.genderInput],
    });


    this.claimIdSaveGroup = new SaveGroup({ key: "claimIdSaveGroup", header: "EDGE Claim ID" });
    this.setupSaveGroups({
      saveGroup: this.claimIdSaveGroup,
      inputs: [this.claimIdInput],
    });


    this.prescriptionFillSaveGroup = new SaveGroup({ key: "prescriptionFillSaveGroup", header: "Prescription Fill Date" });
    this.setupSaveGroups({
      saveGroup: this.prescriptionFillSaveGroup,
      inputs: [this.prescriptionFillInput],
    });

    this.nationalDrugCodeSaveGroup = new SaveGroup({ key: "nationalDrugCodeSaveGroup", header: "National Drug Code" });
    this.setupSaveGroups({
      saveGroup: this.nationalDrugCodeSaveGroup,
      inputs: [this.nationalDrugCodeInput],
    });

    // CREATE FORM
    this.form = this.formService.createFormGroup([
      this.memberStandardGroup,
      this.firstNameSaveGroup,
      this.lastNameSaveGroup,
      this.dobSaveGroup,
      this.genderSaveGroup,
      this.claimIdSaveGroup,
      this.prescriptionFillSaveGroup,
      this.nationalDrugCodeSaveGroup,
    ]);
  }

  private updateForm(state: ChaseDetailState): void {
    const chaseId = state.chaseId;
    const workflowStatusName = this.getWorkflowStatusName(state.workflowStatus);
    const singleEntity = state.formEnities[0];

    const entityId = singleEntity.entityId;
    const entityTypeId = singleEntity.entityTypeId;
    const disableByMemberValidation = !this.enabled;

    this.memberValidationEntity = state.memberValidationEntity;

    if (this.memberValidationEntity) {
      this.memberValidationEntity.attributes.forEach(element => {
        singleEntity.attributes.push(element);
      });
    }

    this.form.get(this.insuredMemberIdInput.getMasterKey()).setValue(state.member.enrolleeId);
    this.form.get(this.internalMemberIdInput.getMasterKey()).setValue(state.member.memberSourceAliasId);

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.firstNameInput,
      ATTRIBUTE_DATA: MEMBER_FIRST_NAME,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.lastNameInput,
      ATTRIBUTE_DATA: MEMBER_LAST_NAME,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.dobInput,
      ATTRIBUTE_DATA: MEMBER_DATE_OF_BIRTH,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.genderInput,
      ATTRIBUTE_DATA: MEMBER_GENDER,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.claimIdInput,
      ATTRIBUTE_DATA: CLAIM_ID,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.prescriptionFillInput,
      ATTRIBUTE_DATA: FILL_DATE,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.nationalDrugCodeInput,
      ATTRIBUTE_DATA: NATIONAL_DRUG_CODE,
    });
  }

  private getWorkflowStatusName(workflowStatus: WorkflowStatusDb): string {
    return StringHelper.clean(WorkflowStatusDb[workflowStatus]).toLowerCase();
  }

  private setupConfirmAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    entityId,
    entityTypeId,
    disableByMemberValidation,
    workflowStatusName,
  }: any): void {
    const attributes = RiskHelper.getAttributes(ATTRIBUTE_DATA.attributeCode, singleEntity);
    const adminAttribute = attributes.find(a => a.isAdmin) || {} as any;

    let singleEntityId: Number;
    let singleEntityTypeId: Number;
    attributes.forEach(element => {
      if (element.attributeCode === ATTRIBUTE_DATA.attributeCode) {
        singleEntityId = element.entityId;
        singleEntityTypeId = element.entityTypeId;
      }
    });
    const attribute = {
      ...attributes.find(a => !a.isAdmin),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId: singleEntityId,
      entityTypeId: singleEntityTypeId,
    };

    input.saveInfo = attribute;
    input.value = attribute.value;
    input.adminValue = adminAttribute.value;
    input.disabled = disableByMemberValidation;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    // (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    disableByMemberValidation ? control.disable() : control.enable();
  }

  private setupSaveGroups({
    saveGroup,
    inputs,
  }: any): void {
    saveGroup.controls = inputs;
    inputs.forEach(a => a.parent = saveGroup);
  }
}
