import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { UserToken } from "../../../../../auth/user-token.model";
import { LoaderService } from "../../../../../core/interceptor/loader/loader.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../core/user/user.service";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { TextboxType } from "../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { DocumentPageAssignmentComponent } from "../../../../../shared/document-page-assignment/document-page-assignment.component";
import { DocumentPageViewerComponent } from "../../../../../shared/document/document-page-viewer/document-page-viewer.component";
import { ListItem } from "../../../../../shared/list/list-item";
import { MemberCentricChase } from "../../../../../shared/membercentric-doc-attachment/membercentric-chase.model";
import { MemberCentricDocAttachmentService } from "../../../../../shared/membercentric-doc-attachment/membercentric-doc-attachment.service";
import { MenuItem } from "../../../../../shared/menu/menu-item.model";
import { EmitState } from "../../../../../shared/models/emit-state.model";
import { ConfirmationModalComponent } from "../../../../../shared/panel/confirmation-modal/confirmation-modal.component";
import { ModalComponent } from "../../../../../shared/panel/modal/modal.component";
import { ChasePendItem } from "../../../../../shared/pend/chase-pend-item.model";
import { CreatePendService } from "../../../../../shared/pend/create-pend.service";
import { DocumentPageRequest } from "../../../../../shared/pend/document-pageid-request.model";
import { PendStatus } from "../../../../../shared/pend/pend-status.enum";
import { PendType } from "../../../../../shared/pend/pend-type.enum";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";
import { MemberValidation } from "../../../../api/member-validation/member-validation.model";
import { MemberValidationService } from "../../../../api/member-validation/member-validation.service";
import { InvoicesService } from "../../../invoice/invoices/invoices.service";
import { REVIEW_ALL_PAGES_OF_CHART } from "../../../member/chase-detail/chase-detail-chart/attributes";
import { Encounter } from "../../../member/chase-detail/chase-detail-chart/risk/encounter/encounter.model";
import { ChaseDetailService } from "../../../member/chase-detail/chase-detail.service";
import { DocumentSourceType } from "../../../retrieval/retrieval-document-source-type.enum";
import { ServiceOrgAttribute } from "../../../service-org-admin/service-org-config/model/service-org-attribute.model";
import { ServiceOrgConfigurationService } from "../../../service-org-admin/service-org-config/service-org-config.service";
import { RetrievalDocumentServiceService } from "../../retrieval-document-service.service";
import { ChasePageHistory } from "../chase-page-history.model";
import { DocumentReviewLevel } from "../document-review-level.enum";
import { DocumentTypeName } from "../document-type.enum";
import { PageTypeName } from "../page-type.enum";
import { DocumentAssignmentSummary } from "./document-assignment-summary.model";
import { DocumentIntakePage } from "./document-intake-page";
import { DocumentSummary } from "./document-summary.model";

@Component({
  selector: "app-retreival-document-intake",
  templateUrl: "./retreival-document-intake.component.html",
  styleUrls: ["./retreival-document-intake.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetreivalDocumentIntakeComponent implements OnInit, OnChanges {
  @Input() documentId: number;
  @Input() sourceDocumentTypeId: number;
  @Output() pageEvent = new EventEmitter<number>();
  @Output() changedEvent = new EventEmitter();
  @Output() notifyDocumentStatusChanged = new EventEmitter<any>();
  @Output() assignedPagesEvent = new EventEmitter<number>();
  @Output() unassignedPagesEvent = new EventEmitter<number[]>();
  @Output() createDosPursuit = new EventEmitter<string>();
  @Input() currentlyAssignedUser: number;
  numberOfAssignedPages: number;
  reviewAllPagesAttribute: boolean;

  constructor(private service: RetrievalDocumentServiceService,
              private changeDetector: ChangeDetectorRef,
              private readonly loaderService: LoaderService,
              private router: Router,
              private messagingService: MessagingService,
              private readonly chaseDetailService: ChaseDetailService,
              private readonly memberValidationService: MemberValidationService,
              private userService: UserService,
              private memberCentricDocAttachmentService: MemberCentricDocAttachmentService,
              private pendService: CreatePendService,
              private readonly formService: FormService,
              private serviceOrgConfigurationService: ServiceOrgConfigurationService,
              private readonly invoicesService: InvoicesService
  ) {
  }
  @ViewChild("confirmationForInActiveChases", { static: true }) confirmationForInActiveChases: ConfirmationModalComponent;
  @ViewChild("confirmationForActiveChases", { static: true }) confirmationForActiveChases: ConfirmationModalComponent;
  @ViewChild("documentPageAssignment", { static: true }) documentPageAssignment: DocumentPageAssignmentComponent;
  @ViewChild("notAllowedModal", { static: true }) notAllowedModal: ModalComponent;

  @ViewChild(DocumentPageViewerComponent, { static: true })
  private pageViewerComponent: DocumentPageViewerComponent;
  loggedInUser: UserToken;
  documentSummary: DocumentSummary;
  documentIntakePages: DocumentIntakePage[] = [];
  documentAssignmentSummaries: DocumentAssignmentSummary[] = [];
  currentdocumentIntakePage: DocumentIntakePage;
  pageHistories: ChasePageHistory[] = [];
  currentPageNumber: number;
  maxPages = 3;
  pageloading = 0;
  numberOfPages = 0;
  docTypeInvoice = 4;
  docTypePrePayInvoice = 5;
  showDialog = false;
  currentAssignSection: string;
  invoiceType = 0;
  runMode: number;
  runModeDocument = 1;
  runModeChase = 2;
  previousDocumentId = 0;
  showConfirmationForInActiveChases: boolean;
  showConfirmationForActiveChases: boolean;
  confirmationTextForAttachingToActiveChase: string;
  confirmationTextForAttachingToInactiveChase: string;
  chaseActive: boolean;
  showMemberCentricDocAttachmentInfo = false;
  memberCentricData: MemberCentricChase[];
  lastSelectedSection: string;

  chaseForm: FormGroup;
  ignoreForm: FormGroup;
  invoiceForm: FormGroup;
  deleteForm: FormGroup;
  chaseSearchTarget: FormControl;
  pendForm: FormGroup;

  defaultFormValidators: any[] = [];

  memberValidationData = new MemberValidation();
  private chaseDetails: ListItem[] = [];
  idSubscriber: Subscription;
  savedIsValidMember: boolean;
  currentlyAssignedTo: number;
  isChartMaxed = false;
  searchDocumentSourceTypeId?: number;
  disableDeleteBtn = false;
  ignoreBegPage: Textbox;
  ignoreEndPage: Textbox;
  chaseBegPage: Textbox;
  chaseEndPage: Textbox;
  assignChaseId: Textbox;
  invoiceBegPage: Textbox;
  invoiceEndPage: Textbox;
  invoiceChaseId: Textbox;
  pendAmount: Textbox;
  pendInvoice: Textbox;
  pendBegPage: Textbox;
  pendEndPage: Textbox;
  pendTypes: Dropdown;
  pendCompany: Dropdown;
  pendReasonInput: Dropdown;
  deleteBegPage: Textbox;
  deleteEndPage: Textbox;
  assignPendChaseId: Textbox;
  unAssignEntityAlertVisible = false;
  docAssignmentData: DocumentAssignmentSummary;
  workingDocumentAssignmentSummaries: DocumentAssignmentSummary[] = [];
  attachChaseNotAllowedMsg: string;
  showNotAllowedModal: boolean;
  menuItems: MenuItem[] = [];
  selectedTab: MenuItem;
  showMemberInputs = true;
  encounterValidation: Encounter[] = [];
  encounterValidationFlag = true;

  ngOnInit() {
    this.loggedInUser = this.userService.getUserToken();
    this.previousDocumentId = this.documentId;

    this.defaultFormValidators = [
      Validators.required,
      Validators.min(1),
      Validators.pattern(RegExHelper.number),
    ];

    this.initializeDoc();
    this.setTabMenuItems();

    this.serviceOrgConfigurationService
      .getServiceOrgConfigurationByAttribute(REVIEW_ALL_PAGES_OF_CHART.attributeId)
      .pipe(map((attribute: ServiceOrgAttribute) => this.reviewAllPagesAttribute = attribute?.attributeValue === "1"))
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (NumberHelper.isAvailable(this.currentlyAssignedUser)) {
      this.initializeDoc();
      this.currentlyAssignedUser = null;
    } else if (NumberHelper.isGreaterThan(Number(this.previousDocumentId), 0) && this.previousDocumentId !== changes.documentId.currentValue) {
      this.initializeDoc();
      this.previousDocumentId = this.documentId;
    }
  }

  private setTabMenuItems(): void {
    this.service
      .getMenuItems()
      .subscribe(menuItems => {
        if (this.isRunModeDocument) {
          menuItems.find(m => m.description.toLowerCase() === "review").show = false;
          this.selectedTab = menuItems.find(m => m.description.toLowerCase() === "identification");
        } else if (this.isRunModeChase) {
          this.selectedTab = menuItems.find(m => m.description.toLowerCase() === "review");
        }

        this.menuItems = menuItems;
        this.changeDetector.markForCheck();
      });
  }

  hideTab(tabName: string): boolean {
    // Hide Tab
    if (this.selectedTab == undefined) {
      return null;
    }

    return this.selectedTab.description.toLowerCase() !== tabName.toLowerCase();
  }

  onSelectTab($event: MenuItem): void {
    this.selectedTab = $event;
  }

  getMemberValidation(): void {

    this.memberValidationService.getData(this.documentId, this.status).subscribe(data => {
      this.memberValidationData = data;
      this.savedIsValidMember = this.memberValidationData.isValid;
    });

  }
  getCurrentlyAssignedTo(): void {

    // Get currentlyAssignedTo to determine if editable by current user
    const assignedTo = this.chaseDetails.filter(x => x.key === "Assigned To Id");
    if (assignedTo !== null) {
      this.currentlyAssignedTo = parseInt(assignedTo[0].value || "0", 10);
    }
  }

  getData(): void {
    // Create observer object
    const observer = {
      next: this.assignAndNotify,
      error: null,
      complete: () => {
        if (this.isRunModeChase) {

          this.chaseDetailService.getSummaryItems(this.documentId)
            .subscribe(chaseDetails => {

              this.chaseDetails = chaseDetails;
              this.getCurrentlyAssignedTo();
              this.getMemberValidation();
              this.changeDetector.markForCheck();

            });
        }
      },
    };

    this.service
      .getDocumentSummary(this.documentId, this.sourceDocumentTypeId)
      .subscribe(observer);
  }


  get showMemberValidation(): boolean {

    if (this.currentlyAssignedTo === this.loggedInUser.userId && this.isRunModeChase) {
      return true;
    } else {
      return false;
    }

  }

  get allowEdit(): boolean {

    if (this.isRunModeChase && this.currentlyAssignedTo !== this.loggedInUser.userId) {
      return false;
    }

    if (this.isRunModeChase) {
      return this.savedIsValidMember;

    } else {

      return true;
    }
  }

  get status(): string {
    const statusItem = this.chaseDetails.find(item => item.key === "Workflow Status");
    return statusItem ? statusItem.value : "";
  }

  get isPursuitChase(): boolean {
    const parentChaseId = this.chaseDetails.find(item => item.key === "Parent Chase Id").value;
    return StringHelper.isAvailable(parentChaseId);
  }

  get showDosValidationSection(): boolean {
    const reviewLevel = this.chaseDetails.find(item => item.key === "Project DocumentReview Level");
    return reviewLevel ?
      (this.isRunModeChase && Number(reviewLevel.value) === DocumentReviewLevel.MemberAndDosValidationRisk
        || this.isRunModeChase && Number(reviewLevel.value) === DocumentReviewLevel.MemberAndDosValidationIva) : false;
  }

  setRunMode() {
    this.runMode = this.sourceDocumentTypeId;
  }

  searchChase(target) {
    this.searchDocumentSourceTypeId = null;

    this.showDialog = true;
    this.chaseSearchTarget = target;
  }

  searchChaseThirdParty(target) {
    this.searchDocumentSourceTypeId = DocumentSourceType.THIRDPARTY;
    this.showDialog = true;
    this.chaseSearchTarget = target;
  }

  searchClosed(chaseId) {
    this.showDialog = false;
    if (chaseId) {
      this.chaseSearchTarget.setValue(chaseId);
    }
  }

  private initializeDoc(): void {
    this.setRunMode();
    this.getData();
  }

  private assignAndNotify = (data: DocumentSummary): void => {

    this.documentSummary = data;
    this.numberOfPages = data.numberOfPages;
    if (StringHelper.isAvailable(this.lastSelectedSection)) { this.toggleAssignSection(this.lastSelectedSection); }
    this.pageEvent.emit(this.numberOfPages);
    DocumentPageViewerComponent.documentId.next(this.documentId);
    this.notifyDocumentStatusChanged.emit({
      documentStateDescription: data.description,
      documentStateGroupId: data.documentStateGroupId,
      documentStateId: data.documentStateId,
    });

    this.resetFormValidators(this.documentSummary);
    this.loadDocumentSections();
    this.assignedPageCount();
  }

  private resetFormValidators(documentSummary: DocumentSummary): void {
    let formValidators = [];
    formValidators = [...this.defaultFormValidators];
    formValidators.push(Validators.max(documentSummary.numberOfPages));
    const maxErrorMessage = `Enter number between 1 - ${documentSummary.numberOfPages}`;

    if (this.chaseForm) {
      this.chaseForm.get("chaseBegPage").setValidators(formValidators);
      this.chaseForm.get("chaseEndPage").setValidators(formValidators);
      this.chaseBegPage.errorMessages = {
        ...this.chaseBegPage?.errorMessages,
        max: maxErrorMessage,
      };
      this.chaseEndPage.errorMessages = {
        ...this.chaseEndPage?.errorMessages,
        max: maxErrorMessage,
      };
    }

    if (this.pendForm) {
      this.pendForm.get("pendBegPage").setValidators(formValidators);
      this.pendForm.get("pendEndPage").setValidators(formValidators);
      this.pendBegPage.errorMessages = {
        ...this.pendBegPage?.errorMessages,
        max: maxErrorMessage,
      };
      this.pendEndPage.errorMessages = {
        ...this.pendEndPage?.errorMessages,
        max: maxErrorMessage,
      };
    }

    if (this.ignoreForm) {
      this.ignoreForm.get("ignoreBegPage").setValidators(formValidators);
      this.ignoreForm.get("ignoreEndPage").setValidators(formValidators);
      this.ignoreBegPage.errorMessages = {
        ...this.ignoreBegPage?.errorMessages,
        max: maxErrorMessage,
      };
      this.ignoreEndPage.errorMessages = {
        ...this.ignoreEndPage?.errorMessages,
        max: maxErrorMessage,
      };
    }

    if (this.invoiceForm) {
      this.invoiceForm.get("invoiceBegPage").setValidators(formValidators);
      this.invoiceForm.get("invoiceEndPage").setValidators(formValidators);
      this.invoiceBegPage.errorMessages = {
        ...this.invoiceBegPage?.errorMessages,
        max: maxErrorMessage,
      };
      this.invoiceEndPage.errorMessages = {
        ...this.invoiceEndPage?.errorMessages,
        max: maxErrorMessage,
      };
    }

    if (this.deleteForm) {
      this.deleteForm.get("deleteBegPage").setValidators(formValidators);
      this.deleteForm.get("deleteEndPage").setValidators(formValidators);
      this.deleteBegPage.errorMessages = {
        ...this.deleteBegPage?.errorMessages,
        max: maxErrorMessage,
      };
      this.deleteEndPage.errorMessages = {
        ...this.deleteEndPage?.errorMessages,
        max: maxErrorMessage,
      };
    }
  }

  assignedPageCount(): void {
    if (this.isRunModeDocument) {
      this.service.getAssigendPagesCount(this.documentId)
        .subscribe((result: number) => {
          this.numberOfAssignedPages = result;
          this.assignedPagesEvent.emit(this.numberOfAssignedPages);
        });
    }
  }

  loadAssignments() {

    this.documentPageAssignment.refresh();

    return;

  }
  pageAssignmentsLoaded(data) {
    this.documentAssignmentSummaries = (data);
    this.unassignedPagesEvent.emit(this.unassignedPages);

    if (this.firstDocumentAssignmentSummaryIsAnInvoice && this.firstDocumentAssignmentSummaryIsValid) {
      this.getPendDropdowns();
    }
    this.initilizeChaseForm();
    this.changeDetector.markForCheck();
  }


  get hasDocumentAssignmentSummaries(): boolean {
    return ArrayHelper.isAvailable(this.documentAssignmentSummaries);
  }

  get firstDocumentAssignmentSummaryIsAnInvoice(): boolean {
    return this.hasDocumentAssignmentSummaries
      && this.documentAssignmentSummaries[0].docType === "Invoice";
  }

  get firstDocumentAssignmentSummaryIsValid(): boolean {
    return this.documentAssignmentSummaries[0].startPage === 1
      && this.documentAssignmentSummaries[0].endPage === this.numberOfPages;
  }

  get unassignedPages(): number[] {
    let unAssignedPages = [];
    const unAssignedDocumentSummaries = this.documentPageAssignment.getUnassignedPages();
    if (ArrayHelper.isAvailable(unAssignedDocumentSummaries)) {
      unAssignedDocumentSummaries.forEach(d => {
        const unAssignedPageRangeForChase = Array(d.endPage - d.startPage + 1).fill(0).map((_, idx) => d.startPage + idx);
        unAssignedPages.push(unAssignedPageRangeForChase);
      });

      unAssignedPages = unAssignedPages.reduce((a, b) => a.concat(b), []);
      unAssignedPages = unAssignedPages.filter((el, i, a) => i === a.indexOf(el));
      unAssignedPages.sort();
    }

    return Array.from(Array(this.numberOfPages), (v, i) => i + 1).filter(i => unAssignedPages.includes(i));
  }

  get targetChaseId(): number {
    if (this.chaseForm && this.chaseForm.controls.assignChaseId.value != null) {
      return this.chaseForm.controls.assignChaseId.value;
    } else {
      return null;
    }
  }

  loadPageHistories() {

    this.service
      .getChasePagesHistory(this.documentId, 0)
      .subscribe((data: ChasePageHistory[]) => {
        this.pageHistories = (data);
        this.changeDetector.markForCheck();
      });
  }

  validatePagesViewed(begPage: number, endPage: number): boolean {

    return this.pageViewerComponent.validatePagesViewed(begPage, endPage);
  }

  assignChaseClick() {
    if (this.chaseForm.valid) {
      if (!this.validatePagesViewed(this.chaseForm.controls.chaseBegPage.value, this.chaseForm.controls.chaseEndPage.value) && this.reviewAllPagesAttribute) {
        this.messagingService.showMessage("Not all Pages in range have been viewed", SeverityType.ERROR);

        return;
      }
    }
    if (this.documentId === this.targetChaseId) {
      this.messagingService.showMessage("Cannot assign chase pages to the same chase.", SeverityType.ERROR);

      return;
    }
    this.validateChaseStatusForDocAttachment();


  }

  attachDocToMemberCentricChases(selectedChaseIds: number[]): void {
    if (this.sourceDocumentTypeId === this.runModeDocument) {
      this.memberCentricDocAttachmentService.attachDocToMemberCentricChases(
        selectedChaseIds, Number(this.documentId), DocumentTypeName.DocTypeMedicalRecord, this.docBeginPage, this.docEndPage, PageTypeName.MedicalRecord, true).subscribe(
          next => {
            this.messagingService.showMessage("Chase(s) assigned successfully", SeverityType.SUCCESS);
            this.loadDocumentSections();
            this.assignedPageCount();
            this.chaseForm.reset();
          },
          err => this.messagingService.showMessage("Chase(s) assign Unsuccessful", SeverityType.ERROR));

    } else if (this.sourceDocumentTypeId === this.runModeChase) {
      this.memberCentricDocAttachmentService.copyMrqaChasePagesToMemberCentricChases(this.documentId, selectedChaseIds, DocumentTypeName.DocTypeMedicalRecord,
                                                                                     this.chaseForm.controls.chaseBegPage.value, this.chaseForm.controls.chaseEndPage.value,
                                                                                     PageTypeName.MedicalRecord).subscribe(
          next => {
            this.messagingService.showToast("Chase assigned successfully", SeverityType.SUCCESS);
            this.getData();
            this.assignedPageCount();
            this.loadDocumentSections();
            this.setChangedEvent("movechasepages", { chaseId: this.documentId });
            this.chaseForm.reset();
          },
          err => this.messagingService.showMessage("Chase(s) assign Unsuccessful", SeverityType.ERROR));
    }
  }

  get memberCentricGridData(): MemberCentricChase[] {
    if (ArrayHelper.isAvailable(this.memberCentricData) && this.showMemberCentricDocAttachmentInfo) {
      return this.memberCentricData;
    }

    return null;
  }

  get docBeginPage(): number {
    if (this.showMemberCentricDocAttachmentInfo && StringHelper.isAvailable(this.chaseForm.controls.chaseBegPage.value)) {
      return Number(this.chaseForm.controls.chaseBegPage.value);
    }

    return null;
  }

  get docEndPage(): number {
    if (this.showMemberCentricDocAttachmentInfo && StringHelper.isAvailable(this.chaseForm.controls.chaseEndPage.value)) {
      return Number(this.chaseForm.controls.chaseEndPage.value);
    }

    return null;
  }

  assignPendChaseClick() {
    if (this.pendForm.valid) {
      if (!this.validatePagesViewed(this.pendForm.controls.pendBegPage.value, this.pendForm.controls.pendEndPage.value) && this.reviewAllPagesAttribute) {
        this.messagingService.showMessage("Not all Pages in range have been viewed", SeverityType.ERROR);

        return;
      }
      this.assignPagesToChasePend();
    }

  }

  private assignPagesToChasePend(): void {
    const documentPageIds =
      this.pageViewerComponent.getPageKeys(this.pendForm.controls.pendBegPage.value, this.pendForm.controls.pendEndPage.value);
    const documentPageNumbersAsCsv = this.getAllPages(Number(this.pendForm.controls.pendBegPage.value), Number(this.pendForm.controls.pendEndPage.value));
    const owner = this.hasSelectedPendType ? this.selectedPendType.extra.owner : "";
    const pendReasonId = StringHelper.isAvailable(this.pendForm.get(this.pendReasonInput.key).value) ? Number(this.pendForm.get(this.pendReasonInput.key).value) : 0;
    const chasePendItem = new ChasePendItem({
      chasePendId: null,
      pendTypeId: this.selectedPendTypeId,
      pendStatusId: PendStatus.New,
      assignedToUserId: null,
      notes: "",
      chaseIds: [this.pendForm.controls.assignPendChaseId.value],
      owner,
      documentPageIds,
      amount: Number(this.pendForm.controls.pendAmount.value),
      invoiceNumber: this.pendForm.controls.pendInvoice.value,
      pendCompanyId: this.pendForm.controls.pendCompany.value,
      documentPageNumbersAsCsv,
      documentQueueId: this.documentId,
      pageRangeStart: this.pendForm.controls.pendBegPage.value,
      pageRangeEnd: this.pendForm.controls.pendEndPage.value,
      pageSource: this.pageSource,
      pendReasonId,
    });
    const documentPageIdRequest = new DocumentPageRequest(
      this.documentId,
      this.pendForm.controls.pendBegPage.value,
      this.pendForm.controls.pendEndPage.value
    );
    if (!documentPageIds || documentPageIds.some(id => id == null)) {
      this.service.getDocumentPageIds(documentPageIdRequest)
      .subscribe(response => {
        if (response.ok) {
          chasePendItem.documentPageIds = response.body.map(item => item.documentPageID);
          this.createChasePendPages(chasePendItem);
        }
      });
    } else {
    this.createChasePendPages(chasePendItem);
  }
  }

  private createChasePendPages(chasePendItem: any) {
    this.service.createChasePendPages(chasePendItem, DocumentTypeName.DocTypeMessage, PageTypeName.ProviderMessage)
    .subscribe(response => {
      if (response.ok) {
        this.messagingService.showToast("Pend created successfully", SeverityType.SUCCESS);
        this.loadDocumentSections();
        this.assignedPageCount();
        this.pendForm.reset();
      }
    });
  }
  movePageRangeToChase(): any {

    this.service.moveChasePageRange(
      this.documentId,
      this.targetChaseId,
      DocumentTypeName.DocTypeMedicalRecord,
      this.chaseForm.controls.chaseBegPage.value,
      this.chaseForm.controls.chaseEndPage.value,
      PageTypeName.MedicalRecord
    )
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showToast("Chase assigned successfully", SeverityType.SUCCESS);
          this.getData();
          this.assignedPageCount();
          this.loadDocumentSections();
          this.setChangedEvent("movechasepages", { chaseId: this.documentId });
          this.chaseForm.reset();
        }
      });
  }

  validateChaseStatusForDocAttachment() {
    this.showNotAllowedModal = false;

    this.service.validateChaseStatusForDocAttachment(this.targetChaseId)
      .subscribe((result: string) => {

        // Only check for not authorized or invalid chase here.  Other validations will occur on WorkingDocument Commit
        // because chase status could have changed before committing
        switch (result) {

          case "invalid":
          case "notAuthorized":
            this.attachChaseNotAllowedMsg = `Chase Id: ${this.targetChaseId} is invalid`;
            this.showNotAllowedModal = true;
            break;


          default:

            this.assignPageRangeToWorkingDocument();
            break;
        }

        this.changeDetector.markForCheck();
      });


  }

  assignPagesToChase() {
    this.showConfirmationForActiveChases = false;
    this.showConfirmationForInActiveChases = false;

    const documentPageIds =
      this.pageViewerComponent.getPageKeys(this.chaseForm.controls.chaseBegPage.value, this.chaseForm.controls.chaseEndPage.value);

    this.service.assignPagesToEntity("chase", String(this.targetChaseId),
                                     this.documentId, DocumentTypeName.DocTypeMedicalRecord, documentPageIds, PageTypeName.MedicalRecord, true)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showMessage("Chase assign successful", SeverityType.SUCCESS);
          this.loadDocumentSections();
          this.assignedPageCount();
          this.chaseForm.reset();
        }
      });
  }

  assignPageRangeToWorkingDocument() {
    this.showConfirmationForActiveChases = false;
    this.showConfirmationForInActiveChases = false;

    this.service.assignPageRangeToWorkingDocument(this.sourceDocumentTypeId === this.runModeChase ? "chase2chase" : "chase", String(this.chaseForm.controls.assignChaseId.value),
                                                  this.documentId, DocumentTypeName.DocTypeMedicalRecord, this.chaseForm.controls.chaseBegPage.value,
                                                  this.chaseForm.controls.chaseEndPage.value, PageTypeName.MedicalRecord,
                                                  this.sourceDocumentTypeId === this.runModeChase ? this.documentId : null)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showToast("Chase assign successful", SeverityType.SUCCESS);
          this.loadDocumentSections();
          this.assignedPageCount();
          this.chaseForm.reset();
        }
      });
  }
  assignPageRangeToChase() {
    this.showConfirmationForActiveChases = false;
    this.showConfirmationForInActiveChases = false;

    this.service.assignPageRangeToEntity("chase", String(this.targetChaseId),
                                         this.documentId, DocumentTypeName.DocTypeMedicalRecord, this.chaseForm.controls.chaseBegPage.value,
                                         this.chaseForm.controls.chaseEndPage.value, PageTypeName.MedicalRecord, true)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showMessage("Chase assign successful", SeverityType.SUCCESS);
          this.loadDocumentSections();
          this.assignedPageCount();
          this.chaseForm.reset();
        }
      });
  }

  assignInvoiceClick() {
    this.showNotAllowedModal = false;
    if (this.invoiceForm.valid) {
      if (!this.validatePagesViewed(this.invoiceForm.controls.invoiceBegPage.value,
                                    this.invoiceForm.controls.invoiceEndPage.value) === true && this.reviewAllPagesAttribute) {
        this.messagingService.showToast("Not all Pages in range have been viewed", SeverityType.ERROR);

        return;
      }
    }
    this.invoicesService
      .validateChases(String(this.invoiceForm.controls.invoiceChaseId.value).split(" ").map(Number))
      .subscribe(data => {
        if (data.filter(chase => chase.validationStatus === "Success").length > 0) {
          this.assignInvoiceToChase();
        } else if (data[0].message.indexOf("Chase does not belong to a 3rd party") === 0) {
          this.attachChaseNotAllowedMsg = `Chase does not belong to a 3rd party`;
          this.showNotAllowedModal = true;
        } else {
          this.attachChaseNotAllowedMsg = `Chase Id: ${this.invoiceForm.controls.invoiceChaseId.value} already has an invoice`;
          this.showNotAllowedModal = true;
        }
        this.changeDetector.markForCheck();
      });
  }

  assignInvoiceToChase(): void {
    let documentPageIds =
      this.pageViewerComponent.getPageKeys(this.invoiceForm.controls.invoiceBegPage.value, this.invoiceForm.controls.invoiceEndPage.value);

    const documentPageIdRequest = new DocumentPageRequest(
      this.documentId,
      this.invoiceForm.controls.invoiceBegPage.value,
      this.invoiceForm.controls.invoiceEndPage.value
    );
    this.service.getDocumentPageIds(documentPageIdRequest)
      .subscribe(response => {
        if (response.ok) {
          documentPageIds = response.body.map(item => item.documentPageID);
          this.assignPagesToInvoice(documentPageIds);
        }
      });

    // TODO: fix hardcode doctye to get from select box

  }

  assignPagesToInvoice(documentPageIds: number[]): void {
    this.service.assignPagesToEntity(
      "invoice",
      String(this.invoiceForm.controls.invoiceChaseId.value),
      this.documentId,
      DocumentTypeName.DocTypeInvoice,
      documentPageIds,
      PageTypeName.Invoice)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showToast("Invoice assign successful", SeverityType.SUCCESS);
          this.loadAssignments();
          this.assignedPageCount();
          this.invoiceForm.reset();
        }
      });
  }

  assignIgnoreClick() {
    if (this.ignoreForm.valid) {
      if (!this.validatePagesViewed(
        this.ignoreForm.get(this.ignoreBegPage.getMasterKey()).value,
        this.ignoreForm.get(this.ignoreEndPage.getMasterKey()).value) && this.reviewAllPagesAttribute) {
        this.messagingService.showToast("Not all Pages in range have been viewed", SeverityType.ERROR);
        return;
      }
    }

    this.assignIgnoreToWorkingDocument();
  }

  assignIgnoreToWorkingDocument() {

    this.service.assignPageRangeToWorkingDocument("document", "0",
                                                  this.documentId, DocumentTypeName.DocTypeMedicalRecord, this.ignoreForm.get(this.ignoreBegPage.getMasterKey()).value,
                                                  this.ignoreForm.get(this.ignoreEndPage.getMasterKey()).value, PageTypeName.Ignore, null)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showMessage("Ignore assign successful", SeverityType.SUCCESS);
          this.loadDocumentSections();
          this.assignedPageCount();
          this.loadDocumentSections();
          this.ignoreForm.reset();
        }
      });
  }

  confirmEntityUnassignment(data: DocumentAssignmentSummary): void {
    this.unAssignEntityAlertVisible = true;
    this.docAssignmentData = data;
  }

  deleteEntityAssignedPages(): void {
    this.service.deletePagesToEntity("chase"
      ,                              String(this.docAssignmentData.chaseId)
      ,                              this.docAssignmentData.documentId
      ,                              this.docAssignmentData.documentTypeId
      ,                              this.docAssignmentData.startPage
      ,                              this.docAssignmentData.endPage
      ,                              this.sourceDocumentTypeId)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showToast("Chase Page Deletion Successful", SeverityType.SUCCESS);
          this.loadDocumentSections();
        }
      });
  }

  async deleteFromChaseClick() {
    this.disableDeleteBtn = true;
    this.loaderService.isLoading.next(true);
    await (await this.pageViewerComponent.getAllDocumentPages(this.deleteForm.controls.deleteBegPage.value, this.deleteForm.controls.deleteEndPage.value)).toPromise().then(documentPages => {
      const documentPageIds = documentPages.pages
        .filter(page => page && page.pageNumber >= this.deleteForm.controls.deleteBegPage.value && page.pageNumber <= this.deleteForm.controls.deleteEndPage.value)
        .map(prop => prop.documentPageId);
      this.deleteCharts(documentPageIds);
    });
  }

  deleteCharts(documentPageIds) {
    this.disableDeleteBtn = false;
    this.loaderService.isLoading.next(false);
    this.service.deleteChasePages(this.documentId, DocumentTypeName.DocTypeMedicalRecord, documentPageIds, PageTypeName.MedicalRecord)
      .subscribe((result: boolean) => {
        if (result) {
          this.messagingService.showToast("Delete successful", SeverityType.SUCCESS);
          this.initializeDoc();
          this.pageViewerComponent.removePages(this.deleteForm.controls.deleteBegPage.value, this.deleteForm.controls.deleteEndPage.value);
          this.assignedPageCount();
          this.loadDocumentSections();
          this.setChangedEvent("deletedchasepages", { chaseId: this.documentId });
          this.deleteForm.reset();
        }
      });
  }


  memberValidationSubmit(memberValidation: MemberValidation): void {
    this.memberValidationService.submit(memberValidation, this.status).subscribe(data => {
      this.memberValidationData = data;
      this.savedIsValidMember = this.memberValidationData.isValid;

      // Cant do MRQA if not valid member so let parent component know
      if (this.memberValidationData.isValid !== true) {
        this.setChangedEvent("membernotvalid", { chaseId: this.documentId });
      }
      this.messagingService.showToast("Member Validation Completed", SeverityType.SUCCESS);
      this.changeDetector.markForCheck();
    });
  }

  loadDocumentSections() {

    this.loadAssignments();
    this.loadPageHistories();
  }

  toggleAssignSection(section: string) {
    this.lastSelectedSection = section;
    switch (section) {
      case "pend":
        this.initilizePendIntake();
        break;
      case "ignore":
        this.initilizeIgnoreForm();
        break;
      case "chase":
        this.initilizeChaseForm();
        break;
      case "invoice":
        this.initilizeInvoiceForm();
        break;
      case "delete":
        this.initilizeDeleteForm();
        break;
      default:
        break;
    }

    this.currentAssignSection = this.currentAssignSection === section ? "" : section;
  }

  private initilizeIgnoreForm(): void {
    const formValidators = [...this.defaultFormValidators];
    formValidators.push(Validators.max(this.maximumPages));

    this.ignoreBegPage = new Textbox({
      key: "ignoreBegPage",
      type: TextboxType.NUMBER,
      label: "Start Pg",
      validators: formValidators,
      errorMessages: {
        required: `Enter number between 1 - ${this.maximumPages}`,
        min: `Enter number between 1 - ${this.maximumPages}`,
        max: `Enter number between 1 - ${this.maximumPages}`,
        pattern: `Enter number between 1 - ${this.maximumPages}`,
      },
      placeholder: "0",
    });

    this.ignoreEndPage = new Textbox({
      key: "ignoreEndPage",
      type: TextboxType.NUMBER,
      label: "End Pg",
      validators: formValidators,
      errorMessages: {
        required: `Enter number between 1 - ${this.maximumPages}`,
        min: `Enter number between 1 - ${this.maximumPages}`,
        max: `Enter number between 1 - ${this.maximumPages}`,
        pattern: `Enter number between 1 - ${this.maximumPages}`,
      },
      placeholder: "0",
    });

    this.ignoreForm = this.formService.createFormGroup([
      this.ignoreBegPage,
      this.ignoreEndPage,
    ]);
  }

  private initilizeChaseForm(): void {
    const formValidators = [...this.defaultFormValidators];
    formValidators.push(Validators.max(this.maximumPages));

    this.chaseBegPage = new Textbox({
      key: "chaseBegPage",
      type: TextboxType.NUMBER,
      label: "Start Pg",
      validators: formValidators,
      errorMessages: {
        required: `Enter number between 1 - ${this.maximumPages}`,
        min: `Enter number between 1 - ${this.maximumPages}`,
        max: `Enter number between 1 - ${this.maximumPages}`,
        pattern: `Enter number between 1 - ${this.maximumPages}`,
      },
      placeholder: "0",
    });

    this.chaseEndPage = new Textbox({
      key: "chaseEndPage",
      type: TextboxType.NUMBER,
      label: "End Pg",
      validators: formValidators,
      errorMessages: {
        required: `Enter number between 1 - ${this.maximumPages}`,
        min: `Enter number between 1 - ${this.maximumPages}`,
        max: `Enter number between 1 - ${this.maximumPages}`,
        pattern: `Enter number between 1 - ${this.maximumPages}`,
      },
      placeholder: "0",
    });

    this.assignChaseId = new Textbox({
      key: "assignChaseId",
      type: TextboxType.NUMBER,
      label: "Chase ID",
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Chase Id is required",
      },
    });

    this.chaseForm = this.formService.createFormGroup([
      this.chaseBegPage,
      this.chaseEndPage,
      this.assignChaseId,
    ]);
  }

  private initilizeInvoiceForm(): void {
    const formValidators = [...this.defaultFormValidators];
    formValidators.push(Validators.max(this.maximumPages));

    this.invoiceBegPage = new Textbox({
      key: "invoiceBegPage",
      type: TextboxType.NUMBER,
      label: "Start Pg",
      validators: formValidators,
      errorMessages: {
        required: `Enter number between 1 - ${this.maximumPages}`,
        min: `Enter number between 1 - ${this.maximumPages}`,
        max: `Enter number between 1 - ${this.maximumPages}`,
        pattern: `Enter number between 1 - ${this.maximumPages}`,
      },
      placeholder: "0",
    });

    this.invoiceEndPage = new Textbox({
      key: "invoiceEndPage",
      type: TextboxType.NUMBER,
      label: "End Pg",
      validators: formValidators,
      errorMessages: {
        required: `Enter number between 1 - ${this.maximumPages}`,
        min: `Enter number between 1 - ${this.maximumPages}`,
        max: `Enter number between 1 - ${this.maximumPages}`,
        pattern: `Enter number between 1 - ${this.maximumPages}`,
      },
      placeholder: "0",
    });

    this.invoiceChaseId = new Textbox({
      key: "invoiceChaseId",
      type: TextboxType.NUMBER,
      label: "Chase ID",
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Chase Id is required",
      },
    });

    this.invoiceForm = this.formService.createFormGroup([
      this.invoiceBegPage,
      this.invoiceEndPage,
      this.invoiceChaseId,
    ]);
  }

  private initilizeDeleteForm(): void {
    const formValidators = [...this.defaultFormValidators];

    this.deleteBegPage = new Textbox({
      key: "deleteBegPage",
      type: TextboxType.NUMBER,
      label: "Start Pg",
      validators: formValidators,
      errorMessages: {
        required: `Enter number between 1 - ${this.maximumPages}`,
        min: `Enter number between 1 - ${this.maximumPages}`,
        max: `Enter number between 1 - ${this.maximumPages}`,
        pattern: `Enter number between 1 - ${this.maximumPages}`,
      },
      placeholder: "0",
    });

    this.deleteEndPage = new Textbox({
      key: "deleteEndPage",
      type: TextboxType.NUMBER,
      label: "End Pg",
      validators: formValidators,
      errorMessages: {
        required: `Enter number between 1 - ${this.maximumPages}`,
        min: `Enter number between 1 - ${this.maximumPages}`,
        max: `Enter number between 1 - ${this.maximumPages}`,
        pattern: `Enter number between 1 - ${this.maximumPages}`,
      },
      placeholder: "0",
    });

    this.deleteForm = this.formService.createFormGroup([
      this.deleteBegPage,
      this.deleteEndPage,
    ]);
  }

  navToChase(item: DocumentAssignmentSummary) {

    this.router.navigate(["members", "chase", item.chaseId]);
  }

  trackByIndex(index, item) {
    return index;
  }

  setChangedEvent(eventName, content) {
    const event = new EmitState(eventName, content);
    this.changedEvent.emit(event);
  }

  restoreChasePage(documentPageId) {

    const documentPageIds: number[] = [documentPageId];

    this.service.assignPagesToEntity("chase", String(this.documentId),
                                     this.documentId, DocumentTypeName.DocTypeMedicalRecord, documentPageIds, PageTypeName.MedicalRecord)
      .subscribe((result: boolean) => {

        if (result) {
          this.messagingService.showToast("Page restore successful", SeverityType.SUCCESS);

          DocumentPageViewerComponent.documentId.next(this.documentId);
          this.loadDocumentSections();
          this.setChangedEvent("restorechasepages", { chaseId: this.documentId });
        }
      });
  }

  validateForChaseSubmit() {

    if (this.allowEdit === false) {
      this.messagingService.showMessage("You are not allowed to make changes to this Chase", SeverityType.ERROR);
      return false;
    }

    if (this.reviewAllPagesAttribute) {
      if (!this.pageViewerComponent.validateAllPagesViewed()) {
        this.messagingService.showMessage("All Chase Pages must be viewed to SUBMIT", SeverityType.ERROR);
        return false;
      }
    }

    if (!this.savedIsValidMember) {
      this.messagingService.showMessage("Member Validtion must be completed to SUBMIT", SeverityType.ERROR);
      return false;
    }

    if (!this.encounterValidationFlag) {
      this.messagingService.showMessage("All Dates Of Service Validation must be completed to SUBMIT", SeverityType.ERROR);
      return false;
    }

    return true;
  }


  validateForDocumentSubmit() {

    if (this.reviewAllPagesAttribute) {
      if (!this.pageViewerComponent.validateAllPagesViewed()) {
        this.messagingService.showMessage("All Document Pages must be viewed", SeverityType.ERROR);
        return false;
      }
    }

    if (!(this.documentAssignmentSummaries && this.documentAssignmentSummaries.length > 0)) {

      let msg = "At least one Page must be assigned to a Chase to SUBMIT.";
      msg += "  If you want to submit without any pages assigned, use the 'REMOVE' button.  This will remove the Document from the active Intake Queue";
      this.messagingService.showMessage(msg, SeverityType.ERROR);
      return false;

    }

    return true;
  }

  pageViewerMaximizeChange(event) {

  }

  private getPendDropdowns(): void {

    this.pendService
      .getPendDropdown(false)
      .pipe(map((result: any) => {
        return result.pendCodes.map(item => new SelectableInput({
          text: item.displayName,
          value: item.pendTypeId,
          extra: { owner: item.defaultOwner, isThirdParty: item.isThirdParty },
        }));
      }))
      .subscribe(options => {
        this.pendTypes = new Dropdown({ ...this.pendTypes, options } as any);
        this.changeDetector.markForCheck();
      });
  }

  get thirdPartyPends(): SelectableInput[] {
    return this.pendTypes.options.filter(a => a.extra.isThirdParty);
  }

  private getPendCompaniesDropdown(): void {
    this.pendService.getPendCompaniesDropdown()
      .pipe(map((result: any): void => {
        return result.map(item => new SelectableInput({
          text: item.value,
          value: item.key,
        }));
      }))
      .subscribe(data => {
        this.pendCompany = new Dropdown({ ...this.pendCompany, options: data } as any);
        this.changeDetector.markForCheck();
      });

  }

  get thirdPartyPendCodesCsv(): string {
    return this.thirdPartyPends
      .map(a => a.text.split("-")[0])
      .join(", ");
  }

  private initilizePendIntake() {
    this.getPendDropdowns();
    const formValidators = [...this.defaultFormValidators];
    formValidators.push(Validators.max(this.maximumPages));

    this.pendBegPage = new Textbox({
      key: "pendBegPage",
      type: TextboxType.NUMBER,
      label: "Start Pg",
      validators: formValidators,
      errorMessages: {
        required: `Enter number between 1 - ${this.maximumPages}`,
        min: `Enter number between 1 - ${this.maximumPages}`,
        max: `Enter number between 1 - ${this.maximumPages}`,
        pattern: `Enter number between 1 - ${this.maximumPages}`,
      },
      placeholder: "0",
    });

    this.pendEndPage = new Textbox({
      key: "pendEndPage",
      type: TextboxType.NUMBER,
      label: "End Pg",
      validators: formValidators,
      errorMessages: {
        required: `Enter number between 1 - ${this.maximumPages}`,
        min: `Enter number between 1 - ${this.maximumPages}`,
        max: `Enter number between 1 - ${this.maximumPages}`,
        pattern: `Enter number between 1 - ${this.maximumPages}`,
      },
      placeholder: "0",
    });

    this.pendAmount = new Textbox({
      key: "pendAmount",
      label: "Amount",
      validators: [
        Validators.required,
        Validators.min(0),
        Validators.max(10000),
        Validators.pattern(RegExHelper.number),
      ],
      errorMessages: {
        required: "Enter number between 0 - 10,000",
        min: "Enter number between 0 - 10,000",
        max: "Enter number between 0 - 10,000",
        pattern: "Enter valid number not more than 2 digits after decimal.",
      },
      placeholder: "0.00",
    });

    this.pendInvoice = new Textbox({
      key: "pendInvoice",
      label: "Invoice Number",
      validators: [Validators.maxLength(10)],
      errorMessages: {
        maxlength: "Invoice number can not be more than 10 characters.",
      },
    });

    this.assignPendChaseId = new Textbox({
      key: "assignPendChaseId",
      type: TextboxType.NUMBER,
      label: "Chase ID",
      validators: [
        Validators.required,
        Validators.min(1),
        Validators.pattern(RegExHelper.number),
      ],
      errorMessages: {
        required: "Chase Id is required",
        min: "Chase Id is required",
        pattern: "Chase Id is required",
      },
    });

    this.pendTypes = new Dropdown({
      key: "pendCode",
      label: "Pend Code",
      placeholder: "Select...",
      validators: [Validators.required],
      errorMessages: {
        required: "Pend Code is required.",
      },
      appendTo: "body",
    });

    this.pendCompany = new Dropdown({
      key: "pendCompany",
      label: "Company",
      placeholder: "Select...",
      validators: [Validators.required],
      errorMessages: {
        required: "Company is required.",
      },
    });

    this.pendReasonInput = new Dropdown({
      key: "pendReasonId",
      label: "Reason",
      placeholder: "Select from List",
      disabled: true,
      hidden: true,
      appendTo: "body",
    });

    this.setForm();
  }

  setForm() {
    this.pendForm = this.formService.createFormGroup([
      this.pendBegPage,
      this.pendEndPage,
      this.pendAmount,
      this.pendInvoice,
      this.assignPendChaseId,
      this.pendTypes,
      this.pendCompany,
      this.pendReasonInput]);
  }

  changePendType() {
    if (this.hasSelectedPendType) {
      if (this.isThirdPartyPendTypeSelected) {
        this.getPendCompaniesDropdown();
        this.pendForm.controls.pendCompany.enable();
        this.pendForm.controls.pendAmount.enable();
        this.pendForm.controls.pendInvoice.enable();
      } else {
        this.getPendReasons();
        this.pendForm.controls.pendCompany.disable();
        this.pendForm.controls.pendAmount.disable();
        this.pendForm.controls.pendInvoice.disable();
        this.pendForm.patchValue({
          pendAmount: null,
          pendInvoice: null,
          pendCompany: null,
        });
      }

      this.changeDetector.markForCheck();
    }
  }

  get selectedPendTypeId(): number {
    const selectedPendTypeId = Number(this.pendForm.get(this.pendTypes.key).value);
    return selectedPendTypeId;
  }

  get hasSelectedPendType(): boolean {
    return this.selectedPendType != null;
  }

  get selectedPendType(): SelectableInput {
    const selectedPendType = this.pendTypes.options.find(a => Number(a.value) === this.selectedPendTypeId);
    return selectedPendType;
  }

  get isThirdPartyPendTypeSelected(): boolean {
    return this.hasSelectedPendType && this.selectedPendType.extra.isThirdParty;
  }

  get maximumPages(): number {
    return this.pageViewerComponent?.totalPages || this.numberOfPages || 1;
  }

  getAllPages(start: number, end: number): string {
    return Array.from({ length: (end - start) + 1 }, (_, i) => start + (i * 1)).join(",");
  }

  get isOcrDataAvailable(): boolean {
    return this.documentSummary ? this.documentSummary.ocrDataAvailable : false;
  }

  get isRunModeDocument(): boolean {
    return this.runMode === this.runModeDocument;
  }

  get isRunModeChase(): boolean {
    return this.runMode === this.runModeChase;
  }

  get pageSource(): string {
    return this.isRunModeChase ? "DOCQA" : "DOCINTAKE";
  }

  onPageAssignmentGotoPage($event) {


    this.pageViewerComponent.gotoPage($event);
  }

  private getPendReasons(): void {
    const pendCodeValue = this.pendForm.get(this.pendTypes.key).value;

    if (pendCodeValue === PendType.PC100 || pendCodeValue === PendType.PC107) {
      this.pendForm.get(this.pendReasonInput.key).enable();
      this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, hidden: false } as any);
      this.pendService.getPendReasons(pendCodeValue).subscribe(data => {
        this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, options: data } as any);
        this.pendForm.get(this.pendReasonInput.key).reset();
        this.changeDetector.markForCheck();
      });
    } else {
      this.pendForm.get(this.pendReasonInput.key).disable();
      this.pendReasonInput = new Dropdown({ ...this.pendReasonInput, hidden: true } as any);


    }
  }

  get isReasonPendType(): boolean {
    const pendCodeValue = this.pendForm.get(this.pendTypes.key).value;
    return (pendCodeValue === PendType.PC100 || pendCodeValue === PendType.PC107);
  }

  get documentFileName(): string {
    if (this.runMode === this.runModeDocument && this.documentSummary) {

      return this.documentSummary.fileName;
    } else {

      return null;
    }
  }

  get showIncorrectMemberValidationInputs(): string {
    return !this.showMemberInputs ? "membervalidation__incorrectMemberValidation" : "";
  }

  showMemberValidationInputs(isCorrectMember: boolean): void {
    this.showMemberInputs = isCorrectMember;
  }
  get documentAddressId(): number {
    if (this.runMode === this.runModeDocument && this.documentSummary) {

      return this.documentSummary.masterDocumentSourceId;
    } else {

      return null;
    }
  }

  datesOfServiceValidation(event: Encounter[]): void {
    this.encounterValidation = event;
    this.encounterValidationFlag = true;

    this.encounterValidation.forEach(e => {
      if (e.enc == null || e.enc == undefined) {
        this.encounterValidationFlag = false;
      } else if ((e.enc === true) && (e.sig == null || e.sig == undefined)) {
        this.encounterValidationFlag = false;
      }
    });

    this.checkIfDosPursuitNeedToBeCreated();
  }

  checkIfDosPursuitNeedToBeCreated(): void {
    if (this.showMemberInputs && !this.isPursuitChase) {
      if (this.encounterValidation.some(e => e.enc === false || e.sig === false)) {
        this.createDosPursuit.emit("new");
      } else {
        this.createDosPursuit.emit(null);
      }
    } else if (this.showMemberInputs && this.isPursuitChase) {
      if ((this.encounterValidation.some(e => e.enc === true || e.sig === true))
        || !(this.encounterValidation.some(e => e.enc === true || e.sig === true))) {
        this.createDosPursuit.emit("closed");
      } else {
        this.createDosPursuit.emit(null);
      }
    }
  }
}
