import { InjectionToken, Type } from "@angular/core";
import { environment } from "../../environments/environment"; // angular will choose the right environment file.


// Helpers
interface IInjectionTokenConfig {
  provideIn?: "root" | Type<any>;
  factory(): any;
}

const provideValue = (value: any, provideIn: "root" | Type<any> = "root"): IInjectionTokenConfig => {
  return {
    provideIn,
    factory: () => value,
  };
};


// Injection Tokens
export const BASE_API_URL = new InjectionToken("Base url for the Web.API based on the environment.", provideValue(environment.baseApiUrl));
export const DOCUMENT_SERVICE_API_URL = new InjectionToken("Url for the Document Microservice based on the environment.", provideValue(environment.documentServiceApiUrl));
export const IDLE_PERIOD_IN_SEC = new InjectionToken("Sets an idle period based on the environment.", provideValue(environment.idlePeriodInSec));
export const IDLE_PERIOD_IN_SEC_FOR_EMPLOYEE = new InjectionToken("Sets an idle period for employee role based on the environment.", provideValue(environment.idlePeriodInSecForEmployee));
export const TIMEOUT_PERIOD_IN_SEC = new InjectionToken("Sets a timeout period based on the environment.", provideValue(environment.timeoutPeriodInSec));
