import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { UserToken } from "../../../../auth/user-token.model";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { ModalComponent } from "../../../../shared/panel/modal/modal.component";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { NumeratorListItem } from "../../../api/numerator/numerator-list-item.model";
import { ProviderService } from "../../../api/provider/provider.service";
import { ProviderPacketItem } from "../../internal-pends/internal-pends-detail/internal-pends-detail-info/provider-packet-item.model";
import { AddressDetailState } from "../address-detail/address-detail-state.model";
import { AddressDetailStateService } from "../address-detail/address-detail-state.service";
import { AddressTimelineStateService } from "../address-timeline/address-timeline-state.service";
import { AddressTimelineService } from "../address-timeline/address-timeline.service";
import { ContactMethodType } from "../cover-letter-template/contact-method-type.enum";
import { ContactRequest } from "../cover-letter-template/contact-request.model";
import { CoverLetterTemplateService } from "../cover-letter-template/cover-letter-template.service";
import { EmailRequest } from "../cover-letter-template/email-request.model";
import { ExternalRequest } from "../cover-letter-template/external-request.model";
import { FaxRequest } from "../cover-letter-template/fax-request.model";
import { RetrievalOutreachService } from "./retrieval-outreach.service";

@Component({
  selector: "app-retrieval-outreach",
  templateUrl: "./retrieval-outreach.component.html",
  styleUrls: ["./retrieval-outreach.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalOutreachComponent extends ModalComponent implements OnInit, OnDestroy {
  @Input() selectedChaseList: any[] = [];
  @Input() isGap = false;
  @Input() isUserInfoPassed = false;
  @Input() gapType = "";
  @Input() additionalOutreachChaseList: any[] = [];
  @Output() onUpdate: EventEmitter<null> = new EventEmitter();

  formGroup: FormGroup;
  formGroupAdditionalOutreach: FormGroup;
  outreachTypeInput: Dropdown;
  coverLetterTemplate: Dropdown;
  additionalCoverLetterTemplate: Dropdown;
  private sink = new SubSink();
  addressDetailState: AddressDetailState;
  isFaxChasePromptVisible = false;
  private user: UserToken;
  userName: string;
  phoneNumber: string;
  userEmail: string;
  contactRequest: ContactRequest;
  isTemplateModalVisible = false;
  providerPacketItem: ProviderPacketItem;
  isAdditionalOutreachPromptVisible = false;
  additionalOutreachInitiated = false;
  isPrimaryOutreachTypeGap: boolean;
  batchChaseCountForOutreach = 500;

  constructor(
    private formService: FormService,
    private retrievalOutreachService: RetrievalOutreachService,
    private readonly addressDetailStateService: AddressDetailStateService,
    private userService: UserService,
    private providerService: ProviderService,
    private coverLetterTemplateService: CoverLetterTemplateService,
    private addressTimelineStateService: AddressTimelineStateService,
    private addressTimelineService: AddressTimelineService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef
  ) { super(); }

  @Input() set userInfo(val: any) {
    this.userName = val?.userName;
    this.phoneNumber = val?.phoneNumber;
    this.userEmail = val?.userEmail;
  }
  ngOnInit() {
    this.user = this.userService.getUserToken();
    this.isPrimaryOutreachTypeGap = this.isGap;

    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.addressDetailState = state;

        this.changeDetector.markForCheck();
      })

    );
    if (!this.isUserInfoPassed) {
      this.getUser();
    }
    this.initializeForm();
  }

  getUser() {
    this.userService.getUser(this.user.userId).subscribe(result => {
      this.changeDetector.markForCheck();
      this.userName = `${result.firstName} ${result.lastName}`;
      this.phoneNumber = result.phone;
      this.userEmail = result.email;
    });
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  onVisibleChange(visible: boolean): void {
    if (visible) {
      this.initializeForm();
      this.getOutreachTypeList();
      this.getCoverLetterTemplateList();
      this.additionalOutreachInitiated = false;
    }
    this.visibleChange.emit(visible);
  }

  get subHeader(): string {
    const subHeaderTitle = StringHelper.isAvailable(this.gapType)
      ? this.gapType.toLowerCase() === "gap loaded"
        ? "TOTAL GAP CHASES: "
        : "TOTAL GAP PURSUITS: "
      : "TOTAL CHASES: ";

    return ArrayHelper.isAvailable(this.selectedChaseIds) ? subHeaderTitle + this.selectedChaseIds.length : subHeaderTitle + 0;
  }

  get totalChases(): number {
    return ArrayHelper.isAvailable(this.selectedChaseIds) ? this.selectedChaseIds.length : 0;
  }

  get selectedChaseIds(): number[] {

    if (!ArrayHelper.isAvailable(this.selectedChaseList)) {
      return [];
    }

    return this.selectedChaseList.map(chase => +chase.chaseId || +chase.chaseID);
  }

  get showTemplate(): boolean {
    return !this.isGap;
  }

  get isOutReachTypeSelected(): boolean {
    return this.formGroup.get("outreachType").value != null;
  }

  get isOutreachTypeFax(): boolean {
    return this.formGroup.get("outreachType").value === ContactMethodType.Fax;
  }

  get isOutreachTypeMail(): boolean {
    return this.formGroup.get("outreachType").value === ContactMethodType.Mail;
  }

  get isOutreachTypeExternal(): boolean {
    return this.formGroup.get("outreachType").value === ContactMethodType.External;
  }

  get toName(): string {
    return this.isOutreachTypeFax ?
      this.addressDetailState.contact.contactName
      : this.isOutreachTypeMail ?
        this.addressDetailState.address1
        : this.addressDetailState.contact.contactEmail;
  }

  get fromName(): string {
    return this.isOutreachTypeFax ?
      this.userName
      : this.userEmail;
  }

  get toLabel(): string {
    return "Fax #";
  }

  get toOutreachLabel(): string {
    return this.isOutreachTypeMail ?
      "Mail To"
      : "To";
  }

  get to(): string {
    return this.addressDetailState.contact.contactFax;
  }

  get batchedFaxCount(): number {
    return Math.ceil(this.totalChases / this.batchChaseCountForOutreach);
  }

  get additionalOutreachType(): string {
    return this.isGap ? "Gap Pursuits" : "Chases";
  }

  get additionalOutreachChaseCount(): number {
    return ArrayHelper.isAvailable(this.additionalOutreachChaseList) ? this.additionalOutreachChaseList.length : 0;
  }

  private initializeForm(): void {
    this.outreachTypeInput = new Dropdown({
      key: "outreachType",
      label: "COMMUNICATION TYPE",
      placeholder: "Select From List",
      validators: Validators.required,
      errorMessages: {
        required: "Communication Type is required.",
      },
      appendTo: "body",
    });

    this.coverLetterTemplate = new Dropdown({
      key: "coverLetterTemplate",
      label: "Cover Letter",
      placeholder: "Select From List",
      validators: [Validators.required],
      errorMessages: {
        required: "Cover letter template is required.",
      },
      appendTo: "body",
    });

    this.formGroup = this.formService.createFormGroup([this.outreachTypeInput, this.coverLetterTemplate]);
  }

  private getOutreachTypeList(): void {
    this.retrievalOutreachService.getOutReachTypes()
      .subscribe(types => {
        this.outreachTypeInput = new Dropdown({ ...this.outreachTypeInput, options: types } as any);
        this.changeDetector.markForCheck();
      });
  }

  private getCoverLetterTemplateList(): void {
    this.coverLetterTemplateService.getCoverLetterTemplate(this.gapType)
      .subscribe(templatelist => {
        this.coverLetterTemplate = new Dropdown({ ...this.coverLetterTemplate, options: templatelist } as any);
        this.changeDetector.markForCheck();
      });
  }

  intiateOutreach(): void {
    const outreachType = this.formGroup.get("outreachType").value;

    switch (outreachType) {
      case ContactMethodType.Fax:
        this.openFaxChasePrompt();
        break;

      case ContactMethodType.Email:
        this.openEmailModal();
        break;

      case ContactMethodType.Mail:
        this.openMailToProviderPacketModal();
        break;

      case ContactMethodType.External:
        this.openExternalModal();
        break;


      default:
        break;
    }
  }

  openFaxChasePrompt(): void {
    if (!StringHelper.isAvailable(this.addressDetailState.contact.contactFax)) {
      this.messagingService.showToast("The Fax number is invalid.", SeverityType.WARN);
    } else {
      if (NumberHelper.isGreaterThan(this.selectedChaseIds.length, 25, true)) {
        this.isFaxChasePromptVisible = true;
      } else {
        this.initateContact(this.getFaxRequest());
      }
    }
  }

  onCancelFax(): void {
    this.isFaxChasePromptVisible = false;
    this.formGroup.reset();
  }

  openFaxModal(): void {
    this.isFaxChasePromptVisible = false;
    this.initateContact(this.getFaxRequest());
  }

  getFaxRequest(): FaxRequest {
    return new FaxRequest({
      ...this.getSharedContactRequestProperties(),
      to: this.addressDetailState.contact.contactFax,
      chaseIds: this.selectedChaseIds,
      chaseNumeratorList: this.selectedChaseNumeratorList,
    });
  }

  private getSharedContactRequestProperties(): any {
    return {
      documentSourceId: this.addressDetailState.masterDocumentSourceId,
      toPhone: this.addressDetailState.contact.contactPhone,
      toName: this.addressDetailState.contact.contactName,
      from: this.phoneNumber,
      fromName: this.userName,
      templateName: this.formGroup.get("coverLetterTemplate").value,
    };
  }

  initateContact(contactRequest: ContactRequest): void {
    if (!contactRequest.isValid) {
      const toValue = StringHelper.isAvailable(contactRequest.toValue) ? ` '${contactRequest.toValue}' ` : " ";
      this.messagingService.showToast(`The ${contactRequest.toLabel}${toValue}is invalid.`, SeverityType.WARN);
    }

    this.contactRequest = contactRequest;
    this.sendContactRequest(this.contactRequest);
    this.changeDetector.markForCheck();
  }

  sendContactRequest(event: ContactRequest): void {
    this.close();
    this.providerService.sendProviderContactRequest(event).subscribe({
      next: () => {
        this.messagingService.showToast(`${event.typeName} Queued for Sending.`, SeverityType.SUCCESS);
        this.onUpdate.emit(null);
        this.changeDetector.markForCheck();
        this.fetchTimelineItems();
      },
      error: () => this.messagingService.showToast(`Error while sending ${event.typeName}, please try again.`, SeverityType.ERROR),
    });
  }

  openEmailModal(): void {
    const emailRequest = new EmailRequest({
      ...this.getSharedContactRequestProperties(),
      to: this.addressDetailState.contact.contactEmail,
      toPhone: this.addressDetailState.contact.contactPhone,
      chaseIds: this.selectedChaseIds,
      chaseNumeratorList: this.selectedChaseNumeratorList,
    });
    this.initateContact(emailRequest);
  }

  openExternalModal(): void {
    const externalRequest = new ExternalRequest({
      templateName: this.formGroup.get("coverLetterTemplate").value,
      documentSourceId: this.addressDetailState.masterDocumentSourceId,
      chaseIds: this.selectedChaseIds,
      chaseNumeratorList: this.selectedChaseNumeratorList,
      to: "N/A",
    });
    this.initateContact(externalRequest);
  }

  checkForAdditionalOutreach(): void {
    if (this.additionalOutreachChaseCount > 0) {
      this.visible = false;
      this.isGap = !this.isGap;
      this.getCoverLetterTemplateList();
      this.selectedChaseList = this.additionalOutreachChaseList;

      if (!this.isGap) {
        this.formGroup.get("coverLetterTemplate").reset();
        this.formGroup.get("coverLetterTemplate").setValidators([Validators.required]);
        this.formGroup.get("coverLetterTemplate").updateValueAndValidity();
      }
      this.isAdditionalOutreachPromptVisible = true;
    } else {
      this.close();
    }
  }

  close(): void {
    this.visible = false;
    this.onHide.emit();
    this.formGroup.reset();
    this.isGap = this.isPrimaryOutreachTypeGap;
    this.changeDetector.markForCheck();
  }


  get isValid(): boolean {
    if (this.formGroup == null) {
      return false;
    } else {
      return this.formGroup.valid;
    }
  }

  openMailToProviderPacketModal(): void {
    if (ArrayHelper.isAvailable(this.selectedChaseIds)) {
      this.providerPacketItem = new ProviderPacketItem({
        chaseIds: this.selectedChaseIds,
        toName: this.addressDetailState.contact.contactName,
        toPhone: this.addressDetailState.contact.contactPhone,
        toFax: this.addressDetailState.contact.contactFax,
        toAddress: this.addressDetailState.address1,
        fromName: this.userName,
        fromPhone: this.phoneNumber,
        providerName: "",
        serviceOrgName: this.user.organizationName,
        chaseNumeratorList: this.selectedChaseNumeratorList,
        masterDocumentSourceId: this.addressDetailState.masterDocumentSourceId,
        type: ContactMethodType.Mail,
        templateName: this.formGroup.get("coverLetterTemplate").value,
      });

      this.printProviderPacket();
    } else {
      this.messagingService.showToast("Chase(s) not in right status to be actioned upon.", SeverityType.WARN);
      return;
    }
  }

  async printProviderPacket(): Promise<void> {
    const chaseIdsToProcess = ArrayHelper.chunkArray(this.providerPacketItem.chaseIds, this.batchChaseCountForOutreach);
    const chaseNumeratorsToProcess = ArrayHelper.chunkArray(this.providerPacketItem.chaseNumeratorList, this.batchChaseCountForOutreach);
    this.close();

    chaseIdsToProcess.forEach(async (item, index) => {
      const providerPacketItem: ProviderPacketItem = { ...this.providerPacketItem, chaseIds: item, chaseNumeratorList: chaseNumeratorsToProcess[index] };
      const data = await this.providerService.printProviderPacket(providerPacketItem).toPromise();

      if (data != null) {
        this.providerService.downloadPdf(data);
        this.changeDetector.markForCheck();
      } else {
        this.messagingService.showToast("No provider packet available for download.", SeverityType.INFO);
      }
    });

    this.fetchTimelineItems();
    this.onUpdate.emit(null);
  }

  intiateAdditionalOutreach(): void {
    this.additionalOutreachInitiated = true;
    this.intiateOutreach();
  }

  closeAdditionalOutreach(): void {
    this.isAdditionalOutreachPromptVisible = false;
    this.close();
  }

  private fetchTimelineItems(): void {
    this.addressTimelineService
      .get(this.addressDetailState.masterDocumentSourceId)
      .subscribe(timelineItems => this.addressTimelineStateService.timelineItems.next(timelineItems));
  }

  get selectedChaseNumeratorList(): NumeratorListItem[] {
    if (!ArrayHelper.isAvailable(this.selectedChaseList)) {
      return [];
    }

    return this.selectedChaseList.map(chase =>
      new NumeratorListItem({
        chaseId: Number(chase.chaseId || chase.chaseID),
        numeratorId: chase.numeratorId,
        numeratorCode: chase.openGaps,
        numeratorName: chase.numeratorName,
      }));
  }
}
