import { SelectableInput } from "../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../utilities/contracts/boolean-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { obsolete } from "../../../utilities/decorators/obsolete.decorator";
import { BulkAction } from "../bulk-actions/bulk-action.model";
import { GridView } from "../grid-menu/grid-views/grid-view.model";
import { GridColumnDefinition } from "./grid-column-definition.model";

export class GridConfiguration {
    isInit: boolean;
    stateName: string;
    isStateDisabled = false;
    columns: GridColumnDefinition[];
    pageSize: number;
    pageSizeOptions: number[];
    selectionMode: string;
    showSelectionControl: boolean;
    hasPagination: boolean;
    @obsolete("Not used.") isScrollable = true; // Obsolete // TODO: remove when obsolete grid is gone.
    showActionColumn: boolean;
    showMenu: boolean;
    showExportAction: boolean;
    showRowExpansionColumn: boolean;
    trackByField: string;
    @obsolete("Use the component's [action] input.") bulkActions: BulkAction[]; // Obsolete // TODO: remove when bulk actions are removed from all GridConfigurations
    filterGridFn: (data?: any | null) => void;
    rowExpandMode: string;
    expandedRows: any;
    isHeaderCheckBoxDisabled = false;
    showViews: boolean;
    viewAttributeId: number;
    showSaveQuery: boolean;
    saveQueryId: number;
    saveQueryAttributeId: number;
    savedQueryName?: string;
    isSavedQueryPublic?: boolean;
    savedQueryView?: GridView;
    disableUnusedFiltersFunc?: boolean;
    showHeaderCheckbox: boolean;
    showGridHeader: boolean;
    selectAllPages: boolean;
    disableExportButton: boolean;

    constructor(options: {
        isInit?: boolean;
        stateName?: string;
        isStateDisabled?: boolean;
        columns?: GridColumnDefinition[];
        pageSize?: number;
        pageSizeOptions?: number[];
        selectionMode?: string;
        showSelectionControl?: boolean;
        hasPagination?: boolean;
        showActionColumn?: boolean;
        showMenu?: boolean;
        showExportAction?: boolean;
        showRowExpansionColumn?: boolean;
        trackByField?: string;
        bulkActions?: BulkAction[];
        rowExpandMode?: string;
        expandedRows?: any;
        isHeaderCheckBoxDisabled?: boolean;
        showViews?: boolean;
        viewAttributeId?: number;
        showSaveQuery?: boolean;
        saveQueryId?: number;
        saveQueryAttributeId?: number;
        savedQueryName?: string;
        isSavedQueryPublic?: boolean;
        savedQueryView?: GridView;
        disableUnusedFiltersFunc?: boolean;
        showHeaderCheckbox?: boolean;
        showGridHeader?: boolean;
        selectAllPages?: boolean;
        disableExportButton?: boolean;
        filterGridFn?(data?: any | null): void;
    } = {}) {
        this.isInit = BooleanHelper.tryGet(options.isInit, false);
        this.stateName = StringHelper.clean(options.stateName);
        this.isStateDisabled = BooleanHelper.tryGet(options.isStateDisabled, false);
        this.columns = ArrayHelper.isAvailable(options.columns) ? options.columns.map(column => new GridColumnDefinition(column)) : [];
        this.pageSize = NumberHelper.isGreaterThan(options.pageSize, 3, true) ? options.pageSize : 10;
        this.pageSizeOptions = ArrayHelper.isAvailable(options.pageSizeOptions) ? options.pageSizeOptions : [10, 25, 50, 100];
        this.selectionMode = StringHelper.isAvailable(options.selectionMode) ? options.selectionMode : "";
        this.showSelectionControl = BooleanHelper.tryGet(options.showSelectionControl, true);
        this.hasPagination = BooleanHelper.tryGet(options.hasPagination, true);
        this.showActionColumn = BooleanHelper.tryGet(options.showActionColumn, true);
        this.showMenu = BooleanHelper.tryGet(options.showMenu, true);
        this.showExportAction = BooleanHelper.tryGet(options.showExportAction, true);
        this.showRowExpansionColumn = BooleanHelper.tryGet(options.showRowExpansionColumn, false);
        this.trackByField = StringHelper.isAvailable(options.trackByField) ? options.trackByField : null;
        this.bulkActions = ArrayHelper.isAvailable(options.bulkActions) ? options.bulkActions : [];
        this.filterGridFn = typeof options.filterGridFn === "function" ? options.filterGridFn : null;
        this.rowExpandMode = StringHelper.isAvailable(options.rowExpandMode) ? options.rowExpandMode : "";
        this.expandedRows = typeof options.expandedRows === "object" && options.expandedRows != null ? options.expandedRows : {};
        this.isHeaderCheckBoxDisabled = BooleanHelper.tryGet(options.isHeaderCheckBoxDisabled, false);
        this.showViews = BooleanHelper.tryGet(options.showViews, false);
        this.viewAttributeId = options.viewAttributeId;
        this.showSaveQuery = BooleanHelper.tryGet(options.showSaveQuery, false);
        this.saveQueryId = options.saveQueryId;
        this.saveQueryAttributeId = options.saveQueryAttributeId;
        this.savedQueryName = options.savedQueryName;
        this.isSavedQueryPublic = options.isSavedQueryPublic;
        this.savedQueryView = options.savedQueryView;
        this.disableUnusedFiltersFunc = BooleanHelper.tryGet(options.disableUnusedFiltersFunc, false);
        this.showHeaderCheckbox = BooleanHelper.tryGet(options.showHeaderCheckbox, true);
        this.showGridHeader = BooleanHelper.tryGet(options.showGridHeader, true);
        this.selectAllPages = BooleanHelper.tryGet(options.selectAllPages, false);
        this.disableExportButton = BooleanHelper.tryGet(options.disableExportButton, false);
    }


    get hasStateName(): boolean {
        return StringHelper.isAvailable(this.stateName);
    }

    get pageSizeSelectableInputs(): SelectableInput[] {
        return this.pageSizeOptions.map(size => new SelectableInput({ text: size.toString(), value: size }));
    }

    get isSingleSelection(): boolean {
        return this.selectionMode === "single";
    }

    get isMultipleSelection(): boolean {
        return this.selectionMode === "multiple";
    }

    get hasSelectionMode(): boolean {
        return this.isSingleSelection || this.isMultipleSelection;
    }

    get hasBulkActions(): boolean {
        return ArrayHelper.isAvailable(this.bulkActions);
    }
}
