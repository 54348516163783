import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../auth/auth-guard.guard";
import { PursuitsComponent } from "../approval-center/pursuits/pursuits.component";
import { AuditQueryComponent } from "../audit/audit-query/audit-query.component";
import { ClinicalOrFeedbackComponent } from "../dashboard/clinical/clinical-orfeedback/clinical-orfeedback.component";
import { InternalPendsListingComponent } from "../internal-pends/internal-pends-listing/internal-pends-listing.component";
import { ProjectListComponent } from "../project/project-list/project-list.component";
import { ChpMyScoreComponent } from "./chp/chp-myscore.component";
import { ClinicalPageComponent } from "./clinical-page/clinical-page.component";
import { ClinicalQueueComponent } from "./clinical-queue/clinical-queue.component";
import { ClinicalComponent } from "./clinical.component";

const routes: Routes = [
  { path: "", component: ClinicalComponent, canActivate: [AuthGuard] },
  { path: "assignment", component: ClinicalQueueComponent, canActivate: [AuthGuard] },
  { path: "audit/auditquery", component: AuditQueryComponent, canActivate: [AuthGuard] },
  { path: "chp/myscore", component: ChpMyScoreComponent, canActivate: [AuthGuard] },
  { path: "pend/:pendType/view", component: InternalPendsListingComponent, canActivate: [AuthGuard] },
  { path: "view/challenges", component: ClinicalOrFeedbackComponent, data: { defaultPath: "/clinical/view/or1" }, canActivate: [AuthGuard] },
  { path: "view/:pageType", component: ClinicalPageComponent, canActivate: [AuthGuard] },
  { path: "projects/:fileType", component: ProjectListComponent, canActivate: [AuthGuard] },
  { path: "pursuits", component: PursuitsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClinicalRoutingModule { }
