import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { APPROVE_PURSUITS_GRID } from "../../member/chase-detail/chase-detail-chart/attributes";

@Component({
  selector: "app-pursuits",
  templateUrl: "./pursuits.component.html",
  styleUrls: ["./pursuits.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PursuitsComponent implements OnInit {
  url = `${this.baseApiUrl}approvalCenter/grid`;
  stateName = APPROVE_PURSUITS_GRID.attributeCode;
  approvalRequestType = "Pursuit";
  additionalFilters: GridFilter[] = [];
  additionalColumns: GridColumnDefinition[] = [];
  viewAttributeId = APPROVE_PURSUITS_GRID.attributeId;

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string
  ) { }

  ngOnInit() {
    this.createPursuitApprovalGrid();
  }

  private createPursuitApprovalGrid(): void {
    this.additionalFilters = [
      new GridFilter({
        input: new Textbox(),
        key: "OriginalAID",
        name: "AID",
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "DestinationAID",
        name: "Destination AID",
        show: false,
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "PursuitTypeCsv",
        name: "Pursuit Type",
        show: true,
      }),
      new GridFilter({
        input: new Autocomplete(),
        key: "RequestedBy",
        name: "Requested By",
        show: false,
      }),
    ];

    this.additionalColumns = [
      new GridColumnDefinition({
        field: "chaseId",
        header: "Original Chase ID",
        routeUrl: "/members/chase/:chaseId",
      }),
      new GridColumnDefinition({
        field: "requestType",
        header: "Pursuit Type",
        show: true,
      }),
      new GridColumnDefinition({
        field: "pursuitRequestedBy",
        header: "Pursuit requested by",
        show: true,
      }),
      new GridColumnDefinition({
        field: "originalMasterDocumentSourceId",
        header: "AID",
        routeUrl: "/retrieval/addressdetail/:originalMasterDocumentSourceId",
      }),
      new GridColumnDefinition({
        field: "destinationMasterDocumentSourceId",
        header: "Destination AID",
        show: false,
        customView: false,
      }),
    ];
  }
}
