import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { SubSink } from "subsink";
import { ChaseDetailStateService } from "../../../platform/modules/member/chase-detail/chase-detail-state.service";
import { DynamicControlDirective } from "../../dynamic-control-component.model";
import { DynamicFormEvent } from "../../dynamic-form-event.model";
import { HighlightService } from "../../highlighter/highlight.service";
import { DynamicInput } from "../../inputs/dynamic-input.model";
import { NegativeReasonCodeGroup } from "./negative-reason-code-group.model";
import { NegativeReasonCodeService } from "./negative-reason-code.service";

@Component({
  selector: "app-negative-reason-code-group",
  templateUrl: "./negative-reason-code-group.component.html",
  styleUrls: [
    "../dynamic-group.scss",
    "../../highlighter/highlight.scss",
    "./negative-reason-code-group.component.scss",
  ],
  providers: [
    HighlightService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NegativeReasonCodeGroupComponent extends DynamicControlDirective<NegativeReasonCodeGroup> implements OnInit, OnDestroy {
  private sink = new SubSink();
  isNegativeReasonCodeRequired = true;

  save$: Subject<number> = new Subject<number>();

  constructor(
    private readonly service: NegativeReasonCodeService,
    readonly highlighter: HighlightService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.sink.add(
      this.save$.pipe(debounceTime(250)).subscribe(index => {
        this.save(index);
      }),
      this.chaseDetailStateService.state.subscribe(state => {
        this.isNegativeReasonCodeRequired = state.negativeReasonCodeRequired;
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  save(index: number): void {
    if (this.control.dirty) {
      const model = this.model.controls[index];
      const negativeReasonCode = (this.control as FormGroup).controls[model.key].value.extra;
      const chaseId = (model as DynamicInput).saveInfo.chaseId;
      this.service.saveNegativeReasonCode(chaseId, negativeReasonCode).subscribe({
        next: () => {
          this.onChange.emit(new DynamicFormEvent({
                                                    key: this.model.key,
                                                    type: "compliance",
                                                    value: this.control.value,
                                                    control: this.control,
                                                    model: this.model,
                                                  }));
        },
        error: () => (this.control as FormGroup).controls[model.key].setValue(null),
      });
    }
  }

  trackByIndex(index, item) {
    return index;
  }
}
