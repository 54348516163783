import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { DynamicFormEvent } from "../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Confirm } from "../../../../../../dynamic-forms/inputs/confirm/confirm.model";
import { DynamicInput } from "../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { TextboxType } from "../../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../utilities/reg-Ex-Helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { WorkflowStatusDb } from "../../../../../api/workflow/workflow-status-db.enum";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";
import { APTC, FROM_DATE, MEMBER_DATE_OF_BIRTH, MEMBER_FIRST_NAME, MEMBER_GENDER, MEMBER_ID, MEMBER_LAST_NAME, PLAN_ID, PREMIUM_AMOUNT, RATING_AREA, SUBSCRIBER_TYPE, THRU_DATE } from "../attributes";
import { ChartService } from "../chart.service";
import { RiskHelper } from "../risk/risk-helper.model";

@Component({
  selector: "member-enr",
  templateUrl: "./enr.component.html",
  styleUrls: ["./enr.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private hasChaseId = false;
  enabled = false;
  miscellaneousEntity: any;
  form: FormGroup;
  enrolleeIdInput: Textbox;

  memberIdSaveGroup: SaveGroup;
  private memberIdInput: Confirm;

  subscriberTypeSaveGroup: SaveGroup;
  private subscriberTypeInput: Confirm;

  firstNameSaveGroup: SaveGroup;
  private firstNameInput: Confirm;
  lastNameSaveGroup: SaveGroup;
  private lastNameInput: Confirm;
  dobSaveGroup: SaveGroup;
  private dobInput: Confirm;
  genderSaveGroup: SaveGroup;
  private genderInput: Confirm;

  planIdSaveGroup: SaveGroup;
  private planIdInput: Confirm;
  fromDateSaveGroup: SaveGroup;
  private fromDateInput: Confirm;
  thruDateSaveGroup: SaveGroup;
  private thruDateInput: Confirm;
  premiumAmountSaveGroup: SaveGroup;
  private premiumAmountInput: Confirm;
  aptcSaveGroup: SaveGroup;
  private aptcPageNumberInput: Textbox;
  private aptcInput: Textbox;
  ratingAreaSaveGroup: SaveGroup;
  private ratingAreaInput: Confirm;


  constructor(
    private chaseDetailStateService: ChaseDetailStateService,
    private formService: FormService,
    private chartService: ChartService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializeForm();

    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        if (!this.hasChaseId && NumberHelper.isGreaterThan(state.chaseId, 0)) {
          this.hasChaseId = true;
          this.chartService.getEntities(state.chaseId).subscribe(entities => this.chaseDetailStateService.setData({ entities }));
        }

        this.enabled = state.isEnabled;

        if (ArrayHelper.isAvailable(state.entities)) {
          this.updateForm(state);
        }

        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  handleChange(event: DynamicFormEvent): void {
    switch (event.type) {
      case "save":
        this.saveAttributesByInputs(event.model.controls);
        break;
      case "clear":
      case "delete":
        if (!this.formService.isKeyDownEnter(event.originalEvent)) {
          this.deleteAttributesByInputs(event.model.controls);
        }
        break;
      default:
      case "compliance":
        break;
    }
  }


  private saveAttributesByInputs(controls: DynamicInput[]): void {
    const attributes = controls
      .filter(this.isAvailableAttribute.bind(this))
      .map(this.getSaveAttribute.bind(this)) as DynamicEntityAttribute[];
    this.saveAttributes(attributes);
  }

  private deleteAttributesByInputs(controls: DynamicInput[]): void {
    const allControlsDisabled = controls.every(a => a.disabled);
    if (!allControlsDisabled) {
      const attributesToDelete = controls
        .filter(this.isAvailableAttribute.bind(this))
        .map(this.getDeleteAttribute.bind(this)) as DynamicEntityAttribute[];
      this.saveAttributes(attributesToDelete);
    }
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]) {
    this.chartService
      .save2(attributes)
      .subscribe(
        entities => this.chaseDetailStateService.setData({ entities }),
        (e: HttpErrorResponse) => this.form.setErrors({ saveError: e.error })
      );
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    saveAttribute.value = this.form.get(control.getMasterKey()).value;
    return saveAttribute;
  }

  private getDeleteAttribute(control: DynamicInput): DynamicEntityAttribute {
    const deleteAttribute = this.getSaveAttribute(control);
    if (!deleteAttribute.isAdmin) {
      deleteAttribute.value = "";
      deleteAttribute.pageNumber = null;
      deleteAttribute.validationId = null;
      deleteAttribute.validationNote = "";
    }
    return deleteAttribute;
  }

  private isAvailableAttribute(attribute: DynamicInput): boolean {
    return attribute != null && attribute.saveInfo != null && NumberHelper.isGreaterThan(attribute.saveInfo.attributeId, 0);
  }

  private updateForm(state: ChaseDetailState): void {
    const chaseId = state.chaseId;
    const workflowStatusName = this.getWorkflowStatusName(state.workflowStatus);
    const singleEntity = state.formEnities[0];
    this.miscellaneousEntity = state.miscellaneousEntity;
    if (this.miscellaneousEntity) {
      this.miscellaneousEntity.attributes.forEach(element => {
        if (element.attributeId === SUBSCRIBER_TYPE.attributeId) {
            singleEntity.attributes.push(element);
        }
      });
    }

    const entityId = singleEntity.entityId;
    const entityTypeId = singleEntity.entityTypeId;
    const disableByMemberValidation = !this.enabled;

    this.form.get(this.enrolleeIdInput.getMasterKey()).setValue(state.member.enrolleeId);

    this.subscriberTypeInput.saveInfo = null;
    const subscriberTypeAttribute = RiskHelper.getAttribute(SUBSCRIBER_TYPE.attributeCode, state.miscellaneousEntity);
    this.form.get(this.subscriberTypeInput.getMasterKey()).setValue(subscriberTypeAttribute.value);
    const isSubscriber = StringHelper.clean(subscriberTypeAttribute.value as string).toUpperCase() === "S";
    const disableByNonSubscriber = disableByMemberValidation || !isSubscriber;

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.memberIdInput,
      ATTRIBUTE_DATA: MEMBER_ID,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.subscriberTypeInput,
      ATTRIBUTE_DATA: SUBSCRIBER_TYPE,
    });
    this.subscriberTypeInput.value = RiskHelper.getAttribute(SUBSCRIBER_TYPE.attributeCode, singleEntity).value;
    this.form.get(this.subscriberTypeInput.getMasterKey()).setValue(this.subscriberTypeInput.value);

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.firstNameInput,
      ATTRIBUTE_DATA: MEMBER_FIRST_NAME,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.lastNameInput,
      ATTRIBUTE_DATA: MEMBER_LAST_NAME,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.dobInput,
      ATTRIBUTE_DATA: MEMBER_DATE_OF_BIRTH,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.genderInput,
      ATTRIBUTE_DATA: MEMBER_GENDER,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.planIdInput,
      ATTRIBUTE_DATA: PLAN_ID,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.fromDateInput,
      ATTRIBUTE_DATA: FROM_DATE,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation,
      workflowStatusName,
      singleEntity,
      input: this.thruDateInput,
      ATTRIBUTE_DATA: THRU_DATE,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation: disableByNonSubscriber,
      workflowStatusName,
      singleEntity,
      input: this.premiumAmountInput,
      ATTRIBUTE_DATA: PREMIUM_AMOUNT,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation: disableByNonSubscriber,
      workflowStatusName,
      singleEntity,
      input: this.aptcPageNumberInput,
      ATTRIBUTE_DATA: { ...APTC, dataType: null },
    });
    this.aptcPageNumberInput.saveInfo = null;
    this.aptcPageNumberInput.value = RiskHelper.getAttribute(APTC.attributeCode, singleEntity).pageNumber;
    this.form.get(this.aptcPageNumberInput.getMasterKey()).setValue(this.aptcPageNumberInput.value);

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation: disableByNonSubscriber,
      workflowStatusName,
      singleEntity,
      input: this.aptcInput,
      ATTRIBUTE_DATA: APTC,
    });

    this.setupConfirmAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disableByMemberValidation: disableByNonSubscriber,
      workflowStatusName,
      singleEntity,
      input: this.ratingAreaInput,
      ATTRIBUTE_DATA: RATING_AREA,
    });
  }

  private initializeForm(): void {
    // INPUTS
    this.enrolleeIdInput = new Textbox({
      key: "EnrolleId",
      label: "Enrollee ID (RADVEE)",
      disabled: true,
    });

    this.subscriberTypeInput = new Confirm({
      key: "SubscriberType",
      label: "Subscriber Type",
    });

    this.memberIdInput = new Confirm({
      key: "MemberId",
      label: "Member ID",
      validators: [Validators.required],
      errorMessages: {
        required: "Member ID is required",
      },
    });

    this.firstNameInput = new Confirm({
      key: "MemberFirstName",
      label: "First Name",
      validators: [Validators.required],
      errorMessages: {
        required: "Member ID is required",
      },
    });

    this.lastNameInput = new Confirm({
      key: "MemberLastName",
      label: "Last Name",
      validators: [Validators.required],
      errorMessages: {
        required: "Last Name is required",
      },
    });

    this.dobInput = new Confirm({
      key: "MemberDateOfBirth",
      label: "DOB",
      validators: [Validators.required],
      errorMessages: {
        required: "DOB is required",
      },
    });

    this.genderInput = new Confirm({
      key: "MemberGender",
      label: "Gender",
      validators: [Validators.required],
      errorMessages: {
        required: "Gender is required",
      },
    });

    this.planIdInput = new Confirm({
      key: "PlanId",
      label: "Plan ID",
      validators: [Validators.required],
      errorMessages: {
        required: "Plan ID is required",
      },
    });

    this.fromDateInput = new Confirm({
      key: "EnrollmentFromDate",
      label: "Enrollment From Date",
      validators: [Validators.required],
      errorMessages: {
        required: "Enrollment From Date is required",
      },
    });

    this.thruDateInput = new Confirm({
      key: "EnrollmentThruDate",
      label: "Enrollment Thru Date",
      validators: [Validators.required],
      errorMessages: {
        required: "Enrollment Thru Date is required",
      },
    });

    this.premiumAmountInput = new Confirm({
      key: "PremiumAmount",
      label: "Premium Amount",
      type: TextboxType.NUMBER,
      validators: [
        Validators.min(0),
        Validators.max(999999),
      ],
      errorMessages: {
        min: "Premium Amount min is $0.00",
        max: "Premium Amount max is $999,999.00",
      },
    });

    this.aptcPageNumberInput = new Textbox({
      key: "AptcPageNumber",
      label: "Page Number",
      type: TextboxType.NUMBER,
      placeholder: "Page #",
      validators: [
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        min: "Page Number min is 1",
        pattern: "Page Number must be an integer",
      },
    });

    this.aptcInput = new Textbox({
      key: "Aptc",
      label: "APTC",
      type: TextboxType.NUMBER,
      placeholder: "Actual Value",
      validators: [
        Validators.min(0),
        Validators.max(999999),
      ],
      errorMessages: {
        min: "APTC min is $0.00",
        max: "APTC max is $999,999.00",
      },
    });

    this.ratingAreaInput = new Confirm({
      key: "RatingArea",
      label: "Rating Area",
      validators: [Validators.required],
      errorMessages: {
        required: "Rating Area is required",
      },
    });

    // SAVE GROUPS
    this.memberIdSaveGroup = new SaveGroup({ key: "MemberIdSaveGroup", header: "Member ID" });
    this.setupSaveGroups({
      saveGroup: this.memberIdSaveGroup,
      inputs: [this.memberIdInput],
    });


    this.subscriberTypeSaveGroup = new SaveGroup({ key: "subscriberTypeSaveGroup", header: "Subscriber Type" });
    this.setupSaveGroups({
      saveGroup: this.subscriberTypeSaveGroup,
      inputs: [this.subscriberTypeInput],
    });

    this.firstNameSaveGroup = new SaveGroup({ key: "FirstNameSaveGroup", header: "First Name" });
    this.setupSaveGroups({
      saveGroup: this.firstNameSaveGroup,
      inputs: [this.firstNameInput],
    });

    this.lastNameSaveGroup = new SaveGroup({ key: "LastNameSaveGroup", header: "Last Name" });
    this.setupSaveGroups({
      saveGroup: this.lastNameSaveGroup,
      inputs: [this.lastNameInput],
    });

    this.dobSaveGroup = new SaveGroup({ key: "dobSaveGroup", header: "DOB" });
    this.setupSaveGroups({
      saveGroup: this.dobSaveGroup,
      inputs: [this.dobInput],
    });

    this.genderSaveGroup = new SaveGroup({ key: "genderSaveGroup", header: "Gender" });
    this.setupSaveGroups({
      saveGroup: this.genderSaveGroup,
      inputs: [this.genderInput],
    });

    this.planIdSaveGroup = new SaveGroup({ key: "planIdSaveGroup", header: "Plan ID" });
    this.setupSaveGroups({
      saveGroup: this.planIdSaveGroup,
      inputs: [this.planIdInput],
    });

    this.fromDateSaveGroup = new SaveGroup({ key: "fromDateSaveGroup", header: "Enrollment From Date" });
    this.setupSaveGroups({
      saveGroup: this.fromDateSaveGroup,
      inputs: [this.fromDateInput],
    });

    this.thruDateSaveGroup = new SaveGroup({ key: "thruDateSaveGroup", header: "Enrollment Thru Date" });
    this.setupSaveGroups({
      saveGroup: this.thruDateSaveGroup,
      inputs: [this.thruDateInput],
    });

    this.premiumAmountSaveGroup = new SaveGroup({ key: "premiumAmountSaveGroup", header: "Premium Amount" });
    this.setupSaveGroups({
      saveGroup: this.premiumAmountSaveGroup,
      inputs: [this.premiumAmountInput],
    });

    this.aptcSaveGroup = new SaveGroup({ key: "aptcSaveGroup", header: "APTC" });
    this.setupSaveGroups({
      saveGroup: this.aptcSaveGroup,
      inputs: [this.aptcPageNumberInput, this.aptcInput],
    });

    this.ratingAreaSaveGroup = new SaveGroup({ key: "ratingAreaSaveGroup", header: "Rating Area" });
    this.setupSaveGroups({
      saveGroup: this.ratingAreaSaveGroup,
      inputs: [this.ratingAreaInput],
    });

    // CREATE FORM
    this.form = this.formService.createFormGroup([
      this.enrolleeIdInput,
      this.subscriberTypeSaveGroup,
      this.memberIdSaveGroup,
      this.firstNameSaveGroup,
      this.lastNameSaveGroup,
      this.dobSaveGroup,
      this.genderSaveGroup,
      this.planIdSaveGroup,
      this.fromDateSaveGroup,
      this.thruDateSaveGroup,
      this.premiumAmountSaveGroup,
      this.aptcSaveGroup,
      this.ratingAreaSaveGroup,
    ]);

    // SETUP PAGE NUMBER SUBSCRIPTIONS
    this.sink.add(this.form.get(this.aptcPageNumberInput.getMasterKey()).valueChanges.subscribe(value => this.aptcInput.saveInfo.pageNumber = value == null ? null : Number(value)));
  }

  private getWorkflowStatusName(workflowStatus: WorkflowStatusDb): string {
    return StringHelper.clean(WorkflowStatusDb[workflowStatus]).toLowerCase();
  }

  private setupConfirmAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    entityId,
    entityTypeId,
    disableByMemberValidation,
    workflowStatusName,
  }: any): void {
    const attributes = RiskHelper.getAttributes(ATTRIBUTE_DATA.attributeCode, singleEntity);
    const adminAttribute = attributes.find(a => a.isAdmin) || {} as any;
    const attribute = {
      ...attributes.find(a => !a.isAdmin),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId,
      entityTypeId,
    };

    input.saveInfo = attribute;
    input.value = attribute.value;
    input.adminValue = adminAttribute.value;
    input.disabled = disableByMemberValidation;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    // (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    disableByMemberValidation ? control.disable() : control.enable();
  }

  private setupSaveGroups({
    saveGroup,
    inputs,
  }: any): void {
    saveGroup.controls = inputs;
    inputs.forEach(a => a.parent = saveGroup);
  }

  validateInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const maxLength = 16;

    if (input?.value.length > maxLength) {
      input.value = input.value.slice(0, maxLength);
    }
  }

}
