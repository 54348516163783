import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { DynamicFormEvent } from "../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { StandardGroup } from "../../../../../../dynamic-forms/form-groups/standard-group/standard-group.model";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { DynamicEntity } from "../../../../../api/member-validation/dynamic-entity.model";
import { EntityType } from "../../../../../api/member-validation/entity-type.enum";
import { WorkflowStatusDb } from "../../../../../api/workflow/workflow-status-db.enum";
import { WorkflowStatus } from "../../../../../api/workflow/worklow-status.enum";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";
import { GENDER_CONFIRM } from "../attributes";
import { ChartService } from "../chart.service";
import { RiskHelper } from "../risk/risk-helper.model";

@Component({
  selector: "member-gender",
  templateUrl: "./gen.component.html",
  styleUrls: ["./gen.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private hasChaseId = false;
  enabled = false;
  chaseId: number;

  form: FormGroup;
  saveGroup: SaveGroup;
  standardGroup: StandardGroup;
  private memberFirstNameInput: Textbox;
  private memberLastNameInput: Textbox;
  private memberGenderInput: Textbox;
  private confirmGenderInput: Dropdown;

  constructor(
    private chaseDetailStateService: ChaseDetailStateService,
    private formService: FormService,
    private chartService: ChartService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializeForm();

    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        if (!this.hasChaseId && NumberHelper.isGreaterThan(state.chaseId, 0)) {
          this.hasChaseId = true;
          this.chaseId = state.chaseId;
          this.chartService.getEntities(this.chaseId).subscribe(this.setEntities(this.chaseId).bind(this));
        }

        this.enabled = state.isEnabled;

        this.createMemberForm(state);
        if (ArrayHelper.isAvailable(state.formEnities)) {
          this.createForm(state);
        }

        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  handleChange(event: DynamicFormEvent): void {
    switch (event.type) {
      case "save":
        this.saveAttributesByInputs(event.model.controls);
        break;
      case "clear":
      case "delete":
        if (!this.formService.isKeyDownEnter(event.originalEvent)) {
          this.deleteAttributesByInputs(event.model.controls);
        }
        break;
      default:
      case "compliance":
        break;
    }
  }

  private saveAttributesByInputs(controls: DynamicInput[]): void {
    const attributes = controls
      .map(this.getSaveAttribute.bind(this))
      .filter(this.getUniqueAttributes.bind(this)) as DynamicEntityAttribute[];
    this.saveAttributes(attributes);
  }

  private deleteAttributesByInputs(controls: DynamicInput[]): void {
    const allControlsDisabled = controls.every(a => a.disabled);
    if (!allControlsDisabled) {
      const attributesToDelete = controls
        .map(this.getDeleteAttribute.bind(this))
        .filter(this.getUniqueAttributes.bind(this)) as DynamicEntityAttribute[];
      this.saveAttributes(attributesToDelete);
    }
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]) {
    this.chartService
      .save2(attributes)
      .subscribe({
        next: this.setEntities(this.chaseId).bind(this),
        error: (e: HttpErrorResponse) => this.form.setErrors({ saveError: e.error }),
      });
  }

  private setEntities(chaseId: number): any {
    return (entities: DynamicEntity[]): void => {
      const hasConfirmationEntity = entities.some(a => a.entityTypeId === EntityType.CONFIRMATION);
      if (!hasConfirmationEntity) {
        const defaultConfirmationEntity = new DynamicEntity({
          chaseId,
          entityTypeId: EntityType.CONFIRMATION,
        });
        entities.push(defaultConfirmationEntity);
      }

      this.chaseDetailStateService.setData({ entities });
    };

  }

  private initializeForm(): void {

    this.memberFirstNameInput = new Textbox({
      key: "MemberFirstName",
      label: "Member First Name",
      disabled: true,
    });

    this.memberLastNameInput = new Textbox({
      key: "MemberLastName",
      label: "Member Last Name",
      disabled: true,
    });

    this.memberGenderInput = new Textbox({
      key: "MemberGender",
      label: "Member Gender",
      disabled: true,
    });

    this.confirmGenderInput = new Dropdown({
      key: "ConfirmGender",
      label: "Confirm Gender?",
      placeholder: "Yes/No",
      validators: [Validators.required],
      errorMessages: {
        required: "Confirm Gender is required",
      },
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
    });

    this.setSaveGroupAndCreateForm();
  }

  private setSaveGroupAndCreateForm(): void {
    this.saveGroup = new SaveGroup({
      key: "GenderSave",
      controls: [this.confirmGenderInput],
    });
    this.confirmGenderInput.parent = this.saveGroup;

    this.standardGroup = new StandardGroup({
      key: "MemberStandard",
      header: "Member Information",
      controls: [
        this.memberFirstNameInput,
        this.memberLastNameInput,
        this.memberGenderInput,
      ],
    });
    this.memberFirstNameInput.parent = this.standardGroup;
    this.memberLastNameInput.parent = this.standardGroup;
    this.memberGenderInput.parent = this.standardGroup;

    this.form = this.formService.createFormGroup([
      this.standardGroup,
      this.saveGroup,
    ]);
  }

  private createForm(state: ChaseDetailState): void {
    const chaseId = state.chaseId;
    const workflowStatusName = this.getWorkflowStatusName(state.workflowStatus);
    const singleEntity = state.formEnities[0];
    const entityId = singleEntity.entityId;
    const entityTypeId = singleEntity.entityTypeId;
    const disableByMemberValidation = !this.enabled;

    const confirmGenderAttribute = {
      ...RiskHelper.getAttribute(GENDER_CONFIRM.attributeCode, singleEntity),
      chaseId,
      entityId,
      entityTypeId,
      ...GENDER_CONFIRM,
    };

    this.confirmGenderInput.saveInfo = confirmGenderAttribute;
    this.confirmGenderInput.value = confirmGenderAttribute.value;
    this.confirmGenderInput.disabled = disableByMemberValidation;
    this.confirmGenderInput.isChanged = confirmGenderAttribute.isChanged;
    this.confirmGenderInput.workflowStatusName = workflowStatusName;
    const confirmGenderControl = this.form.get(this.confirmGenderInput.getMasterKey());
    confirmGenderControl.setValue(confirmGenderAttribute.value);
    disableByMemberValidation ? confirmGenderControl.disable() : confirmGenderControl.enable();
  }

  private createMemberForm(state: ChaseDetailState): void {
    if (state != null && state.member != null) {
      this.form.get(this.memberFirstNameInput.getMasterKey()).setValue(state.member.memberFirstName);
      this.form.get(this.memberLastNameInput.getMasterKey()).setValue(state.member.memberLastName);
      this.form.get(this.memberGenderInput.getMasterKey()).setValue(state.member.memberGender);
    }
  }

  private getWorkflowStatusName(workflowStatus: WorkflowStatusDb): string {
    return StringHelper.clean(WorkflowStatusDb[workflowStatus]).toLowerCase();
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    saveAttribute.value = this.form.get(control.getMasterKey()).value;
    return saveAttribute;
  }

  private getDeleteAttribute(control: DynamicInput): DynamicEntityAttribute {
    const deleteAttribute = this.getSaveAttribute(control);
    if (!deleteAttribute.isAdmin) {
      deleteAttribute.value = "";
      deleteAttribute.pageNumber = null;
      deleteAttribute.validationId = null;
      deleteAttribute.validationNote = "";
    }
    return deleteAttribute;
  }

  private getUniqueAttributes(attribute: DynamicEntityAttribute, index: number, attributes: DynamicEntityAttribute[]): boolean {
    return attributes.findIndex(a => a.attributeId === attribute.attributeId && a.isAdmin === attribute.isAdmin) === index;
  }
}
