import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SessionService } from "../../../../../../../core/storage/session.service";
import { CheckboxGroup } from "../../../../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { GridStateService } from "../../../../../../../shared/grid/grid-state.service";
import { GridRequest } from "../../../../../../../shared/grid/models/grid-request.model";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { ChaseItem } from "../../../../../../api/chase-search/chase-search-result-item";
import { ChaseSearchService } from "../../../../../../api/chase-search/chase-search.service";
import { FunctionalRole } from "../../../../../dashboard/retrieval/functional-role.enum";
import { DASHBOARD_MY_CHASES_GRID } from "../../../../chase-detail/chase-detail-chart/attributes";
import { ChaseDetailState } from "../../../../chase-detail/chase-detail-state.model";

@Injectable({
    providedIn: "root",
})
export class ChaseQueueService {
    stateName = DASHBOARD_MY_CHASES_GRID.attributeCode;
    defaultStatus = [
        "Data Entry",
        "Client Overread",
        "Overread",
        "Overread Feedback",
    ];

    private userId: number | null = null;
    private functionalRoleId: number | null = null;
    private chaseDetail: ChaseDetailState | null = null;

    constructor(
        private sessionService: SessionService,
        private gridStateService: GridStateService,
        private chaseSearchService: ChaseSearchService,
        private router: Router
    ) { }

    get isFromDashboardClinical(): boolean {
        const prevUrl: string = this.sessionService.get("chase_prev_url", null);
        return prevUrl?.includes("/dashboard/clinical");
    }

    setUserId(userId: number): void {
        this.userId = userId;
    }

    setFunctionalRoleId(functionalRoleId: number): void {
        this.functionalRoleId = functionalRoleId;
    }

    setChaseDetail(chaseDetail: ChaseDetailState): void {
        this.chaseDetail = chaseDetail;
    }

    private scrollToTop() {
        const scrollElement = document.getElementById("scrollElement");
        if (scrollElement) {
            scrollElement.scrollTop = 0;
        }
    }

    getQueueOrNextChase(userId: number, functionalRoleId: number, chaseDetail: ChaseDetailState): void {

        this.setUserId(userId);
        this.setFunctionalRoleId(functionalRoleId);
        this.setChaseDetail(chaseDetail);

        if (
            StringHelper.isAvailable(this.stateName) &&
            this.gridStateService.hasKey(this.stateName) &&
            this.isFromDashboardClinical
        ) {
            this.fetchGridStateRecord();
        } else {
            this.fetchGridStateRecordWithoutFilter();
        }
    }

    private fetchGridStateRecordWithoutFilter(): void {
        const requestWithoutFilters = {
            AssignedToUserId: this.userId,
            FunctionalRoleIds: this.isFromDashboardClinical
                ? [FunctionalRole.ABSTRACTOR, FunctionalRole.CLIENTOVERREAD, FunctionalRole.OVERREAD].join(",")
                : this.functionalRoleId,
            endRecord: 2,
            startRecord: 0,
            sortDirection: "asc",
            Statuses: this.isFromDashboardClinical ? this.defaultStatus.join(",") : this.chaseDetail.reportingStatusName,
            sortField: null,
        };

        this.chaseSearchService.chaseQuery(requestWithoutFilters).subscribe(data => {
            const chaseId = this.getNextChaseId(data);
            if (NumberHelper.isAvailable(chaseId)) {
                this.navigateToChase(chaseId, true);
                this.scrollToTop();
            } else {
                this.router.navigate(["dashboard", "clinical"]);
            }
        });
    }

    private fetchGridStateRecord(): void {
        const gridState = this.gridStateService.get(this.stateName);
        const serverRequest = new GridRequest({
            filters: gridState.request.filters,
            sortDirection: gridState.request.sortDirection,
            sortField: gridState.request.sortField,
            startRecord: 0,
            endRecord: 2,
        });

        serverRequest.setForm();
        const request = serverRequest.createRequestObject();
        if (!request.Statuses) {
            const statusInputs: CheckboxGroup = serverRequest.getInput("Statuses");
            request.Statuses = statusInputs.options.reduce((acc, input) => input.value !== "Pended"
                ? [...acc, input.value] : acc,             []).join(",");
        } else { request.Statuses = request.Statuses.replace(",Pended", ""); }

        this.chaseSearchService.chaseQuery(request).subscribe(data => {
            const chaseId = this.getNextChaseId(data);
            if (NumberHelper.isAvailable(chaseId)) {
                this.navigateToChase(chaseId, true);
                this.scrollToTop();
            } else {
                this.fetchGridStateRecordWithoutFilter();
            }
        });
    }

    private navigateToChase(chaseId: number, reload: boolean = false): void {
        const path = "/members/chase";

        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
            this.router.navigate([`${path}/${chaseId}`], { state: { reload } });
        });
    }

    private getNextChaseId(chases: ChaseItem[]): number | null {
        const chase = chases?.filter(chaseItem => this.chaseDetail?.chaseId !== chaseItem.chaseId)[0];
        return chase?.chaseId ?? null;
    }
}
