import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { DynamicFormEvent } from "../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../dynamic-forms/form.service";
import { DynamicInput } from "../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { Textbox } from "../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { DynamicEntity } from "../../../../../api/member-validation/dynamic-entity.model";
import { EntityType } from "../../../../../api/member-validation/entity-type.enum";
import { WorkflowStatusDb } from "../../../../../api/workflow/workflow-status-db.enum";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";
import { ADDRESS_1, ADDRESS_2, CITY, CONTACT_NAME, EMAIL, FAX, GROUP_NAME, PHONE, POSTAL_CODE, STATE } from "../attributes";
import { ChartService } from "../chart.service";
import { RiskHelper } from "../risk/risk-helper.model";

@Component({
  selector: "member-adrv",
  templateUrl: "./adrv.component.html",
  styleUrls: ["./adrv.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdrvComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private hasChaseId = false;
  private enabled = false;
  chaseId: number;

  form: FormGroup;

  adrvSaveGroup: SaveGroup;
  private groupNameInput: Textbox;
  private addr1Input: Textbox;
  private addr2Input: Textbox;
  private cityInput: Textbox;
  private stateInput: Textbox;
  private zipCodeInput: Textbox;
  private contactNameInput: Textbox;
  private phoneInput: Textbox;
  private emailInput: Textbox;
  private faxInput: Textbox;

  constructor(
    private chaseDetailStateService: ChaseDetailStateService,
    private formService: FormService,
    private chartService: ChartService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializeForm();

    this.sink.add(
      this.chaseDetailStateService.state.subscribe(state => {
        if (!this.hasChaseId && NumberHelper.isGreaterThan(state.chaseId, 0)) {
          this.hasChaseId = true;
          this.chaseId = state.chaseId;
          this.chartService.getEntities(state.chaseId).subscribe(entities => this.chaseDetailStateService.setData({ entities }));
        }

        this.enabled = state.isEnabled;

        if (ArrayHelper.isAvailable(state.entities)) {
          this.updateForm(state);
        }

        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  handleChange(event: DynamicFormEvent): void {
    switch (event.type) {
      case "save":
        this.saveAttributesByInputs(event.model.controls);
        break;
      case "clear":
      case "delete":
        if (!this.formService.isKeyDownEnter(event.originalEvent)) {
          this.deleteAttributesByInputs(event.model.controls);
        }
        break;
      default:
      case "compliance":
        break;
    }
  }

  private initializeForm(): void {
    this.groupNameInput = new Textbox({
      key: "GroupName",
      label: "Group Name",
    });

    this.addr1Input = new Textbox({
      key: "Address1",
      label: "Address 1",
    });

    this.addr2Input = new Textbox({
      key: "Address2",
      label: "Address 2",
    });

    this.cityInput = new Textbox({
      key: "City",
      label: "City",
    });

    this.stateInput = new Textbox({
      key: "State",
      label: "State",
    });

    this.zipCodeInput = new Textbox({
      key: "ZipCode",
      label: "Zip Code",
    });

    this.contactNameInput = new Textbox({
      key: "Contact Name",
      label: "Contact Name",
    });

    this.phoneInput = new Textbox({
      key: "Phone",
      label: "Phone",
    });

    this.emailInput = new Textbox({
      key: "Email",
      label: "Email",
    });

    this.faxInput = new Textbox({
      key: "Fax",
      label: "Fax",
    });

    // Save Group
    this.adrvSaveGroup = new SaveGroup({key: "AdrvSaveGroup"});
    this.setupSaveGroups({
      saveGroup: this.adrvSaveGroup,
      inputs: [
        this.groupNameInput,
        this.addr1Input,
        this.addr2Input,
        this.cityInput,
        this.stateInput,
        this.zipCodeInput,
        this.contactNameInput,
        this.phoneInput,
        this.emailInput,
        this.faxInput,
      ],
    });

    // CREATE FORM
    this.form = this.formService.createFormGroup([
      this.adrvSaveGroup,
    ]);
  }

  private updateForm(state: ChaseDetailState): void {
    const chaseId = state.chaseId;
    const workflowStatusName = this.getWorkflowStatusName(state.workflowStatus);
    const singleEntity = state.formEnities[0];
    const entityId = singleEntity.entityId;
    const entityTypeId = singleEntity.entityTypeId;
    const disabled = !this.enabled;

    this.setupAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity,
      input: this.groupNameInput,
      ATTRIBUTE_DATA: GROUP_NAME,
    });

    this.setupAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity,
      input: this.addr1Input,
      ATTRIBUTE_DATA: ADDRESS_1,
    });

    this.setupAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity,
      input: this.addr2Input,
      ATTRIBUTE_DATA: ADDRESS_2,
    });

    this.setupAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity,
      input: this.cityInput,
      ATTRIBUTE_DATA: CITY,
    });

    this.setupAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity,
      input: this.stateInput,
      ATTRIBUTE_DATA: STATE,
    });

    this.setupAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity,
      input: this.zipCodeInput,
      ATTRIBUTE_DATA: POSTAL_CODE,
    });

    this.setupAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity,
      input: this.contactNameInput,
      ATTRIBUTE_DATA: CONTACT_NAME,
    });

    this.setupAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity,
      input: this.phoneInput,
      ATTRIBUTE_DATA: PHONE,
    });

    this.setupAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity,
      input: this.emailInput,
      ATTRIBUTE_DATA: EMAIL,
    });

    this.setupAttribute({
      chaseId,
      entityId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity,
      input: this.faxInput,
      ATTRIBUTE_DATA: FAX,
    });
  }

  private getWorkflowStatusName(workflowStatus: WorkflowStatusDb): string {
    return StringHelper.clean(WorkflowStatusDb[workflowStatus]).toLowerCase();
  }

  private setupAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    entityId,
    entityTypeId,
    disabled,
    workflowStatusName,
  }: any): void {
    const attribute = {
      ...RiskHelper.getAttribute(ATTRIBUTE_DATA.attributeCode, singleEntity),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId,
      entityTypeId,
    };

    input.saveInfo = attribute;
    input.value = attribute.value;
    input.disabled = disabled;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    input.disabled ? control.disable() : control.enable();
  }

  private setupSaveGroups({
    saveGroup,
    inputs,
  }: any): void {
    saveGroup.controls = inputs;
    inputs.forEach(a => a.parent = saveGroup);
  }

  private saveAttributesByInputs(controls: DynamicInput[]): void {
    const attributes = controls
      .map(this.getSaveAttribute.bind(this))
      .filter(this.getUniqueAttributes.bind(this)) as DynamicEntityAttribute[];
    this.saveAttributes(attributes);
  }

  private deleteAttributesByInputs(controls: DynamicInput[]): void {
    const allControlsDisabled = controls.every(a => a.disabled);
    if (!allControlsDisabled) {
      const attributesToDelete = controls
        .map(this.getDeleteAttribute.bind(this))
        .filter(this.getUniqueAttributes.bind(this)) as DynamicEntityAttribute[];
      this.saveAttributes(attributesToDelete);
    }
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    saveAttribute.value = this.form.get(control.getMasterKey()).value;
    return saveAttribute;
  }

  private getUniqueAttributes(attribute: DynamicEntityAttribute, index: number, attributes: DynamicEntityAttribute[]): boolean {
    return attributes.findIndex(a => a.attributeId === attribute.attributeId && a.isAdmin === attribute.isAdmin) === index;
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]) {
    this.chartService
      .save2(attributes)
      .subscribe({
        next: this.setEntities(this.chaseId).bind(this) as any,
        error: (e: HttpErrorResponse) => this.form.setErrors({ saveError: e.error }),
      });
  }

  private getDeleteAttribute(control: DynamicInput): DynamicEntityAttribute {
    const deleteAttribute = this.getSaveAttribute(control);
    if (!deleteAttribute.isAdmin) {
      deleteAttribute.value = "";
      deleteAttribute.pageNumber = null;
      deleteAttribute.validationId = null;
      deleteAttribute.validationNote = "";
    }
    return deleteAttribute;
  }

  private setEntities(chaseId: number): any {
    return (entities: DynamicEntity[]): void => {
      const hasConfirmationEntity = entities.some(a => a.entityTypeId === EntityType.CONFIRMATION);
      if (!hasConfirmationEntity) {
        const defaultConfirmationEntity = new DynamicEntity({
          chaseId,
          entityTypeId: EntityType.CONFIRMATION,
        });
        entities.push(defaultConfirmationEntity);
      }
      this.chaseDetailStateService.setData({ entities });
    };
  }
}
