import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { combineLatest } from "rxjs";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../../core/automapper/automapper.service";
import { DynamicFormEvent } from "../../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { NoConfirmProvider } from "../../../../../../../dynamic-forms/inputs/no-confirm-provider/no-confirm-provider.model";
import { NoConfirm } from "../../../../../../../dynamic-forms/inputs/no-confirm/no-confirm.model";
import { SelectableInput } from "../../../../../../../dynamic-forms/inputs/selectable-input.model";
import { TextboxType } from "../../../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { dateBetweenValidator } from "../../../../../../../dynamic-forms/validators/date-between.validator";
import { DateHelper } from "../../../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../../utilities/reg-Ex-Helper";
import { DynamicEntityAttribute } from "../../../../../../api/member-validation/dynamic-entity-attribute.model";
import { ChaseDetailState } from "../../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../../chase-detail-state.service";
import { CHART_PAGE_NUMBER, CLAIM_ID, ENCOUNTER_FOUND, ENCOUNTER_TYPE, FROM_DATE, PROVIDER_ID, THRU_DATE } from "../../attributes";
import { RiskEntity } from "../risk-entity.model";
import { RiskHelper } from "../risk-helper.model";
import { RiskState } from "../risk-state.model";
import { RiskService } from "../risk.service";

@Component({
  selector: "member-risk-encounter",
  templateUrl: "./encounter.component.html",
  styleUrls: ["./encounter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncounterComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  selectedEncounter: RiskEntity;
  showEncFoundModal = false;
  serviceProviderRequired = "";
  encounterTypeRequired = "";

  form: FormGroup;
  encSaveGroup: SaveGroup;
  private encInput: Dropdown;

  idSaveGroup: SaveGroup;
  private idInput: Textbox;
  encounterSaveGroup: SaveGroup;
  private encounterTypeInput: Dropdown;
  pageNumberGroup: SaveGroup;
  private pageNumberInput: Textbox;

  startDateSaveGroup: SaveGroup;
  private startDateInput: NoConfirm;
  endDateSaveGroup: SaveGroup;
  private endDateInput: NoConfirm;
  providerSaveGroup: SaveGroup;
  private providerInput: NoConfirmProvider;

  constructor(
    private formService: FormService,
    private riskService: RiskService,
    private readonly changeDetector: ChangeDetectorRef,
    private automapper: AutomapperService,
    private chaseDetailState: ChaseDetailStateService
  ) { }

  get isServiceProviderRequired(): boolean {
    return this.serviceProviderRequired === "1";
  }

  get isEncounterTypeRequired(): boolean {
    return this.encounterTypeRequired === "1";
  }

  ngOnInit() {
    this.initializeForm();

    this.sink.add(
      combineLatest([
        this.riskService.data,
        this.chaseDetailState.state,
      ]).subscribe(([riskState, chaseDetailState]: any[]) => {
        this.updateForm(riskState);
        this.updateFormWithProjectConfiguration(chaseDetailState);
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  handleChange(event: DynamicFormEvent): void {
    switch (event.type) {
      case "save":
        this.saveAttributesByInputs(event.model.controls);
        break;
      case "clear":
      case "delete":
        if (!this.formService.isKeyDownEnter(event.originalEvent)) {
          this.deleteAttributesByInputs(event.model.controls);
        }
        break;
      default:
      case "compliance":
        break;
    }
  }

  clearEncounterAndSaveEncFound(): void {
    const chaseId = this.riskService.data.value.chaseId;
    const attributes = [this.encInput.saveInfo];
    this.riskService.clearEncounter(attributes).subscribe(() => {
      this.riskService.save(attributes).subscribe(() => {
        this.riskService.refreshData(chaseId);
      });
    });
  }

  revertEncFound(): void {
    this.form.get(this.encInput.getMasterKey()).setValue("1");
  }

  private saveAttributesByInputs(controls: DynamicInput[]): void {
    const attributes = controls
      .filter(this.isAvailableAttribute.bind(this))
      .map(this.getSaveAttribute.bind(this)) as DynamicEntityAttribute[];

    if (this.isChangingEncToNo(attributes)) {
      this.showEncFoundModal = true;
    } else {
      this.saveAttributes(attributes);
    }
  }

  private deleteAttributesByInputs(controls: DynamicInput[]): void {
    const allControlsDisabled = controls.every(a => a.disabled);
    if (!allControlsDisabled) {
      const attributesToDelete = controls
        .filter(this.isAvailableAttribute.bind(this))
        .map(this.getDeleteAttribute.bind(this)) as DynamicEntityAttribute[];
      this.saveAttributes(attributesToDelete);
    }
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]) {
    const selectedIndex = this.riskService.data.value.selectedEncounterIndex;
    this.riskService
      .save(attributes)
      .subscribe(
        newAttributes => this.riskService.setEncounterAttributes(selectedIndex, newAttributes),
        (e: HttpErrorResponse) => this.form.setErrors({ saveError: e.error })
      );
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    saveAttribute.value = this.form.get(control.getMasterKey()).value;
    return saveAttribute;
  }

  private getDeleteAttribute(control: DynamicInput): DynamicEntityAttribute {
    const deleteAttribute = this.getSaveAttribute(control);
    if (!deleteAttribute.isAdmin) {
      deleteAttribute.value = "";
      deleteAttribute.pageNumber = null;
      deleteAttribute.validationId = null;
      deleteAttribute.validationNote = "";
    }
    return deleteAttribute;
  }

  private isAvailableAttribute(attribute: DynamicInput): boolean {
    return attribute != null && attribute.saveInfo != null && NumberHelper.isGreaterThan(attribute.saveInfo.attributeId, 0);
  }

  private updateForm(state: RiskState): void {
    if (state.hasSelectedEncounterIndex) {
      this.selectedEncounter = state.selectedEncounter;
      const singleEntity = this.selectedEncounter;
      const encounter = this.automapper.map("RiskData", "Encounter", singleEntity);
      const chaseId = state.chaseId;
      const workflowStatusName = state.workflowStatusName.toLowerCase();
      const entityId = this.selectedEncounter.entityId;
      const entityTypeId = this.selectedEncounter.entityTypeId;
      const enableByMemberValidation = state.isEnabled;
      const enableMemberQuestions = enableByMemberValidation && state.isEncYes;
      const enableForm = enableMemberQuestions;
      const isAdminEncounter = RiskHelper.getAttribute("ClaimID", singleEntity).isAdmin;
      const enableServiceProvider = enableForm && this.isServiceProviderRequired;
      const enableEncounterType = enableForm && this.isEncounterTypeRequired;
      const disabled = !enableForm;

      this.setupAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled: true,
        disableControl: true,
        workflowStatusName,
        singleEntity,
        input: this.idInput,
        ATTRIBUTE_DATA: CLAIM_ID,
      });

      this.setupConfirmAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled: !enableByMemberValidation,
        disableControl: !enableByMemberValidation,
        workflowStatusName,
        singleEntity,
        input: this.encInput,
        ATTRIBUTE_DATA: ENCOUNTER_FOUND,
      });

      this.setupAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled,
        disableControl: !((enableForm && enableEncounterType) && !isAdminEncounter),
        workflowStatusName,
        singleEntity,
        input: this.encounterTypeInput,
        ATTRIBUTE_DATA: ENCOUNTER_TYPE,
      });
      this.encounterTypeInput.options = state.encounterTypes;
      this.encounterSaveGroup.isAdmin = this.encounterTypeInput.saveInfo.isAdmin;

      this.setupConfirmAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled: disabled || encounter.isBotSource,
        disableControl: !(enableForm && !isAdminEncounter),
        workflowStatusName,
        singleEntity,
        input: this.startDateInput,
        ATTRIBUTE_DATA: FROM_DATE,
      });

      this.startDateInput.hidden = !isAdminEncounter;
      this.startDateSaveGroup.hidden = isAdminEncounter;

      this.setupConfirmAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled: disabled || encounter.isBotSource,
        disableControl: !(enableForm && !isAdminEncounter),
        workflowStatusName,
        singleEntity,
        input: this.endDateInput,
        ATTRIBUTE_DATA: THRU_DATE,
      });

      this.endDateSaveGroup.hidden = isAdminEncounter;
      this.endDateInput.hidden = !isAdminEncounter;

      this.setupAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled,
        workflowStatusName,
        singleEntity,
        input: this.pageNumberInput,
        ATTRIBUTE_DATA: CHART_PAGE_NUMBER,
      });
      this.pageNumberGroup.hidden = isAdminEncounter;

      this.setupConfirmAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled,
        disableControl: !((enableForm && enableServiceProvider) || !isAdminEncounter),
        workflowStatusName,
        singleEntity,
        input: this.providerInput,
        ATTRIBUTE_DATA: PROVIDER_ID,
      });
      this.providerInput.placeholder = "Select...";
      this.providerInput.options = state.providers.map(this.automapper.curry("Provider", "SelectableInput"));

      this.providerSaveGroup.hidden = isAdminEncounter;
      this.providerInput.hidden = !isAdminEncounter;
    } else {
      this.form.reset();
      this.form.disable(); }
  }

  private updateFormWithProjectConfiguration({ projectConfiguration }: ChaseDetailState): void {
    if (projectConfiguration != null) {
      const { reviewPeriodFromDate, reviewPeriodThruDate } = projectConfiguration;
      const dateValidators = [dateBetweenValidator(reviewPeriodFromDate, reviewPeriodThruDate)];
      const datebetween = `The date must be between ${DateHelper.format(reviewPeriodFromDate)} - ${DateHelper.format(reviewPeriodThruDate)}`;

      this.form.get(this.startDateInput.getMasterKey()).setValidators(dateValidators);
      this.startDateInput.validators = dateValidators;
      this.startDateInput.errorMessages = {
        ...this.startDateInput.errorMessages,
        datebetween,
      };

      this.form.get(this.endDateInput.getMasterKey()).setValidators(dateValidators);
      this.endDateInput.validators = dateValidators;
      this.endDateInput.errorMessages = {
        ...this.endDateInput.errorMessages,
        datebetween,
      };

      this.serviceProviderRequired =
        StringHelper.isAvailable(projectConfiguration.serviceProviderRequired) ? projectConfiguration.serviceProviderRequired : "1";

      this.encounterTypeRequired =
        StringHelper.isAvailable(projectConfiguration.encounterTypeRequired) ? projectConfiguration.encounterTypeRequired : "1";
    }
  }

  private initializeForm(): void {
    // INPUTS
    const yesNoOptions = [
      new SelectableInput({ text: "Yes", value: "1" }),
      new SelectableInput({ text: "No", value: "0" }),
    ];

    this.encInput = new Dropdown({
      key: "enc",
      placeholder: "Yes/No",
      options: yesNoOptions,
      validators: [Validators.required],
      errorMessages: {
        required: "ENC is required",
      },
      disabled: true,
    });

    this.idInput = new Textbox({
      key: "encounterId",
      label: "Claim ID",
      isAdmin: true,
      validators: [Validators.required],
      errorMessages: {
        required: "Claim ID is required",
      },
      disabled: true,
    });

    this.pageNumberInput = new Textbox({
      key: "pageNumber",
      label: "Page Number",
      type: TextboxType.NUMBER,
      validators: [
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        min: "Enter a page number greater than 1",
        pattern: "The page number must be an integer",
      },
      disabled: true,
    });

    this.encounterTypeInput = new Dropdown({
      key: "encounterType",
      placeholder: "Select...",
      validators: [Validators.required],
      errorMessages: {
        required: "Encounter Type is required",
      },
      disabled: true,
    });

    this.startDateInput = new NoConfirm({
      key: "StartDate",
      label: "Service Date From",
      dataType: "date",
      hidden: true,
      disabled: true,
    });

    this.endDateInput = new NoConfirm({
      key: "EndDate",
      label: "Service Date Thru",
      dataType: "date",
      hidden: true,
      disabled: true,
    });

    this.providerInput = new NoConfirmProvider({
      key: "Provider",
      label: "Provider",
      hidden: true,
      disabled: true,
    });

    // SAVE GROUPS
    this.idSaveGroup = new SaveGroup({
      key: "idSave",
      controls: [this.idInput],
      isAdmin: true,
    });
    this.idInput.parent = this.idSaveGroup;

    this.pageNumberGroup = new SaveGroup({
      key: "pageNumberSave",
      controls: [this.pageNumberInput],
    });
    this.pageNumberInput.parent = this.pageNumberGroup;

    this.encSaveGroup = new SaveGroup({
      key: "EncSave",
      header: "Specific service dates found in document (ENC)?",
      controls: [this.encInput],
    });
    this.encInput.parent = this.encSaveGroup;

    this.encounterSaveGroup = new SaveGroup({
      key: "encounterSave",
      header: "Service Type",
      controls: [this.encounterTypeInput],
    });
    this.encounterTypeInput.parent = this.encounterSaveGroup;

    this.startDateSaveGroup = new SaveGroup({
      key: "StartDateSave",
      header: "Service Start Date",
      controls: [this.startDateInput],
    });
    this.startDateInput.parent = this.startDateSaveGroup;

    this.endDateSaveGroup = new SaveGroup({
      key: "EndDateSave",
      header: "Service End Date",
      controls: [this.endDateInput],
    });
    this.endDateInput.parent = this.endDateSaveGroup;

    this.providerSaveGroup = new SaveGroup({
      key: "ProviderSave",
      header: "Provider",
      controls: [this.providerInput],
    });
    this.providerInput.parent = this.providerSaveGroup;


    // CREATE FORM
    this.form = this.formService.createFormGroup([
      this.encSaveGroup,
      this.idSaveGroup,
      this.encounterSaveGroup,
      this.startDateSaveGroup,
      this.endDateSaveGroup,
      this.providerSaveGroup,
      this.pageNumberGroup,
    ]);
  }

  private setupConfirmAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    entityId,
    entityTypeId,
    disabled,
    disableControl,
    workflowStatusName,
  }: any): void {
    const attributes = RiskHelper.getAttributes(ATTRIBUTE_DATA.attributeCode, singleEntity);
    const adminAttribute = attributes.find(a => a.isAdmin) || {} as any;
    const attribute = {
      ...attributes.find(a => !a.isAdmin),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId,
      entityTypeId,
    };

    attribute.value = disableControl ? adminAttribute.value : attribute.value;

    input.saveInfo = attribute;
    input.value = attribute.value;
    input.readonly = disableControl;
    input.adminValue = adminAttribute.value;
    input.disabled = disabled;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    disabled ? control.disable() : control.enable();
  }

  private setupAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    entityId,
    entityTypeId,
    disabled,
    disableControl,
    workflowStatusName,
  }: any): void {
    const attribute = {
      ...RiskHelper.getAttribute(ATTRIBUTE_DATA.attributeCode, singleEntity),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId,
      entityTypeId,
    };

    input.saveInfo = attribute;
    input.value = attribute.value;
    input.disabled = disabled;
    input.readonly = disableControl;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    disabled ? control.disable() : control.enable();
  }

  private isChangingEncToNo(attributes: DynamicEntityAttribute[]): boolean {
    const isChangingEncToNo = (attributes.find(a => a.attributeId === ENCOUNTER_FOUND.attributeId) || {} as any).value === "0";
    return isChangingEncToNo;
  }
}
