import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component,  OnDestroy, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { combineLatest } from "rxjs";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../../core/automapper/automapper.service";
import { DynamicFormEvent } from "../../../../../../../dynamic-forms/dynamic-form-event.model";
import { SaveGroup } from "../../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { DynamicInput } from "../../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { ProviderDropdown } from "../../../../../../../dynamic-forms/inputs/provider-dropdown/provider-dropdown.model";
import { TextboxType } from "../../../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { VrcDropdown } from "../../../../../../../dynamic-forms/inputs/vrc-dropdown/vrc-dropdown.model";
import { dateBetweenValidator } from "../../../../../../../dynamic-forms/validators/date-between.validator";
import { GenericAsyncValidator } from "../../../../../../../dynamic-forms/validators/generic.async-validator";
import { DocumentViewerSessionService } from "../../../../../../../shared/document/document-page-viewer/document-viewer-session.service";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../../utilities/reg-Ex-Helper";
import { DynamicEntityAttribute } from "../../../../../../api/member-validation/dynamic-entity-attribute.model";
import { ChaseDetailState } from "../../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../../chase-detail-state.service";
import { CHART_PAGE_NUMBER, DIAGNOSIS_CODE, FROM_DATE, PROVIDER_ID, THRU_DATE, VALIDATION_REASON_CODES } from "../../attributes";
import { RiskEntity } from "../risk-entity.model";
import { RiskHelper } from "../risk-helper.model";
import { RiskState } from "../risk-state.model";
import { RiskService } from "../risk.service";
import { Diagnosis } from "./diagnosis.model";
import { DiseaseDetail } from "./disease-detail.model";
import { IcdService } from "./icd.service";

@Component({
  selector: "member-risk-diagnosis",
  templateUrl: "./diagnosis.component.html",
  styleUrls: ["./diagnosis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosisComponent implements OnInit, OnDestroy {
  private sink = new SubSink();

  private chaseId: number;
  selectedDiagnosis: RiskEntity;
  hasSelectedDiagnosis: boolean;

  form: FormGroup;
  saveGroup: SaveGroup;
  private pageNumberInput: Textbox;
  private startDateInput: Textbox;
  private endDateInput: Textbox;
  private icdCodeInput: Autocomplete;
  private providerInput: ProviderDropdown;
  private vrcInput: VrcDropdown;

  diseaseDetails: DiseaseDetail[];
  diagnoses: Diagnosis[];
  VRC50or51Regex = /\b50\b|\b51\b/;
  VRC50or51orEmptyStringRegex = /\b50\b|\b51\b|^$/;

  isVRCWarningVisible = false;
  projectConfigurationVrcRequired = "";
  projectConfigurationOmissionCodeEnabled = "";
  serviceProviderRequired = "";
  currentIcdDescription = "";

  constructor(
    private readonly riskService: RiskService,
    private readonly formService: FormService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly automapper: AutomapperService,
    private genericAsyncValidator: GenericAsyncValidator,
    private chaseDetailStateService: ChaseDetailStateService,
    private documentViewerSessionService: DocumentViewerSessionService,
    private readonly icdService: IcdService
  ) { }

  ngOnInit() {
    this.initializeForm();

    this.sink.add(
      combineLatest([
        this.riskService.data,
        this.chaseDetailStateService.state,
      ]).subscribe(([riskState, chaseDetailState]: any) => {
        this.chaseId = riskState.chaseId;
        this.hasSelectedDiagnosis = riskState.hasSelectedDiagnosisIndex;
        this.updateFormWithProjectConfiguration(chaseDetailState);
        this.updateForm(riskState);
        const diagnosisEntities = riskState.hasSelectedEncounterIndex ? riskState.selectedEncounter.diagnoses : [];
        this.diagnoses = this.automapper.mapMany("RiskData", "Diagnosis", diagnosisEntities);
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  handleChange(event: DynamicFormEvent): void {
    switch (event.type) {
      case "save":
        this.saveAttributesByInputs(event.model.controls);
        break;
      case "clear":
      case "delete":
        if (!this.formService.isKeyDownEnter(event.originalEvent)) {
          this.deleteAttributesByInputs(event.model.controls);
        }
        break;
      default:
      case "compliance":
        break;
    }
  }

  get hasDiseaseDetails(): boolean {
    return ArrayHelper.isAvailable(this.diseaseDetails);
  }

  get icdDescription(): string {
    if (this.hasDiseaseDetails && this.hasSelectedDiagnosis) {
      const firstHcc = this.diseaseDetails[0];
      if (this.currentIcdDescription !== `${firstHcc.icdCode} - ${firstHcc.diseaseName}`) {
        this.documentViewerSessionService.updateSelectedIcdDescription(`${firstHcc.icdCode} - ${firstHcc.diseaseName}`);
      }

      this.currentIcdDescription = `${firstHcc.icdCode} - ${firstHcc.diseaseName}`;
      return this.currentIcdDescription;
    } else {
      return "";
    }
  }

  get hccs(): DiseaseDetail[] {
    const hccs = this.hasDiseaseDetails && this.hasSelectedDiagnosis ? this.diseaseDetails.filter(a => StringHelper.isAvailable(a.categoryCode)) : [];
    return hccs;
  }

  get hasHccs(): boolean {
    return ArrayHelper.isAvailable(this.hccs);
  }

  get diagnosisHasVRC50or51(): boolean {
    return this.diagnoses.some(diagnosis => this.VRC50or51Regex.test(diagnosis.vrcs));
  }

  get doesAllDiagnosisHaveVRC50or51orEmptyString(): boolean {
    return this.diagnoses.every(diagnosis => this.VRC50or51orEmptyStringRegex.test(diagnosis.vrcs));
  }

  get isvrcRequired(): boolean {
    return this.projectConfigurationVrcRequired === "1";
  }

  get enableOmissionCode(): boolean {
    return this.projectConfigurationOmissionCodeEnabled === "1";
  }

  get isServiceProviderRequired(): boolean {
    return this.serviceProviderRequired === "1";
  }

  private updateForm(state: RiskState): void {
    if (this.hasSelectedDiagnosis) {
      this.selectedDiagnosis = state.selectedDiagnosis;
      const singleEntity = this.selectedDiagnosis;
      const diagnosis = this.automapper.map("RiskData", "Diagnosis", singleEntity);
      const chaseId = state.chaseId;
      const workflowStatusName = state.workflowStatusName.toLowerCase();
      const entityId = this.selectedDiagnosis.entityId;
      const entityTypeId = this.selectedDiagnosis.entityTypeId;
      const parentEntityId = this.selectedDiagnosis.parentEntityId;
      const disabled = !state.isEnabled || !state.isSelectedEncounterValid;

      this.setupAttribute({
        chaseId,
        entityId,
        parentEntityId,
        entityTypeId,
        disabled: disabled || diagnosis.isBotSource,
        workflowStatusName,
        singleEntity,
        input: this.pageNumberInput,
        ATTRIBUTE_DATA: CHART_PAGE_NUMBER,
      });

      this.setupAttribute({
        chaseId,
        entityId,
        parentEntityId,
        entityTypeId,
        disabled: disabled || diagnosis.isBotSource,
        workflowStatusName,
        singleEntity,
        input: this.startDateInput,
        ATTRIBUTE_DATA: FROM_DATE,
      });

      this.setupAttribute({
        chaseId,
        entityId,
        parentEntityId,
        entityTypeId,
        disabled: disabled || diagnosis.isBotSource,
        workflowStatusName,
        singleEntity,
        input: this.endDateInput,
        ATTRIBUTE_DATA: THRU_DATE,
      });

      this.setupAttribute({
        chaseId,
        entityId,
        parentEntityId,
        entityTypeId,
        disabled: disabled || diagnosis.isBotSource,
        workflowStatusName,
        singleEntity,
        input: this.icdCodeInput,
        ATTRIBUTE_DATA: DIAGNOSIS_CODE,
      });

      this.icdService
        .getIcds(chaseId, this.icdCodeInput.value, this.startDateInput.value as string)
        .subscribe(
          result => {
            this.icdCodeInput.options = result;
            // HACK: If the query returns 1 value then select as the value.
            const firstOption = result.length === 1 ? result[0] : null;
            if (firstOption != null && firstOption.value === this.icdCodeInput.value) {
              const icdCodeControl = this.form.get(this.icdCodeInput.getMasterKey());
              icdCodeControl.setValue(firstOption);
            }

            this.changeDetector.markForCheck();
          }
        );

      this.setupAttribute({
        chaseId,
        entityId,
        parentEntityId,
        entityTypeId,
        disabled,
        workflowStatusName,
        singleEntity,
        input: this.providerInput,
        ATTRIBUTE_DATA: PROVIDER_ID,
      });

      const attribute = {
        ...RiskHelper.getAttribute(DIAGNOSIS_CODE.attributeCode, singleEntity),
        ...DIAGNOSIS_CODE,
        chaseId,
        entityId,
        entityTypeId,
        parentEntityId,
      };
      this.providerInput.options = state.providers.map(this.automapper.curry("Provider", "SelectableInput"));
      this.providerInput.disabled = disabled;
      const control = this.form.get(this.providerInput.getMasterKey());
      this.providerInput.disabled ? control.disable() : control.enable();

      this.setupAttribute({
        chaseId,
        entityId,
        parentEntityId,
        entityTypeId,
        disabled,
        workflowStatusName,
        singleEntity,
        input: this.vrcInput,
        hidden: !this.isvrcRequired,
        ATTRIBUTE_DATA: VALIDATION_REASON_CODES,
      });

      if (!ArrayHelper.isAvailable(this.vrcInput.options)) {
        this.vrcInput.options = state.vrcs.filter(x => x.extra.isActive !== false);
      }

      setTimeout(() => this.getHccs());
    }
  }

  private updateFormWithProjectConfiguration({ projectConfiguration }: ChaseDetailState): void {
    if (projectConfiguration != null) {
      const { reviewPeriodFromDate, reviewPeriodThruDate } = projectConfiguration;
      const dateValidators = [
        Validators.required,
        dateBetweenValidator(reviewPeriodFromDate, reviewPeriodThruDate),
      ];
      const datebetween = `The date must be between ${DateHelper.format(reviewPeriodFromDate)} - ${DateHelper.format(reviewPeriodThruDate)}`;

      this.form.get(this.startDateInput.getMasterKey()).setValidators(dateValidators);
      this.startDateInput.validators = dateValidators;
      this.startDateInput.errorMessages = {
        ...this.startDateInput.errorMessages,
        datebetween,
      };

      this.form.get(this.endDateInput.getMasterKey()).setValidators(dateValidators);
      this.endDateInput.validators = dateValidators;
      this.endDateInput.errorMessages = {
        ...this.endDateInput.errorMessages,
        datebetween,
      };

      this.projectConfigurationVrcRequired =
        StringHelper.isAvailable(projectConfiguration.validationReasonCodeRequired) ? projectConfiguration.validationReasonCodeRequired : "1";

      this.projectConfigurationOmissionCodeEnabled =
        StringHelper.isAvailable(projectConfiguration.omissionCodeEnabled) ? projectConfiguration.omissionCodeEnabled : "0";

      this.serviceProviderRequired =
        StringHelper.isAvailable(projectConfiguration.serviceProviderRequired) ? projectConfiguration.serviceProviderRequired : "1";

    }
  }

  private initializeForm(): void {
    // INPUTS
    this.pageNumberInput = new Textbox({
      key: "pageNumber",
      label: "Page Number",
      type: TextboxType.NUMBER,
      validators: [
        Validators.required,
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        required: "Enter a page number greater than 1",
        min: "Enter a page number greater than 1",
        pattern: "The page number must be an integer",
      },
    });

    this.startDateInput = new Textbox({
      key: "startDate",
      label: "Date From",
      type: TextboxType.TEXT,
      dataType: "date",
      validators: [
        Validators.required,
      ],
      asyncValidators: [this.genericAsyncValidator.validate.bind(this.genericAsyncValidator)],
      errorMessages: {
        required: "Date From is required",
      },
    });

    this.endDateInput = new Textbox({
      key: "endDate",
      label: "Date Thru",
      type: TextboxType.TEXT,
      dataType: "date",
      validators: [
        Validators.required,
      ],
      asyncValidators: [this.genericAsyncValidator.validate.bind(this.genericAsyncValidator)],
      errorMessages: {
        required: "Date Thru is required",
      },
    });

    this.icdCodeInput = new Autocomplete({
      key: "Icd",
      label: "ICD",
      searchMinimum: 2,
      serverFilter: (query, setOptionsFn) =>
        this.icdService.getIcds(this.chaseId, query, this.startDateInput.value as string).subscribe(setOptionsFn),
      validators: [Validators.required],
      errorMessages: {
        required: "ICD Code is required",
        invalidicd: "Invalid ICD Code",
      },
    });

    this.providerInput = new ProviderDropdown({
      key: "Provider",
      label: "Provider",
      placeholder: "Select...",
    });

    this.vrcInput = new VrcDropdown({
      key: "vrc",
      label: "VRC",
      placeholder: "Select...",
    });

    // SAVE GROUPS
    this.saveGroup = new SaveGroup({
      key: "Diagnosis",
      isHideClearButton: true,
      controls: [
        this.pageNumberInput,
        this.startDateInput,
        this.endDateInput,
        this.icdCodeInput,
        this.providerInput,
        this.vrcInput,
      ],
    });
    this.pageNumberInput.parent = this.saveGroup;
    this.startDateInput.parent = this.saveGroup;
    this.endDateInput.parent = this.saveGroup;
    this.icdCodeInput.parent = this.saveGroup;
    this.providerInput.parent = this.saveGroup;
    this.vrcInput.parent = this.saveGroup;

    // CREATE FORM
    this.form = this.formService.createFormGroup([this.saveGroup]);
  }

  private saveAttributesByInputs(controls: DynamicInput[]): void {
    const attributes = controls
      .filter(this.isAvailableAttribute.bind(this))
      .map(this.getSaveAttribute.bind(this)) as DynamicEntityAttribute[];
    this.saveAttributes(attributes);
  }

  private deleteAttributesByInputs(controls: DynamicInput[]): void {
    const allControlsDisabled = controls.every(a => a.disabled);
    if (!allControlsDisabled) {
      const attributesToDelete = controls
        .filter(this.isAvailableAttribute.bind(this))
        .map(this.getDeleteAttribute.bind(this)) as DynamicEntityAttribute[];
      this.saveAttributes(attributesToDelete);
    }
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]) {
    this.riskService
      .save(attributes)
      .subscribe(
        newAttributes => {
          this.riskService.setDiagnosisAttributes(this.riskService.data.value.selectedDiagnosisIndex, newAttributes);
          this.isVRCWarningVisible = !this.checkForVRC50or51();
        },
        (e: HttpErrorResponse) => this.form.setErrors({ saveError: e.error })
      );
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    const formInput = this.form.get(control.getMasterKey());
    saveAttribute.value = saveAttribute.attributeId === DIAGNOSIS_CODE.attributeId ? formInput.value.value : formInput.value;
    return saveAttribute;
  }

  private getDeleteAttribute(control: DynamicInput): DynamicEntityAttribute {
    const deleteAttribute = this.getSaveAttribute(control);
    if (!deleteAttribute.isAdmin) {
      deleteAttribute.value = "";
      deleteAttribute.pageNumber = null;
      deleteAttribute.validationId = null;
      deleteAttribute.validationNote = "";
    }
    return deleteAttribute;
  }

  private isAvailableAttribute(attribute: DynamicInput): boolean {
    return attribute != null
      && attribute.saveInfo != null
      && NumberHelper.isGreaterThan(attribute.saveInfo.attributeId, 0)
      && !attribute.disabled;
  }

  private getHccs(): void {
    const chaseId = this.chaseId;
    const icdCode = (this.form.get(this.icdCodeInput.getMasterKey()) || {} as any).value;
    const dateOfService = (this.form.get(this.endDateInput.getMasterKey()) || {} as any).value;

    if (NumberHelper.isGreaterThan(chaseId, 0) && StringHelper.isAvailable(icdCode) && DateHelper.isAvailable(dateOfService)) {
      this.riskService.getDiseaseDetails(chaseId, icdCode, dateOfService)
        .subscribe(diseaseDetails => {
          this.diseaseDetails = diseaseDetails;

          // HACK: Should this be an async validator? This way only one call is made.
          if (!this.hasDiseaseDetails) {
            const icdCodeControl = this.form.get(this.icdCodeInput.getMasterKey());
            icdCodeControl.setErrors({
              invalidicd: true,
            });
            icdCodeControl.markAsTouched();
            this.formService.updateDom.next();
          }

          this.changeDetector.markForCheck();
        });
    } else {
      this.diseaseDetails = [];
      this.changeDetector.markForCheck();
    }
  }

  private setupAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    entityId,
    parentEntityId,
    entityTypeId,
    disabled,
    workflowStatusName,
    hidden = false,
  }: any): void {
    const attribute = {
      ...RiskHelper.getAttribute(ATTRIBUTE_DATA.attributeCode, singleEntity),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId,
      entityTypeId,
      parentEntityId,
    };

    this.updateAttributeBasedOnProjectConfiguration(attribute);

    input.hidden = hidden;
    input.saveInfo = attribute;
    input.value = attribute.value;
    input.disabled = disabled || attribute.isAdmin;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    input.disabled ? control.disable() : control.enable();
  }

  private updateAttributeBasedOnProjectConfiguration(attribute: DynamicEntityAttribute): DynamicEntityAttribute {
    if (!this.isvrcRequired && attribute.attributeCode === VALIDATION_REASON_CODES.attributeCode) {
      attribute.value = "01";
    }
    return attribute;
  }

  private checkForVRC50or51(): boolean {
    if (ArrayHelper.isAvailable(this.diagnoses)) {
      return this.diagnosisHasVRC50or51 ? this.doesAllDiagnosisHaveVRC50or51orEmptyString : true;
    } else {
      return true;
    }
  }

  trackByIndex(index, item) {
    return index;
  }
}
